<template>
  <div>
    <div
        v-if="$route.name === name"
    >
      <v-row v-if="routes.length > 0" no-gutters justify="center" justify-md="start">
        <v-btn
            v-for="(item, i) in routes"
            :key="i"
            :to="{name: item, query: {number: number, page: page}}"
            color="white"
            class="shadow"
        >
          <div class="text-center">
            <Icon :name="item" size="45" class="primary--text mb-3"/>
            {{ t[item] }}
          </div>
        </v-btn>
      </v-row>
    </div>
    <router-view v-else/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Icon from "@/components/svg/Icon";

export default {
  name: "DefaultPageMenu",
  components: {Icon},
  computed: {
    ...mapGetters(["number", "page"]),
    t() {
      return Object.assign(this.$store.state.t[this.$route.params.language]['menu'])
    }
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    routes: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style scoped lang="scss">
.v-btn {
  min-width: 300px !important;
  height: 200px !important;
  margin: 12px !important;
  font-size: 18px !important;

  &:hover {
    background-color: $primary !important;
    color: white !important;

    * {
      color: white !important;
    }
  }
}
</style>
