<template>
  <PagesAuthorizationLogin/>
</template>

<script>
import PagesAuthorizationLogin from "@/components/pages/authorization/PagesAuthorizationLogin";

export default {
  name: "login",
  components: {PagesAuthorizationLogin}
}
</script>
