<template>
  <div>
    <v-sheet class="border mx-auto" width="100%" max-width="1024"> <!-- :width="edit ? '100%' : 1024" -->
      <v-sheet tile min-height="60"
               class="px-5 px-md-10 py-4 d-flex flex-column flex-sm-row justify-space-between align-center">
        <LogoDark class="logo"/>
        <div>
          <!--            {{$store.state.t[$route.params.language].project_doc.doc_created_at + ': ' + date}}-->
          {{ date.slice(0, -5) }}
        </div>
      </v-sheet>
    </v-sheet>
    <slot/>
    <v-sheet class="border mx-auto" width="100%" max-width="1024">
      <v-sheet tile min-height="80" class="px-8 px-md-10 py-2 grid">
        <div class="font-weight-bold">
          <LogoDoc/>
          <span>
            VuokraHyva
          </span>
        </div>
        <div class="text-md-center">
          <v-icon>mdi-phone</v-icon>
          +358 400 515 824
        </div>
        <div class="text-md-end">
          Y-tunnus: 3088041-6
        </div>
        <div>
          Päivärinteenpolku 4 E 04600 Mäntsälä
        </div>
        <div class="text-md-center">
          <v-icon>mdi-at</v-icon>
          toimisto@vuokrahyva.fi
        </div>
        <div class="text-md-end">
          Kotipaikka: Mäntsälä
        </div>
      </v-sheet>
    </v-sheet>

    <div class="print print_container" :style="loadingAnimation ? 'opacity: 1; overflow: scroll;' : 'opacity: 0; overflow: hidden;'">
      <v-card class="print_doc" width="800" min-width="800">
        <v-card id="pdf_header" flat tile min-height="60">
          <div class="px-10 py-4 d-flex justify-space-between align-center">
            <LogoDark class="logo"/>
            {{ date.slice(0, -5) }}
          </div>
          <v-divider/>
        </v-card>
        <v-card id="target" flat tile width="800" min-width="800">
          <slot name="doc"/>
        </v-card>
        <v-card flat tile id="pdf_footer">
          <v-divider/>
          <v-card flat tile min-height="60" class="px-10 py-2 print_grid_footer">
            <div class="font-weight-bold d-flex align-center">
              <LogoDoc/>
              <span class="ml-1">
                VuokraHyva
              </span>
            </div>
            <div class="text-center">
              <v-icon>mdi-phone</v-icon>
              +358 400 515 824
            </div>
            <div class="text-end">
              Y-tunnus: 3088041-6
            </div>
            <div>
              Päivärinteenpolku 4 E 04600 Mäntsälä
            </div>
            <div class="text-center">
              <v-icon>mdi-at</v-icon>
              toimisto@vuokrahyva.fi
            </div>
            <div class="text-end">
              Kotipaikka: Mäntsälä
            </div>
          </v-card>
        </v-card>


      </v-card>

    </div>


  </div>
</template>

<script>
import LogoDark from "components/svg/LogoDark";
import LogoXS from "components/svg/LogoXS";
import LogoDoc from "components/svg/LogoDoc";
import {mapGetters} from "vuex";

export default {
  name: "PagesOrdersProjectDocHeader",
  props: {
    date: {
      type: String,
      default: ''
    },
    edit: Boolean
  },
  components: {LogoDoc, LogoXS, LogoDark},
  computed: {
    ...mapGetters(["loadingAnimation"])
  }
}
</script>

<style scoped lang="scss">
.doc {
  max-width: 100%;
  //overflow-x: scroll;
  //-ms-overflow-x: scroll;

  font-family: 'Gotham Pro' !important;
}

.logo {
  min-height: 32px;
}

.grid {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 10px;
  font-size: 14px !important;
  padding-bottom: 12px !important;


  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

}

.print {

  ::v-deep div {
    font-size: 14px !important;
  }

  &_container {
    position: relative;
    height: 5px;
    width: 5px;
  }

  &_doc {
    position: absolute;
    top: 0;
    left: 0;
  }

  &_grid {


    &_footer {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      grid-gap: 5px;
      padding-bottom: 4px !important;

      div {
        font-size: 12px !important;
      }
    }
  }
}

</style>
