<template>
  <div class="d-flex align-center number">
    <v-menu offset-y tile open-on-hover>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            tile
            :text="attrs['aria-expanded'] === 'false'"
            :dark="attrs['aria-expanded'] === 'true'"
            v-bind="attrs"
            v-on="on"
            class="fs"
        >
          <div v-for="(item, i) in items" :key="i" class="d-flex align-center">
            <span v-if="item.number == number" class="fs">
            {{ item.title }}
          </span>
          </div>
          <v-icon class="ml-1">mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <div v-for="(item, i) in items" :key="i">
          <v-list-item v-if="item.number != number" link @click="changeNumber(item.number)">
            <v-list-item-title class="fs">{{ item.title }}</v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
    </v-menu>
    <span class="fs from">
      {{ $store.state.t[this.$route.params.language]['page_toolbar']['from'] }} {{ total ? total : total_temp }}
    </span>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "DefaultPageNumber",
  props: {
    total: {
      type: [Number, String],
      default: 0
    }
  },
  data: () => ({
    items: [],
    total_temp: 0
  }),
  computed: {
    ...mapGetters(["number", "page"]),
  },
  watch: {
    total() {
      if (this.total > 0) {
        this.total_temp = this.total
      }
    }
  },
  created() {
    this.items = [
      {title: this.$store.state.t[this.$route.params.language].page_toolbar.all, number: 0},
      {title: '10', number: 10},
      {title: '20', number: 20},
      {title: '50', number: 50},
      {title: '100', number: 100},
    ];
    if (!this.items.filter(i => i.number === Number(this.number))[0]) {
      this.items.push({title: this.number, number: Number(this.number)})
    }
  },
  methods: {
    changeNumber(item) {
      this.items = [
        {title: this.$store.state.t[this.$route.params.language].page_toolbar.all, number: 0},
        {title: '10', number: 10},
        {title: '20', number: 20},
        {title: '50', number: 50},
        {title: '100', number: 100},
      ];
      this.$store.commit("SetNumber", item);
      this.$router.push({query: {number: item, page: this.page}}).catch(() => {
      });
    }
  }
}
</script>

<style scoped lang="scss">
.number {
  .fs {
    font-size: 13px !important;
    //color: #2C2C2C !important;
  }

  .from {
    width: 50px !important;
    text-align: center;
  }

  .v-btn {
    padding: 0 4px !important;
  }

  .theme--dark.v-btn.v-btn--has-bg {
    background-color: var(--v-primary-base) !important;
  }

  .theme--dark.v-btn:focus::before {
    opacity: 0 !important;
  }
}

.v-list-item {
  min-height: 36px !important;
  height: 36px !important;
}
</style>
