<template>
  <DefaultPageToolbar
      :total="list.meta.total"
      :last_page="list.meta.last_page"
      :create-text="$store.state.t[this.$route.params.language]['page_toolbar']['add_author']"
      :on-create="openNewItem"
  >
    <DefaultPageTable
        :title="$store.state.t[this.$route.params.language]['menu']['authors']"
        :data="list.data"
        :headers="headers"
        :on-active="changeActive"
        :on-edit="editItem"
        :on-delete="askDeleteItem"
        :ask-delete-fields="['name']"
    />
    <v-dialog v-model="modalNewItem" width="600">
      <PagesBlogAuthor v-if="modalNewItem" :on-save="createItem" :on-close="closeModals"/>
    </v-dialog>
    <v-dialog v-model="modalEditItem" width="600">
      <PagesBlogAuthor v-if="modalEditItem" :on-item="getItem" :on-save="saveItem" :on-close="closeModals" />
    </v-dialog>
    <v-dialog v-model="modalAskDelete" width="600">
      <DefaultPageAskDelete
          text="author"
          :name="name"
          :on-close="closeModals"
          :on-delete="deleteItem"
      />
    </v-dialog>
  </DefaultPageToolbar>
</template>

<script>
import {mapActions} from "vuex";
import DefaultPageToolbar from "@/components/default/page/DefaultPageToolbar";
import DefaultPageTable from "components/default/page/DefaultPageTable";
import PagesBlogAuthor from "components/pages/blog/PagesBlogAuthor";
import DefaultPageAskDelete from "components/default/page/DefaultPageAskDelete";

export default {
  name: "authors",
  components: {DefaultPageAskDelete, PagesBlogAuthor, DefaultPageTable, DefaultPageToolbar},
  data: () => ({
    list: {
      meta: {
        total: 0
      }
    },
    id: 0,
    name: '',
    modalNewItem: false,
    modalEditItem: false,
    modalAskDelete: false
  }),
  computed: {
    headers() {
      return [
        {
          value: ["name"],
          text: this.$store.state.t[this.$route.params.language]['fields']['author_name']
        },
        {
          value: ["articles_number"],
          text: this.$store.state.t[this.$route.params.language]['fields']['articles_count']
        }
      ]
    }
  },
  watch: {
    '$route.query'() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    ...mapActions("author", ["onAuthorList", "onAuthorCreate", "onAuthor", "onAuthorSave", "onAuthorDelete", "onAuthorFieldSave"]),
    async getList() {
      this.list = {meta: {}}
      await this.onAuthorList().then(res => {
        this.list = res.data;
      })
    },
    closeModals() {
      this.modalNewItem = false;
      this.modalEditItem = false;
      this.modalAskDelete = false;
    },
    openNewItem() {
      this.modalNewItem = true;
    },
    askDeleteItem(id, name) {
      this.id = id;
      this.name = name;
      this.modalAskDelete = true;
    },
    changeActive(id, state) {
      this.onAuthorFieldSave({id: id, data: {
          field: 'is_active',
          value: state
        }})
    },
    async deleteItem() {
      await this.onAuthorDelete(this.id).then(() => {
        this.getList();
        this.closeModals()
      })
    },
    editItem(id) {
      this.id = id;
      this.modalEditItem = true
    },
    async saveItem(data) {
      await this.onAuthorSave({id: data.id, data: data}).then(()=> {
        this.getList();
      })
    },
    async getItem() {
      console.log('id: ' + this.id)
      await this.onAuthor(this.id).then(res => {
        console.log(res.data)
      })
    },
    async createItem(data) {
      await this.onAuthorCreate(data).then(()=> {
        this.getList();
        this.closeModals()
      })
    }
  }
}
</script>
