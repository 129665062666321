import axios from "axios";

const state = {
  authLoggedIn: false,
  authCheckEmail: []
};

const getters = {
  authLoggedIn(state) {
    return state.authLoggedIn;
  },
  authCheckEmail(state) {
    return state.authCheckEmail;
  }
};

const mutations = {
  setAuthLoggedIn: (state, item) => {
    state.authLoggedIn = item;
  },
  setAuthCheckEmail: (state, item) => {
    state.authCheckEmail = item;
  }
};

const actions = {
  onAuthIsLoggedIn({ commit }) {
    if (localStorage.getItem("token")) {
      commit("setAuthLoggedIn", true);
    } else if (sessionStorage.getItem("token")) {
      commit("setAuthLoggedIn", true);
    } else {
      commit("setAuthLoggedIn", false);
    }
  },

  async onAuthLogin({ commit }, { data, remember }) {
    let url = this.state.apiDomain + "/oauth/token";
    await axios
      .post(url, data)
      .then(response => {
        console.log(response);
        if (remember) {
          localStorage.setItem("token", response.data.access_token);
          localStorage.setItem("token_type", response.data.token_type);
        } else {
          sessionStorage.setItem("token", response.data.access_token);
          sessionStorage.setItem("token_type", response.data.token_type);
        }
        axios.defaults.headers.common["Authorization"] =
          response.data.access_token;
        commit("setAuthLoggedIn", true);
      })
      .catch(error => {
        console.log(error);
      });
  },

  async onCheckEmail({commit}, data) {
    let url = this.state.apiDomain + "/api/auth/check-email";
    await axios
      .post(url, data)
      .then(response => {
        console.log(response);
        commit("setAuthCheckEmail", response)
      })
      .catch(error => {
        console.log(error);
        commit("setAuthCheckEmail", error)
      })
  },

  // eslint-disable-next-line no-unused-vars
  onAuthConfirm({commit}, data) {
    return new Promise((resolve, reject) => {
      let url = this.state.apiDomain + "/api/auth/confirm";
      axios
        .post(url, data)
        .then(response => {
          commit("setAuthCheckEmail", response)
          resolve(response)
        })
        .catch(error => {
          commit("setAuthCheckEmail", error)
          reject(error)
        })
      })
  },

  onAuthLogout() {
    localStorage.clear();
    sessionStorage.clear();
  },

  // eslint-disable-next-line no-unused-vars
  async onAuthRestorePassword({commit}, email){
    return new Promise((resolve, reject) => {
    let url = this.state.apiDomain + "/api/auth/restore";
    axios
      .post(url, {email: email})
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
    })
  },

  // eslint-disable-next-line no-unused-vars
  async onAuthConfirmRestore({commit}, data){
    return new Promise((resolve, reject) => {
      let url = this.state.apiDomain + "/api/auth/confirm-restore";
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    })
  },

  // eslint-disable-next-line no-unused-vars
  async onAuthRegisterUser({commit}, data){
    return new Promise((resolve, reject) => {
      let url = this.state.apiDomain + "/api/auth/register";
      axios
          .post(url, data)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
    })
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
