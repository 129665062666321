import { render, staticRenderFns } from "./CrossMark.vue?vue&type=template&id=457fe880&scoped=true&"
import script from "./CrossMark.vue?vue&type=script&lang=js&"
export * from "./CrossMark.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "457fe880",
  null
  
)

export default component.exports