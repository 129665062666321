<template>
  <div>
    <PagesClientPhysId v-if="item.id" :data="item" :on-save="saveItem" :edit="$route.name === 'phys_id_edit'"/>
    <DefaultPageToolbar
        :total="Number(list.amount)"
        :last_page="list.lastPage"
        :create-text="$store.state.t[this.$route.params.language]['page_toolbar']['open_orders']"
        :on-create="openList"
        class="mt-5"
        none_icon
    >
      <DefaultPageTable
          :title="$store.state.t[this.$route.params.language]['menu']['orders']"
          :data="list.data"
          :headers="headers"
      />
    </DefaultPageToolbar>

  </div>
</template>

<script>
import PagesClientPhysId from "components/pages/clients/PagesClientPhysId";
import {mapActions} from "vuex";
import DefaultPageTable from "components/default/page/DefaultPageTable";
import DefaultPageToolbar from "components/default/page/DefaultPageToolbar";

export default {
  name: "phys_id",
  components: {DefaultPageToolbar, DefaultPageTable, PagesClientPhysId},
  data: () => ({
    item: {},
    list: {}
  }),
  computed: {
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['menu']
      )
    },
    headers() {
      return [
        {
          value: ["service"],
          text: this.$store.state.t[this.$route.params.language]['fields']['product']
        },
        {
          value: ["status"],
          text: this.$store.state.t[this.$route.params.language]['fields']['status']
        }
      ]
    }
  },
  mounted() {
    this.getItem()
    this.getList()
  },
  methods:{
    ...mapActions("user", ["onUser", "onUserSave"]),
    ...mapActions("order", ["onOrderList", "onDeleteOrder"]),

    async getItem() {
      await this.onUser(this.$route.params.id).then(res => {
        this.item = Object.assign(res.data)
        this.item.files = JSON.parse(this.item.files)
        while (typeof this.item.data === 'string') {
          this.item.data = JSON.parse(this.item.data)
        }
        if (!this.item.files) {
          this.item.files = []
        }
      })
    },
    async saveItem(data) {
      let sendData = Object.assign({}, data)
      sendData.data = JSON.stringify(sendData.data)
      sendData.files = JSON.stringify(sendData.files)
      await this.onUserSave(sendData)
      // .then(() => {
      //   // this.closeModals()
      // })
    },

    async getList() {
      this.list = {}
      await this.onOrderList({type: "order", user_id: this.$route.params.id }).then(res => {
        this.list = res.data;
        for (let i in this.list.data) {
          this.transformList(this.list.data[i]);
        }
      })
    },
    transformList(data) {
      data.service = data.service.title;
      if (data.status === 'new') {
        data.status = [{
          value: data.status,
          color: '#25C565',
          type: 'btn'
        }]
      } else data.status = [{
        value: data.status,
        color: '#F68A2A',
        type: 'btn'
      }];
      data.price = data.price + ' €';
      data.date = data.start.slice(0, 10).replace('-', '.').replace('-', '.') + ' - ' + data.end.slice(0, 10).replace('-', '.').replace('-', '.')
    },

    openList() {
      this.$router.push({name: 'orders'})
    },
  }
}
</script>

<style scoped>

</style>
