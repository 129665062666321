import axios from "axios";

const state = {
    pageList: [],
    pageId: []
};

const getters = {
    pageList(state) {
        return state.pageList;
    },
    pageId(state) {
        return state.pageId;
    }
};

const mutations = {
    setPageList: (state, item) => {
        state.pageList = item;
    },
    setPageId: (state, item) => {
        state.pageId = item;
    }
};

const actions = {
    onPageList({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/page?number=" + this.state.number + "&page=" + this.state.page;
            axios
                .get(url)
                .then(response => {
                    commit("setPageList", response.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },

    onPage({ commit, dispatch }, id) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/page/" + id + "/edit?language_id=" + this.state.language;
            axios
                .get(url)
                .then(response => {
                    commit("setPageId", response.data);
                    commit("SetLoadingAnimation", false, {root: true});
                    resolve(response);
                })
                .catch(error => {
                    commit("SetLoadingAnimation", false, {root: true});
                    reject(error);
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },

    onPageSave({ commit, dispatch }, {id, data}) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/page/" + id;
            axios
                .put(url, data)
                .then(response => {
                    commit("SetLoadingAnimation", false, {root: true});
                    resolve(response);
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    commit("SetLoadingAnimation", false, {root: true});
                    reject(error);
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },

    onPageCreate({ commit, dispatch }, data) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/page/";
            axios
                .post(url, data)
                .then(response => {
                    commit("SetLoadingAnimation", false, {root: true});
                    resolve(response);
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    commit("SetLoadingAnimation", false, {root: true});
                    reject(error);
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },


};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
