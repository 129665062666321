<template>
  <DefaultPageToolbar
      :total="Number(list.amount)"
      :last_page="list.lastPage"
      :create-text="$store.state.t[this.$route.params.language]['page_toolbar']['add_control']"
      :on-create="openNewItem"
  >
    <DefaultPageTable
        :title="$store.state.t[this.$route.params.language]['menu']['control']"
        :data="list.data"
        :headers="headers"
        :on-delete="askDeleteItem"
        :ask-delete-fields="['name']"
        :on-edit="editItem"
    />
    <v-dialog v-model="modalNewItem" width="800">
      <PagesSettingsControl
          v-if="modalNewItem"
          :on-save="createItem"
          :on-close="closeModals"
      />
    </v-dialog>
    <v-dialog v-model="modalEditItem" width="800">
      <PagesSettingsControl
          v-if="modalEditItem"
          :on-save="saveItem"
          :on-close="closeModals"
          :on-item="getItem"
      />
    </v-dialog>
    <v-dialog v-model="modalAskDelete" width="600">
      <DefaultPageAskDelete
          text="control"
          :name="name"
          :on-close="closeModals"
          :on-delete="deleteItem"
      />
    </v-dialog>
  </DefaultPageToolbar>
</template>

<script>
import DefaultPageToolbar from "components/default/page/DefaultPageToolbar";
import {mapActions} from "vuex";
import DefaultPageTable from "components/default/page/DefaultPageTable";
import DefaultPageAskDelete from "components/default/page/DefaultPageAskDelete";
import PagesSettingsControl from "components/pages/settings/PagesSettingsControl";

export default {
  name: "control",
  components: {PagesSettingsControl, DefaultPageAskDelete, DefaultPageTable, DefaultPageToolbar},
  data: () => ({
    list: {meta: {}, data: []},
    id: 0,
    name: '',
    modalNewItem: false,
    modalEditItem: false,
    modalAskDelete: false
  }),
  computed: {
    headers() {
      return [
        {
          value: ["name"],
          text: this.$store.state.t[this.$route.params.language]['fields']['title']
        }
      ]
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    ...mapActions("serviceControl", ["onServiceControlList", "onServiceControlDelete", "onServiceControlCreate", "onServiceControl", "onServiceControlSave"]),

    async getList() {
      this.list = {meta: {}}
      await this.onServiceControlList({}).then(res => {
        console.log(res.data)
        this.list = res.data;
      })
    },
    closeModals() {
      this.modalNewItem = false;
      this.modalAskDelete = false;
      this.modalEditItem = false
    },
    openNewItem() {
      this.id = 0;
      this.modalNewItem = true;
    },
    askDeleteItem(id, name) {
      this.id = id;
      this.name = name;
      this.modalAskDelete = true;
    },
    async deleteItem() {
      this.onServiceControlDelete(this.id).then(() => {
        this.getList();
        this.closeModals()
      })
    },
    editItem(id) {
      this.id = id
      this.modalEditItem = true
    },
    async getItem() {
      await this.onServiceControl(this.id).then( res => {
        console.log(res)
      })
    },
    saveItem(data) {
      data.data = JSON.stringify(data.data)
      this.onServiceControlSave({id: data.id, data: data})
    },
    async createItem(data) {
      data.data = JSON.stringify(data.data)
      await this.onServiceControlCreate(data).then(() => {
        this.getList()
        this.closeModals()
      })
    },
    transformData(data) {
      data.data = JSON.stringify(data.data)
      return data
    }
  }
}
</script>
