<template>
  <PagesAuthorizationUserData/>
</template>

<script>
import PagesAuthorizationUserData from "@/components/pages/authorization/PagesAuthorizationUserData";

export default {
  name: "user_data",
  components: {PagesAuthorizationUserData}
}
</script>
