import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import auth from "@/store/modules/auth";
import service from "@/store/modules/service";
import location from "@/store/modules/location";
import notification from "@/store/modules/notification";
import authUser from '@/store/modules/authUser'
import user from "@/store/modules/user";
import userRole from "@/store/modules/userRole";
import feedback from "@/store/modules/feedback";
import order from "@/store/modules/order";
import client from "@/store/modules/client";
import author from "@/store/modules/author";
import articleCategory from "@/store/modules/articleCategory";
import article from "@/store/modules/article";
import discount from "@/store/modules/discount";
import pages from "@/store/modules/pages";
import serviceControl from "@/store/modules/serviceControl";
import serviceAction from "@/store/modules/serviceAction";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        apiDomain: "https://api.vuokrahyva.fi",
        file: "",
        loadingAnimation: false,
        number: 10,
        page: 1,
        page_name: '',
        breadcrumbs: '',
        languages: [
            {id: 1, name: "ru", flag: "/images/flags/ru.jpg"},
            {id: 2, name: "en", flag: "/images/flags/en.jpg"},
            {id: 3, name: "fi", flag: "/images/flags/fi.jpg"},
        ],
        language: 1,
        application_language: null,
        temp_route: '',
        t: {
            ru: {
                login: {
                    enter: 'Вход',
                    sign_in: 'Войти',
                    register: 'Зарегистрироваться',
                    send: 'Отправить',
                    recovery_pass: 'Восстановление пароля',
                    create_new_pass: 'Создание нового пароля',
                    create_pass: 'Создание пароля',
                    password: 'Пароль',
                    pick_pass: 'Придумайте пароль',
                    repeat_pass: 'Повторите пароль',
                    mismatch_pass: 'Пароли не совпадают',
                    bad_password: 'Не правильный логин или пароль',
                    email_not_exist: 'Данная почта не зарегистрирована',
                    confirm_email: 'Подтвердите электронную почту',
                    confirmation_error: 'Ошибка подтверждения',
                    remember_me: 'Запомнить меня',
                    forgot_password: 'Забыли пароль?',
                    message: 'Ссылка для восстановления пароля успешно отправлена на почту',
                    personal_data: 'Персональные данные'
                },
                form: {
                    required_field: 'Обязательное поле',
                    email: 'Электронная почта',
                    bad_email: 'Введите корректный адрес электронной почты',
                    first_name: 'Имя',
                    last_name: 'Фамилия',
                    phone: 'Телефон',
                    bad_phone: 'Введите корректный номер телефона',
                    number_of_characters_must_be_at_least: 'Количество символов должно быть не менее'
                },
                home: {
                    welcome: 'Добро пожаловать в VuokraHyva!'
                },
                menu: {
                    account: 'Учетная запись',
                    exit: 'Выйти',
                    ord: 'Все заказы',
                    orders: 'Заказы',
                    projects: 'Проекты',
                    project_id: 'Проект',
                    article_id: 'Статья',
                    messages: 'Обратная связь',
                    clients: 'Клиенты',
                    phys: 'Физ. лица',
                    jur: 'Юр. лица',
                    locations: 'Пункты аренды',
                    discount: 'Промокоды',
                    services: 'Услуги',
                    products: 'Товары',
                    units: 'Карточки товара',
                    blog: 'Блог',
                    authors: 'Авторы',
                    categories: 'Категории',
                    articles: 'Статьи',
                    settings: 'Настройки',
                    pages: 'Страницы',
                    order_status: 'Статусы заказов',
                    control: 'Нормативы контроля',
                    interface: 'Управление интерфейсом',
                    languages: 'Языки',
                    roles: 'Роли',
                    users: 'Пользователи',
                    jur_id: 'Клиент',
                    phys_id: 'Клиент',
                    jur_address: 'Адреса',
                    jur_contacts: 'Контакты',
                    jur_clients: 'Контактные лица',
                },
                footer: {
                    rights: '© 2020-2021 Все права защищены',
                    developer: 'Developer'
                },
                page_toolbar: {
                    add: 'Добавить',
                    all: 'Все',
                    from: 'из',
                    add_order: 'Добавить заказ',
                    add_projects: 'Добавить проект',
                    add_client: 'Добавить клиента',
                    add_location: 'Добавить пункт аренды',
                    add_discount: 'Добавить промокод',
                    add_service: 'Добавить услугу',
                    add_product: 'Добавить товар',
                    add_unit: 'Добавить карточку товара',
                    add_author: 'Добавить автора',
                    add_category: 'Добавить категорию',
                    add_article: 'Добавить статью',
                    add_page: 'Добавить статью',
                    add_control: 'Добавить норматив контроля',
                    add_interface: 'Добавить интерфейс',
                    add_user: 'Добавить пользователя',
                    add_role: 'Добавить роль',
                    add_address: 'Добавить адрес',
                    add_client_in_company: 'Добавить контактное лицо',
                    add_email: 'Добавить электронную почту',
                    add_phone: 'Добавить телефон',
                    open_orders: 'Перейти к списку заказов'
                },
                fields: {
                    service: 'Услуга',
                    service_name: 'Название услуги',
                    product: 'Товар',
                    product_name: 'Название товара',
                    product_description: 'Описание товара',
                    unit: 'Карточка товара',
                    unit_name: 'Название карточки товара',
                    status: 'Статус',
                    date: 'Дата',
                    price: 'Цена',
                    total_price: 'Итоговая цена',
                    location: 'Пункт аренды',
                    is_no_price: 'цена товара не указана (уточнить)',
                    date_from: 'Дата с',
                    date_to: 'Дата по',
                    discount_value: 'Скидка',
                    discount_code: 'Промокод',
                    discount_type: 'Тип скидки',
                    percentage: 'Процент от суммы',
                    fixed_value: 'Фиксированное значение',
                    images: 'Изображения',
                    image: 'Изображение',
                    icon: 'Иконка',
                    created_at: 'Дата создания',
                    client: 'Клиент',
                    message: 'Сообщение',
                    title: 'Название',
                    address: 'Адрес',
                    city: 'Город',
                    street: 'Улица',
                    zip: 'Индекс',
                    url: 'Ссылка',
                    keywords: 'Ключевые слова',
                    description: 'Описание',
                    alt: 'Альтернативный текст',
                    specifications: 'Спецификации',
                    specification_name: 'Название спецификации',
                    specification_data: 'Название характеристики',
                    prices: 'Несколько цен',
                    check_price: 'Уточнить цену',
                    day1: '1 день',
                    day3: '3 дня',
                    day7: '7 дней',
                    documentation: 'Документация',
                    file_name: 'Название файла (которое будет отображаться на сайте)',
                    serial_number: 'Порядковый номер',
                    registration_number: 'Регистрационный номер',
                    commissioning: 'Введение в эксплуатацию',
                    decommissioning: 'Вывод из эксплуатации',
                    out_of_service: 'Вывести из эксплуатации',
                    put_into_service: 'Ввести в эксплуатацию',
                    unit_price: 'Стоимость закупки',
                    responsible_person: 'Ответственное лицо',
                    contact_person: 'Контактное лицо',
                    month_price: 'Стоимость в месяц',
                    week_price: 'Стоимость за неделю',
                    product_installation_address: 'Адрес доставки',
                    agreement_date: 'Дата договора',
                    agreement_number: 'Номер договора',
                    customer_contact_person: 'Контактное лицо со стороны заказчика',
                    balance: 'Сальдо единиц товара',
                    note: 'Примечание',
                    validity: 'Срок действия',
                    transportation: 'Транспортировка',
                    transportation_price: 'Стоимость транспортировки',
                    project_data: 'Проектные данные',
                    client_data: 'Клиентские данные',
                    transportation_address: 'Адрес транспортировки товара',
                    project_name: 'Название проекта',
                    project_type: 'Тип проекта',
                    project_number: 'Номер проекта',
                    project_start: 'Предполагаемая дата начала проекта',
                    project_end: 'Предполагаемая дата окончания проекта',
                    actual_project_end: 'Фактическая дата окончания проекта',
                    customer_object_number: 'Номер объекта клиента',
                    hint_create_or_choose: 'Создайте новую запись или выберите из списка',
                    region: 'Регион',
                    author_name: 'Имя автора',
                    articles_count: 'Количество статей',
                    author: 'Автор',
                    category: 'Категория',
                    subtext: 'Краткое содержание текста',
                    article_text: 'Текст статьи',
                    no_role: 'Роль не выбрана',
                    role: 'Роль',
                    company_title: 'Название компании',
                    logo: 'Логотип',
                    photo: 'фотография',
                    vat: 'VAT-номер',
                    ytunnus: 'Y-tunnus',
                    ovt: 'Адрес электронной накладной (OVT)',
                    operator: 'Оператор',
                    operator_id: 'ID-оператора',
                    electronic_invoice: 'Электронный счёт-фактура',
                    registration_data: 'Данные регистрации',
                    billing_address: 'Адрес для выставления счетов',
                    billing_address1: 'Бумажный счет-фактура',
                    position: 'Должность',
                    dob: 'Дата рождения',
                    files: 'Документы',
                    activity_segment: 'Сегмент вида деятельности',
                    branch: 'Филиал',
                    doc_created_at: 'Дата создания документа',
                    doc_client_data: 'Данные клиента',
                    doc_company_data: 'Данные компании',
                    phone_number: 'Номер телефона',
                    doc_our_data: 'Наши данные',
                    control: 'Норматив контроля',
                    control_name: 'Название контроля',
                    type: 'Тип',
                    sum: 'Сумма',
                    history: 'История',
                    unit_info: 'Данные об единице товара',
                    none_controls: 'Нормативный контроль не указан',
                    in_operation: 'В эксплуатации',
                    out_operation: 'Выведен из эксплуатации',
                    page: 'Страница',
                    user: 'Пользователь',
                    is_units: 'Невозможно удалить: к данному товару привязаны карточки',
                    roles: 'Роли',
                    add_contact_person: 'Добавление контактного лица',
                    registration_address: 'Адрес регистрации',
                    use_for_reg_address: 'Использовать этот адрес для адреса регистрации',
                    use_for_address: 'Использовать этот адрес для адреса выставления счетов',
                    project_address: 'Адрес проекта',
                    e_invoice: 'Электронный счет-фактура',
                    location_address: 'Адрес размещения'
                },
                ask_delete: {
                    order: 'Вы уверенны, что хотите удалить заказ',
                    image: 'Вы уверенны, что хотите удалить изображение',
                    message: 'Вы уверенны, что хотите удалить сообщение от',
                    location: 'Вы действительно хотите удалить пункт аренды',
                    discount: 'Вы действительно хотите удалить промокод',
                    service: 'Вы действительно хотите удалить услугу',
                    product: 'Вы действительно хотите удалить товар',
                    author: 'Вы действительно хотите удалить автора',
                    article: 'Вы действительно хотите удалить статью',
                    category: 'Вы действительно хотите удалить категорию',
                    client: 'Вы действительно хотите удалить клиента',
                    control: 'Вы действительно хотите удалить нормативный контроль',
                    address: 'Вы действительно хотите удалить адрес',
                    email: 'Вы действительно хотите удалить электронную почту',
                    phone: 'Вы действительно хотите удалить телефон',
                    contact: 'Вы действительно хотите удалить контактное лицо',
                },
                buttons: {
                    create: 'Создать',
                    save: 'Сохранить',
                    clear: 'Сбросить',
                    check_code: 'Проверить код',
                    delete: 'Удалить',
                    cancel: 'Отменить',
                    close: 'Закрыть',
                    change: 'Изменить',
                    add: 'Добавить',
                    confirm: 'Подтвердить',
                    add_project_photo: 'Загрузить фото проекта',
                    show: 'Смотреть',
                    block: 'Заблокировать',
                    unblock: 'Разблокировать'
                },
                notification: {
                    notification_error: 'Произошла ошибка',
                    notification_success: 'Данные успешно обновлены',
                    notification_close: 'Окно автоматически закроется через несколько секунд',
                    no_data: 'Нет данных'
                },
                user_status: {
                    invited: {title: 'Приглашение отправлено', color: '#207AF4'},
                    authorized: {title: 'Авторизирован', color: '#25C565'},
                    restore: {title: 'Восстановление пароля', color: '#F68A2A'},
                    blocked: {title: 'Заблокирован', color: '#EC1B0E'},
                    not_completed: {title: 'Зарегистрирован', color: '#207AF4'}
                },
                order_status: [
                    { name: 'new', title: 'Новый', color: '#25C565'},
                    { name: 'payed', title: 'Оплачен', color: '#207AF4'},
                    { name: 'processing', title: 'В обработке', color: '#F68A2A'},
                    { name: 'working', title: 'В работе', color: '#F68A2A'},
                    { name: 'done', title: 'Завершен', color: '#207AF4'},
                    { name: 'accident', title: 'Инцидент', color: '#EC1B0E'}
                ],
                service_control: [
                    { name: 'notes', title: 'Интервальный контроль'},
                    { name: 'repair', title: 'Ремонт'},
                    { name: 'inspection', title: 'Техосмотр'},
                ],
                project_doc: {
                    doc_client_data: 'Данные клиента',
                    doc_company_data: 'Данные компании',
                    doc_email: 'Почта',
                    doc_created_at: 'Дата создания документа',
                    doc_phone_number: 'Номер телефона',
                    doc_our_data: 'Наши данные',
                    doc_product_data: 'Данные о товаре',
                    doc_num_name_project: 'Номер и имя проекта',
                    doc_billing: 'Выписывание счета',
                    doc_account_address: 'Адрес для счетов',
                    doc_address_paper_invoices: 'Адрес для бумажных счетов',
                    doc_address_e_invoices: 'Адрес для электронных счетов',
                    doc_offer_date_code: 'Дата и код предложения',
                    doc_contract_term: 'Срок контракта'
                }
            },
            en: {
                login: {
                    enter: 'Sign in',
                    sign_in: 'Sign in',
                    register: 'Register',
                    send: 'Send',
                    recovery_pass: 'Password recovery',
                    create_new_pass: 'Create new password',
                    create_pass: 'Create password',
                    password: 'Password',
                    pick_pass: 'Create a password',
                    repeat_pass: 'Repeat password',
                    mismatch_pass: 'Password does not match',
                    bad_password: 'Wrong login or password',
                    email_not_exist: 'This email is not regitered',
                    confirm_email: 'Please confirm the email',
                    confirmation_error: 'Error',
                    remember_me: 'Remember me',
                    forgot_password: 'Forgot password?',
                    message: 'Link, to reset the password is sent to your email.',
                    personal_data: 'Personal information'
                },
                form: {
                    required_field: 'Required field',
                    email: 'email',
                    bad_email: 'Incorrect email',
                    first_name: 'Name',
                    last_name: 'Last name',
                    phone: 'Phone number',
                    bad_phone: 'Incorrect phone number',
                    number_of_characters_must_be_at_least: 'Ammount of characters have to be at least'
                },
                home: {
                    welcome: 'Welcome to VuokraHyva!'
                },
                menu: {
                    account: 'Account',
                    exit: 'Exit',
                    ord: 'All orders',
                    orders: 'Orders',
                    projects: 'Projects',
                    project_id: 'Project',
                    article_id: 'Artikle',
                    messages: 'Contact us',
                    clients: 'Customer',
                    phys: 'Private person',
                    jur: 'Company',
                    locations: 'Rental point',
                    discount: 'Promo code',
                    services: 'Services',
                    products: 'Goods',
                    units: 'Product card',
                    blog: 'Blogs',
                    authors: 'Authors',
                    categories: 'Categories',
                    articles: 'Articles',
                    settings: 'Settings',
                    pages: 'Pages',
                    order_status: 'Order status',
                    control: 'Control standards',
                    interface: 'Interface language',
                    languages: 'Languages',
                    roles: 'Roles',
                    users: 'Users',
                    jur_id: 'Customer',
                    phys_id: 'Customer',
                    jur_address: 'Address',
                    jur_contacts: 'Contacts',
                    jur_clients: 'Contacts',
                },
                footer: {
                    rights: '© 2020-2021 All rights reserved',
                    developer: 'Developer'
                },
                page_toolbar: {
                    add: 'Add',
                    all: 'All',
                    from: 'from',
                    add_order: 'Add order',
                    add_projects: 'Add project',
                    add_client: 'Add customer',
                    add_location: 'Add rental point',
                    add_discount: 'Add promo code',
                    add_service: 'Add service',
                    add_product: 'Add product',
                    add_unit: 'Add product card',
                    add_author: 'Add author',
                    add_category: 'Add category',
                    add_article: 'Add article',
                    add_page: 'Add page',
                    add_control: 'Add control standards',
                    add_interface: 'Add interface',
                    add_user: 'Add user',
                    add_role: 'Add role',
                    add_address: 'Add address',
                    add_client_in_company: 'Add contact',
                    add_email: 'Add email',
                    add_phone: 'Add phone number',
                    open_orders: 'Transfer to order list'
                },
                fields: {
                    service: 'Service',
                    service_name: 'Service name',
                    product: 'Product  ',
                    product_name: 'Product name',
                    product_description: 'Product description',
                    unit: 'Product card',
                    unit_name: 'Product card name',
                    status: 'Status',
                    date: 'Date',
                    price: 'Price',
                    total_price: 'Total',
                    location: 'Rental point',
                    is_no_price: 'Price is not specified',
                    date_from: 'Start',
                    date_to: 'Finnish',
                    discount_value: 'Discount',
                    discount_code: 'Promo code',
                    discount_type: 'Discount type',
                    percentage: 'Total %',
                    fixed_value: 'Fixed value',
                    images: 'Images',
                    image: 'Image',
                    icon: 'Icon',
                    created_at: 'Created at',
                    client: 'Customer',
                    message: 'Message',
                    title: 'Title',
                    address: 'Address',
                    city: 'City',
                    street: 'Street',
                    zip: 'ZIP',
                    url: 'Link',
                    keywords: 'Keywords',
                    description: 'Description',
                    alt: 'Alternative text',
                    specifications: 'Specification',
                    specification_name: 'Specification name',
                    specification_data: 'Characteristic name',
                    prices: 'Prises',
                    check_price: 'Check the price',
                    day1: '1 day',
                    day3: '3 days',
                    day7: '7 days',
                    documentation: 'Documents',
                    file_name: 'File name',
                    serial_number: 'Number',
                    registration_number: 'Registration number',
                    commissioning: 'Commisioning',
                    decommissioning: 'Decomissioning',
                    out_of_service: 'Out of servise',
                    put_into_service: 'Put into srevice',
                    unit_price: 'Price of purchise',
                    responsible_person: 'Responsible porson',
                    contact_person: 'Contact person',
                    month_price: 'Price per month',
                    week_price: 'Стоимость за неделю',
                    product_installation_address: 'Delivery address',
                    agreement_date: 'Contract date',
                    agreement_number: 'Contract number',
                    customer_contact_person: 'Contact person (customer)',
                    balance: 'Balance',
                    note: 'Note',
                    validity: 'Valid to',
                    transportation: 'Delivery',
                    transportation_price: 'Стоимость транспортировки',
                    project_data: 'Project data',
                    client_data: 'Customer data',
                    transportation_address: 'Delivery adderss',
                    project_name: 'Project name',
                    project_type: 'Project type',
                    project_number: 'Project number',
                    project_start: 'Estimated starting date of the project',
                    project_end: 'Estimating duedate',
                    actual_project_end: 'Duedate',
                    customer_object_number: 'Customer object number',
                    hint_create_or_choose: 'Create new profile or chose from the list',
                    region: 'Region',
                    author_name: 'Authors name',
                    articles_count: 'Ammount of articles',
                    author: 'Author',
                    category: 'Categorie',
                    subtext: 'Short summary',
                    article_text: 'Article text',
                    no_role: 'Rose is not chosen',
                    role: 'Roles',
                    company_title: 'Company name',
                    logo: 'Logo',
                    photo: 'Photo',
                    vat: 'VAT-number',
                    ytunnus: 'Company code',
                    ovt: 'e-invoicing address',
                    operator: 'Operator',
                    operator_id: 'Opedator ID',
                    electronic_invoice: 'e-invoice',
                    registration_data: 'Registration data',
                    billing_address: 'Billing address',
                    billing_address1: 'Бумажный счет-фактура',
                    position: 'Position',
                    dob: 'Date of birth',
                    files: 'Documents',
                    activity_segment: 'Segment of activity',
                    branch: 'Branch',
                    doc_created_at: 'Document creation date',
                    doc_client_data: 'Customer data',
                    doc_company_data: 'Company data',
                    phone_number: 'Phone number',
                    doc_our_data: 'Our data',
                    control: 'Control standards',
                    control_name: 'Control standards name',
                    type: 'Type',
                    sum: 'Total',
                    history: 'History',
                    unit_info: 'Unit info',
                    none_controls: 'Нормативный контроль не указан',
                    in_operation: 'In use',
                    out_operation: 'not in use',
                    page: 'Page',
                    user: 'User',
                    is_units: 'Cannot delete, this product is in use',
                    roles: 'Roles',
                    add_contact_person: 'Add contact person',
                    registration_address: 'Адрес регистрации',
                    use_for_reg_address: 'Использовать этот адрес для адреса регистрации',
                    use_for_address: 'Использовать этот адрес для адреса выставления счетов',
                    project_address: 'Адрес проекта',
                    e_invoice: 'Электронный счет-фактура',
                    location_address: 'Адрес размещения'
                },
                ask_delete: {
                    order: 'Вы уверенны, что хотите удалить заказ',
                    image: 'Вы уверенны, что хотите удалить изображение',
                    message: 'Вы уверенны, что хотите удалить сообщение от',
                    location: 'Вы действительно хотите удалить пункт аренды',
                    discount: 'Вы действительно хотите удалить промокод',
                    service: 'Вы действительно хотите удалить услугу',
                    product: 'Вы действительно хотите удалить товар',
                    author: 'Вы действительно хотите удалить автора',
                    article: 'Вы действительно хотите удалить статью',
                    category: 'Вы действительно хотите удалить категорию',
                    client: 'Вы действительно хотите удалить клиента',
                    control: 'Вы действительно хотите удалить нормативный контроль',
                    address: 'Вы действительно хотите удалить адрес',
                    email: 'Вы действительно хотите удалить электронную почту',
                    phone: 'Вы действительно хотите удалить телефон',
                    contact: 'Вы действительно хотите удалить контактное лицо',
                },
                buttons: {
                    create: 'Create',
                    save: 'Save',
                    clear: 'Clear',
                    check_code: 'Check the code',
                    delete: 'Delete',
                    cancel: 'Cancel',
                    close: 'Close',
                    change: 'Change',
                    add: 'Add',
                    confirm: 'Confirm',
                    add_project_photo: 'Add project picture',
                    show: 'Check',
                    block: 'Block',
                    unblock: 'Unblock'
                },
                notification: {
                    notification_error: 'Error',
                    notification_success: 'Data is updated',
                    notification_close: 'Changes have been successfully saved',
                    no_data: 'No data'
                },
                user_status: {
                    invited: {title: 'Invitation is sent', color: '#207AF4'},
                    authorized: {title: 'Athorized', color: '#25C565'},
                    restore: {title: 'Password recovery', color: '#F68A2A'},
                    blocked: {title: 'Blocked', color: '#EC1B0E'},
                    not_completed: {title: 'Registered', color: '#207AF4'}
                },
                order_status: [
                    { name: 'new', title: 'New', color: '#25C565'},
                    { name: 'payed', title: 'Payed', color: '#207AF4'},
                    { name: 'processing', title: 'Processing', color: '#F68A2A'},
                    { name: 'working', title: 'В работе', color: '#F68A2A'},
                    { name: 'done', title: 'Completed', color: '#207AF4'},
                    { name: 'accident', title: 'Accident', color: '#EC1B0E'}
                ],
                service_control: [
                    { name: 'notes', title: 'Interval control'},
                    { name: 'repair', title: 'Repair'},
                    { name: 'inspection', title: 'Inspection'},
                ],
                project_doc: {
                    doc_client_data: 'Customer data',
                    doc_company_data: 'Company data',
                    doc_email: 'Email',
                    doc_created_at: 'Document creation date',
                    doc_phone_number: 'Phone number',
                    doc_our_data: 'Our data',
                    doc_product_data: 'Product data',
                    doc_num_name_project: 'Number and name of the project',
                    doc_billing: 'Billing',
                    doc_account_address: 'Billing address',
                    doc_address_paper_invoices: 'Paper billing address',
                    doc_address_e_invoices: 'E-invoice billing address',
                    doc_offer_date_code: 'Offer date and code',
                    doc_contract_term: 'Срок контракта'
                }
            },
            fi: {
                login: {
                    enter: 'Kirjaudu',
                    sign_in: 'Kirjaudu sisään',
                    register: 'Rekisteröidy',
                    send: 'Lähettää',
                    recovery_pass: 'Salasanan palauttaminen',
                    create_new_pass: 'Luo uusi salasana',
                    create_pass: 'Luo salasana',
                    password: 'Salasana',
                    pick_pass: 'Luo salasana',
                    repeat_pass: 'Toista salasana',
                    mismatch_pass: 'Salasana ei täsmää',
                    bad_password: 'Väärä käyttäjätunnus salasana',
                    email_not_exist: 'Sähkopostia ei ole rekisteröity',
                    confirm_email: 'Toistaa sähköposia',
                    confirmation_error: 'Virhe',
                    remember_me: 'Talenna',
                    forgot_password: 'Salasana hukassa?',
                    message: 'Salasanan palauttaminen linkki on lähetetty sähköpostille',
                    personal_data: 'Персональные данные'
                },
                form: {
                    required_field: 'Pakollinen kenttä',
                    email: 'sähköposti',
                    bad_email: 'Väärä sähköposti',
                    first_name: 'Nimi',
                    last_name: 'Sukunimi',
                    phone: 'Puhelinnumero',
                    bad_phone: 'Väärä puhelinnumero',
                    number_of_characters_must_be_at_least: 'Merkkien määrä on oltava vähintän'
                },
                home: {
                    welcome: 'Tervetuloa Vuokrahyvälle!'
                },
                menu: {
                    account: 'Tili',
                    exit: 'Ulos',
                    ord: 'Kaikki tilaukset',
                    orders: 'Tilaukset',
                    projects: 'Tilaus',
                    project_id: 'Projekti',
                    article_id: 'Artikkeli',
                    messages: 'Ota yhteytta',
                    clients: 'Asiakas',
                    phys: 'Yksityishenkilö',
                    jur: 'Yritys',
                    locations: 'Vuokrauspisteet',
                    discount: 'Etukoodi',
                    services: 'Palvelut',
                    products: 'Tavarat',
                    units: 'Tuotekortti',
                    blog: 'Bloggi',
                    authors: 'Kirjoittaja',
                    categories: 'Luokat',
                    articles: 'Aktikkelit',
                    settings: 'Asetukset',
                    pages: 'Sivut',
                    order_status: 'Tilauksen tilanne',
                    control: 'Valvontastandardit',
                    interface: 'Kayttöliittymän kieli',
                    languages: 'Kielet',
                    roles: 'Roolit',
                    users: 'Käyttäjä',
                    jur_id: 'Asiakas',
                    phys_id: 'Asiakas',
                    jur_address: 'Osoite',
                    jur_contacts: 'Yhteystidot',
                    jur_clients: 'Yhteyshenkilö',
                },
                footer: {
                    rights: '© 2020-2021 All rights reserved',
                    developer: 'Kehittäjä'
                },
                page_toolbar: {
                    add: 'Добавить',
                    all: 'Все',
                    from: 'из',
                    add_order: 'Добавить заказ',
                    add_projects: 'Добавить проект',
                    add_client: 'Добавить клиента',
                    add_location: 'Добавить пункт аренды',
                    add_discount: 'Добавить промокод',
                    add_service: 'Добавить услугу',
                    add_product: 'Добавить товар',
                    add_unit: 'Добавить карточку товара',
                    add_author: 'Добавить автора',
                    add_category: 'Добавить категорию',
                    add_article: 'Добавить статью',
                    add_page: 'Добавить статью',
                    add_control: 'Добавить норматив контроля',
                    add_interface: 'Добавить интерфейс',
                    add_user: 'Добавить пользователя',
                    add_role: 'Добавить роль',
                    add_address: 'Добавить адрес',
                    add_client_in_company: 'Добавить контактное лицо',
                    add_email: 'Добавить электронную почту',
                    add_phone: 'Добавить телефон',
                    open_orders: 'Перейти к списку заказов'
                },
                fields: {
                    service: 'Услуга',
                    service_name: 'Название услуги',
                    product: 'Товар',
                    product_name: 'Название товара',
                    product_description: 'Описание товара',
                    unit: 'Карточка товара',
                    unit_name: 'Название карточки товара',
                    status: 'Статус',
                    date: 'Дата',
                    price: 'Цена',
                    total_price: 'Итоговая цена',
                    location: 'Пункт аренды',
                    is_no_price: 'цена товара не указана (уточнить)',
                    date_from: 'Дата с',
                    date_to: 'Дата по',
                    discount_value: 'Скидка',
                    discount_code: 'Промокод',
                    discount_type: 'Тип скидки',
                    percentage: 'Процент от суммы',
                    fixed_value: 'Фиксированное значение',
                    images: 'Изображения',
                    image: 'Изображение',
                    icon: 'Иконка',
                    created_at: 'Дата создания',
                    client: 'Клиент',
                    message: 'Сообщение',
                    title: 'Название',
                    address: 'Адрес',
                    city: 'Город',
                    street: 'Улица',
                    zip: 'Индекс',
                    url: 'Ссылка',
                    keywords: 'Ключевые слова',
                    description: 'Описание',
                    alt: 'Альтернативный текст',
                    specifications: 'Спецификации',
                    specification_name: 'Название спецификации',
                    specification_data: 'Название характеристики',
                    prices: 'Несколько цен',
                    check_price: 'Уточнить цену',
                    day1: '1 день',
                    day3: '3 дня',
                    day7: '7 дней',
                    documentation: 'Документация',
                    file_name: 'Название файла (которое будет отображаться на сайте)',
                    serial_number: 'Порядковый номер',
                    registration_number: 'Регистрационный номер',
                    commissioning: 'Введение в эксплуатацию',
                    decommissioning: 'Вывод из эксплуатации',
                    out_of_service: 'Вывести из эксплуатации',
                    put_into_service: 'Ввести в эксплуатацию',
                    unit_price: 'Стоимость закупки',
                    responsible_person: 'Ответственное лицо',
                    contact_person: 'Контактное лицо',
                    month_price: 'Стоимость в месяц',
                    week_price: 'Стоимость за неделю',
                    product_installation_address: 'Адрес установки товара',
                    agreement_date: 'Дата договора',
                    agreement_number: 'Номер договора',
                    customer_contact_person: 'Контактное лицо со стороны заказчика',
                    balance: 'Сальдо единиц товара',
                    note: 'Примечание',
                    validity: 'Срок действия',
                    transportation: 'Транспортировка',
                    transportation_price: 'Стоимость транспортировки',
                    project_data: 'Проектные данные',
                    client_data: 'Клиентские данные',
                    transportation_address: 'Адрес транспортировки товара',
                    project_name: 'Название проекта',
                    project_type: 'Тип проекта',
                    project_number: 'Номер проекта',
                    project_start: 'Предполагаемая дата начала проекта',
                    project_end: 'Предполагаемая дата окончания проекта',
                    actual_project_end: 'Фактическая дата окончания проекта',
                    customer_object_number: 'Номер объекта клиента',
                    hint_create_or_choose: 'Создайте новую запись или выберите из списка',
                    region: 'Регион',
                    author_name: 'Имя автора',
                    articles_count: 'Количество статей',
                    author: 'Автор',
                    category: 'Категория',
                    subtext: 'Краткое содержание текста',
                    article_text: 'Текст статьи',
                    no_role: 'Роль не выбрана',
                    role: 'Роль',
                    company_title: 'Название компании',
                    logo: 'Логотип',
                    photo: 'фотография',
                    vat: 'VAT-номер',
                    ytunnus: 'Y-tunnus',
                    ovt: 'Адрес электронной накладной (OVT)',
                    operator: 'Оператор',
                    operator_id: 'ID-оператора',
                    electronic_invoice: 'Электронный счёт-фактура',
                    registration_data: 'Данные регистрации',
                    billing_address: 'Адрес для выставления счетов',
                    billing_address1: 'Бумажный счет-фактура',
                    position: 'Должность',
                    dob: 'Дата рождения',
                    files: 'Документы',
                    activity_segment: 'Сегмент вида деятельности',
                    branch: 'Филиал',
                    doc_created_at: 'Дата создания документа',
                    doc_client_data: 'Данные клиента',
                    doc_company_data: 'Данные компании',
                    phone_number: 'Номер телефона',
                    doc_our_data: 'Наши данные',
                    control: 'Норматив контроля',
                    control_name: 'Название контроля',
                    type: 'Тип',
                    sum: 'Сумма',
                    history: 'История',
                    unit_info: 'Данные об единице товара',
                    none_controls: 'Нормативный контроль не указан',
                    in_operation: 'В эксплуатации',
                    out_operation: 'Выведен из эксплуатации',
                    page: 'Страница',
                    user: 'Пользователь',
                    is_units: 'Невозможно удалить: к данному товару привязаны карточки',
                    roles: 'Роли',
                    add_contact_person: 'Добавление контактного лица',
                    registration_address: 'Адрес регистрации',
                    use_for_reg_address: 'Использовать этот адрес для адреса регистрации',
                    use_for_address: 'Использовать этот адрес для адреса выставления счетов',
                    project_address: 'Адрес проекта',
                    e_invoice: 'Электронный счет-фактура',
                    location_address: 'Адрес размещения'
                },
                ask_delete: {
                    order: 'Вы уверенны, что хотите удалить заказ',
                    image: 'Вы уверенны, что хотите удалить изображение',
                    message: 'Вы уверенны, что хотите удалить сообщение от',
                    location: 'Вы действительно хотите удалить пункт аренды',
                    discount: 'Вы действительно хотите удалить промокод',
                    service: 'Вы действительно хотите удалить услугу',
                    product: 'Вы действительно хотите удалить товар',
                    author: 'Вы действительно хотите удалить автора',
                    article: 'Вы действительно хотите удалить статью',
                    category: 'Вы действительно хотите удалить категорию',
                    client: 'Вы действительно хотите удалить клиента',
                    control: 'Вы действительно хотите удалить нормативный контроль',
                    address: 'Вы действительно хотите удалить адрес',
                    email: 'Вы действительно хотите удалить электронную почту',
                    phone: 'Вы действительно хотите удалить телефон',
                    contact: 'Вы действительно хотите удалить контактное лицо',
                },
                buttons: {
                    create: 'Создать',
                    save: 'Сохранить',
                    clear: 'Сбросить',
                    check_code: 'Проверить код',
                    delete: 'Удалить',
                    cancel: 'Отменить',
                    close: 'Закрыть',
                    change: 'Изменить',
                    add: 'Добавить',
                    confirm: 'Подтвердить',
                    add_project_photo: 'Загрузить фото проекта',
                    show: 'Смотреть',
                    block: 'Заблокировать',
                    unblock: 'Разблокировать'
                },
                notification: {
                    notification_error: 'Произошла ошибка',
                    notification_success: 'Данные успешно обновлены',
                    notification_close: 'Окно автоматически закроется через несколько секунд',
                    no_data: 'Нет данных'
                },
                user_status: {
                    invited: {title: 'Приглашение отправлено', color: '#207AF4'},
                    authorized: {title: 'Авторизирован', color: '#25C565'},
                    restore: {title: 'Восстановление пароля', color: '#F68A2A'},
                    blocked: {title: 'Заблокирован', color: '#EC1B0E'},
                    not_completed: {title: 'Зарегистрирован', color: '#207AF4'}
                },
                order_status: [
                    { name: 'new', title: 'Новый', color: '#25C565'},
                    { name: 'payed', title: 'Оплачен', color: '#207AF4'},
                    { name: 'processing', title: 'В обработке', color: '#F68A2A'},
                    { name: 'working', title: 'В работе', color: '#F68A2A'},
                    { name: 'done', title: 'Завершен', color: '#207AF4'},
                    { name: 'accident', title: 'Инцидент', color: '#EC1B0E'}
                ],
                service_control: [
                    { name: 'notes', title: 'Интервальный контроль'},
                    { name: 'repair', title: 'Ремонт'},
                    { name: 'inspection', title: 'Техосмотр'},
                ],
                project_doc: {
                    doc_client_data: 'Данные клиента',
                    doc_company_data: 'Данные компании',
                    doc_email: 'Sähköposti',
                    doc_created_at: 'Дата создания документа',
                    doc_phone_number: 'Puh. numero',
                    doc_our_data: 'Meidän tietoa',
                    doc_product_data: 'Tavaran info',
                    doc_num_name_project: 'Projectin nimi ja numero',
                    doc_billing: 'Laskutus',
                    doc_account_address: 'Laskutus osoite',
                    doc_address_paper_invoices: 'Paperilasku osoite',
                    doc_address_e_invoices: 'E-laskutus osoite',
                    doc_offer_date_code: 'Tarjouksen pvm ja koodi',
                    doc_contract_term: 'Срок контракта'
                }
            },
        }
    },
    getters: {
        apiDomain(state) {
            return state.apiDomain;
        },
        file(state) {
            return state.file;
        },
        loadingAnimation(state) {
            return state.loadingAnimation;
        },
        number(state) {
            return state.number;
        },
        page(state) {
            return state.page;
        },
        page_name(state) {
            return state.page_name
        },
        breadcrumbs(state) {
            return state.breadcrumbs
        },
        language(state) {
            return state.language;
        },
        languages(state) {
            return state.languages;
        },
        application_language(state) {
            return state.application_language;
        },
        temp_route(state) {
            return state.temp_route;
        }
    },
    mutations: {
        SetNumber: (state, item) => {
            state.number = item;
        },
        SetPage: (state, item) => {
            state.page = item;
        },
        SetFile: (state, item) => {
            state.file = item;
        },
        SetLoadingAnimation: (state, item) => {
            state.loadingAnimation = item;
        },
        SetPageName: (state, item) => {
            state.page_name = item;
        },
        SetBreadcrumbs: (state, item) => {
            state.breadcrumbs = item
        },
        SetLanguage: (state, item) => {
            state.language = item
        },
        SetApplicationLanguage: (state, item) => {
            state.application_language = item
        },
        SetTempRoute: (state, item) => {
            state.temp_route = item
        }
    },
    actions: {
        async uploadFile({commit, dispatch}, {data, use_case}) {
            commit("SetLoadingAnimation", true, {root: true});
            //let url = this.state.apiDomain + "api/uploadfile?use_case=" + use_case;
            let url = this.state.apiDomain + "/api/uploadfile";
            if(use_case) {
                url = url + "?use_case=" + use_case
            }
            await axios
                .post(url, data)
                .then(response => {
                    commit("SetFile", response.data);
                    commit("SetLoadingAnimation", false, {root: true});
                })
                .catch(error => {
                    console.log(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        },
        destroyFile({commit,dispatch}, image) {
            let url = this.state.apiDomain + "/api/destroyfile?url=" + image;
            axios
                .delete(url)
                .then(response => {
                    console.log(response);
                })
                .catch(error => {
                    console.log(error);
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        },
        getInfo({commit}, info) {
            return new Promise((resolve, reject) => {
                let url = this.state.apiDomain + "/" + info;
                axios
                    .get(url)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        checkLanguageApplication({commit}) {
            if(localStorage.getItem("language")){
                //console.log(JSON.parse(sessionStorage.getItem("language")).name)
                commit('SetApplicationLanguage', JSON.parse(localStorage.getItem("language")))
            }
        },
        onLanguage({commit}, name) {
            commit('SetApplicationLanguage', this.state.languages.filter(i => i.name === name)[0])
            //console.log(this.state.languages.filter(i => i.name === name)[0])
            localStorage.setItem("language", JSON.stringify(this.state.languages.filter(i => i.name === name)[0]))
        }
    },
    modules: {
        auth,
        service,
        location,
        notification,
        authUser,
        user,
        userRole,
        feedback,
        order,
        client,
        author,
        articleCategory,
        article,
        discount,
        pages,
        serviceControl,
        serviceAction
    }
});
