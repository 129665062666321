import axios from "axios";

const state = {
    serviceControlList: [],
    serviceControl: []
};

const getters = {
    serviceControlList(state) {
        return state.serviceControlList;
    },
    serviceControl(state) {
        return state.serviceControl;
    }
};

const mutations = {
    setServiceControlList: (state, item) => {
        state.serviceControlList = item;
    },
    setServiceControl: (state, item) => {
        state.serviceControl = item;
    }
};

const actions = {
    onServiceControlList({commit, dispatch}, {number, id}) {
        commit("setServiceControlList", []);
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/admin/service-control/grid?" +
                "limit=" +
                (number ? number : this.state.number) +
                "&page=" +
                this.state.page;
            if (id) {
                url = url + '&filter[]=service_id:' + id
            }
            axios
                .get(url)
                .then(response => {
                    commit("setServiceControlList", response.data);
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    onServiceControlDelete({commit, dispatch}, id) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain + "/api/admin/service-control/" + id
            axios.delete(url)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    onServiceControlCreate({commit, dispatch}, data) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/admin/service-control";
            axios
                .post(url, data)
                .then(response => {
                    commit("SetLoadingAnimation", false, {root: true});
                    resolve(response);
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },

    onServiceControl({commit, dispatch}, id) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/admin/service-control/" + id;
            axios
                .get(url)
                .then(response => {
                    commit("setServiceControl", response.data);
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },

    onServiceControlSave({commit, dispatch}, {id, data}) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/admin/service-control/" + id;
            axios
                .put(url, data)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
