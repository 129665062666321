<template>
  <DefaultPageModal
      :text="t['control']"
      :on-close="onClose"
  >
    <v-form lazy-validation ref="form" :key="key">
      <DevInput
          :text="t['title']"
          v-model="data.name"
          :rules="[v => !!v || t['required_field']]"
      />
      <DevInput
          :text="t['type']"
          select
          v-model="data.type"
          :items="type"
          item-text="title"
          item-value="name"
          :rules="[v => !!v || t['required_field']]"
      />
      <DevInput
          v-if="serviceList.data"
          :text="t['service']"
          autocomplete
          multiple
          chips
          clearable
          v-model="data.service_ids"
          :items="serviceList.data"
          item-value="id"

      /><!--:rules="[data.service_ids.length > 0 || t['required_field']]" -->
      <div class="text-start">
        <div class="font12">
          {{ t['control_name'] }}
        </div>
        <div class="grid">
          <div v-for="(lang, l) in languages" :key="l" class="d-flex align-center justify-center">
            {{ lang.name }}
            <div>
              <v-img :src="apiDomain + '/' + lang.flag" width="30" height="30" class="rounded-circle border ml-2"/>
            </div>
          </div>
          <v-btn icon disabled></v-btn>
        </div>
        <div v-for="(dat, d) in data.data" :key="d" class="grid">
          <DevInput v-model="dat.ru" hide-details/>
          <DevInput v-model="dat.en" hide-details/>
          <DevInput v-model="dat.fi" hide-details/>
          <v-btn @click="deleteControl(d)" icon :disabled="data.data.length < 2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-btn @click="addControl" class="second mt-2">
          {{ t['add'] }}
        </v-btn>
      </div>
    </v-form>
    <v-btn @click="saveItem" class="primary" slot="btn">
      {{ data.id ? t['save'] : t['create'] }}
    </v-btn>
  </DefaultPageModal>
</template>

<script>
import DefaultPageModal from "components/default/page/DefaultPageModal";
import {mapActions, mapGetters} from "vuex";
import DevInput from "components/dev/DevInput";

export default {
  name: "PagesSettingsControl",
  components: {DevInput, DefaultPageModal},
  props: {
    onClose: {},
    onSave: {},
    onItem: {}
  },
  data: () => ({
    data: {
      data: [
        {ru: '', en: '', fi: ''}
      ],
      service_ids: []
    },
    key: 0
  }),
  computed: {
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['page_toolbar'],
          this.$store.state.t[this.$route.params.language]['fields'],
          this.$store.state.t[this.$route.params.language]['buttons'],
          this.$store.state.t[this.$route.params.language]['form'],
      )
    },
    type() {
      return Object.assign(this.$store.state.t[this.$route.params.language]['service_control'])
    },
    ...mapGetters(["language", "languages", "apiDomain"]),
    ...mapGetters("serviceControl", ["serviceControl"]),
    ...mapGetters("service", ["serviceList"])
  },
  watch: {
    serviceControl() {
      this.data = Object.assign(this.transformData(this.serviceControl))
      this.key++
    }
  },
  created() {
    this.getItem()
  },
  mounted() {
    this.onServiceList({number: '0'})
  },
  methods: {
    ...mapActions("service", ["onServiceList"]),

    getItem() {
      if (this.onItem) {
        this.onItem()
      }
    },

    transformData(data) {
      data.service_ids = []
      for (let i in data.services) {
        console.log(data.services[i].id)
        data.service_ids.push(data.services[i].id)
      }
      if (!data.data) {
        data.data = [{ru: '', en: '', fi: ''}]
      } else {
        data.data = JSON.parse(data.data)
      }
      return data
    },

    addControl() {
      this.data.data.push({ru: '', en: '', fi: ''})
    },

    saveItem() {
      if (this.$refs.form.validate()) {
        this.onSave({
          id: this.data.id,
          type: this.data.type,
          name: this.data.name,
          service_ids: this.data.service_ids,
          data: this.data.data,
        })
      }
    },

    deleteControl(id) {
      console.log(id)
      this.data.data.splice(id, 1)
      this.key++
    }
  }
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr  1fr auto;
  align-items: center;
  grid-gap: 2px;
  margin-bottom: 2px;
}
</style>
