<template>
  <div>
    <DefaultPageToolbar
        v-if="$route.name === 'jur'"
        :total="Number(list.amount)"
        :last_page="list.lastPage"
        :create-text="$store.state.t[this.$route.params.language]['page_toolbar']['add_client']"
        :on-create="openNewItem"
    >
      <DefaultPageTable
          :title="$store.state.t[this.$route.params.language]['menu']['jur']"
          :data="list.data"
          :headers="headers"
          :on-edit="editItem"
          :on-click="openItem"
          :on-delete="askDeleteItem"
          :ask-delete-fields="['company_name']"
      />
      <v-dialog v-model="modalNewItem" :width="$vuetify.breakpoint.mdAndDown ? 600 : 1200">
        <PagesClientJurNew v-if="modalNewItem" :on-save="createItem" :on-close="closeModals"/>
      </v-dialog>
      <v-dialog v-model="modalEditItem" :width="$vuetify.breakpoint.mdAndDown ? 600 : 1200">
        <PagesClientJurNew v-if="modalEditItem" :on-item="getItem" :on-save="saveItem" :on-close="closeModals"/>
      </v-dialog>
      <v-dialog v-model="modalAskDelete" width="600">
        <DefaultPageAskDelete
            text="client"
            :name="name"
            :on-close="closeModals"
            :on-delete="deleteItem"
        />
      </v-dialog>
    </DefaultPageToolbar>
    <router-view v-else/>
  </div>
</template>

<script>
import DefaultPageToolbar from "components/default/page/DefaultPageToolbar";
import DefaultPageTable from "components/default/page/DefaultPageTable";
import {mapActions, mapGetters} from "vuex";
import PagesClientJurNew from "components/pages/clients/PagesClientJurNew";
import DefaultPageAskDelete from "components/default/page/DefaultPageAskDelete";

export default {
  name: "jur",
  components: {DefaultPageAskDelete, PagesClientJurNew, DefaultPageTable, DefaultPageToolbar},
  data: () => ({
    list: {data: []},
    id: 0,
    name: '',
    modalNewItem: false,
    modalAskDelete: false,
    modalEditItem: false,
  }),
  computed: {
    headers() {
      return [
        {
          value: ["image", "company_name"],
          text: this.$store.state.t[this.$route.params.language]['fields']['company_title'],
          text_position: "company_name"
        },
        {
          value: ["vat"],
          text: this.$store.state.t[this.$route.params.language]['fields']['vat']
        },
        {
          value: ["ytunnus"],
          text: this.$store.state.t[this.$route.params.language]['fields']['ytunnus']
        },
        {
          value: ["ovt"],
          text: this.$store.state.t[this.$route.params.language]['fields']['ovt']
        },
        {
          value: ["operator_id"],
          text: this.$store.state.t[this.$route.params.language]['fields']['operator_id']
        },
        {
          value: ["operator"],
          text: this.$store.state.t[this.$route.params.language]['fields']['operator']
        },
      ]
    },
    ...mapGetters(["apiDomain"])
  },
  watch: {
    '$route.query'() {
      if(this.$route.name === 'jur') {
        this.getList()
      }
    }
  },
  mounted() {
    if(this.$route.name === 'jur') {
      this.getList()
    }
  },
  methods: {
    ...mapActions("user", ["onUserList", "onUserDelete", "onUser", 'onUserSave']),
    ...mapActions("auth", ["onAuthRegisterUser"]),
    async getList() {
      this.list = {}
      await this.onUserList({type: 'legal', sort: 'desc', field: 'id'}).then(res => {
        this.list = res.data
        for (let i in this.list.data) {
          this.transformList(this.list.data[i]);
        }
      })
    },
    transformList(data){
      if (data.image) {
        data.image = [{
          type: 'image',
          src: this.apiDomain + '/' + data.image
        }]
      } else {
        data.image = [{
          type: 'image',
          src: null
        }]
      }

      data.data = JSON.parse(data.data)
      if (data.data) {
        data.vat = data.data.vat
        data.ovt = data.data.ovt
        data.operator = data.data.operator
        data.operator_id = data.data.operator_id
      }
    },
    closeModals() {
      this.modalNewItem = false;
      this.modalAskDelete = false;
      this.modalEditItem = false;
    },
    openNewItem() {
      this.modalNewItem = true;
    },
    openItem(id) {
      this.$router.push({name: 'jur_address', params: {id: id}})
    },
    async createItem(data) {
      data.data = JSON.stringify(data.data)
      console.log(data)
      await this.onAuthRegisterUser(data).then(() => {
        this.getList();
        this.closeModals()
      })
    },
    async deleteItem() {
      await this.onUserDelete(this.id).then(() => {
        this.getList();
        this.closeModals()
      })
    },
    askDeleteItem(id, name) {
      this.id = id;
      this.name = name;
      this.modalAskDelete = true;
    },
    editItem(id) {
      this.id = id;
      this.modalEditItem = true
    },
    async getItem() {
      console.log('id: ' + this.id)
      await this.onUser(this.id).then(res => {
        console.log('+')
      })
    },
    async saveItem(data) {
      data.data = JSON.stringify(data.data)
      console.log(data)
      await this.onUserSave(data).then(() => {
        this.getList()
        this.closeModals()
      })
    }
  }
}
</script>
