<template>
  <DefaultPageModal
      :text="t['add_client']"
      :on-close="onClose"
      :key="key"
  >
    <v-form lazy-validation ref="form">
      <DefaultPageLanguage/>
      <DevInput
          :text="t['email']"
          v-model="data.email"
          :rules="[v => !!v || t['required_field']]"
      />
      <DevInput
          :text="t['first_name']"
          v-model="data.first_name"
          :rules="[v => !!v || t['required_field']]"
      />
      <DevInput
          :text="t['last_name']"
          v-model="data.last_name"
          :rules="[v => !!v || t['required_field']]"
      />
      <DevInput
          :text="t['phone']"
          v-model="data.phone"
          :rules="[v => !!v || t['required_field']]"
      />
      <DevInput
          v-if="list.data"
          select
          :text="t['company_title']"
          v-model="data.users"
          :items="list.data"
          item-text="first_name"
          @input="key++"
      />
      <div class="d-flex align-center">
        <DevImageBlock :images="Array(data.image)" :text="t['photo']" />
        <v-btn @click="loadImage()" class="second ml-5 mt-5">
          {{ data.image ? t['change'] : t['add'] }}
        </v-btn>
      </div>
    </v-form>
    <v-btn @click="saveData" class="primary mb-n10">
      {{ t['create'] }}
    </v-btn>
    <input
        type="file"
        id="file"
        ref="file"
        accept=".JPG, .PNG, .SVG, .GIF, .WebP"
        style="display: none"
        v-on:change="handleFileUpload()"
    />
  </DefaultPageModal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DefaultPageModal from "components/default/page/DefaultPageModal";
import DefaultPageLanguage from "components/default/page/DefaultPageLanguage";
import DevInput from "components/dev/DevInput";
import DevImageBlock from "components/dev/DevImageBlock";

export default {
  name: "PagesClientPhysNew",
  components: {DevImageBlock, DevInput, DefaultPageLanguage, DefaultPageModal},
  props: {
    onClose: {},
    onSave: {},
    company: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    data: {
      type: 'natural',
      data: {}
    },
    list: {},
    key: 0,
    error: false,
    load_file: '',
    click_save: false,
    deleted_image: [],
    new_files: [],
  }),
  computed: {
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['page_toolbar'],
          this.$store.state.t[this.$route.params.language]['fields'],
          this.$store.state.t[this.$route.params.language]['buttons'],
          this.$store.state.t[this.$route.params.language]['form']
      )
    },
    ...mapGetters(["language", "file"])
  },
  watch: {
    load_file() {
      if (this.load_file) {
        this.error = false;
        this.submitFile();
      }
    },
  },
  created() {
    if (this.company && !this.data.users) {
      this.data.users = this.company
      this.key++
    }
    this.getList()
  },
  beforeDestroy() {
    this.clearData()
  },
  methods: {
    ...mapActions(["uploadFile", "destroyFile"]),
    ...mapActions("user", ["onUserList"]),
    async getList(){
      await this.onUserList({}).then(res => {
        this.list = res.data
      })
    },
    clearData() {
      if (this.click_save) {
        console.log('жмакнули кнопку сохранения записи');
        for (let del in this.deleted_image) {
          this.destroyFile(this.deleted_image[del]);
        }
      } else {
        console.log('не сохраняя закрыли окно');
        for (let del in this.new_files) {
          this.destroyFile(this.new_files[del]);
        }
      }
    },
    saveData() {
      if (this.$refs.form.validate()) {
        this.data.language_id = this.language;
        this.click_save = true;
        this.onSave(this.data);
      } else {
        this.error = true;
      }
    },
    loadImage() {
      this.$refs.file.click();
    },
    handleFileUpload() {
      this.load_file = this.$refs.file.files[0];
    },
    async submitFile() {
      let formData = new FormData();
      formData.append("file", this.load_file);
      console.log(this.load_file);
      await this.uploadFile({
        data: formData,
        use_case: "cover",
      }).then(() => {
        if (this.data.image) {
          this.deleted_image.push(this.data.image);
        }
        this.data.image = this.file.url;
        this.new_files.push(this.file.url);
        this.key++;
      });
    }
  }
}
</script>
