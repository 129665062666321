<template>
  <div>
    <DefaultPageLanguage/>
    <v-form v-if="data.data" ref="form" lazy-validation class="grid" :key="key">
      <div class="grid_inner">
        <v-sheet>
          <v-card class="border pa-4">
            <DevImageBlock v-if="image" :text="t['images']" :images="image" :on-delete="deleteImage"/>
            <div v-if="image" class="font12 mt-n3 mb-1 red--text">
              {{ error && image.length < 1 ? t['required_field'] : '' }}
            </div>
            <v-btn @click="loadImage('image')" class="second">
              <v-icon class="mr-3 ml-n1">mdi-plus</v-icon>
              {{ t['add'] }}
            </v-btn>
          </v-card>
          <v-card class="border mt-4">
            <div class="pa-4">
              <DevInput
                  v-if="serviceCategoryList.data"
                  select
                  :text="t['service']"
                  v-model="data.service_category"
                  :items="serviceCategoryList.data"
                  return-object
                  item-text="name"
                  :rules="[v => !!v || t['required_field']]"
              />
              <DevInput
                  :text="t['product_name']"
                  v-model="data.data.title"
                  :rules="[v => !!v || t['required_field']]"
              />
              <DevInput
                  :text="t['url']"
                  v-model="data.data.url"
                  :rules="[v => !!v || t['required_field']]"
              />
            </div>
            <v-divider/>
            <div class="pa-4">
              <v-radio-group v-model="data.data.is_no_price">
                <v-radio :label="t['prices']" :value="false" color="secondary"/>
                <v-sheet
                    v-if="!data.data.is_no_price"
                    class="grid_price ml-8 mt-2 mb-4"
                >
                  <v-input hide-details>{{ t['day1'] }}</v-input>
                  <v-text-field
                      v-model="data.data.price"
                      type="Number"
                      outlined
                      hide-details
                  >
                    <template slot="append-outer">
                      <v-icon size="20">mdi-currency-eur</v-icon>
                    </template>
                  </v-text-field>
                  <v-input hide-details>{{ t['day3'] }}</v-input>
                  <v-text-field
                      v-model="data.data.price2"
                      type="Number"
                      outlined
                      hide-details
                  >
                    <template slot="append-outer">
                      <v-icon size="20">mdi-currency-eur</v-icon>
                    </template>
                  </v-text-field>
                  <v-input hide-details>{{ t['day7'] }}</v-input>
                  <v-text-field
                      v-model="data.data.price3"
                      type="Number"
                      outlined
                      hide-details
                  >
                    <template slot="append-outer">
                      <v-icon size="20">mdi-currency-eur</v-icon>
                    </template>
                  </v-text-field>
                </v-sheet>
                <v-radio :label="t['check_price']" :value="true" color="secondary"/>
              </v-radio-group>
            </div>
          </v-card>
        </v-sheet>
        <v-sheet>
          <v-card class="border pa-4">
            <div class="mb-2">SEO</div>
            <div class="d-flex align-center">
              <DevImageBlock :text="t['image']" :images="Array(seo_image)"/>
              <v-btn @click="loadImage('seo_image')" class="second ml-5">
                {{ seo_image ? t['change'] : t['add'] }}
              </v-btn>
            </div>
            <div class="font12 mt-n3 mb-1 red--text">
              {{ error && !seo_image ? t['required_field'] : '' }}
            </div>
            <DevInput
                :text="t['title']"
                v-model="data.data.seo_title"
                :rules="[v => !!v || t['required_field']]"
            />
            <DevInput
                :text="t['alt']"
                v-model="data.data.alt"
                :rules="[v => !!v || t['required_field']]"
            />
            <DevInput
                text-area
                :text="t['keywords']"
                v-model="data.data.keywords"
                :rules="[v => !!v || t['required_field']]"
            />
            <DevInput
                text-area
                :text="t['description']"
                v-model="data.data.description"
                :rules="[v => !!v || t['required_field']]"
            />
          </v-card>
        </v-sheet>
      </div>
      <div class="grid_inner">
        <v-sheet>
          <v-card v-if="data.data.text.specification" class="border pa-4">
            <div>{{ t['specifications'] }}</div>
            <div v-for="(item, i) in  data.data.text.specification.length" :key="i">
              <div class="grid_specification">
                <DevInput v-model="data.data.text.specification[item-1].name" hide-details/>
                <DevInput v-model="data.data.text.specification[item-1].value" hide-details/>
                <v-btn @click="deleteSpecification(i)" icon>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
            <v-btn @click="addSpecification" class="second mt-4">
              <v-icon class="mr-3 ml-n1">mdi-plus</v-icon>
              {{ t['add'] }}
            </v-btn>
          </v-card>
        </v-sheet>
        <v-sheet>
          <v-card class="border pa-4">
            <DevInput
                text-area
                :text="t['product_description']"
                v-model="data.data.text.info"
                hide-details
            />
          </v-card>
          <v-card class="border pa-4 mt-4">
            <div class="mb-2">{{ t['documentation'] }}</div>
            <DevInput
                :text="t['file_name']"
                v-model="temp"
            />
            <v-btn @click="loadImage('doc')" class="second">
              <v-icon class="mr-3 ml-n1">mdi-plus</v-icon>
              {{ t['add'] }}
            </v-btn>
            <div v-if="docs && docs.length > 0" class="mt-5">
              <div v-for="(doc, d) in docs.length" :key="d" class="grid_doc">
                <v-text-field
                    v-if="edit_doc === doc"
                    v-model="docs[doc - 1].name"
                    hide-details
                    dense
                    outlined
                />
                <v-btn text target="_blank" :href="apiDomain + '/' + docs[doc - 1].url">
                  {{ docs[doc - 1].name }}
                </v-btn>
                <v-btn @click="deleteDoc(d)" icon>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn
                    v-if="edit_doc === doc"
                    @click="edit_doc = -1"
                    icon
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
                <v-btn
                    v-else
                    @click="edit_doc = doc"
                    icon
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-sheet>
      </div>
      <v-sheet>
        <v-btn @click="onSave" class="primary mt-4 mb-10 mx-5 mx-lg-0">
          {{ data.data.id ? t['save'] : t['create'] }}
        </v-btn>
      </v-sheet>
    </v-form>
    <input
        type="file"
        id="file"
        ref="file"
        accept=".JPG, .PNG, .SVG, .GIF, .WebP"
        style="display: none"
        v-on:change="handleFileUpload()"
    />
    <input
        type="file"
        id="doc"
        ref="doc"
        accept=".PDF"
        style="display: none"
        v-on:change="handleFileUpload()"
    />
  </div>
</template>

<script>
import DevImageBlock from "@/components/dev/DevImageBlock";
import {mapGetters, mapActions} from "vuex";
import DevInput from "@/components/dev/DevInput";
import DefaultPageLanguage from "components/default/page/DefaultPageLanguage";

export default {
  name: "PagesServicesProductId",
  components: {DefaultPageLanguage, DevInput, DevImageBlock},
  props: {
    saveItem: {},
    onItem: {}
  },
  data: () => ({
    data: {
      data: {
        image: [],
        text: {
          specification: [],
          info: ''
        },
        docs: [],
        is_no_price: true,
        is_delivery_required: false
      }
    },
    key: 0,

    image: [],
    seo_image: null,
    docs: null,
    edit_doc: -1,

    load_file: '',
    deleted_files: [],
    new_files: [],

    error: false,
    click_save: false,

    temp: '',
    field: '',

  }),
  computed: {
    ...mapGetters(["apiDomain", "file", "language"]),
    ...mapGetters("location", ["locationList"]),
    ...mapGetters("service", ["serviceList", "serviceCategoryList", "service", "serviceUnit"]),
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['fields'],
          this.$store.state.t[this.$route.params.language]['form'],
          this.$store.state.t[this.$route.params.language]['buttons']
      )
    }
  },
  watch: {
    load_file() {
      console.log(this.load_file)
      if (this.load_file) {
        this.submitFile();
      }
    },
    language() {
      this.getItem()
    },
    service() {
      this.data = this.transformData(this.service)
      this.key++
    }
  },
  created() {
    this.getItem()
  },
  mounted() {
    this.onServiceCategoryList({number: '0'})
  },
  beforeDestroy() {
    this.onClose()
  },
  methods: {
    ...mapActions("service", ["onServiceCategoryList", "onServiceList"]),
    ...mapActions(["uploadFile", "destroyFile"]),

    getItem() {
      this.onItem()
    },

    transformData(data) {
      if (!this.image || this.image.length === 0) {
        this.image = JSON.parse(data.data.image)
      }
      if (!this.seo_image) {
        this.seo_image = data.data.seo_image
      }
      if (data.data.docs) {
        this.docs = JSON.parse(data.data.docs)
      } else {
        this.docs = []
      }
      if (data.data.text) {
        data.data.text = JSON.parse(data.data.text)
        let temp = data.data.text.specification;
        let specification = []
        for (let item in temp) {
          specification.push({name: item, value: temp[item]});
        }
        data.data.text.specification = specification
      } else {
        data.data.text = {
          specification: [],
          info: ''
        }
      }
      data.data.subtext = data.data.title
      data.data.is_delivery_required = false
      return data
    },

    onSave() {
      if (this.$refs.form.validate() && this.image && this.seo_image) {
        this.click_save = true
        this.data.data.image = this.image
        this.data.data.seo_image = this.seo_image
        this.data.data.docs = this.docs
        this.data.data.language_id = this.language
        this.saveItem(this.data)
        this.onClose()
      } else {
        this.error = true
      }
    },

    addSpecification() {
      this.data.data.text.specification.push({name: '', value: ''})
    },
    deleteSpecification(id) {
      this.data.data.text.specification.splice(id, 1)
      this.key++
    },

    deleteDoc(id) {
      this.deleted_files.push(this.docs[id].url)
      this.docs.splice(id, 1)
      this.key++
    },

    deleteImage(id) {
      this.deleted_files.push(this.image[id])
      this.image.splice(id, 1)
    },

    loadImage(field) {
      this.field = field
      field === 'doc' ? this.$refs.doc.click() : this.$refs.file.click();
    },

    handleFileUpload() {
      this.field === 'doc' ? this.load_file = this.$refs.doc.files[0] : this.load_file = this.$refs.file.files[0];
    },

    onClose() {
      if (this.click_save) {
        console.log('жмакнули кнопку сохранения записи')
        for (let del in this.deleted_files) {
          this.destroyFile(this.deleted_files[del])
        }
      } else {
        console.log('не сохраняя закрыли окно')
        for (let del in this.new_files) {
          this.destroyFile(this.new_files[del])
        }
      }
      this.deleted_files = []
      this.new_files = []
      this.click_save = false
    },

    async submitFile() {
      let formData = new FormData();
      formData.append("file", this.load_file);
      console.log(this.load_file)
      if (this.field === 'image') {
        await this.uploadFile({
          data: formData,
          use_case: "cover",
        }).then(() => {
          this.image.push(this.file.url)
          this.new_files.push(this.file.url)
          this.key++
        });
      } else if (this.field === 'seo_image') {
        await this.uploadFile({
          data: formData,
          use_case: "icon",
        }).then(() => {
          if (this.seo_image && this.seo_image.length > 1) {
            this.deleted_files.push(this.seo_image)
          }
          this.seo_image = this.file.url
          this.new_files.push(this.file.url)
          this.key++
        });
      } else {
        await this.uploadFile({
          data: formData,
          use_case: "file",
        }).then(() => {
          this.docs.push({name: this.temp || this.load_file.name, url: this.file.url})
          this.new_files.push(this.file.url)
          this.temp = ''
          this.key++
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-columns: 2fr 3.5fr;
  grid-gap: 20px;
  margin-top: 20px;
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    grid-template-columns: 1fr;
  }
  @media #{map-get($display-breakpoints, 'lg-only')} {
    grid-template-columns: 1fr 1.5fr;
  }

  &_inner {
    @extend .grid;
    margin-top: 0;
    grid-template-columns: 1.5fr 1fr;
    grid-template-rows: auto;
    @media screen and (max-width: 2500px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
    }
  }

  &_price {
    @extend .grid;
    grid-template-columns: minmax(auto, 50px) minmax(auto, 120px) !important;
    grid-gap: 8px;
    align-items: center;
  }

  &_specification {
    @extend .grid;
    grid-template-columns: 1fr 1fr auto;
    grid-template-rows: auto;
    grid-gap: 8px;
    align-items: center;
    margin-top: 8px;
  }

  &_doc {
    @extend .grid;
    grid-template-columns: auto auto auto 1fr;
    grid-template-rows: auto;
    grid-gap: 8px;
    align-items: center;
  }
}
</style>
