<template>
  <DefaultPageToolbar
      :total="Number(list.meta.total)"
      :last_page="list.meta.last_page"
      :create-text="$store.state.t[this.$route.params.language]['page_toolbar']['add_order_status']"
      :on-create="openNewItem"
  >
    <DefaultPageTable
        :title="$store.state.t[this.$route.params.language]['menu']['order_status']"
        :data="list.data"
        :headers="headers"
        :on-delete="askDeleteItem"
        :ask-delete-fields="['title']"
        :on-edit="editItem"
    />
  </DefaultPageToolbar>
</template>

<script>
import DefaultPageToolbar from "components/default/page/DefaultPageToolbar";
import {mapActions} from "vuex";
import DefaultPageTable from "components/default/page/DefaultPageTable";
export default {
  name: "order-status",
  components: {DefaultPageTable, DefaultPageToolbar},
  data: () => ({
    list: {meta: {}, data: []},
    id: 0,
    name: '',
    modalNewItem: false,
    modalAskDelete: false
  }),
  computed: {
    headers() {
      return [
        {
          value: ["title"],
          text: this.$store.state.t[this.$route.params.language]['fields']['title']
        }
      ]
    }
  },
  methods: {
    async getList() {
      //
    },
    transformList(data) {
     //
    },
    closeModals() {
      this.getList();
      this.modalNewItem = false;
      this.modalAskDelete = false;
    },
    openNewItem() {
      this.modalNewItem = true;
    },
    askDeleteItem(id, name) {
      this.id = id;
      this.name = name;
      this.modalAskDelete = true;
    },
    async deleteItem() {
      //
    },
    editItem(id) {
      //
    }
  }
}
</script>

<style scoped>

</style>
