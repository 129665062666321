<template>
  <PagesBlogArticleId/>
</template>

<script>
import PagesBlogArticleId from "components/pages/blog/PagesBlogArticleId";

export default {
  name: "article_id",
  components: {PagesBlogArticleId},
}
</script>
