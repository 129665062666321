<template>
    <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.121 15L21.3645 19.242C21.646 19.5235 21.8041 19.9052 21.8041 20.3032C21.8041 20.7013 21.646 21.083 21.3645 21.3645C21.083 21.646 20.7013 21.8041 20.3032 21.8041C19.9052 21.8041 19.5235 21.646 19.242 21.3645L15 17.121L10.758 21.3645C10.4765 21.646 10.0948 21.8041 9.69675 21.8041C9.29871 21.8041 8.91696 21.646 8.6355 21.3645C8.35404 21.083 8.19592 20.7013 8.19592 20.3032C8.19592 20.1062 8.23474 19.911 8.31016 19.7289C8.38558 19.5468 8.49613 19.3814 8.6355 19.242L12.879 15L8.6355 10.758C8.35404 10.4765 8.19592 10.0948 8.19592 9.69675C8.19592 9.49966 8.23474 9.3045 8.31016 9.12241C8.38558 8.94032 8.49613 8.77487 8.6355 8.6355C8.77487 8.49613 8.94032 8.38558 9.12241 8.31016C9.3045 8.23474 9.49966 8.19592 9.69675 8.19592C10.0948 8.19592 10.4765 8.35404 10.758 8.6355L15 12.879L19.242 8.6355C19.5235 8.35404 19.9052 8.19592 20.3032 8.19592C20.7013 8.19592 21.083 8.35404 21.3645 8.6355C21.646 8.91696 21.8041 9.29871 21.8041 9.69675C21.8041 10.0948 21.646 10.4765 21.3645 10.758L17.121 15ZM15 30C6.7155 30 0 23.2845 0 15C0 6.7155 6.7155 0 15 0C23.2845 0 30 6.7155 30 15C30 23.2845 23.2845 30 15 30ZM15 27C18.1826 27 21.2348 25.7357 23.4853 23.4853C25.7357 21.2348 27 18.1826 27 15C27 11.8174 25.7357 8.76516 23.4853 6.51472C21.2348 4.26428 18.1826 3 15 3C11.8174 3 8.76516 4.26428 6.51472 6.51472C4.26428 8.76516 3 11.8174 3 15C3 18.1826 4.26428 21.2348 6.51472 23.4853C8.76516 25.7357 11.8174 27 15 27Z"
            fill="#EC1B0E"
        />
    </svg>
</template>

<script>
    export default {
        name: 'CrossMark'
    }
</script>

<style scoped>
</style>
