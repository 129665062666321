<template>
  <div v-if="order.id">
    <div class="grid">
      <v-sheet>
        <v-card class="border pa-4">
          <DevImageBlock :text="t['images']" :images="images"/>
          <DevInput
              autocomplete
              v-if="locationList.data"
              :text="t['location']"
              :items="locationList.data"
              v-model="order.service_unit.location"
              item-value="name"
          />
          <DevInput
              :text="t['created_at']"
              v-model="order.created_at"
              disabled
              hide-details
          />
        </v-card>
      </v-sheet>
      <v-sheet>
        <v-card class="border pa-4">
          <DevInput
              v-if="service.service_category"
              autocomplete
              :text="t['service']"
              v-model="service.service_category"
              :items="service.service_categories"
              item-text="title"
              return-object
              @change="onChangeCategory"
          />
          <DevInput
              autocomplete
              :text="t['product']"
              v-model="order.service"
              :items="serviceList.data"
              item-value="title"
              return-object
              @change="onChangeService"
          />
          <DevInput
              v-if="serviceUnit.data"
              autocomplete
              :text="t['unit']"
              v-model="order.service_unit"
              :items="serviceUnit.data"
              return-object
              hide-details
              @change="onChangeUnit"
          />
        </v-card>
        <v-card class="border pa-4 mt-4">
          <div class="grid_datetime-picker">
            <div>
              <v-input hide-details class="font12">
                {{ t['date_from'] }}
              </v-input>
              <v-datetime-picker
                  @input="getDaysCount"
                  v-model="order.start"
                  :textFieldProps="{
                  outlined: true,
                  dense: true,
                  'hide-details': true }"
                  :datePickerProps="{
                'no-title': true,
                scrollable: true }"
                  :timePickerProps="{
                'no-title': true,
                scrollable: true,
                'ampm-in-title': true }"
                  :okText="t['save']"
                  :clearText="t['clear']"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar-outline</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock-outline</v-icon>
                </template>
              </v-datetime-picker>
            </div>
            <div>
              <v-input hide-details class="font12">
                {{ t['date_to'] }}
              </v-input>
              <v-datetime-picker
                  @input="getDaysCount"
                  v-model="order.end"
                  :textFieldProps="{
                outlined: true,
                dense: true,
                'hide-details': true }"
                  :datePickerProps="{
                'no-title': true,
                scrollable: true }"
                  :timePickerProps="{
                'no-title': true,
                scrollable: true,
                'ampm-in-title': true }"
                  :okText="t['save']"
                  :clearText="t['clear']"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar-outline</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock-outline</v-icon>
                </template>
              </v-datetime-picker>
            </div>
          </div>
          <v-input hide-details class="text--darken-1 mt-5 mb-2">
            {{ t['price'] }}
          </v-input>
          <div v-if="service.data">
            <v-sheet
                v-if="!service.data.is_no_price"
                class="grid_price"
            >
              <v-input hide-details>1 день</v-input>
              <v-text-field
                  v-model="service.data.price"
                  outlined
                  disabled
                  hide-details
              >
                <template slot="append-outer">
                  <v-icon size="20">mdi-currency-eur</v-icon>
                </template>
              </v-text-field>
              <v-input hide-details>3 дня</v-input>
              <v-text-field
                  v-model="service.data.price2"
                  outlined
                  disabled
                  hide-details
              >
                <template slot="append-outer">
                  <v-icon size="20">mdi-currency-eur</v-icon>
                </template>
              </v-text-field>
              <v-input hide-details>7 дней</v-input>
              <v-text-field
                  v-model="service.data.price3"
                  outlined
                  disabled
              >
                <template slot="append-outer">
                  <v-icon size="20">mdi-currency-eur</v-icon>
                </template>
              </v-text-field>
            </v-sheet>
          </div>
          <DevInput
              :text="t['discount_code']"
              v-model="order.discount_code"
          />
          <DevInput
              :text="t['total_price']"
              v-model="order.price"
          >
            <v-icon slot="append-outer" size="20" class="mt-1">mdi-currency-eur</v-icon>
          </DevInput>
        </v-card>
      </v-sheet>
      <v-sheet class="status_block">
        <v-card class="border pa-4">
          <DevInput
              select
              :text="t['status']"
              v-model="order.status"
              :items="order_status"
              item-value="name"
              item-text="title"
              return-object
              hide-details
          />
        </v-card>
        <v-card class="border pa-4 mt-4">
          <v-input hide-details class="text--darken-1 mb-2">
            {{ t['client'] }}
          </v-input>
          <div class="grid_client">
            <DevInput
                :text="t['first_name']"
                v-model="order.first_name"
                hide-details
                disabled
            />
            <DevInput
                :text="t['email']"
                v-model="order.email"
                hide-details
                disabled
            />
            <DevInput
                class="grid_last_name"
                :text="t['last_name']"
                v-model="order.last_name"
                hide-details
                disabled
            />
            <DevInput
                :text="t['phone']"
                v-model="order.phone"
                hide-details
                disabled
            />
          </div>
        </v-card>
      </v-sheet>
    </div>
    <div class="py-10">
      <v-btn @click="saveOrder" class="primary mx-4 mx-lg-0">
        {{t['save']}}
      </v-btn>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import moment from 'moment';
import DevImageBlock from "@/components/dev/DevImageBlock";
import DevInput from "@/components/dev/DevInput";

const _MS_PER_DAY = 1000 * 60 * 60 * 24;
export default {
  name: "PagesOrdersId",
  components: {DevInput, DevImageBlock},
  data: () => ({
    order: {},
    images: [],
    tempNumber: 0
  }),
  computed: {
    ...mapGetters(["number"]),
    ...mapGetters("location", ["locationList"]),
    ...mapGetters("service", ["serviceList", "service", "serviceUnit"]),
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['fields'],
          this.$store.state.t[this.$route.params.language]['form'],
          this.$store.state.t[this.$route.params.language]['buttons']
      )
    },
    order_status() {
      return Object.assign(this.$store.state.t[this.$route.params.language]['order_status'])
    },
  },
  created() {
    this.tempNumber = this.number
    this.$store.commit("SetPage", 1);
    this.$store.commit("SetNumber", 0);
    this.$store.commit('SetPageName', '')
  },
  mounted() {
    this.getOrder();
    this.onLocationList();
  },
  beforeDestroy() {
    this.$store.commit("SetNumber", this.tempNumber);
  },
  methods: {
    ...mapActions("order", ["onOrder", "onCheckCode", "onOrderSave"]),
    ...mapActions("location", ["onLocationList"]),
    ...mapActions("service", ["onServiceList", "onService", "onServiceUnit"]),

    async getOrder() {
      await this.onOrder(this.$route.params.id).then(res => {
        this.order = res.data;
        this.images = JSON.parse(res.data.service.image);
        this.order.start = new Date(this.order.start);
        this.order.end = new Date(this.order.end);
        this.getServices(res.data.service.id);
        this.$store.commit('SetPageName', this.order.service.title)
      })
    },
    async getServices(id) {
      await this.onService({id: id, lang: 1}).then(res => {
        this.onServiceList({category: res.data.service_category.id})
      })
      await this.onServiceUnit({service: id})
    },
    onChangeCategory(item) {
      this.onServiceList({category: item.id})
      this.order.service = {}
      this.order.service_unit = {}
    },
    onChangeService(item) {
      this.getServices(item.id)
      this.order.service_unit = {}
    },
    onChangeUnit() {
      this.order.service_unit.location = this.locationList.data[0].name
    },
    getDaysCount() {
      if (this.order.start && this.order.end) {
        this.days = Math.abs(Math.ceil((this.order.end - this.order.start) / _MS_PER_DAY));
        console.log('days: ')
        console.log(this.days)
        if (this.service.data) this.getPrice()
      }
    },
    async saveOrder() {
      let temp = {
        id: this.order.id,
        service_id: this.order.service.id,
        service_unit_id: this.order.service_unit.id,
        location: this.order.service_unit.location,
        start: moment(this.order.start).format('YYYY-MM-DD hh:mm:ss'),
        end: moment(this.order.end).format('YYYY-MM-DD hh:mm:ss'),
        email: this.order.email,
        phone: this.order.phone,
        first_name: this.order.first_name,
        last_name: this.order.last_name
      }
      await this.onOrderSave(temp).then(() => {
        this.getOrder();
        window.scrollTo(0,0);
      })
    }
  }
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr;
  grid-gap: 20px;
  margin-top: 30px;
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    grid-template-columns: 1fr;
  }
  @media #{map-get($display-breakpoints, 'lg-only')} {
    grid-template-columns: 1fr 1fr;
  }

  .status_block {
    @media #{map-get($display-breakpoints, 'lg-only')} {
      grid-row: 1/2;
      grid-column: 2/3;
    }
  }

  &_datetime-picker {
    @extend .grid;
    margin-top: 0 !important;
    grid-template-columns: 1fr 1fr;

  }

  &_price {
    @extend .grid;
    margin-top: 0;
    grid-template-columns: minmax(auto, 50px) minmax(auto, 120px) !important;
    grid-gap: 8px;
    align-items: center;
  }

  &_client {
    @extend .grid;
    margin-top: 0;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    align-items: center;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      grid-template-columns: 1fr;
    }
  }

  &_last_name {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      grid-row: 2/3;
    }
  }
}

::v-deep .theme--light.v-input--is-disabled input {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
