<template>
  <DefaultPageToolbar
      :create-text="$store.state.t[this.$route.params.language]['page_toolbar']['add_service']"
      :on-create="openNewItem"
      :total="Number(list.meta.total)"
      :last_page="list.meta.last_page"
  >
    <DefaultPageTable
        :title="$store.state.t[this.$route.params.language]['menu']['services']"
        :data="list.data"
        :headers="headers"
        :on-edit="editItem"
        :on-delete="askDeleteItem"
        :on-active="activeItem"
        :on-click="clickItem"
        :ask-delete-fields="['name']"
    />
    <v-dialog v-model="modalNewItem" :width="$vuetify.breakpoint.mdAndDown ? 600 : 1200">
      <PagesServicesId
          v-if="modalNewItem"
          :data="item"
          :on-save="createItem"
          :on-close="closeModals"
      />
    </v-dialog>
    <v-dialog v-model="modalEditItem" :width="$vuetify.breakpoint.mdAndDown ? 600 : 1200">
      <PagesServicesId
          v-if="modalEditItem"
          :data="item"
          :on-save="saveItem"
          :on-close="closeModals"
          :on-delete="askDeleteItem"
          :on-item="getItem"
      />
    </v-dialog>
    <v-dialog v-model="modalAskDelete" width="600" style="z-index: 1000">
      <DefaultPageAskDelete
          text="service"
          :name="name"
          :on-close="closeModals"
          :on-delete="deleteItem"
      />
    </v-dialog>
  </DefaultPageToolbar>
</template>

<script>
import DefaultPageToolbar from "@/components/default/page/DefaultPageToolbar";
import {mapActions, mapGetters} from "vuex";
import DefaultPageTable from "@/components/default/page/DefaultPageTable";
import PagesServicesId from "@/components/pages/services/PagesServicesId";
import DefaultPageAskDelete from "@/components/default/page/DefaultPageAskDelete";

export default {
  name: "services",
  components: {DefaultPageAskDelete, PagesServicesId, DefaultPageTable, DefaultPageToolbar},
  data: () => ({
    list: {
      meta: {}
    },
    item: {},
    id: 0,
    name: '',
    modalAskDelete: false,
    modalNewItem: false,
    modalEditItem: false
  }),
  computed: {
    headers() {
      return [
        {
          value: ["image", "name"],
          text: this.$store.state.t[this.$route.params.language]['fields']['service_name'],
          text_position: "name"
        }
      ]
    },
    ...mapGetters(["apiDomain"])
  },
  watch: {
    '$route.query'() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    ...mapActions("service", ["onServiceCategoryFieldPut", "onServiceCategoryList", "onServiceCategory", "onServiceCategoryCreate", "onServiceCategorySave", "onServiceCategoryDelete"]),
    async getList() {
      this.list = {meta: {}}
      await this.onServiceCategoryList({}).then(res => {
        this.list = res.data;
        for (let i in this.list.data) {
          this.transformList(this.list.data[i]);
        }
      })
    },
    transformList(data) {
      data.image = [{
        src: this.apiDomain + '/' + data.image,
        type: 'image'
      }]
    },
    closeModals() {
      this.modalAskDelete = false;
      this.modalNewItem = false;
      this.modalEditItem = false
    },
    openNewItem() {
      this.item = { image: '', seo_image: ''}
      this.modalNewItem = true;
    },
    askDeleteItem(id, name) {
      this.id = id;
      this.name = name;
      this.modalAskDelete = true;
    },
    async deleteItem() {
      await this.onServiceCategoryDelete(this.id).then(() => {
        this.getList();
        this.closeModals()
      })
    },
    async editItem(id) {
      this.id = id;
      this.modalEditItem = true
    },
    async getItem() {
      console.log('id: ' + this.id)
      await this.onServiceCategory(this.id).then(res => {
        console.log(res.data)
      })
    },
    saveItem(data) {
      this.onServiceCategorySave({id: data.id, data: data})
      this.getList();
    },
    createItem(data) {
      this.onServiceCategoryCreate(data)
      this.getList();
      this.closeModals()
    },
    activeItem(id, state) {
      this.onServiceCategoryFieldPut({id: id, data: {
          field: "is_active",
          value: state
        }})
    },
    clickItem(id) {
      let query = Object.assign({}, this.$route.query);
      query['service'] = id;
      this.$router.push({name: 'products', query})
    }
  }
}
</script>
