<template>
  <router-view/>
</template>

<script>
export default {
  name: "services_parent"
}
</script>

<style scoped>

</style>
