<template>
  <DefaultPageToolbar
      :total="Number(list.meta.total)"
      :last_page="list.meta.last_page"
      :create-text="$store.state.t[this.$route.params.language]['page_toolbar']['add_page']"
      :on-create="openNewItem"
  >
    <DefaultPageTable
        :title="$store.state.t[this.$route.params.language]['menu']['pages']"
        :data="list.data"
        :headers="headers"
        :on-delete="askDeleteItem"
        :ask-delete-fields="['title']"
        :on-edit="editItem"
    />
    <v-dialog v-model="modalEditItem" width="600">
      <PagesSettingsPage v-if="modalEditItem" :on-item="getItem" :on-save="saveItem" :on-close="closeModals"/>
    </v-dialog>
    <v-dialog v-model="modalNewItem" width="600">
      <PagesSettingsPage v-if="modalNewItem" :on-save="createItem" :on-close="closeModals"/>
    </v-dialog>
  </DefaultPageToolbar>
</template>

<script>
import DefaultPageToolbar from "components/default/page/DefaultPageToolbar";
import {mapActions} from "vuex";
import DefaultPageTable from "components/default/page/DefaultPageTable";
import PagesSettingsPage from "components/pages/settings/PagesSettingsPage";

export default {
  name: "pages",
  components: {PagesSettingsPage, DefaultPageTable, DefaultPageToolbar},
  data: () => ({
    list: { meta: {}},
    id: 0,
    name: '',
    modalNewItem: false,
    modalEditItem: false,
    modalAskDelete: false
  }),
  computed: {
    headers() {
      return [
        {
          value: ["title"],
          text: this.$store.state.t[this.$route.params.language]['fields']['title']
        }
      ]
    }
  },
  watch: {
    '$route.query'() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    ...mapActions("pages", ["onPageList", "onPage", "onPageSave"]),
    async getList() {
      this.list = { meta: {}}
      await this.onPageList().then(res => {
        this.list = res.data;
      })
    },
    closeModals() {
      this.modalNewItem = false;
      this.modalEditItem = false;
      this.modalAskDelete = false;
    },
    openNewItem() {
      this.modalNewItem = true;
    },
    askDeleteItem(id, name) {
      this.id = id;
      this.name = name;
      this.modalAskDelete = true;
    },
    async deleteItem() {
      // await this.onDeleteOrder(this.id).then(() => {
      //   this.getList();
      //   this.closeModals()
      // })
    },
    editItem(id) {
      this.id = id
      this.modalEditItem = true
      // this.$router.push({name: 'order_id', params: {id: id}})
    },
    async getItem(){
      await this.onPage(this.id).then(res => {
        console.log(res)
      })
    },
    async saveItem(data) {
      await this.onPageSave({id: this.id, data: data}).then(() => {
        this.getList()
      })
    },
    async createItem(data) {
      await this.onPageCreate(data).then(() => {
        this.getList()
        this.closeModals
      })
    }
  }
}
</script>

<style scoped>

</style>
