export default {
    namespaced: true,
    state: {
        notificationShow: false,
        notificationError: false
    },
    mutations: {
        setNotificationShow(state, payload) {
            state.notificationShow = payload
        },
        setNotificationError(state, payload) {
            state.notificationError = payload
        },

    },
    getters: {
        notificationShow: state => state.notificationShow,
        notificationError: state => state.notificationError
    },
    actions: {
        onNotification({commit}, data) {
            commit('setNotificationShow', true)
            commit('setNotificationError', data.IsError)
            setTimeout(function () {
                commit('setNotificationShow', false)
            }, 3000)
        },
    }
}
