<template>
  <div v-if="user.data" :key="key">
    <v-btn @click="addModal = true" class="primary my-5 ml-6">
      {{ $store.state.t[this.$route.params.language]['page_toolbar']['add_address'] }}
    </v-btn>
    <DefaultPageTable
      :headers="headers"
      :data="user.data.addresses"
      :on-delete="showModalDelete"
      :on-edit="showModalEdit"
      :on-click="test"
      :ask-delete-fields="['street', 'city']"
    />
    <v-dialog v-model="addModal" width="600">
      <PagesClientJurAddressModal
        v-if="addModal"
        :add-new="addAddress"
        :on-close="closeModalAdd"
      />
    </v-dialog>
    <v-dialog v-model="editModal" width="600">
      <PagesClientJurAddressModal
        v-if="editModal"
        :on-edit="editAddress"
        :item="user.data.addresses[selectIndex]"
        :on-close="closeModalEdit"
        edit
      />
    </v-dialog>
    <v-dialog v-model="deleteModal" width="600">
      <DefaultPageAskDelete text="address" :on-close="closeModalDelete" :on-delete="deleteAddress" :name="name"/>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DefaultPageTable from 'components/default/page/DefaultPageTable'
import DefaultPageModal from 'components/default/page/DefaultPageModal'
import DefaultPageAskDelete from 'components/default/page/DefaultPageAskDelete'
import DevInput from 'components/dev/DevInput'
import PagesClientJurAddressModal from 'components/pages/clients/PagesClientJurAddressModal'

export default {
  name: 'jur_address',
  components: {
    PagesClientJurAddressModal,
    DevInput,
    DefaultPageModal,
    DefaultPageTable,
    DefaultPageAskDelete,
  },
  data: () => ({
    addModal: false,
    key: 0,
    tableKey: 0,
    editModal: false,
    deleteModal: false,
    selectIndex: null,
    name: ''
  }),
  computed: {
    headers() {
      return [
        {
          value: ['street'],
          text: this.$store.state.t[this.$route.params.language]['fields']['street'],
        },
        {
          value: ['zip'],
          text: this.$store.state.t[this.$route.params.language]['fields']['zip'],
        },
        {
          value: ['city'],
          text: this.$store.state.t[this.$route.params.language]['fields']['city'],
        },
        {
          value: ['description'],
          text: this.$store.state.t[this.$route.params.language]['fields']['description'],
        },
      ]
    },
    ...mapGetters('user', ['user']),
  },
  methods: {
    ...mapActions('user', ['onUser', 'onUserSave']),
    test() {

    },
    preparingDataForSending(data) {
      let saveData = {}
      saveData.id = data.id
      saveData.address = JSON.stringify(data.address)
      saveData.first_name = data.first_name
      saveData.last_name = data.last_name
      saveData.role_id = data.role_id
      saveData.email = data.email
      if (typeof data.data !== 'string') {
        saveData.data = JSON.stringify(data.data)
      }
      return saveData
    },
    async onSaveChange() {
      await this.onUserSave(this.preparingDataForSending(this.user))
      // .then(() =>
      //   this.$parent.$parent.$parent.getItem(),
      // )
    },
    async addAddress(data) {
      if (this.addModal) {
        if(this.user.data.addresses.length) {
          // data.id = this.user.data.addresses.length + 1
          const maxId = await this.user.data.addresses.reduce((acc, curr) => acc.id > curr.id ? acc : curr).id
          data.id = maxId + 1
        } else {
          data.id = 1
        }
      }
      this.user.data.addresses.push(data)
      await this.onSaveChange()
      this.closeModalAdd()
    },
    async editAddress(data) {
      this.user.data.addresses[this.selectIndex] = data
      await this.onSaveChange()
      this.closeModalEdit()
    },
    showModalEdit(item,index) {
      this.selectIndex = index
      this.editModal = true
    },
    showModalDelete(id, name, index) {
      this.name = name
      this.selectIndex = index
      this.deleteModal = true
    },
    async deleteAddress() {
      this.user.data.addresses.splice(this.selectIndex, 1)
      await this.onSaveChange()
      this.closeModalDelete()
    },
    closeModal() {
      this.selectIndex = null
      this.key++
    },
    closeModalAdd() {
      this.closeModal()
      this.addModal = false
    },
    closeModalEdit() {
      this.closeModal()
      this.editModal = false
    },
    closeModalDelete() {
      this.closeModal()
      this.deleteModal = false
    },
  },
}
</script>
