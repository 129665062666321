<template>
  <v-card class="px-5 px-sm-10 pt-12 pb-14 text-center">
    <v-btn @click="close" absolute top right icon>
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <span class="title">
      {{ text }}
    </span>
    <div class="my-5">
      <slot/>
    </div>
    <div class="d-flex justify-center">
      <slot name="btn"/>
    </div>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DefaultPageModal",
  props: {
    text: {
      type: String,
      default: ''
    },
    onClose: Function
  },
  computed: {
    ...mapGetters(["number"]),
  },
  methods: {
    close(){
      this.onClose()
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  font-size: 24px !important;
  font-weight: 500 !important;
}
</style>
