import axios from "axios";

const state = {
  authUser: []
};

const getters = {
  authUser(state) {
    return state.authUser;
  }
};

const mutations = {
  setAuthUser: (state, item) => {
    state.authUser = item;
  }
};

const actions = {
  async onAuthUser({commit}) {
    let url = this.state.apiDomain + "/api/online/user/data";
    await axios
      .get(url)
      .then(response => {
        commit("setAuthUser", response.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  // eslint-disable-next-line no-unused-vars
  async onAuthUserUpdate({commit}, data) {
    let url = this.state.apiDomain + "/api/online/user/data"
    await axios
      .put(url, data)
      .then(response => {
        console.log(response)
      })
      .catch(error => {
        console.log(error)
      })
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
