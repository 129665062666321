<template>
  <DefaultPageModal
      :text="t['page']"
      :on-close="onClose"
  >
    <DefaultPageLanguage/>
    <v-form ref="form" lazy-validation :key="key">
      <DevInput
          :text="t['page']"
          v-model="data.name"
          :rules="[v => !!v || t['required_field']]"
      />
      <DevInput
          :text="t['title']"
          v-model="data.title"
          :rules="[v => !!v || t['required_field']]"
      />
      <DevInput
          :text="t['url']"
          v-model="data.url"
          :rules="[v => !!v || t['required_field']]"
      />
      <div class="d-flex align-center">
        <DevImageBlock :images="Array(image)" :text="t['image']"/>
        <v-btn class="second mt-4 ml-2">
          {{image ? t['change'] : t['add']}}
        </v-btn>
      </div>
      <v-card class="pa-4 border text-start">
        SEO
        <DevInput
            :text="t['title']"
            v-model="data.seo_title"
            :rules="[v => !!v || t['required_field']]"
        />
        <DevInput
            :text="t['keywords']"
            v-model="data.keywords"
            :rules="[v => !!v || t['required_field']]"
        />
        <DevInput
            :text="t['description']"
            v-model="data.description"
            text-area
            :rules="[v => !!v || t['required_field']]"
            class="mb-n4"
        />
      </v-card>
    </v-form>
    <v-btn @click="saveItem" slot="btn" class="primary">
      {{data.id ? t['save'] : t['create']}}
    </v-btn>
  </DefaultPageModal>
</template>

<script>
import DefaultPageModal from "components/default/page/DefaultPageModal";
import {mapGetters} from "vuex";
import DefaultPageLanguage from "components/default/page/DefaultPageLanguage";
import DevInput from "components/dev/DevInput";
import DevImageBlock from "components/dev/DevImageBlock";

export default {
  name: "PagesSettingsPage",
  components: {DevImageBlock, DevInput, DefaultPageLanguage, DefaultPageModal},
  props: {
    onClose: {},
    onSave: {},
    onItem: {}
  },
  data: () => ({
    data: {},
    key: 0,
    image: null,
    deleted_images: [],
    new_images: [],
    load_file: '',
    saved: false
  }),
  computed: {
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['page_toolbar'],
          this.$store.state.t[this.$route.params.language]['fields'],
          this.$store.state.t[this.$route.params.language]['buttons'],
          this.$store.state.t[this.$route.params.language]['form'],
      )
    },
    ...mapGetters(["language", "languages", "apiDomain", "file"]),
    ...mapGetters("pages", ["pageId"])
  },
  watch: {
    pageId() {
      this.data = Object.assign(this.pageId.data)
      this.data.language_id = this.language
      if (!this.image) {
        this.image = this.data.image
      }
      this.key++
    },
    language() {
      this.getItem()
      this.key++
    }
  },
  created() {
    this.getItem()
  },
  methods: {
    getItem() {
      if (this.onItem) {
        this.onItem()
      }
    },

    saveItem() {
      if (this.$refs.form.validate()) {
        this.saved = true
        this.onSave(this.data)
        this.closeModal()
      }
    },

    closeModal() {
      this.saved = false
    }
  }
}
</script>

<style scoped>

</style>
