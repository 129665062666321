import axios from "axios";

const state = {
    authorList: [],
    author: []
};

const getters = {
    authorList(state) {
        return state.authorList;
    },
    author(state) {
        return state.author;
    }
};

const mutations = {
    setAuthorList: (state, item) => {
        state.authorList = item;
    },
    setAuthor: (state, item) => {
        state.author = item;
    }
};

const actions = {
    onAuthorList({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/articleAuthor?number=" + this.state.number + "&page=" + this.state.page;
            axios
                .get(url)
                .then(response => {
                    commit("setAuthorList", response.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onAuthorFieldSave({commit}, {id, data}){
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/articleAuthor/" + id + "/field";
            axios
                .put(url, data)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onAuthorCreate({commit, dispatch}, data) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/articleAuthor";
            axios
                .post(url, data)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onAuthorDelete({commit, dispatch}, id) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/articleAuthor/" + id;
            axios
                .delete(url)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onAuthor({commit, dispatch}, id) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/articleAuthor/" + id + "/edit?language_id=" + this.state.language;
            axios
                .get(url)
                .then(response => {
                    commit("setAuthor", response.data);
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onAuthorSave({commit, dispatch}, {id, data}){
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/articleAuthor/" + id;
            axios
                .put(url, data)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },

};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
