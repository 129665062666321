<template>
  <div>
    <DefaultPageToolbar
        v-if="$route.name === 'orders'"
        :total="Number(list.amount)"
        :last_page="list.lastPage"
        :create-text="$store.state.t[this.$route.params.language]['page_toolbar']['add_order']"
        :on-create="openNewItem"
    >
      <DefaultPageTable
          :title="$store.state.t[this.$route.params.language]['menu']['orders']"
          :data="list.data"
          :headers="headers"
          :on-delete="askDeleteItem"
          :ask-delete-fields="['service']"
          :on-edit="editItem"
      />
      <v-dialog v-model="modalNewItem" width="600">
        <PagesOrdersNewOrder v-if="modalNewItem" :on-save="createItem" :on-close="closeModals"/>
      </v-dialog>
      <v-dialog v-model="modalAskDelete" width="600">
        <DefaultPageAskDelete
            text="order"
            :name="name"
            :on-close="closeModals"
            :on-delete="deleteItem"
        />
      </v-dialog>
    </DefaultPageToolbar>
    <router-view v-else/>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import DefaultPageToolbar from "@/components/default/page/DefaultPageToolbar";
import DefaultPageTable from "@/components/default/page/DefaultPageTable";
import PagesOrdersNewOrder from "@/components/pages/orders/PagesOrdersNewOrder";
import DefaultPageAskDelete from "@/components/default/page/DefaultPageAskDelete";

export default {
  name: "orders",
  components: {DefaultPageAskDelete, PagesOrdersNewOrder, DefaultPageTable, DefaultPageToolbar},
  data: () => ({
    list: {},
    id: 0,
    name: '',
    modalNewItem: false,
    modalAskDelete: false
  }),
  computed: {
    headers() {
      return [
        {
          value: ["service"],
          text: this.$store.state.t[this.$route.params.language]['fields']['product']
        },
        {
          value: ["status"],
          text: this.$store.state.t[this.$route.params.language]['fields']['status']
        }
      ]
    },
    order_status() {
      return Object.assign(this.$store.state.t[this.$route.params.language]['order_status'])
    },
  },
  watch: {
    '$route.query'() {
      if (this.$route.name === 'orders') {
        this.getList()
      }
    }
  },
  mounted() {
    if (this.$route.name === 'orders') {
      this.getList()
    }
  },
  methods: {
    ...mapActions("order", ["onOrderList", "onDeleteOrder", "onNewOrder"]),
    async getList() {
      this.list = {}
      await this.onOrderList({type: "order"}).then(res => {
        this.list = res.data;
        for (let i in this.list.data) {
          this.transformList(this.list.data[i]);
        }
      })
    },
    transformList(data) {
      data.service = data.service.title;
      data.status = [{
        value: this.order_status.filter(i => i.name === data.status)[0].title,
        color: this.order_status.filter(i => i.name === data.status)[0].color,
        type: 'btn'
      }]
      data.price = data.price + ' €';
      data.date = data.start.slice(0, 10).replace('-', '.').replace('-', '.') + ' - ' + data.end.slice(0, 10).replace('-', '.').replace('-', '.')

    },
    closeModals() {
      //this.getList();
      this.modalNewItem = false;
      this.modalAskDelete = false;
    },
    openNewItem() {
      this.modalNewItem = true;
    },
    askDeleteItem(id, name) {
      this.id = id;
      this.name = name;
      this.modalAskDelete = true;
    },
    async deleteItem() {
      await this.onDeleteOrder(this.id).then(() => {
        this.getList();
        this.closeModals()
      })
    },
    editItem(id) {
      this.$router.push({name: 'order_id', params: {id: id}})
    },
    async createItem(data) {
      await this.onNewOrder(data).then(() => {
        this.getList();
        this.closeModals()
      })
    }
  }
}
</script>
