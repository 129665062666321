<template>
  <div v-if="$route.fullPath !== '/'">
    <v-app-bar
        app
        fixed
        clipped-left
        dark
        flat
        height="48"
        color="secondary"
    >
      <v-btn
          text
          plain
          retain-focus-on-click
          depressed
          @click="drawer = true"
          class="hidden-lg-and-up"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-btn
          text
          plain
          retain-focus-on-click
          depressed
          :to="'/' + $route.params.language"
          @click="goHome"
      >
        <Logo class="hidden-xs-only"/>
        <LogoXS class="hidden-sm-and-up"/>
      </v-btn>
      <v-menu tile offset-y open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              text
              plain
              retain-focus-on-click
              depressed
              :color="attrs['aria-expanded'] === 'true' ? 'primary' : 'white'"
              v-bind="attrs"
              v-on="on"
          >
            <v-icon class="account">mdi-account</v-icon>
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
              link
              v-for="item in account"
              :key="item.name"
              @click="onAccount(item.to)"
          >
            <v-icon color="primary" class="mr-2">
              {{ item.icon }}
            </v-icon>
            <span>{{ t[item.name] }}</span>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
        app
        fixed
        clipped
        v-model="drawer"
        :mini-variant="miniVariant"
        mini-variant-width="88"
        width="316"
        class="shadow"
    >
      <v-list v-if="menu.length > 0" :key="componentKey">
        <div v-for="item in menu.length" :key="menu[item - 1].name">
          <v-list-group
              v-if="!miniVariant"
              no-action
              @click="goto(menu[item - 1])"
              :class="item === list_item ? 'item--active' : ''"
          >
            <template slot="prependIcon">
              <v-icon class="primary--text">{{ menu[item - 1].icon }}</v-icon>
            </template>
            <template slot="appendIcon">
              <v-icon v-if="menu[item - 1].items">mdi-chevron-down</v-icon>
              <v-icon v-else></v-icon>
            </template>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{
                    t[menu[item - 1].name]
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
                v-for="(j, l) in menu[item - 1].items"
                :key="l"
                @click="gotoLink(item, j)"
                link
            >
              <v-list-item-icon>
                <Icon :name="j" size="20" class="primary--text"/>
                <!--                <v-icon size="20" class="primary--text">-->
                <!--                  mdi-file-document-outline-->
                <!--                </v-icon>-->
              </v-list-item-icon>
              <v-list-item-title
                  :class="$route.name === j ? 'primary--text' : ''"
                  v-text="t[j]"
              />
            </v-list-item>
          </v-list-group>
          <v-menu v-else tile offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-list-group
                  v-bind="attrs"
                  v-on="on"
                  @click="goto(menu[item - 1])"
                  :class="item === list_item ? 'item--active' : ''"
              >
                <template slot="prependIcon">
                  <v-icon class="primary--text">
                    {{ menu[item - 1].icon }}
                  </v-icon>
                </template>
              </v-list-group>
            </template>
            <v-list-item
                v-for="(j, l) in menu[item - 1].items"
                :key="l"
                @click="gotoLink(item, j)"
                link
            >
              <v-list-item-icon>
                <Icon :name="j" size="20" class="primary--text"/>
                <!--                <v-icon size="20" class="primary&#45;&#45;text">-->
                <!--                  mdi-file-document-outline-->
                <!--                </v-icon>-->
              </v-list-item-icon>
              <v-list-item-title
                  :class="$route.name === j ? 'primary--text' : ''"
                  v-text="t[j]"
              />
            </v-list-item>
          </v-menu>
        </div>
      </v-list>
      <template v-slot:append>
        <div class="hidden-md-and-down">
          <v-divider/>
          <div
              class="d-flex"
              :class="miniVariant ? 'justify-center' : 'justify-end mr-4'"
          >
            <v-btn
                @click.stop="miniVariant = !miniVariant"
                class="mini"
                color="secondary"
            >
              <v-icon
                  size="26"
                  :class="miniVariant ? 'rotate' : ''"
                  class="min"
              >
                mdi-arrow-left
              </v-icon>
            </v-btn>
          </div>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Logo from "@/components/svg/Logo";
import LogoXS from "@/components/svg/LogoXS";
import {mapActions, mapGetters} from "vuex";
import Icon from "@/components/svg/Icon";

export default {
  name: "DefaultHeader",
  components: {Icon, LogoXS, Logo},
  data: () => ({
    list_item: null,
    drawer: true,
    miniVariant: false,
    componentKey: 0,
    account: [
      {
        name: "account",
        icon: "mdi-account",
        to: "account"
      },
      {
        name: "exit",
        icon: "mdi-exit-to-app"
      }
    ],
    menu: [
      {
        name: "ord",
        icon: "mdi-format-list-bulleted",
        items: ["orders", "projects"]
      },
      {
        name: "messages",
        icon: "mdi-message-alert-outline"
      },
      {
        name: "clients",
        icon: "mdi-account-outline",
        items: ["phys", "jur"]
      },
      {
        name: "locations",
        icon: "mdi-map-marker-radius-outline"
      },
      {
        name: "discount",
        icon: "mdi-label-percent-outline"
      },
      {
        name: "services",
        icon: "mdi-clipboard-text-outline",
        items: ["services", "products", "units"]
      },
      {
        name: "blog",
        icon: "mdi-file-document-outline",
        items: ["authors", "categories", "articles"]
      },
      {
        name: "settings",
        icon: "mdi-cog-outline",
        items: ["pages",
          // "order_status",
          "control",
          //"interface",
        "languages",
          //"roles",
        "users"]
      }
    ]
  }),
  computed: {
    t() {
      return Object.assign(this.$store.state.t[this.$route.params.language]['menu'])
    },
    ...mapGetters(["number"])
  },
  watch: {
    '$route'() {
      this.checkUrl()
    }
  },
  created() {
    this.checkUrl()
  },
  methods: {
    ...mapActions('auth', ['onAuthLogout', 'onAuthIsLoggedIn']),
    checkUrl() {
      let temp = 0;
      for (let i in this.menu) {
        if (this.menu[i].name === this.$route.name) {
          temp = Number(i) + 1
        } else if (this.menu[i].items) {
          for (let j in this.menu[i].items) {
            if (this.menu[i].items[j] === this.$route.name) {
              temp = Number(i) + 1
            }
          }
        }
      }
      this.list_item = temp;
      //this.list_item = this.menu.indexOf(this.menu.filter(i => i.name === this.$route.name)[0]) + 1;
    },
    goto(item) {
      if (!item.items) {
        this.list_item = this.menu.indexOf(item) + 1;
        this.$router.push({name: item.name, query: {number: this.number, page: 1}}).catch(() => {
        });
      }
    },
    gotoLink(item, url) {
      this.list_item = item;
      this.$router.push({name: url, query: {number: this.number, page: 1}}).catch(() => {
      });
    },
    goHome() {
      this.list_item = null;
      this.componentKey += 1;
    },
    async onAccount(url) {
      if (url) {
        await this.$router.push({name: 'account'});
      } else {
        await this.onAuthLogout().then(() => {
          this.$router.push({name: 'login'}).catch(() => {
          });
          this.onAuthIsLoggedIn();
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">
header {
  z-index: 50 !important;
}

::v-deep .v-toolbar__content {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  grid-gap: 1vw;
  justify-items: end;
  align-items: center;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 0 !important;
    grid-gap: 0;
  }
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    grid-template-columns: auto auto 1fr;
  }
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    padding: 0 8px !important;
  }

  .account {
    border: 1px solid currentColor !important;
    border-radius: 50%;
    padding: 3px !important;
    margin-right: 8px;
  }
}

::v-deep .v-btn {
  min-height: 100% !important;

  &--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content {
    opacity: 1 !important;
    /*@media #{map-get($display-breakpoints, 'xs-only')} {
      padding: 0 !important;
    }*/
  }
}

.v-menu__content {
  background-color: white !important;
}

.v-list {
  margin: 22px 0 !important;
}

.mini {
  width: 24px !important;
  min-width: auto !important;
  height: 24px !important;
  padding: 0 !important;
  margin: 16px !important;

  &:hover {
    background-color: var(--v-primary-base) !important;
  }

  .rotate {
    transform: rotateZ(-180deg);
  }

  ::v-deep .v-ripple__container {
    opacity: 0 !important;
  }
}

.item--active {
  & ::v-deep .v-list-group__header {
    box-shadow: inset 8px 0 0 -4px var(--v-primary-base) !important;

    .v-list-item__content {
      color: $primary !important;
    }
  }
}

.v-navigation-drawer--is-mobile {
  margin-top: 48px !important;
}
</style>
