<template>
  <div>
    <v-input v-if="text" hide-details class="font12">
      {{ text }}
    </v-input>
    <v-select
      v-if="select"
      v-model="selected"
      :items="items"
      :rules="rules"
      :item-text="itemText"
      :item-value="itemValue"
      :menu-props="menu_props"
      :hide-details="hideDetails"
      :return-object="returnObject"
      :disabled="disabled"
      @input="inputSelected"
      @change="changeSelected"
      append-icon="mdi-chevron-down"
      outlined
      dense
      :multiple="multiple"
      :chips="chips"
      :clearable="clearable"
      :hint="hint"
      :persistent-hint="!!hint"
    />
    <v-autocomplete
      v-else-if="autocomplete"
      v-model="selected"
      :items="items"
      :rules="rules"
      :item-text="itemText"
      :item-value="itemValue"
      :menu-props="menu_props"
      :hide-details="hideDetails"
      :return-object="returnObject"
      :disabled="disabled"
      @input="inputSelected"
      @change="changeSelected"
      append-icon="mdi-chevron-down"
      :multiple="multiple"
      :chips="chips"
      outlined
      dense
      :clearable="clearable"
      :hint="hint"
      :persistent-hint="!!hint"
    />
    <v-textarea
      v-else-if="textArea"
      v-model="selected"
      :disabled="disabled"
      :hide-details="hideDetails"
      :rules="rules"
      @input="inputSelected"
      @change="changeSelected"
      outlined
      auto-grow
      :hint="hint"
      :persistent-hint="!!hint"
    />
    <v-datetime-picker
      v-else-if="dateTime"
      v-model="selected"
      dateFormat="dd.MM.yyyy"
      @input="inputSelected"
      @change="changeSelected"
      :hint="hint"
      :persistent-hint="!!hint"
      :textFieldProps="{
        outlined: true,
        dense: true,
        class: 'mb-n4',
        rules: rules,
        'validate-on-blur': true,
      }"
      :datePickerProps="{
        'no-title': true,
        scrollable: true,
      }"
      :timePickerProps="{
        'no-title': true,
        scrollable: true,
        'ampm-in-title': true,
      }"
      :okText="t['save']"
      :clearText="t['clear']"
    >
      <template slot="dateIcon">
        <v-icon>mdi-calendar-outline</v-icon>
      </template>
      <template slot="timeIcon">
        <v-icon>mdi-clock-outline</v-icon>
      </template>
    </v-datetime-picker>
    <v-text-field
      v-else
      v-model="selected"
      :hide-details="hideDetails"
      :disabled="disabled"
      :rules="rules"
      @input="inputSelected"
      @change="changeSelected"
      outlined
      dense
      :type="type"
      :hint="hint"
      :persistent-hint="!!hint"
    >
      <template slot="append-outer">
        <slot name="append-outer" />
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'DevInput',
  props: {
    select: Boolean,
    autocomplete: Boolean,
    textArea: Boolean,
    dateTime: Boolean,
    text: {
      type: [String, Function],
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
    value: {
      type: [Number, String, Object, Array, Date],
      default: null,
    },
    itemText: {
      type: [String, Function],
      default: 'name',
    },
    itemValue: {
      type: [String, Function],
      default: 'id',
    },
    hint: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => {
        return []
      },
    },
    type: {
      type: String,
      default: '',
    },
    hideDetails: Boolean,
    disabled: Boolean,
    returnObject: Boolean,
    multiple: Boolean,
    chips: Boolean,
    clearable: Boolean
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('change', val)
      },
    },
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['form'],
        this.$store.state.t[this.$route.params.language]['buttons'],
        this.$store.state.t[this.$route.params.language]['page_toolbar'],
      )
    },
  },
  data: () => ({
    selected: null,
    menu_props: {
      bottom: true,
      offsetY: true,
      tile: true,
      'open-on-hover': false,
    },
  }),
  created() {
    this.selected = this.value
  },
  methods: {
    inputSelected() {
      this.$emit('input', this.selected)
    },
    changeSelected() {
      this.$emit('change', this.selected)
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .theme--light.v-input--is-disabled input,
::v-deep .theme--light.v-input--is-disabled textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}

::v-deep .v-chip--select {
  margin: 2px !important;
  background-color: $primary !important;
  color: white !important;
}
</style>
