<template>
  <svg width="165" height="20" viewBox="0 0 165 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.400002 0.400024H2.8524L10.8784 17.1896L18.7133 0.400024H21.1936L12.1604 19.4486H9.59649L0.400002 0.400024ZM4.0786 0.400024H6.58673L10.8506 9.82671L15.0029 0.400024H17.5389L11.0456 14.3727H10.7669L4.0786 0.400024Z" fill="white"/>
    <path d="M20.8393 13.4682V6.10938H22.5153V13.1973C22.5153 16.4166 24.1357 18.2334 27.6471 18.2334C31.2938 18.2334 32.7589 16.3409 32.7589 13.1973V6.10938H34.4349V13.4682C34.4349 17.313 32.2493 19.596 27.667 19.596C23.2001 19.6 20.8393 17.4724 20.8393 13.4682ZM23.3753 13.0579V6.10938H25.0513V12.9822C25.0513 15.2094 25.8914 16.0859 27.663 16.0859C29.4585 16.0859 30.2388 15.1894 30.2388 12.9622V6.10938H31.9149V13.0579C31.9149 16.1417 30.4896 17.4485 27.663 17.4485C24.8403 17.4525 23.3753 16.2612 23.3753 13.0579Z" fill="#25C565"/>
    <path d="M36.0672 12.8069C36.0672 9.33263 38.663 5.93408 43.2652 5.93408C47.4972 5.93408 50.5228 8.95811 50.5228 12.7471C50.5228 16.301 47.7918 19.6 43.1696 19.6C38.9536 19.6 36.0672 16.4564 36.0672 12.8069ZM48.8627 12.787C48.8627 9.77886 46.5815 7.30067 43.2851 7.30067C39.6782 7.30067 37.7433 10.0936 37.7433 12.8427C37.7433 15.6915 39.9091 18.2294 43.2055 18.2294C46.7965 18.2334 48.8627 15.5958 48.8627 12.787ZM38.6032 12.8427C38.6032 10.2092 40.3589 8.0019 43.3249 8.0019C46.4262 8.0019 47.9868 10.6554 47.9868 12.767C47.9868 15.9106 45.7813 17.5322 43.2453 17.5322C40.769 17.5282 38.6032 15.556 38.6032 12.8427ZM46.3267 12.767C46.3267 10.9502 45.2359 9.36849 43.2851 9.36849C41.4338 9.36849 40.2634 10.7749 40.2634 12.767C40.2634 14.7791 41.5135 16.1656 43.2692 16.1656C45.0607 16.1616 46.3267 14.8149 46.3267 12.767Z" fill="#25C565"/>
    <path d="M52.2388 6.10938H53.9148V19.4247H52.2388V6.10938ZM54.7748 6.10938H56.4508V11.9861L61.3118 6.10938H63.1631L57.9159 12.512L63.3183 19.4207H61.3676L56.4548 13.0977V19.4207H54.7787V6.10938H54.7748ZM58.8913 12.4323L64.3335 6.10938H66.2047L60.8022 12.4164L66.4197 19.4247H64.4689L58.8913 12.4323Z" fill="#25C565"/>
    <path d="M67.4428 6.10938H74.3302C77.5669 6.10938 79.012 8.12141 79.012 10.1494C79.012 11.7909 78.172 12.8228 77.0016 13.4284C77.6067 13.6834 77.9968 14.1097 78.6776 15.3408L80.9588 19.4207H79.1434L77.1329 15.7512C76.3924 14.4444 75.6519 13.7193 74.6567 13.4682V13.3328C76.2969 13.0579 77.3479 12.006 77.3479 10.3645C77.3479 8.70709 76.1775 7.47597 73.9361 7.47597H69.1189V19.4247H67.4428V6.10938ZM69.9788 8.25687H73.7649C75.5206 8.25687 76.476 9.11747 76.476 10.4442C76.476 12.0857 75.3852 12.9025 73.2195 12.9025H69.9828V8.25687H69.9788ZM69.9788 13.6834H72.3993C74.6208 13.6834 75.4808 14.3647 76.5358 16.3568L78.1561 19.4207H76.3208L75.0906 17.0381C74.2705 15.3966 73.8604 15.046 72.3396 15.046H71.6389V19.4207H69.9788V13.6834ZM73.2951 11.5359C74.0754 11.5359 74.796 11.3606 74.796 10.5598C74.796 9.89439 74.3859 9.62346 73.6056 9.62346H71.6349V11.5359H73.2951Z" fill="#25C565"/>
    <path d="M87.3804 6.08948H91.7876L97.9106 19.4207H96.0594L90.6569 7.47201H88.4912L83.3435 19.4207H81.4923L87.3804 6.08948ZM85.8198 15.6158H93.5035L95.1437 19.4207H93.2725L92.2773 16.9824H87.1097L86.0945 19.4207H84.2234L85.8198 15.6158ZM88.8813 8.2569H90.3066L93.173 14.8548H86.1503L88.8813 8.2569ZM90.9476 13.4882L89.6019 10.3048L88.3916 13.4882H90.9476Z" fill="#25C565"/>
    <path d="M99.1686 0.400024H101.565V19.4247H99.1686V0.400024ZM102.764 0.400024H105.16V7.26087H112.852V0.400024H115.249V9.18525H102.764V0.400024ZM102.764 10.3287H115.249V19.4207H112.852V12.3089H105.16V19.4207H102.764V10.3287ZM116.479 0.400024H118.847V19.4247H116.479V0.400024Z" fill="white"/>
    <path d="M125.293 13.4682L120.145 6.10938H121.921L126.973 13.273V19.4207H125.297V13.4682H125.293ZM122.972 6.10938H124.748L127.363 10.1494L130.055 6.10938H131.85L127.359 12.5718L122.972 6.10938ZM127.809 13.273L132.861 6.10938H134.637L129.485 13.4682V19.4207H127.809V13.273Z" fill="#25C565"/>
    <path d="M135.162 6.10938H136.878L142.496 17.8629L147.978 6.10938H149.714L143.391 19.4406H141.596L135.162 6.10938ZM137.738 6.10938H139.494L142.48 12.7073L145.386 6.10938H147.162L142.615 15.8907H142.42L137.738 6.10938Z" fill="#25C565"/>
    <path d="M153.87 6.08948H158.277L164.4 19.4207H162.549L157.146 7.47201H154.981L149.833 19.4207H147.982L153.87 6.08948ZM152.309 15.6158H159.993L161.633 19.4207H159.762L158.767 16.9824H153.599L152.584 19.4207H150.713L152.309 15.6158ZM155.371 8.2569H156.796L159.662 14.8548H152.64L155.371 8.2569ZM157.441 13.4882L156.095 10.3048L154.885 13.4882H157.441Z" fill="#25C565"/>
  </svg>
</template>

<script>
export default {
name: "LogoXS"
}
</script>

<style scoped>

</style>
