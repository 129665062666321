import axios from "axios";

const state = {
    discountList: []
};

const getters = {
    discountList(state) {
        return state.discountList;
    }
};

const mutations = {
    setDiscountList: (state, item) => {
        state.discountList = item;
    }
};

const actions = {
    onDiscountList({ commit, dispatch }) {
        //commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/admin/discount/grid?limit=" + this.state.number + "&page=" + this.state.page + "&order[]=created_at:desc";
            axios
                .get(url)
                .then(response => {
                    commit("setDiscountList", response.data);
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onDiscountCreate({commit, dispatch}, data) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/admin/discount";
            axios
                .post(url, data)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onDiscount({commit, dispatch}, id) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/admin/discount/grid?limit=1&page=1&filter[]=id:" + id;
            axios
                .get(url)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onDiscountSave({commit, dispatch}, data) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/admin/discount";
            axios
                .put(url, data)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onDiscountDelete({commit, dispatch}, id) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/admin/discount/" + id;
            axios
                .delete(url)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
