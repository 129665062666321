import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'
import login from "@/views/pages/authorization/login";
import welcome from "@/views/pages/other/welcome";
import confirm_email from "@/views/pages/authorization/confirm_email";
import confirm_restore from "@/views/pages/authorization/confirm_restore";
import user_data from "@/views/pages/authorization/user_data";
import account from "@/views/pages/other/account";
import messages from "@/views/pages/other/messages";
import articles from "@/views/pages/blog/articles";
import authors from "@/views/pages/blog/authors";
import categories from "@/views/pages/blog/categories";
import jur from "views/pages/clients/jur/jur";
import phys from "views/pages/clients/phys/phys";
import projects from "@/views/pages/orders/projects";
import discount from "@/views/pages/other/discount";
import locations from "@/views/pages/other/locations";
import products from "@/views/pages/services/products";
import services from "@/views/pages/services/services";
import units from "@/views/pages/services/units";
import control from "@/views/pages/settings/control";
import interface_set from "@/views/pages/settings/interface_set";
import languages from "@/views/pages/settings/languages";
import order_status from "@/views/pages/settings/order_status";
import pages from "@/views/pages/settings/pages";
import roles from "@/views/pages/settings/roles";
import users from "@/views/pages/settings/users";
import settings from "@/views/settings";
import services_parent from "@/views/services";
import blog from "@/views/blog";
import clients from "@/views/clients";
import ord from "@/views/ord";
import order_id from "@/views/pages/orders/order_id";
import orders from "@/views/pages/orders/orders";
import product_id from "@/views/pages/services/product_id";
import unit_id from "@/views/pages/services/unit_id";
import project_id from "views/pages/orders/project_id";
import article_id from "views/pages/blog/article_id";
import jur_id from "views/pages/clients/jur/jur_id";
import jur_address from "views/pages/clients/jur/jur_address";
import jur_clients from "views/pages/clients/jur/jur_clients";
import jur_contacts from "views/pages/clients/jur/jur_contacts";
import phys_id from "views/pages/clients/phys/phys_id";
import languageqq from "views/language";

Vue.use(VueRouter)

const routes = [
  {
    path: '/select_language',
    name: 'select_language',
    component: languageqq
  },
  {
    path: '/:language',
    component: home,
    children: [
      {
        path: '/',
        name: 'home',
        component: welcome
      },
      {
        path: "account",
        name: "account",
        component: account
      },
      {
        path: "discount",
        name: "discount",
        component: discount
      },
      {
        path: "locations",
        name: "locations",
        component: locations
      },
      {
        path: "messages",
        name: "messages",
        component: messages
      },

      {
        path: 'login',
        name: 'login',
        component: login
      },
      {
        path: "confirm-email/:id",
        name: "confirmEmail",
        component: confirm_email
      },
      {
        path: "confirm-restore/:id",
        name: "confirmRestore",
        component: confirm_restore
      },
      {
        path: "user-data",
        name: "userData",
        component: user_data
      },

      {
        path: 'blog',
        name: 'blog',
        component: blog,
        children: [
          {
            path: "articles",
            name: "articles",
            component: articles,
            children: [
              {
                path: ':id',
                name: 'article_id',
                component: article_id
              }
            ]
          },
          {
            path: "authors",
            name: "authors",
            component: authors
          },
          {
            path: "article-categories",
            name: "categories",
            component: categories
          },
        ]
      },
      {
        path: 'clients',
        name: 'clients',
        component: clients,
        children: [
          {
            path: "jur",
            name: "jur",
            component: jur,
            children: [
              {
                path: ":id",
                component: jur_id,
                children: [
                  {
                    path: '',
                    name: 'jur_address',
                    component: jur_address
                  },
                  {
                    path: 'contacts',
                    name: 'jur_contacts',
                    component: jur_contacts
                  },
                  {
                    path: 'clients',
                    name: 'jur_clients',
                    component: jur_clients
                  },

                ]
              }
            ]
          },
          {
            path: "phys",
            name: "phys",
            component: phys,
            children: [
              {
                path: ":id",
                name: "phys_id",
                component: phys_id,
                children: [
                  {
                    path: 'edit',
                    name: "phys_id_edit"
                  }
                ]
              }
            ]
          },
        ]
      },

      {
        path: 'orders',
        name: 'ord',
        component: ord,
        children: [
          {
            path: "orders",
            name: 'orders',
            component: orders,
            children: [
              {
                path: ':id',
                name: 'order_id',
                component: order_id
              }
            ]
          },
          {
            path: "projects",
            name: "projects",
            component: projects,
            children: [
              {
                path: 'create',
                name: 'project_create',
                component: project_id
              },
              {
                path: ':id',
                name: 'project_id',
                component: project_id,
                children: [
                  {
                    path: 'edit',
                    name: 'project_id_edit'
                  },
                  {
                    path: 'doc',
                    name: 'project_id_doc'
                  }
                ]
              }
            ]
          },
        ]
      },

      {
        path: "services",
        component: services_parent,
        children: [
          {
            path: "/",
            name: "services",
            component: services
          },
          {
            path: "products",
            name: "products",
            component: products,
          },
          {
            path: "products/:id",
            name: "product_id",
            component: product_id,
          },
          {
            path: "units",
            name: "units",
            component: units,
          },
          {
            path: "units/:id",
            name: "unit_id",
            component: unit_id,

            children: [
              {
                path: 'inspection/:control',
                name: 'inspection',
                component: () => import("views/pages/services/inspection")
              },
              {
                path: 'notes/:control',
                name: 'notes',
                component: () => import("views/pages/services/inspection")
              },
              {
                path: 'repair/:control',
                name: 'repair',
                component: () => import("views/pages/services/inspection")
              },
              {
                path: 'notes/:control/create',
                name: 'notes_new',
                component: () => import("views/pages/services/inspection_new")
              },
              {
                path: 'notes/:control/:note',
                name: 'notes_id',
                component: () => import("views/pages/services/inspection_new")
              }
            ]
          },
        ]
      },
      {
        path: "settings",
        name: "settings",
        component: settings,
        children: [
          {
            name: "control",
            path: "control",
            component: control
          },
          {
            name: "interface",
            path: "interface",
            component: interface_set
          },
          {
            name: "languages",
            path: "languages",
            component: languages
          },
          {
            name: "order_status",
            path: "order-status",
            component: order_status
          },
          {
            name: "pages",
            path: "pages",
            component: pages
          },
          {
            name: "roles",
            path: "roles",
            component: roles
          },
          {
            name: "users",
            path: "users",
            component: users
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
