<template>
  <div>
    <v-input hide-details class="text--darken-1 mb-3">
      {{text}}
    </v-input>
    <div class="grid mb-5" v-if="images.length && images.length > 0">
      <div v-for="(image, img) in images" :key="img">
        <DevImageBtn
            :image="image"
            width="100%"
            height="80"
            :id="img"
            :on-open="openImage"
            :on-delete="onDelete ? askDeleteImage : null"
            :contain="contain"
        />
      </div>
    </div>
    <div class="grid mb-5" v-else>
      <DevImageBtn
          :image="null"
          width="100%"
          height="80"
      />
    </div>
    <v-dialog v-model="modal" fullscreen>
      <DevImageView
          v-if="modal"
          :items="images"
          :id="id"
          :on-close="closeModal"
      />
    </v-dialog>
    <v-dialog v-model="modalAskDelete" width="600">
      <DefaultPageAskDelete
          text="image"
          name=""
          :on-close="closeModal"
          :on-delete="deleteImage"
      />
    </v-dialog>
  </div>
</template>

<script>
import DevImageBtn from "@/components/dev/DevImageBtn";
import DevImageView from "@/components/dev/DevImageView";
import DefaultPageAskDelete from "@/components/default/page/DefaultPageAskDelete";

export default {
  name: "DevImageBlock",
  components: {DefaultPageAskDelete, DevImageView, DevImageBtn},
  props: {
    text: {
      type: String,
      default: ''
    },
    images: {
      type: Array,
      default: () => {
        return []
      }
    },
    onDelete: {},
    contain: Boolean
  },
  data: () => ({
    modal: false,
    id: -1,
    modalAskDelete: false
  }),
  methods: {
    openImage(id) {
      this.id = id
      this.modal = true
    },
    askDeleteImage(id, name) {
      this.id = id
      this.modalAskDelete = true
    },
    deleteImage(){
      this.onDelete(this.id)
      this.id = -1
      this.modalAskDelete = false
    },
    closeModal() {
      this.modal = false
      this.modalAskDelete = false
    },
  }
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-rows: minmax(auto, 80px);
  grid-template-columns: repeat(auto-fit, 118px) !important;
  grid-gap: 16px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    grid-gap: 8px;
  }
}
</style>
