<template>
  <div :class="data.id ? 'grid' : 'd-flex justify-center'">
    <v-sheet :width="data.id ? null : '100vw'" :max-width="data.id ? null : '550'">
      <v-form ref="form" lazy-validation>
        <v-card class="border pa-4">
          <div class="d-flex align-center">
            <DevImageBlock :text="t['image']" :images="Array(data.image)" :key="key1"/>
            <v-btn @click="loadImage()" class="second ml-5">
              {{ data.image ? t['change'] : t['add'] }}
            </v-btn>
          </div>
          <div class="font12 mt-n3 mb-1 red--text">
            {{ error && !data.image ? t['required_field'] : '' }}
          </div>
        </v-card>
        <v-card class="border mt-4">
          <div class="pa-4">
            <DevInput
                :text="t['title']"
                v-model="data.name"
                :rules="[v => !!v || t['required_field']]"
            />
            <DevInput
                :text="t['registration_number']"
                v-model="data.number"
                :rules="[v => !!v || t['required_field']]"
            />
            <DevInput
                v-if="data.num"
                :text="t['serial_number']"
                v-model="data.num"
                disabled
                hide-details
            />
          </div>
          <v-divider/>
          <div class="pa-4" :key="service_key">
            <DevInput
                select
                :text="t['service_name']"
                :items="serviceCategoryList.data"
                v-model="data.service.category"
                @change="getServiceList"
                :rules="[v => !!v || t['required_field']]"
            />
            <DevInput
                select
                :text="t['product_name']"
                :items="serviceList.data"
                v-model="data.service.id"
                :rules="[v => !!v || t['required_field']]"
                class="mb-n4"
                :disabled="!serviceList.data"
            />
          </div>
          <v-divider/>
          <div class="pa-4">
            <DevInput
                v-if="locationList.data"
                select
                :text="t['location']"
                v-model="data.location.id"
                :items="locationList.data"
                :rules="[v => !!v || t['required_field']]"
                class="mb-n4"
            />
          </div>
          <div v-if="data.id">
            <v-divider/>
            <div class="pa-4">
              <DevInput
                  :text="t['created_at']"
                  v-model="data.created_at"
                  disabled
                  hide-details
              />
            </div>
          </div>
          <v-divider/>
          <div class="pa-4">
<!--            <DevInput-->
<!--                v-if="data.work_start"-->
            <!--                :text="t['commissioning']"-->
            <!--                v-model="data.work_start"-->
            <!--                disabled-->
            <!--            />-->
            <!--            <DevInput-->
            <!--                v-if="data.work_end"-->
            <!--                :text="t['commissioning']"-->
            <!--                v-model="data.work_end"-->
            <!--                disabled-->
            <!--            />-->

            <div>
              <div class="font12">
                {{ t['date'] }} {{ date }}
              </div>
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="true"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  :disabled="!!data.work_start"
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="date"
                      readonly
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon slot="prepend-inner" class="mt-1">mdi-calendar</v-icon>
                  </v-text-field>
                </template>
                <v-date-picker
                    v-model="date"
                    no-title
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </div>
            <div class="text-center">
              <v-btn @click="workChange" class="second">
                <span v-if="!data.work_start">{{ t['put_into_service'] }}</span>
                <span v-else>{{ t['out_of_service'] }}</span>
              </v-btn>
            </div>
          </div>
          <v-divider/>
          <div class="pa-4">
            <DevInput
                :text="t['unit_price']"
                v-model="data.price"
                type="Number"
                :rules="[v => !!v || t['required_field']]"
            >
              <v-icon class="mt-1" slot="append-outer">mdi-currency-eur</v-icon>
            </DevInput>
          </div>
        </v-card>
        <input
            type="file"
            id="file"
            ref="file"
            accept=".JPG, .PNG, .SVG, .GIF, .WebP"
            style="display: none"
            v-on:change="handleFileUpload()"
        />
      </v-form>
    </v-sheet>
    <v-sheet width="100%">
      <div v-if="data.id && serviceControlList.data" class="grid_info">
        <div>
          <v-card class="pa-4 border">
            <v-btn v-if="data.work_start" color="primary" outlined>
              <span class="text-uppercase">
                {{t['in_operation']}}
              </span>
            </v-btn>
            <v-btn v-else color="red" outlined>
              <span class="text-uppercase">
                {{ t['out_operation'] }}
              </span>
            </v-btn>
          </v-card>
          <div class="mt-4">
            <div v-for="(control, c) in controls" :key="c">
              <v-card v-if="serviceControlList.data.filter(i => i.type === control.name)[0]" class="pa-4 border mb-4">
                <div>
                  {{ control.title }}
                </div>
                <div v-if="serviceActionList.data" class="grid_inner mt-4">
                  <DevInput
                      hide-details
                      disabled
                      :value="serviceActionList.data.filter(i => i.service_control.type === control.name)[0].created_at.split('/').join('.')"
                      :text="t['date']"
                  >
                    <v-icon slot="prepend-inner" class="mt-1">mdi-calendar-month-outline</v-icon>
                  </DevInput>
                  <DevInput
                      v-if="serviceActionList.data.filter(i => i.service_control.type === control.name)[0].sum"
                      hide-details
                      disabled
                      :value="serviceActionList.data.filter(i => i.service_control.type === control.name)[0].sum"
                      :text="t['sum']"
                  >
                    <v-icon slot="append-outer" class="mt-1">mdi-currency-eur</v-icon>
                  </DevInput>
                </div>
                <v-btn
                    @click="openInspection(control.name, serviceControlList.data.filter(i => i.type === control.name)[0].id)"
                    class="second mt-4">
                  {{ t['show'] }}
                </v-btn>
              </v-card>
              <v-card v-else class="pa-4 border mb-4">
                {{ control.title }}
                <div class="mt-4 font12">
                  {{ t['none_controls'] }}
                </div>
              </v-card>
            </div>
          </div>
        </div>

      </div>
    </v-sheet>
    <div class="d-flex justify-space-around align-center mt-5 mb-10">
      <v-btn @click="onSave" class="primary">
        {{ data.id ? t['save'] : t['create'] }}
      </v-btn>
      <!--          <v-btn v-if="data.id" class="delete_button" disabled>-->
      <!--            {{ t['delete'] }}-->
      <!--          </v-btn>-->
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DevInput from "@/components/dev/DevInput";
import service from "@/store/modules/service";
import DevImageBlock from "@/components/dev/DevImageBlock";

export default {
  name: "PagesServicesUnitId",
  components: {DevImageBlock, DevInput},
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    saveItem: {},
  },
  data: () => ({
    key: 0,
    key1: 10000,
    service_key: 20000,
    error: false,
    deleted_image: [],
    new_files: [],
    tempNumber: 0,
    load_file: '',
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
  }),
  computed: {
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['fields'],
          this.$store.state.t[this.$route.params.language]['form'],
          this.$store.state.t[this.$route.params.language]['buttons']
      )
    },
    controls() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['service_control']
      )
    },
    ...mapGetters(["number", "file", "apiDomain"]),
    ...mapGetters("service", ["serviceList", "serviceCategoryList", "service"]),
    ...mapGetters("location", ["locationList"]),
    ...mapGetters("serviceControl", ["serviceControlList"]),
    ...mapGetters("serviceAction", ["serviceActionList"]),
  },
  watch: {
    load_file() {
      if (this.load_file) {
        this.submitFile();
      }
    }
  },
  created() {
    this.onServiceCategoryList({number: '0'});
    this.onLocationList('0');
    this.onServiceActionList({number: '0'})
  },
  mounted() {
    //this.getLists()
  },
  beforeDestroy() {
    this.onClose()
  },
  methods: {
    ...mapActions("location", ["onLocationList"]),
    ...mapActions("service", ["onServiceCategoryList", "onServiceList"]),
    ...mapActions(["uploadFile", "destroyFile"]),
    ...mapActions("serviceAction", ["onServiceActionList"]),

    onClose() {
      if (this.click_save) {
        console.log('жмакнули кнопку сохранения записи')
        for (let del in this.deleted_image) {
          this.destroyFile(this.deleted_image[del])
        }
      } else {
        console.log('не сохраняя закрыли окно')
        for (let del in this.new_files) {
          this.destroyFile(this.new_files[del])
        }
      }
    },
    onSave() {
      if (this.$refs.form.validate() && this.data.image) {
        this.click_save = true
        this.saveItem(this.data)
      } else {
        this.error = true
      }
    },
    loadImage() {
      this.$refs.file.click()
    },
    handleFileUpload() {
      this.load_file = this.$refs.file.files[0];
    },
    async submitFile() {
      let formData = new FormData();
      formData.append("file", this.load_file);
      console.log(this.load_file);
      await this.uploadFile({
        data: formData,
        use_case: "cover",
      }).then(() => {
        if (this.data.image) {
          this.deleted_image.push(this.data.image)
        }
        this.data.image = this.file.url
        this.new_files.push(this.file.url)
        this.key1++
      });
    },
    getServiceList({item}) {
      this.onServiceList({category: item})
    },
    openInspection(path, id) {
      this.$router.push({name: path, params: {control: id}})
    },
    workChange() {
      if (!this.data.work_start) {
        this.data.work_start = this.date + ' 00:00:00'
        this.data.work_end = null
      } else {
        this.data.work_end = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) + ' 00:00:00'
        this.data.work_start = null
      }
      this.key++
    }
  }
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-columns: 2fr 3.5fr;
  grid-gap: 20px;
  margin-top: 30px;
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    grid-template-columns: 1fr;
  }
  @media #{map-get($display-breakpoints, 'lg-only')} {
    grid-template-columns: 1fr 1.5fr;
  }

  &_inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }

  &_info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    @media #{map-get($display-breakpoints, 'lg-and-down')} {
      grid-template-columns: 1fr;
    }
  }
}
</style>
