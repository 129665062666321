<template>
  <div>
    <DefaultPageToolbar
      v-if="$route.name === 'projects'"
      :total="Number(list.amount)"
      :last_page="list.lastPage"
      :create-text="$store.state.t[this.$route.params.language]['page_toolbar']['add_projects']"
      :on-create="openNewItem"
    >
      <DefaultPageTable
        :title="$store.state.t[this.$route.params.language]['menu']['projects']"
        :data="list.data"
        :headers="headers"
        :on-delete="askDeleteItem"
        :ask-delete-fields="['project_name']"
        :on-edit="editItem"
      />
      <v-dialog v-model="modalAskDelete" width="600">
        <DefaultPageAskDelete
          text="order"
          :name="name"
          :on-close="closeModals"
          :on-delete="deleteItem"
        />
      </v-dialog>
    </DefaultPageToolbar>
    <router-view v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DefaultPageToolbar from '@/components/default/page/DefaultPageToolbar'
import DefaultPageTable from '@/components/default/page/DefaultPageTable'
import DefaultPageAskDelete from 'components/default/page/DefaultPageAskDelete'

export default {
  name: 'projects',
  components: { DefaultPageAskDelete, DefaultPageTable, DefaultPageToolbar },
  data: () => ({
    list: {},
    id: 0,
    name: '',
    modalNewItem: false,
    modalAskDelete: false,
  }),
  computed: {
    ...mapGetters(['apiDomain']),
    headers() {
      return [
        {
          value: ['project_name'],
          text: this.$store.state.t[this.$route.params.language]['fields']['project_name'],
        },
        {
          value: ['service'],
          text: this.$store.state.t[this.$route.params.language]['fields']['service_name'],
        },
        {
          value: ['unit'],
          text: this.$store.state.t[this.$route.params.language]['fields']['unit'],
        },
        {
          value: ['first_name', 'last_name'],
          text: this.$store.state.t[this.$route.params.language]['fields']['client'],
        },
        {
          value: ['date'],
          text: this.$store.state.t[this.$route.params.language]['fields']['validity'],
        },
        {
          value: ['price'],
          text: this.$store.state.t[this.$route.params.language]['fields']['month_price'],
        },
        {
          value: ['address'],
          text: this.$store.state.t[this.$route.params.language]['fields']['location_address'],
        },
      ]
    },
  },
  watch: {
    '$route.query'() {
      if (this.$route.name === 'orders') {
        this.getList()
      }
    },
    '$route'() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    ...mapActions('order', ['onOrderList', 'onDeleteOrder']),
    async getList() {
      this.list = {}
      await this.onOrderList({ type: 'project' }).then(res => {
        this.list = res.data
        for (let i in this.list.data) {

          this.list.data[i].data = JSON.parse(this.list.data[i].data)

          this.list.data[i].project_name = this.list.data[i].data.project_name
          this.list.data[i].service = this.list.data[i].service.title
          this.list.data[i].price = (this.list.data[i].data.month_price || '-')  + ' €'
          this.list.data[i].first_name = this.list.data[i].user_data.first_name
          this.list.data[i].last_name = this.list.data[i].user_data.last_name

          let temp = String(this.list.data[i].start)
              .slice(0, 10)
              .split('-')
          let temp1 = String(this.list.data[i].end)
              .slice(0, 10)
              .split('-')
          this.list.data[i].date = temp[2] + '.' + temp[1] + '.' + temp[0].slice(2, 4) + '-' + temp1[2] + '.' + temp1[1] + '.' + temp1[0].slice(2, 4)
          this.list.data[i].unit = String(this.list.data[i].service_unit.id).padStart(4, '0') + ' ' + this.list.data[i].service_unit.name
          this.list.data[i].address = this.list.data[i].data.installation_addresses.info

        }
      })
    },
    closeModals() {
      this.getList()
      this.modalNewItem = false
      this.modalAskDelete = false
    },
    openNewItem() {
      this.$router.push({ name: 'project_create' })
    },
    askDeleteItem(id, name) {
      this.id = id
      this.name = name
      this.modalAskDelete = true
    },
    async deleteItem() {
      await this.onDeleteOrder(this.id).then(() => {
        this.closeModals()
      })
    },
    editItem(id) {
      this.$router.push({ name: 'project_id', params: { id: id } })
    },
  },
}
</script>
