<template>
  <v-bottom-sheet class="sheet" v-model="sheet">
    <v-sheet class="sheet_body text-center" color="secondary" height="190px">
      <CheckMark v-if="!notificationError"/>
      <CrossMark v-else/>
      <div class="text">
        {{ notificationError ? t['notification_error'] : t['notification_success'] }}
        <span>{{t['notification_close']}}</span>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import CheckMark from "@/components/svg/CheckMark";
import CrossMark from "@/components/svg/CrossMark";
import {mapGetters, mapMutations} from 'vuex'

export default {
  name: "DefaultNotification",
  components: {CrossMark, CheckMark},
  data: () => ({
    sheet: false
  }),
  watch: {
    notificationShow() {
      this.sheet = this.notificationShow;
    }
  },
  computed: {
    ...mapGetters('notification', ['notificationShow', 'notificationError']),
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['notification']
      )
    },
  },
  methods: {
    ...mapMutations('notification', ['setNotificationShow']),
    close() {
      this.setNotificationShow(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.sheet {
  display: flex;
  align-items: center;

  &_body {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    flex-direction: column;

    .text {
      margin: 20px auto 12px;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #FFFFFF;
      display: grid;

      span {
        margin-top: 10px;
        text-transform: none;
        font-size: 14px;
        font-weight: 300;
      }
    }
  }
}
</style>
