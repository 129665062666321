<template>
  <DefaultPageToolbar
      :create-text="$store.state.t[this.$route.params.language]['page_toolbar']['add_discount']"
      :on-create="openNewItem"
      :total="list.amount"
      :last_page="list.lastPage"
  >
    <DefaultPageTable
        :title="$store.state.t[this.$route.params.language]['menu']['discount']"
        :data="list.data"
        :headers="headers"
        :on-delete="askDeleteItem"
        :ask-delete-fields="['code']"
        :on-edit="editItem"
    />
    <v-dialog v-model="modalEdit" width="600">
      <PagesOtherDiscountId
          v-if="modalEdit"
          :data="item"
          :on-close="closeModals"
          :on-save="saveItem"
      />
    </v-dialog>
    <v-dialog v-model="modalNewItem" width="600">
      <PagesOtherDiscountId
          new-item
          v-if="modalNewItem"
          :data="item"
          :on-close="closeModals"
          :on-save="createItem"
      />
    </v-dialog>
    <v-dialog v-model="modalAskDelete" width="600">
      <DefaultPageAskDelete
          text="discount"
          :name="name"
          :on-close="closeModals"
          :on-delete="deleteItem"
      />
    </v-dialog>
  </DefaultPageToolbar>
</template>

<script>
import DefaultPageToolbar from "@/components/default/page/DefaultPageToolbar";
import {mapActions} from "vuex";
import DefaultPageTable from "@/components/default/page/DefaultPageTable";
import PagesOtherLocationsId from "@/components/pages/other/PagesOtherLocationsId";
import DefaultPageAskDelete from "@/components/default/page/DefaultPageAskDelete";
import PagesOtherDiscountId from "@/components/pages/other/PagesOtherDiscountId";
import moment from 'moment';

export default {
  name: "discount",
  components: {PagesOtherDiscountId, DefaultPageAskDelete, PagesOtherLocationsId, DefaultPageTable, DefaultPageToolbar},
  data: () => ({
    list: {
      meta: {}
    },
    item: {},
    id: 0,
    name: '',
    modalAskDelete: false,
    modalNewItem: false,
    modalEdit: false
  }),
  computed: {
    headers() {
      return [
        {
          value: ["code"],
          text: this.$store.state.t[this.$route.params.language]['fields']['discount_code']
        },
        {
          value: ["date"],
          text: this.$store.state.t[this.$route.params.language]['fields']['date']
        },
        {
          value: ["created_at"],
          text: this.$store.state.t[this.$route.params.language]['fields']['created_at']
        },
        {
          value: ["type"],
          text: this.$store.state.t[this.$route.params.language]['fields']['discount_type']
        },
        {
          value: ["discount"],
          text: this.$store.state.t[this.$route.params.language]['fields']['discount_value']
        }
      ]
    },
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['fields']
      )
    }
  },
  watch: {
    '$route.query'() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    ...mapActions("discount", ["onDiscountList", "onDiscountDelete", "onDiscount", "onDiscountCreate", "onDiscountSave"]),
    async getList() {
      this.list = {meta: {}}
      await this.onDiscountList().then(res => {
        this.list = res.data;
        for (let i in this.list.data) {
          this.transformList(this.list.data[i]);
        }
      })
    },
    transformList(data) {
      if (data.type === 'percent') {
        data.type = this.t['percentage'];
        data.discount = data.percent + ' %';
      } else {
        data.type = this.t['fixed_value'];
        data.discount = data.value + ' €';
      }
      data.created_at = data.created_at.slice(0, 10);
      data.date = data.start.slice(0, 10).replace('-', '.').replace('-', '.') + ' - ' + data.end.slice(0, 10).replace('-', '.').replace('-', '.');
    },
    closeModals() {
      this.modalAskDelete = false;
      this.modalNewItem = false;
      this.modalEdit = false
    },
    openNewItem() {
      this.item = {}
      this.modalNewItem = true;
    },
    askDeleteItem(id, name) {
      this.id = id;
      this.name = name;
      this.modalAskDelete = true;
    },
    async deleteItem() {
      await this.onDiscountDelete(this.id).then(() => {
        this.getList();
        this.closeModals()
      })
    },
    async editItem(id) {
      this.item = {}
      await this.onDiscount(id).then(res => {
        this.item = res.data.data[0]
        this.item.start = new Date(this.item.start);
        this.item.end = new Date(this.item.end);
        this.modalEdit = true
      })
    },
    transformData(data) {
      let start = new Date(data.start);
      let end = new Date(data.end);
      start = moment(start).format('YYYY-MM-DD hh:mm:ss');
      end = moment(end).format('YYYY-MM-DD hh:mm:ss');
      delete data.start;
      delete data.end;
      data.start = start;
      data.end = end;
      if(data.type === 'percent') {
        if (data.value) {
          delete data.value;
          data.percent = Number(data.percent)
        }
      }
      else {
        if (data.percent) {
          delete data.percent;
        }
      }
      data.is_for_admin = 0;
      return data
    },
    saveItem(data) {
      this.onDiscountSave(this.transformData(data))
      this.getList();
      this.closeModals()
    },
    createItem(data) {
      this.onDiscountCreate(this.transformData(data))
      this.getList();
      this.closeModals()
    }
  }
}
</script>
