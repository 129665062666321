<template>
  <DefaultPageModal
      :on-close="closeModal"
      :text="t['service']"
  >
    <DefaultPageLanguage class="mt-n5"/>
    <v-form ref="form" lazy-validation class="text-start grid" :key="key">
      <v-card class="border pa-4">
        <div class="grid_image mb-4">
          <div>{{ t['icon'] }} (PNG, SVG) 90 x 90 px</div>
          <div>{{ t['image'] }} (JPG, PNG, SVG, WebP, GIF)</div>
          <DevImageBlock :images="Array( image )" contain/>
          <DevImageBlock :images="Array( seo_image )" contain/>
          <div class="font12 mt-n2 red--text">
            {{ error && !image ? t['required_field'] : '' }}
          </div>
          <div class="font12 mt-n2 red--text">
            {{ error && !seo_image ? t['required_field'] : '' }}
          </div>
          <v-btn @click="loadImage('image')" class="second">
            <span>{{ image ? t['change'] : t['add'] }}</span>
          </v-btn>
          <v-btn @click="loadImage('seo_image')" class="second">
            <span>{{ seo_image ? t['change'] : t['add'] }}</span>
          </v-btn>
        </div>
        <DevInput
            :text="t['title']"
            v-model="data.title"
            :rules="[v => !!v || t['required_field']]"
        />
        <DevInput
            :text="t['url']"
            v-model="data.url"
            :rules="[v => !!v || t['required_field']]"
        />
      </v-card>
      <v-card class="border pa-4">
        <div>SEO</div>
        <DevInput
            :text="t['title']"
            v-model="data.seo_title"
            :rules="[v => !!v || t['required_field']]"
        />
        <DevInput
            :text="t['alt']"
            v-model="data.alt"
            :rules="[v => !!v || t['required_field']]"
        />
        <DevInput
            text-area
            :text="t['keywords']"
            v-model="data.keywords"
            :rules="[v => !!v || t['required_field']]"
        />
        <DevInput
            text-area
            :text="t['description']"
            v-model="data.description"
            :rules="[v => !!v || t['required_field']]"
            class="mb-n5"
        />
      </v-card>
    </v-form>
    <v-card
        width="50%"
        :min-width="$vuetify.breakpoint.smAndDown ? '100%' : 500"
        flat
        class="d-flex justify-space-around mt-5 mb-n10 mx-auto"
    >
      <v-btn @click="onDelete(data.id, data.title)" v-if="data.id" class="delete_button">
        <v-icon class="mr-3 ml-n1">mdi-close</v-icon>
        {{ t['delete'] }}
      </v-btn>
      <v-btn @click="saveItem" class="primary">
        {{ data.id ? t['save'] : t['create'] }}
      </v-btn>
    </v-card>
    <input
        type="file"
        id="file"
        ref="file"
        :accept="field === 'image' ? '.PNG, .SVG' : '.JPG, .PNG, .SVG, .GIF, .WebP'"
        style="display: none"
        v-on:change="handleFileUpload()"
    />
  </DefaultPageModal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DefaultPageModal from "@/components/default/page/DefaultPageModal";
import DevInput from "@/components/dev/DevInput";
import DefaultPageLanguage from "components/default/page/DefaultPageLanguage";
import DevImageBtn from "components/dev/DevImageBtn";
import DevImageBlock from "components/dev/DevImageBlock";

export default {
  name: "PagesServicesId",
  components: {DevImageBlock, DevImageBtn, DefaultPageLanguage, DevInput, DefaultPageModal},
  props: {
    onClose: {},
    onSave: {},
    onDelete: {},
    onItem: {}
  },
  data: () => ({
    data: {},
    image: null,
    seo_image: null,
    key: 0,
    click_save: false,
    error: false,
    deleted_image: [],
    new_images: [],
    field: '',
    load_file: '',
  }),
  computed: {
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['menu'],
          this.$store.state.t[this.$route.params.language]['form'],
          this.$store.state.t[this.$route.params.language]['fields'],
          this.$store.state.t[this.$route.params.language]['buttons']
      )
    },
    ...mapGetters(["file", "apiDomain", "language"]),
    ...mapGetters("service", ["serviceCategory"])
  },
  watch: {
    serviceCategory() {
      this.data = this.serviceCategory.data;
      if (!this.image) {
        this.image = this.data.image;
      }
      if (!this.seo_image) {
        this.seo_image = this.data.seo_image;
      }
      this.key++;
    },
    load_file() {
      this.submitFile();
    },
    language() {
      this.getItem();
    }
  },
  created() {
    this.getItem()
  },
  beforeDestroy() {
    this.clearData()
  },
  methods: {
    ...mapActions(["uploadFile", "destroyFile"]),
    getItem() {
      if (this.onItem) {
        this.onItem()
      }
    },
    clearData() {
      console.log('закрываем окно')
      if (this.click_save) {
        console.log('нажата кнопка сохранения')
        for (let del in this.deleted_image) {
          this.destroyFile(this.deleted_image[del])
        }
      } else {
        console.log('нажата кнопка выхода')
        for (let del in this.new_images) {
          this.destroyFile(this.new_images[del])
        }
      }
      this.deleted_image = []
      this.new_images = []
      this.click_save = false
    },
    closeModal() {
      this.onClose()
    },
    saveItem() {
      if (this.$refs.form.validate() && this.image && this.seo_image) {
        this.data.language_id = this.language
        this.data.image = this.image
        this.data.seo_image = this.seo_image
        this.click_save = true
        this.onSave(this.data)
        this.clearData()
      } else {
        this.error = true
      }
    },
    loadImage(field) {
      this.field = field
      this.$refs.file.click();
    },
    handleFileUpload() {
      this.load_file = this.$refs.file.files[0];
    },
    async submitFile() {
      let formData = new FormData();
      formData.append("file", this.load_file);
      if (this.field === 'image') {
        await this.uploadFile({
          data: formData,
          use_case: "icon",
        }).then(() => {
          if (this.image && this.image.length > 1) {
            this.deleted_image.push(this.image)
          }
          this.image = this.file.url
          this.new_images.push(this.file.url)
          this.key++
        });
      } else {
        await this.uploadFile({
          data: formData,
          use_case: "cover",
        }).then(() => {
          if (this.seo_image && this.seo_image.length > 1) {
            this.deleted_image.push(this.seo_image)
          }
          this.seo_image = this.file.url
          this.new_images.push(this.file.url)
          this.key++
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    grid-template-columns: 1fr;
  }

  &_image {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    justify-items: center;
  }
}
.v-image {
  cursor: pointer !important;
}
</style>
