<template>
  <div>
    <v-toolbar color="transparent" flat :height="$vuetify.breakpoint.mobile ? 64 : 56">
      <div class="d-flex justify-space-between">
        <v-btn
            v-if="onCreate"
            @click="onCreate"
            class="primary"
            :fab="$vuetify.breakpoint.mobile"
            :disabled="disabled"
        >
          <v-icon v-if="$vuetify.breakpoint.mobile || !none_icon">mdi-plus</v-icon>
          <span v-if="!$vuetify.breakpoint.mobile" class="hidden-sm-and-down">
            {{ createText || $store.state.t[this.$route.params.language]['page_toolbar']['add']}}
          </span>
        </v-btn>
        <div v-else/>
        <DefaultPageNumber :total="total"/>
      </div>
    </v-toolbar>
    <div class="py-md-5">
      <slot/>
    </div>
    <div class="d-flex justify-end align-center">
      <v-pagination
          light
          @input="changePage"
          v-model="current_page"
          :length="last_page"
          :total-visible="7"
          color="transparent"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import DefaultPageNumber from "@/components/default/page/DefaultPageNumber";

export default {
  name: "DefaultPageToolbar",
  components: {DefaultPageNumber},
  props: {
    onCreate: {},
    createText: {
      type: String,
      default: ""
    },
    total: {
      type: Number,
      default: 0
    },
    last_page: {
      type: Number,
      default: 1
    },
    disabled: Boolean,
    none_icon: Boolean
  },
  data: () => ({
    current_page: 1
  }),
  computed: {
    ...mapGetters(["page", "number"]),
  },
  beforeCreate() {
    let query = Object.assign({}, this.$route.query);
    let number = this.$route.query.number || 10;
    let page = this.$route.query.page || 1;
    query['number'] = number;
    query['page'] = page;
    this.$store.commit("SetNumber", number);
    this.$store.commit("SetPage", page);
    if (!this.$route.query.number || !this.$route.query.page) {
      //this.$router.push({query: {number: number, page: page}});
      this.$router.push({query}).catch(() => {
      });
    }
  },
  mounted() {
    this.current_page = Number(this.$route.query.page);
  },
  methods: {
    changePage(item) {
      let query = Object.assign({}, this.$route.query);
      query['page'] = item;
      this.$store.commit("SetPage", item);
      this.$router.push({query}).catch(() => {
      });
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .v-toolbar__content {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr auto;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    //padding: 0 4px !important;
    grid-gap: 5px;
  }
}
</style>
