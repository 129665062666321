<template>
  <DefaultPageModal
      :on-close="onClose"
      :text="t['location']"
  >
    <DevInput
        :text="t['title']"
        v-model="item.name"
    />
    <v-input hide-details class="text--darken-1 mb-2">
      {{ t['address'] }}
    </v-input>
    <DevInput
        :text="t['city']"
        v-model="item.city"
    />
    <DevInput
        :text="t['street']"
        v-model="item.street"
    />
    <DevInput
        :text="t['zip']"
        v-model="item.zip"
    />
    <v-btn @click="saveItem" slot="btn" class="primary">
      {{ data.id ? t['save'] : t['create'] }}
    </v-btn>
  </DefaultPageModal>
</template>

<script>
import DefaultPageModal from "@/components/default/page/DefaultPageModal";
import DevInput from "@/components/dev/DevInput";

export default {
  name: "PagesOtherLocationsId",
  components: {DevInput, DefaultPageModal},
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    onClose: {},
    onSave: {},
  },
  data: () => ({
    item: []
  }),
  computed: {
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['menu'],
          this.$store.state.t[this.$route.params.language]['form'],
          this.$store.state.t[this.$route.params.language]['fields'],
          this.$store.state.t[this.$route.params.language]['buttons']
      )
    },
  },
  created() {
    this.item = Object.assign(this.data)
  },
  methods: {
    saveItem() {
      this.onSave(this.item)
      this.onClose()
    }
  }
}
</script>
