<template>
  <v-sheet width="100vw" height="100vh" color="secondary" class="login">
    <div class="text-center">
      <v-btn text @click="refresh()" :ripple="false" plain>
        <LogoXL/>
      </v-btn>
      <div v-if="restore" class="title white--text">
        {{t.create_new_pass}}
      </div>
      <div v-else class="title white--text">
        {{t.create_pass}}
      </div>
      <v-form ref="form" lazy-validation>
        <v-card
            width="97vw"
            max-width="432"
            light
            class="pa-4"
        >
          <v-input hide-details class="inp">
            {{t.confirm_email}}
          </v-input>
          <v-text-field
              v-model="userInfo.email"
              outlined
              dense
              v-on:keyup.enter="register()"
              validate-on-blur
              :rules="[
                    v => !!v || t.required_field,
                    v =>
                      /.+@.+\..+/.test(v) ||
                      t.bad_email,
                    is_registered || t.email_not_exist,
                    !error_check || t.confirmation_error
                  ]"
          />
          <v-input hide-details class="inp">
            {{t.pick_pass}}
          </v-input>
          <v-text-field
              v-model="userInfo.password"
              dense
              validate-on-blur
              v-on:keyup.enter="register()"
              outlined
              type="password"
              :rules="[v => !!v || t.required_field,
              userInfo.password.length > 5 || t.number_of_characters_must_be_at_least + ' 6']"
          />
          <v-input hide-details class="inp">
            {{t.repeat_pass}}
          </v-input>
          <v-text-field
              v-model="userInfo.password_confirmation"
              dense
              validate-on-blur
              v-on:keyup.enter="register()"
              outlined
              type="password"
              :rules="[v => !!v || t.required_field,
              userInfo.password === userInfo.password_confirmation || t.mismatch_pass]"
          />
        </v-card>
        <v-btn
            @click="register()"
            color="primary"
            class="font-weight-medium mt-4"
            rounded depressed
        >
          {{t.register}}
        </v-btn>
      </v-form>
    </div>
  </v-sheet>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import LogoXL from 'components/svg/LogoXL'

export default {
  name: "PagesAuthorizationConfirmEmail",
  components: {LogoXL},
  computed: {
    t() {
      return Object.assign( this.$store.state.t[this.$route.params.language]['login'], this.$store.state.t[this.$route.params.language]['form'])
    },
    ...mapGetters("auth", ["authCheckEmail", "authLoggedIn"])
  },
  props: {
    restore: Boolean,
  },
  data: () => ({
    is_registered: true,
    userInfo: {
      email: "",
      password: "",
      password_confirmation: ""
    },
    error_check: false,
  }),
  watch: {
    authLoggedIn() {
      this.$router.push({name: 'home'});
      document.location.reload(true);
    }
  },
  methods: {
    ...mapActions("auth", ["onCheckEmail", "onAuthConfirm", "onAuthLogin", "onAuthIsLoggedIn", "onAuthConfirmRestore"]),
    async register() {
      if (this.$refs.form.validate()) {
        await this.submit();
      }
    },
    async submit() {
      if (this.restore) {
        console.log('restore')
        await this.onAuthConfirmRestore({
          email: this.userInfo.email,
          token: this.$route.params.id,
          password: this.userInfo.password,
          password_confirmation: this.userInfo.password_confirmation,
          language_id: 1
        }).then(() => {
          if (this.authCheckEmail) {
            setTimeout(() => {
              this.login()
            }, 500)
          } else this.error_check = true
        })
            .catch(() => {
              this.error_check = true
              // console.log('123456789')
            })
      } else {
        await this.onAuthConfirm({
          email: this.userInfo.email,
          token: this.$route.params.id,
          password: this.userInfo.password,
          password_confirmation: this.userInfo.password_confirmation,
          language_id: 1
        }).then(() => {
          if (this.authCheckEmail) {
            setTimeout(() => {
              this.login()
            }, 500)
          } else this.error_check = true
        })
            .catch(() => {
              this.error_check = true
            })
      }
    },
    async login() {
      await this.onAuthLogin({
        data: {
          username: this.userInfo.email,
          password: this.userInfo.password,
          client_id: "1",
          client_secret: "kAUsFYysaEEi82JrFgzgYcU8SJzx8ubRkf7NJj8l",
          grant_type: "password"
        },
        remember: false
      }).then(() => {
        this.check_email()
      })
    },
    async check_email() {
      await this.onAuthIsLoggedIn()
      console.log('authLoggedIn')
      console.log(this.authLoggedIn)
      if (this.authLoggedIn) {
        await this.$router.push({name: 'home'});
        document.location.reload(true);
      }
    },
    refresh() {
      this.userInfo.email = "";
      this.userInfo.password = "";
      this.userInfo.password_confirmation = "";
    }
  }
}
</script>
