<template>
  <v-sheet width="100vw" height="100vh" color="secondary" class="login">
    <div class="text-center">
      <LogoXL/>
      <v-card
          width="97vw"
          max-width="432"
          light
          class="pa-10 mt-10 grid"
      >
        <v-btn
            v-for="l in languages"
            :key="l.id"
            large
            color="white"
            class="shadow"
            @click="selectLanguage(l.name)"
        >
          <v-img
              :src="apiDomain + '/' + l.flag"
              max-height="30"
              max-width="30"
              width="30"
              height="30"
              class="rounded-circle mr-2"
          />
          <span class="text-uppercase">
            {{l.name}}
          </span>
          <v-spacer/>
          <v-radio/>
        </v-btn>
      </v-card>
    </div>
  </v-sheet>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import LogoXL from "components/svg/LogoXL";

export default {
  name: "language",
  components: {LogoXL},
  computed: {
    ...mapGetters(["languages", "apiDomain", "temp_route"]),
  },
  methods: {
    ...mapActions(['onLanguage']),
    selectLanguage(name) {
      console.log('select name')
      console.log(name)
      this.onLanguage(name)
      this.$router.push(name + this.temp_route).catch(() => {})
      this.checkLogin(name)
    },
    async checkLogin(lang) {
      console.log('lang')
      console.log(lang)
      if (this.authLoggedIn) {
        await this.getUser(lang);
        if (this.$route.name === "login" || this.$route.fullPath === '/') {
          await this.$router.push({name: 'home', params: {language: lang}}).catch(() => {
          });
        }
      } else if (this.$route.name !== "confirmEmail" && this.$route.name !== "confirmRestore") {
        this.$router.push({name: "login", params: {language: lang}}).catch(() => {
        });
      }
    },
    async getUser(lang) {
      await this.onAuthUser().then(() => {
        if (!this.authUser['first_name'] || !this.authUser['last_name'] || !this.authUser['phone']) {
          this.$router.push({name: "userData", params: {language: lang}}).catch(() => {
          });
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;
}
</style>
