import axios from "axios";

const state = {
    articleList: [],
    article: []
};

const getters = {
    articleList(state) {
        return state.articleList;
    },
    article(state) {
        return state.article;
    }
};

const mutations = {
    setArticleList: (state, item) => {
        state.articleList = item;
    },
    setArticle: (state, item) => {
        state.article = item;
    }
};

const actions = {
    onArticleList({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/article?number=" + this.state.number + "&page=" + this.state.page;
            axios
                .get(url)
                .then(response => {
                    commit("setArticleList", response.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onArticleCreate({commit, dispatch}, data) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/article";
            axios
                .post(url, data)
                .then(response => {
                    commit("SetLoadingAnimation", false, {root: true});
                    resolve(response);
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    commit("SetLoadingAnimation", false, {root: true});
                    reject(error);
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onArticle({commit, dispatch}, id) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/article/" + id + "/edit?language_id=" + this.state.language;
            axios
                .get(url)
                .then(response => {
                    commit("setArticle", response.data);
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                    //dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onArticleSave({commit, dispatch}, {id, data}) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/article/" + id;
            axios
                .put(url, data)
                .then(response => {
                    commit("SetLoadingAnimation", false, {root: true});
                    resolve(response);
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    commit("SetLoadingAnimation", false, {root: true});
                    reject(error);
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onArticleDelete({commit, dispatch}, id) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/article/" + id;
            axios
                .delete(url)
                .then(response => {
                    commit("SetLoadingAnimation", false, {root: true});
                    resolve(response);
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    commit("SetLoadingAnimation", false, {root: true});
                    reject(error);
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onArticleSaveField({commit}, {id, data}) {
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/article/" + id + "/field";
            axios
                .put(url, data)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
