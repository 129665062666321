<template>
  <PagesOrdersId/>
</template>

<script>
import PagesOrdersId from "@/components/pages/orders/PagesOrdersId";

export default {
  name: "order_id",
  components: {PagesOrdersId}
}
</script>
