<template>
  <v-sheet width="100vw" height="100vh" color="secondary" class="login">
    <div class="text-center">
      <v-btn text @click="refresh()" :ripple="false" plain>
        <LogoXL/>
      </v-btn>
      <div class="title white--text">
        {{ t.personal_data }}
      </div>
      <v-form ref="form" lazy-validation>
        <v-card
            width="97vw"
            max-width="432"
            light
            class="pa-4"
        >
          <v-input hide-details class="inp">
            {{ t.email }}
          </v-input>
          <v-text-field
              v-model="userInfo.email"
              outlined
              dense
              disabled
          />
          <v-input hide-details class="inp">
            {{ t.first_name }}
          </v-input>
          <v-text-field
              v-model="userInfo.first_name"
              dense
              v-on:keyup.enter="register()"
              outlined
              :rules="[v => !!v || t.required_field]"
          />
          <v-input hide-details class="inp">
            {{ t.last_name }}
          </v-input>
          <v-text-field
              v-model="userInfo.last_name"
              dense
              v-on:keyup.enter="register()"
              outlined
              :rules="[v => !!v || t.required_field]"
          />
          <v-input hide-details class="inp">
            {{ t.phone }}
          </v-input>
          <v-text-field-simplemask v-model="userInfo.phone"
                                   ref="phone"
                                   v-bind:properties="{
                               prefix: '',
                               suffix: '',
                               readonly: false,
                               disabled: false,
                               outlined: true,
                               placeholder: '',
                               required: true,
                               'validate-on-blur': true,
                               rules: [ v => !!v || t.required_field ,
                                v => (v && v.length >= 12) || t.bad_phone]
                               }"
                                   v-bind:options="{
                               inputMask: '+### (#) ### ### ###',
                               outputMask: '+#############',
                               empty: null,
                               applyAfter: false,
                               alphanumeric: false,
                               lowerCase: false
                               }"
                                   v-bind:focus="focus"
                                   v-on:focus="focus = false"
                                   validate-on-blur :messages="['qqq']"/>
        </v-card>
        <v-btn
            @click="register()"
            color="primary"
            class="font-weight-medium mt-4"
            rounded depressed
        >
          {{ t.register }}
        </v-btn>
      </v-form>
    </div>
  </v-sheet>
</template>

<script>
import LogoXL from 'components/svg/LogoXL'
import {mapGetters, mapActions} from 'vuex'

export default {
  name: "PagesAuthorizationUserData",
  components: {LogoXL},
  data: () => ({
    focus: false,
    userInfo: {
      email: "",
      first_name: "",
      last_name: "",
      phone: ""
    }
  }),
  computed: {
    t() {
      return Object.assign(this.$store.state.t[this.$route.params.language]['login'], this.$store.state.t[this.$route.params.language]['form'])
    },
    ...mapGetters("authUser", ["authUser"])
  },
  created() {
    this.userInfo = this.authUser.data
  },
  methods: {
    ...mapActions("authUser", ["onAuthUserUpdate"]),
    async register() {
      if (this.$refs.form.validate()) {
        this.userInfo.language_id = 1;
        await this.onAuthUserUpdate(this.userInfo);
        await this.$router.push({name: 'home'});
        document.location.reload(true);
      }
    },
    refresh() {
      //
    }
  }
}
</script>
