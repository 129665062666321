<template>
  <router-view />
</template>

<script>

export default {
  name: 'home',
  computed: {
    t() {
      return Object.assign(this.$store.state.t[this.$route.params.language]['home'])
    }
  }
}
</script>


