<template>
  <div>
    <v-form lazy-validation ref="form" class="grid">
      <v-sheet>
        <v-input hide-details>
          {{ t['project_data'] }}
        </v-input>
        <v-card class="mt-2 border">
          <DevInput
              :text="t['project_name']"
              class="mx-4 mt-4"
              :rules="[v => !!v || t['required_field']]"
          />
          <v-divider/>
          <DevInput
              :text="t['project_number']"
              class="ma-4"
              disabled
              hide-details
          />
          <v-divider/>
          <div class="ma-4">
            <v-input hide-details class="font12">
              {{ t['project_start'] }}
            </v-input>
            <v-datetime-picker
                :textFieldProps="{
                outlined: true,
                dense: true,
                class: 'mb-n4',
                rules: [v => !!v || t['required_field']],
                'validate-on-blur': true
              }"
                :datePickerProps="{
                'no-title': true,
                scrollable: true
              }"
                :timePickerProps="{
                'no-title': true,
                scrollable: true,
                'ampm-in-title': true
              }"
                :okText="t['save']"
                :clearText="t['clear']"
            >
              <template slot="dateIcon">
                <v-icon>mdi-calendar-outline</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-clock-outline</v-icon>
              </template>
            </v-datetime-picker>
          </div>
          <div class="ma-4">
            <v-input hide-details class="font12">
              {{ t['project_end'] }}
            </v-input>
            <v-datetime-picker
                :textFieldProps="{
                outlined: true,
                dense: true,
                class: 'mb-n4',
                rules: [v => !!v || t['required_field']],
                'validate-on-blur': true
              }"
                :datePickerProps="{
                'no-title': true,
                scrollable: true
              }"
                :timePickerProps="{
                'no-title': true,
                scrollable: true,
                'ampm-in-title': true
              }"
                :okText="t['save']"
                :clearText="t['clear']"
            >
              <template slot="dateIcon">
                <v-icon>mdi-calendar-outline</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-clock-outline</v-icon>
              </template>
            </v-datetime-picker>
          </div>
          <v-divider/>
          <DevInput
              :text="t['note']"
              text-area
              class="ma-4"
              hide-details
          />
        </v-card>
      </v-sheet>
      <v-sheet>
        <v-input hide-details>
          {{ t['client_data'] }}
        </v-input>
        <v-card class="mt-2 border">
          <DevInput
              :text="t['customer_object_number']"
              :rules="[v => !!v || t['required_field']]"
              class="mx-4 mt-4"
          />
          <v-divider/>
          <DevInput
              :text="t['client']"
              autocomplete
              :rules="[v => !!v || t['required_field']]"
              :hint="t['hint_create_or_choose']"
              class="mx-4 mt-4"
          />
          <v-divider/>
          <DevInput
              :text="t['responsible_person']"
              :hint="t['hint_create_or_choose']"
              autocomplete
              :rules="[v => !!v || t['required_field']]"
              class="mx-4 mt-4"
          />
          <v-divider/>
          <div class="px-4 pt-4 pb-2">
            <DevInput
                :text="t['contact_person']"
                :hint="t['hint_create_or_choose']"
                autocomplete
                :rules="[v => !!v || t['required_field']]"
            />
            <v-btn text small color="primary">
              <v-icon class="ml-n2 mr-2">mdi-plus</v-icon>
              {{t['add']}}
            </v-btn>
          </div>
          <v-divider/>
          <DevInput
              :text="t['region']"
              :hint="t['hint_create_or_choose']"
              autocomplete
              :rules="[v => !!v || t['required_field']]"
              class="mx-4 mt-4"
          />
          <v-divider/>
          <v-btn
              large
              color="primary"
              outlined
              class="ma-4"
          >
            <v-icon class="ml-n1 mr-2">mdi-download</v-icon>
            {{t['add_project_photo']}}
          </v-btn>
          <v-divider/>
          <DevInput
              :text="t['note']"
              text-area
              hide-details
              class="ma-4"
          />
        </v-card>
      </v-sheet>
      <v-sheet class="grid_transport">
        <v-input hide-details>
          {{ t['transportation_address'] }}
        </v-input>
        <v-card class="mt-2 pa-4 border">
          <DevInput
              :text="t['address']"
              :rules="[v => !!v || t['required_field']]"
          />
          <DevInput
              :text="t['zip']"
              :rules="[v => !!v || t['required_field']]"
          />
          <DevInput
              :text="t['city']"
              :rules="[v => !!v || t['required_field']]"
              class="mb-n4"
          />
        </v-card>
      </v-sheet>
    </v-form>
    <v-btn class="primary mt-5 mb-10">
      {{t['save']}}
    </v-btn>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import DevInput from "components/dev/DevInput";

export default {
  name: "PagesOrdersProjectIdEdit",
  components: {DevInput},
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters("service", ["serviceList", "serviceCategoryList"]),
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['fields'],
          this.$store.state.t[this.$route.params.language]['form'],
          this.$store.state.t[this.$route.params.language]['buttons'],
          this.$store.state.t[this.$route.params.language]['page_toolbar']
      )
    }
  },
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    grid-template-columns: 1fr;
  }
  @media #{map-get($display-breakpoints, 'xl-only')} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &_date-picker {
    @extend .grid;
    grid-template-columns: 1fr 1fr !important;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      grid-template-columns: 1fr !important;
    }
  }

  &_transport {
    @media #{map-get($display-breakpoints, 'sm-only')} {
      grid-column: 2/3
    }
    @media #{map-get($display-breakpoints, 'md-only')} {
      grid-column: 2/3
    }
    @media #{map-get($display-breakpoints, 'lg-only')} {
      grid-column: 2/3
    }
  }
}
</style>
