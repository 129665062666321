<template>
  <div>
    <v-form lazy-validation ref="form" class="grid" :key="key">
      <v-card class="pa-4 border grid_inner">
        <div class="text-center">
          <DevImageBtn :image="data.image" width="180" height="180" circle />
          <v-btn @click="loadImage('image')" v-if="edit" class="second mt-4">
            {{data.image ? t['change'] : t['add'] }}
          </v-btn>
        </div>
        <div class="grid_inform">
          <div class="inform" v-for="(item, i) in list" :key="i">
            <div class="font12 grey--text text--darken-1">
              {{ t[item] }}
            </div>
            <v-text-field
              v-if="edit"
              v-model="data[item]"
              hide-details
              dense
              :rules="[v => !!v || t['required_field']]"
            />
            <div v-else>
              {{ data[item] || '-' }}
            </div>
          </div>
          <div class="inform">
            <div class="font12 grey--text text--darken-1">
              {{ t.dob }}
            </div>
            <v-menu
              v-if="edit"
              ref="menu_dob"
              v-model="menu_dob"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dobFormatted"
                  hide-details
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="data.dob"
                no-title
                :active-picker.sync="activePicker"
                first-day-of-week="1"
                :max="
                  new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10)
                "
                @input="menu_dob = false"
              ></v-date-picker>
            </v-menu>
            <div v-else>
              {{ dobFormatted || '-' }}
            </div>
          </div>
        </div>
      </v-card>
      <v-card class="pa-4 border">
        <div class="font12 grey--text text--darken-1 mb-4">
          {{ t['files'] }}
        </div>
        <v-row no-gutters v-if="edit">
          <div v-for="(file, f) in data.files.length" :key="f" class="rel mr-2 mb-2">
            <div class="file">
              <iframe
                :src="apiDomain + '/' + data.files[file - 1]"
                width="100"
                height="130"
                scrolling="no"
                style="overflow: hidden;"
              />
            </div>
            <v-hover v-slot="{ hover }">
              <v-btn @click="delFile(file)" text width="90" height="120">
                <v-icon v-if="hover" class="red--text" size="30">mdi-close-circle-outline</v-icon>
              </v-btn>
            </v-hover>
          </div>
          <v-btn @click="loadImage('doc')" class="second mt-8">
            <v-icon class="ml-n2 mr-2">mdi-plus</v-icon>
            {{ t['add'] }}
          </v-btn>
        </v-row>
        <v-row no-gutters v-else>
          <div v-for="(file, f) in data.files" :key="f" class="rel mr-2 mb-2">
            <div class="file">
              <iframe
                :src="apiDomain + '/' + file"
                width="100"
                height="130"
                scrolling="no"
                style="overflow: hidden;"
              />
            </div>
            <v-btn target="_blank" :href="apiDomain + '/' + file" text width="90" height="120">
            </v-btn>
          </div>
        </v-row>
      </v-card>
    </v-form>

    <v-btn v-if="edit" @click="saveItem" class="primary my-5">
      {{ t['save'] }}
    </v-btn>
    <div v-else class="text-end my-5">
      <v-btn @click="editItem" class="primary" fab>
        <v-icon class="min">mdi-pencil</v-icon>
      </v-btn>
    </div>
    <input
      type="file"
      id="file"
      ref="file"
      accept=".JPG, .PNG, .SVG, .GIF, .WebP"
      style="display: none"
      v-on:change="handleFileUpload()"
    />
    <input
        type="file"
        id="doc"
        ref="doc"
        accept=".PDF"
        style="display: none"
        v-on:change="handleFileUpload()"
    />
  </div>
</template>

<script>
import DevImageBtn from 'components/dev/DevImageBtn'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PagesClientPhysId',
  components: { DevImageBtn },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
    edit: Boolean,
    onSave: {},
  },
  data: () => ({
    list: ['first_name', 'last_name', 'email', 'phone'],
    deleted_image: [],
    new_images: [],
    load_file: '',
    key: 0,
    click_save: false,
    menu_dob: false,
    activePicker: null,
    field: ''
  }),
  computed: {
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['page_toolbar'],
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['buttons'],
        this.$store.state.t[this.$route.params.language]['form'],
      )
    },
    ...mapGetters(['file', 'apiDomain']),
    dobFormatted: {
      get() {
        return this.formatDate(this.data.dob)
      },
      set(value) {
        this.data.dob = value
      },
    },
  },
  watch: {
    load_file() {
      this.submitFile()
    },
    menu_dob(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  beforeDestroy() {
    this.closeItem()
  },
  methods: {
    ...mapActions(['uploadFile', 'destroyFile']),
    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year}`
    },

    delFile(id) {
      console.log(id)
      this.deleted_image.push(this.data.files[id])
      this.data.files.splice(id - 1, 1)
    },

    closeItem() {
      console.log('закрываем окно')
      if (this.click_save) {
        console.log('нажата кнопка сохранения')
        for (let del in this.deleted_image) {
          this.destroyFile(this.deleted_image[del])
        }
      } else {
        console.log('нажата кнопка выхода')
        for (let del in this.new_images) {
          this.destroyFile(this.new_images[del])
        }
      }
      this.deleted_image = []
      this.new_images = []
      this.click_save = false
    },

    editItem() {
      this.$router.push({ name: 'phys_id_edit' })
    },

    saveItem() {
      if (this.$refs.form.validate()) {
        this.click_save = true
        this.onSave(this.data)
        this.$router.push({ name: 'phys_id' })
      } else {
        this.error = true
      }
    },

    loadImage(field) {
      this.field = field
      if (field === 'doc') {
        this.$refs.doc.click()
      } else {
        this.$refs.file.click()
      }
    },
    handleFileUpload() {
      if (this.field === 'doc') {
        this.load_file = this.$refs.doc.files[0]
      } else {
        this.load_file = this.$refs.file.files[0]
      }
    },
    async submitFile() {
      let formData = new FormData()
      formData.append('file', this.load_file)

      if (this.field === 'doc') {
        await this.uploadFile({
          data: formData,
          use_case: 'file',
        }).then(() => {
          this.data.files.push(this.file.url)
          this.new_images.push(this.file.url)
          this.key++
        })
      } else {
        await this.uploadFile({
          data: formData,
          use_case: 'cover',
        }).then(() => {
          this.data.image = this.file.url
          this.new_images.push(this.file.url)
          this.key++
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: 12px;

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    grid-template-columns: 1fr;
  }

  &_inner {
    @extend .grid;
    grid-template-columns: auto 1fr;
    align-items: center;
  }

  &_inform {
    @extend .grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: center;

    @media #{map-get($display-breakpoints, 'sm-only')} {
      grid-template-columns: 1fr 1fr;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
      grid-template-columns: 1fr;
    }
  }

  .rel {
    position: relative;
  }

  .file {
    width: 90px;
    height: 120px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
