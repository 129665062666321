<template>
  <div>
    <PagesServicesProductId
        :on-item="getItem"
        :save-item="Number($route.params.id) > 0 ? saveItem : createItem"
    />
<!--    <PagesServicesProductId-->
<!--        :data="item"-->
<!--        :key="key"-->
<!--        :save-item="item.data.id ? saveItem : createItem"-->
<!--        :on-item="getItem"-->
<!--    />-->
  </div>

</template>

<script>
import PagesServicesProductId from "@/components/pages/services/PagesServicesProductId";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "product_id",
  components: {PagesServicesProductId},
  methods: {
    ...mapActions("service", ["onService", "onServiceCreate", "onServiceSave"]),
    async getItem() {
      if (Number(this.$route.params.id) > 0) {
        await this.onService({id: this.$route.params.id}).then(res => {
          console.log(res.data)
        })
      }
    },
    transformData(data) {
      data.data.service_category_id = data.service_category.id
      data.data.image = JSON.stringify(data.data.image)
      data.data.docs = JSON.stringify(data.data.docs)

      let spec = Object.assign(data.data.text.specification);
      let specification = {}
      for (let i in spec) {
        console.log(i)
        specification[spec[i].name] = spec[i].value
      }
      data.data.text.specification = specification
      data.data.text = JSON.stringify(data.data.text)
      data.data.icon = data.data.seo_image
      data.data.subtext = data.data.title
      data.data.is_delivery_required = false
      return data
    },
    async createItem (data) {
      let temp = this.transformData(data)
      await this.onServiceCreate(temp.data).then(()=> {
        this.$router.push({name: 'products'})
      })
    },
    async saveItem(data) {
      let temp = this.transformData(data)
      console.log(temp)
      await this.onServiceSave({id: data.data.id, data: temp.data}).then(()=> {
        //this.getItem()
      })
    }
  }
}
</script>
