<template>
  <DefaultPageModal :text="data.id ? t['client'] : t['add_client']" :on-close="onClose" :key="key">
    <v-form lazy-validation ref="form">
<!--      <DefaultPageLanguage class="mt-n5 mt-lg-n10" />-->
      <div class="grid">
        <v-card class="pa-4 border">
          <v-input hide-details class="mb-3 font-weight-bold">
            {{ t['registration_data'] }}
          </v-input>
          <DevInput
            :text="t['email']"
            v-model="data.email"
            :rules="[v => !!v || t['required_field']]"
          />
          <DevInput
            :text="t['first_name']"
            v-model="data.first_name"
            :rules="[v => !!v || t['required_field']]"
          />
          <DevInput
            :text="t['last_name']"
            v-model="data.last_name"
            :rules="[v => !!v || t['required_field']]"
          />
          <DevInput
            :text="t['phone']"
            v-model="data.phone"
            :rules="[v => !!v || t['required_field']]"
          />
          <div class="d-flex align-center">
            <DevImageBlock :images="Array(data.image)" :text="t['logo']" />
            <v-btn @click="loadImage()" class="second ml-5 mt-5">
              {{ data.image ? t['change'] : t['add'] }}
            </v-btn>
          </div>
          <div class="font12 mt-n3 mb-1 red--text text-start">
            {{ error ? t['required_field'] : '' }}
          </div>
        </v-card>
        <v-card class="pa-4 border">
          <DevInput
            :text="t['company_title']"
            v-model="data.company_name"
            :rules="[v => !!v || t['required_field']]"
          />
          <DevInput
            :text="t['vat']"
            v-model="data.data.vat"
            :rules="[v => !!v || t['required_field']]"
          />
          <DevInput
            :text="t['ytunnus']"
            v-model="data.ytunnus"
            :rules="[v => !!v || t['required_field']]"
          />
<!--          <DevInput-->
<!--              :text="t['billing_address']"-->
<!--              v-model="data.address"-->
<!--              :rules="[v => !!v || t['required_field']]"-->
<!--          />-->
          <v-input hide-details class="mb-3 font-weight-bold">
            {{ t['electronic_invoice'] }}
          </v-input>
          <DevInput
            :text="t['ovt']"
            v-model="data.data.ovt"
            :rules="[v => !!v || t['required_field']]"
          />
          <DevInput
            :text="t['operator']"
            v-model="data.data.operator"
            :rules="[v => !!v || t['required_field']]"
          />
          <DevInput
            :text="t['operator_id']"
            v-model="data.data.operator_id"
            :rules="[v => !!v || t['required_field']]"
          />
        </v-card>
      </div>
      <v-btn @click="saveData" class="primary mt-5 mb-n10">
        {{ data.id ? t['save'] : t['create'] }}
      </v-btn>
    </v-form>
    <input
      type="file"
      id="file"
      ref="file"
      accept=".JPG, .JPEG, .PNG, .SVG, .GIF, .WebP"
      style="display: none"
      v-on:change="handleFileUpload()"
    />
  </DefaultPageModal>
</template>

<script>
import DefaultPageModal from 'components/default/page/DefaultPageModal'
import { mapActions, mapGetters } from 'vuex'
import DevImageBlock from 'components/dev/DevImageBlock'
import DevInput from 'components/dev/DevInput'
import DefaultPageLanguage from 'components/default/page/DefaultPageLanguage'

export default {
  name: 'PagesClientJurNew',
  components: { DefaultPageLanguage, DevInput, DevImageBlock, DefaultPageModal },
  props: {
    onClose: {},
    onSave: {},
    onItem: {},
  },
  data: () => ({
    data: {
      type: 'legal',
      role_id: 1,
      data: {},
    },
    key: 0,
    error: false,
    load_file: '',
    click_save: false,
    deleted_image: [],
    new_files: [],
  }),
  computed: {
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['page_toolbar'],
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['buttons'],
        this.$store.state.t[this.$route.params.language]['form'],
      )
    },
    ...mapGetters(['language', 'file']),
    ...mapGetters('user', ['user']),
  },
  watch: {
    load_file() {
      if (this.load_file) {
        this.error = false
        this.submitFile()
      }
    },
    user() {
      console.log(this.user)
      while (typeof this.user.data === 'string') {
        this.user.data = JSON.parse(this.user.data)
      }
      this.data = this.user
      this.key++
    },
  },
  created() {
    this.getItem()
  },
  beforeDestroy() {
    this.clearData()
  },
  methods: {
    ...mapActions(['uploadFile', 'destroyFile']),
    getItem() {
      if (this.onItem) {
        this.onItem()
      }
    },
    clearData() {
      if (this.click_save) {
        console.log('жмакнули кнопку сохранения записи')
        for (let del in this.deleted_image) {
          this.destroyFile(this.deleted_image[del])
        }
      } else {
        console.log('не сохраняя закрыли окно')
        for (let del in this.new_files) {
          this.destroyFile(this.new_files[del])
        }
      }
    },
    saveData() {
      if (this.$refs.form.validate()) {
        this.data.language_id = this.language
        if (this.data.address) {
          this.data.address = JSON.stringify(this.data.address)
        }
        if (this.data.e_address) {
          this.data.e_address = JSON.stringify(this.data.e_address)
        }

        this.click_save = true
        this.onSave(this.data)
      } else {
        this.error = true
      }
    },
    loadImage() {
      this.$refs.file.click()
    },
    handleFileUpload() {
      this.load_file = this.$refs.file.files[0]
    },
    async submitFile() {
      let formData = new FormData()
      formData.append('file', this.load_file)
      console.log(this.load_file)
      await this.uploadFile({
        data: formData,
        use_case: 'cover',
      }).then(() => {
        if (this.data.image) {
          this.deleted_image.push(this.data.image)
        }
        this.data.image = this.file.url
        this.new_files.push(this.file.url)
        this.key++
      })
    },
  },
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    grid-template-columns: 1fr;
  }
}
</style>
