<template>
  <div>
    <PagesOrdersProjectDoc
        :edit="$route.name !== 'project_id'"
        ref="docProject"
        :on-save="saveItem"
    />
    <v-sheet max-width="1024" class="mx-auto">
      <div class="d-flex justify-space-between mt-5 mb-10">
        <v-progress-circular
            v-if="generate"
            :rotate="-90"
            :size="72"
            :width="3"
            :value="percent"
            color="primary"
            class="mr-5"
        >
          {{ percent + '%'}}
        </v-progress-circular>
        <v-btn x-large @click="generatePdf(true)" v-else-if="$route.name === 'project_id'" class="primary mr-5" fab>
          <DownloadPDF/>
        </v-btn>
        <v-btn x-large @click="editItem" v-if="$route.name === 'project_id'" class="primary" fab>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import html2pdf from 'html2pdf.js'
import PagesOrdersNewProject from "components/pages/orders/PagesOrdersNewProject";
import PagesOrdersProjectId from "components/pages/orders/PagesOrdersProjectId";
import PagesOrdersProjectIdEdit from "components/pages/orders/PagesOrdersProjectIdEdit";
import {mapActions} from "vuex";
import PagesOrdersProjectDoc from "components/pages/orders/PagesOrdersProjectDoc";
import DefaultPageLanguage from "components/default/page/DefaultPageLanguage";
import Logo from "components/svg/Logo";
import PagesOrdersProjectDocHeader from "components/pages/orders/PagesOrdersProjectDocHeader";
import DevImageBtn from "components/dev/DevImageBtn";
import DownloadPDF from "components/svg/DownloadPDF";

export default {
  name: "project_id",
  components: {
    DownloadPDF,
    DevImageBtn,
    PagesOrdersProjectDocHeader,
    Logo,
    DefaultPageLanguage,
    PagesOrdersProjectDoc, PagesOrdersProjectIdEdit, PagesOrdersProjectId, PagesOrdersNewProject
  },
  data: () => ({
    fileName: '',
    date: new Date(),
    generate: false,
    percent: 0
  }),
  mounted() {
    this.getItem()
  },
  methods: {
    ...mapActions("order", ["onOrder", "onOrderSave", "onNewOrder", "onAvailability"]),
    async getItem() {
      if (this.$route.params.id) {
        await this.onOrder(this.$route.params.id).then(res => {
          this.fileName = '#VH' + String(this.date.getFullYear()).slice(2, 4) + String(res.data.id).padStart(4, '0')
        })
      }
    },

    editItem() {
      this.$router.push({name: 'project_id_edit'})
    },

    async saveItem(data) {
      let temp = Object.assign(data)
      temp.data = JSON.stringify(temp.data)
      if (temp.start.length < 15) {
        temp.start = temp.start + ' 00:00:00'
      }
      if (temp.end.length < 15) {
        temp.end = temp.end + ' 00:00:00'
      }

      if (temp.id) {
        await this.onOrderSave(temp).then(() => {
          this.getItem()
          this.$router.push({name: "project_id"})
        })
      } else {
        await this.onNewOrder(temp).then(() => {
          this.$router.push({name: "projects"})
        })
      }
    },



    async generatePdf(save) {
      //this.$store.commit("SetLoadingAnimation", true)
      this.percent = 0
      this.generate = true
      setTimeout(() => {
        this.percent = 20
      }, 500)
      let target = document.getElementById('target')
      let header = document.getElementById('pdf_header')
      let footer = document.getElementById('pdf_footer')
      let headerHeight = header.offsetHeight * 0.75
      let headerWidth = header.offsetWidth * 0.75
      let footerHeight = footer.offsetHeight * 0.75
      let footerWidth = footer.offsetWidth * 0.75
      // число умноженное на 0,75 это перевод px в pt
      // console.log(this.workClientProjectShow.name)
      let name = this.fileName
      let file
      setTimeout(() => {
        this.percent = 60
      }, 1200)
      await html2pdf()
          .from(target)
          .set({
            //margin: [0, -2.6, 0, 0],
            margin: [headerHeight + 30 * 0.75, 0, footerHeight + 24 * 0.75, 0],
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: {
              dpi: 96, //
              scrollX: 0,
              scrollY: 0,
              backgroundColor: '#FFF',
              scale: 3, //2
              useCORS: true,
            },
            jsPDF: {
              unit: 'pt',
              format: 'a4',
              orientation: 'p',
            },
            pagebreak: {
              avoid: 'tr',
              mode: [
                'avoid-all',
                'css',
                'legacy',
              ],
            },
          })
          .toPdf()
          .get('pdf')
          .then(async function (pdf) {
            this.value = 70
            let totalPages = pdf.internal.getNumberOfPages()
            let footerCanvas = await html2canvas(footer, {
              scale: 2,
              useCORS: true,
            })
            let footerImg = footerCanvas.toDataURL('image/png')
            let headerCanvas = await html2canvas(header, {
              scale: 2,
              useCORS: true,
            })
            let headerImg = headerCanvas.toDataURL('image/png')
            for (let i = 1; i <= totalPages; i++) {
              console.log('pdf', pdf)
              pdf.setPage(i)
              pdf.setFontSize(12)
              pdf.setTextColor('#333333')
              pdf.text(
                  i + '/' + totalPages,
                  pdf.internal.pageSize.getWidth() - 22.5,
                  headerHeight + 14,
                  { align: 'right' },
              )
              pdf.addImage(headerImg, 0.7, 0, headerWidth, headerHeight)
              pdf.addImage(
                  footerImg,
                  0.7,
                  pdf.internal.pageSize.getHeight() - footerHeight,
                  footerWidth,
                  footerHeight,
              )
            }
            file = pdf.output('blob') //если надо отправить на сервер
            if (save) {
              pdf.save(name + '.pdf')
            }
          })
      this.fileSend = file
      this.percent = 100
      //this.$store.commit("SetLoadingAnimation", false)
      setTimeout(() => {
        this.percent = 0
        this.generate = false
      }, 3000)
    },
  }
}
</script>
