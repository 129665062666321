<template>
  <div>
    {{$route.name}}
  </div>
</template>

<script>
export default {
name: "account"
}
</script>

<style scoped>

</style>
