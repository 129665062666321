<template>
  <div>
    <PagesServicesUnitId
        v-if="$route.name === 'unit_id'"
        :data="item"
        :key="key"
        :save-item="item.id ? saveItem : createItem"
    />
    <router-view v-else/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PagesServicesUnitId from "@/components/pages/services/PagesServicesUnitId";

export default {
  name: "unit_id",
  components: {PagesServicesUnitId},
  data: () => ({
    item: {
      location_id: 1,
      language_id: 1,
      service: {
        category: null,
      },
      location: {}
    },
    key: 0,
    temp: {}
  }),
  computed: {
    ...mapGetters("service", ["service"])
  },
  mounted() {
    this.getItem()
  },
  beforeDestroy() {
    this.$store.commit("SetBreadcrumbs", null)
  },
  methods: {
    ...mapActions("service", ["onServiceUnit", "onService", "onServiceList", "onServiceUnitPost", "onServiceUnitPut"]),
    ...mapActions("serviceControl", ["onServiceControlList"]),

    async getItem() {
      if (this.$route.params.id != 0) {
        await this.onServiceUnit({
          id: this.$route.params.id,
          lang: 1
        }).then(res => {
          this.item = res.data.data[0];
          this.item.num = String(this.item.id).padStart(4, '0')
          this.onServiceControlList({number: '0', id: this.item.service.id})
          this.getService()
        })
      } else {
        let temp = [
          {
            "path": "home",
            "name": "VuokraHyva"
          },
          {
            "path": "services",
            "name": this.$store.state.t[this.$route.params.language]['menu']['services']
          },
          {
            "path": "products",
            "name": this.$store.state.t[this.$route.params.language]['menu']['products']
          },
          {
            "path": "units",
            "name": this.$store.state.t[this.$route.params.language]['menu']['units']
          },
          {
            "path": "unit_id",
            "name": this.$store.state.t[this.$route.params.language]['fields']['unit']
          }
        ]
        this.$store.commit("SetBreadcrumbs", temp)
      }
    },

    async getService() {
      await this.onService({id: this.item.service.id, lang: 1}).then(res => {
        this.item.service.category = res.data.service_category.id
        this.onServiceList(res.data.service_category.id)
        let temp = [
          {
            "path": "home",
            "name": "VuokraHyva"
          },
          {
            "path": "services",
            "name": this.$store.state.t[this.$route.params.language]['menu']['services']
          },
          {
            "path": "products",
            "query": {service: res.data.service_category.id},
            "name": res.data.service_category.title
          },
          {
            "path": "products",
            "name": this.$store.state.t[this.$route.params.language]['menu']['products']
          },
          {
            "path": "units",
            "query": {product: res.data.data.id},
            "name": res.data.data.title
          },
          {
            "path": "units",
            "name": this.$store.state.t[this.$route.params.language]['menu']['units'],
          },
          {
            "path": "unit_id",
            "name": this.item.name
          }
        ]
        this.$store.commit("SetBreadcrumbs", temp)
      })
      this.key++
    },

    transformData(data) {
      this.temp = Object.assign(data)
      this.temp.service_id = this.temp.service.id
      this.temp.location_id = this.temp.location.id
    },

    async createItem(data) {
      this.transformData(data)
      await this.onServiceUnitPost(this.temp).then(() => {
        this.$router.push({name: 'units'})
      })
    },
    async saveItem(data) {
      this.transformData(data)
      await this.onServiceUnitPut(this.temp).then(() => {
        //this.getItem()
      })
    },
  }
}
</script>
