<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path d="M12.0927 5.99959H8.41989V0.00296117H8.73097C10.944 0.00296117 13.1564 0.00746146 15.3689 -0.000976562C15.8667 -0.000976562 16.2689 0.15147 16.6233 0.51093C18.911 2.81207 21.2048 5.10572 23.5048 7.39186C23.6678 7.54606 23.7964 7.73285 23.8824 7.94007C23.9683 8.14729 24.0097 8.37029 24.0038 8.59456C23.9989 12.8878 23.9976 17.1818 23.9998 21.4766C23.9998 22.9955 22.9963 23.9973 21.4797 23.9985C16.7641 24.0011 12.0482 24.0011 7.33194 23.9985C5.80748 23.9985 4.80054 22.9825 4.80054 21.4558C4.80054 19.4594 4.80054 17.4618 4.79548 15.4659C4.78768 15.244 4.86222 15.027 5.00474 14.8567C7.28825 12.0136 9.56727 9.16778 11.8418 6.31911C11.9138 6.23191 11.9802 6.14303 12.0927 5.99959ZM15.6192 8.3763H21.4982L15.6181 2.5023L15.6192 8.3763ZM12.8892 20.3954C13.4056 20.3954 13.9018 20.4061 14.3968 20.3954C15.4319 20.3684 16.1637 19.7974 16.3854 18.786C16.5887 17.8768 16.5876 16.9338 16.382 16.0251C16.1868 15.1706 15.6541 14.5659 14.7568 14.45C14.1499 14.3718 13.5249 14.4354 12.8892 14.4354V20.3954ZM9.52695 18.2944C9.83466 18.2944 10.0974 18.3017 10.3589 18.2944C11.259 18.264 11.9121 17.7673 12.0465 16.9443C12.1114 16.5671 12.1089 16.1814 12.0392 15.8051C11.898 15.1003 11.4711 14.5524 10.7364 14.4686C9.97361 14.3819 9.19337 14.4494 8.42045 14.4494V20.3825H9.52527L9.52695 18.2944ZM20.1814 16.9443H18.4594V15.408H20.3827V14.423H17.3332V20.3802H18.442V17.9523H20.1814V16.9443Z" fill="white"/>
      <path d="M-0.00170898 7.20325H2.3885V0.0185547H7.19874V7.19257H9.60639L4.80009 13.2004L-0.00170898 7.20325Z" fill="white"/>
      <path d="M14.0195 15.4485C14.856 15.3124 15.265 15.5779 15.3274 16.3823C15.3791 17.0731 15.3776 17.7669 15.3229 18.4575C15.2627 19.231 14.8228 19.5095 14.0195 19.3576" fill="white"/>
      <path d="M9.54297 17.2959V15.4036C9.97781 15.4891 10.483 15.2101 10.7912 15.6944C11.0528 16.1056 11.0871 16.5838 10.8216 17.0006C10.5111 17.4883 9.99074 17.2166 9.54297 17.2959Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24.0045" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "DownloadPDF"
}
</script>

<style scoped>

</style>
