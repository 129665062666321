<template>
  <PagesAuthorizationConfirmEmail/>
</template>

<script>
import PagesAuthorizationConfirmEmail from "@/components/pages/authorization/PagesAuthorizationConfirmEmail";

export default {
  name: "confirm_email",
  components: {PagesAuthorizationConfirmEmail}
}
</script>
