<template>
  <div>
    <div class="grid">
      <v-sheet>
        <v-input hide-details>
          {{ t['project_data'] }}
        </v-input>
        <v-card class="mt-2 pa-4 border">
          <div v-for="(l, key) in list1" :key="key">
            <div class="grid_inner">
              <div class="font12">
                {{ t[key] }}
              </div>
              <div>
                {{ l || '-' }}
              </div>
            </div>
            <v-divider />
          </div>
        </v-card>
        <div class="grid_inner_block mt-4">
          <v-card class="pa-4 border">
            <template v-for="(l) in list3">
              {{l}}
            </template>
            
            <!-- <DevInput v-for="(l, key) in list3" v-model="l" :key="key" :text="t[l]" disabled  /> -->
          </v-card>
          <v-card class="pa-4 border">
            <DevInput text-area :text="t['note']" v-model="data['note']" />
          </v-card>
        </div>
      </v-sheet>
      <v-sheet>
        <v-input hide-details>
          {{ t['client_data'] }}
        </v-input>
        <v-card class="mt-2 pa-4 border">
          <div v-for="(l, key) in list2" :key="key">
            <div class="grid_inner">
              <div class="darken-1 font12">
                {{ t[key] }}
              </div>
              <div>
                {{ l || '-' }}
              </div>
            </div>
            <v-divider />
          </div>
        </v-card>
        <div class="grid_inner_block mt-4">
          <v-card class="pa-4 border">
            <DevImageBtn :image="data.image" width="100%" height="100%" min-height="250" />
          </v-card>
          <v-card class="pa-4 border">
            <DevInput text-area :text="t['description']" v-model="data['description']" />
          </v-card>
        </div>
      </v-sheet>
    </div>
    <div class="text-end">
      <v-btn @click="openDoc" class="primary mt-5 mb-10 mx-5" outlined fab>
        <v-icon class="min">mdi-file-pdf-outline</v-icon>
      </v-btn>
      <v-btn @click="editItem" class="primary mt-5 mb-10" fab>
        <v-icon class="min">mdi-pencil</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DevInput from 'components/dev/DevInput'
import DevImageBtn from 'components/dev/DevImageBtn'

export default {
  name: 'PagesOrdersProjectId',
  components: { DevImageBtn, DevInput },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data: () => ({}),
  created() {
    // this.onServiceCategoryList()
  },
  computed: {
    ...mapGetters('service', ['serviceList', 'serviceCategoryList']),
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['form'],
        this.$store.state.t[this.$route.params.language]['buttons'],
        this.$store.state.t[this.$route.params.language]['page_toolbar'],
      )
    },
    list1() {
      return {
        project_name: this.data.data && this.data.data.project_name,
        project_number: null,
        service: this.data.service && this.data.service.title,
        product: this.data.service_unit && this.data.service_unit.name,
      }
    },
    list2() {
      return {
        client: this.data.user_data && this.data.user_data.email,
        responsible_person: this.data.data && this.data.data.responsible_person,
        contact_person: this.data.data && this.data.data.client_contact_person,
        // region: '',
        branch: this.data.service_unit && this.data.service_unit.location,
      }
    },
    list3() {
      return {
        created_at: this.data.created_at,
        project_start: this.data.start,
        project_end: this.data.end,
        // actual_project_end: null,
      }
    },
  },
  methods: {
    ...mapActions('service', ['onServiceCategoryList', 'onServiceList']),
    editItem() {
      this.$router.push({ name: 'project_id_edit' })
    },
    openDoc() {
      this.$router.push({ name: 'project_id_doc' })
    },
    getProperty(path) {
      const pathArray = path.split('.')
      let result = '-'
      for (var i of pathArray) {
        result = i
      }
      return result
    },
  },
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    grid-template-columns: 1fr;
  }

  &_inner {
    @extend .grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: minmax(60px, auto);
    align-items: center;
  }

  &_inner_block {
    @extend .grid;
    @media #{map-get($display-breakpoints, 'md-and-down')} {
      grid-template-columns: 1fr;
    }
  }
}
</style>
