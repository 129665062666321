<template>
  <div>
    <DefaultPageToolbar
        v-if="$route.name === 'phys'"
        :total="Number(list.amount)"
        :last_page="list.lastPage"
        :create-text="$store.state.t[this.$route.params.language]['page_toolbar']['add_client']"
        :on-create="openNewItem"
    >
      <DefaultPageTable
          :title="$store.state.t[this.$route.params.language]['menu']['phys']"
          :data="list.data"
          :headers="headers"
          :on-edit="editItem"
          :on-delete="askDeleteItem"
          :ask-delete-fields="['first_name', 'last_name']"
      />
      <v-dialog v-model="modalNewItem" width="600">
        <PagesClientPhysNew :on-save="createItem" :on-close="closeModals" />
      </v-dialog>
      <v-dialog v-model="modalAskDelete" width="600">
        <DefaultPageAskDelete
            text="client"
            :name="name"
            :on-close="closeModals"
            :on-delete="deleteItem"
        />
      </v-dialog>
    </DefaultPageToolbar>
    <router-view v-else/>
  </div>
</template>

<script>
import DefaultPageToolbar from "components/default/page/DefaultPageToolbar";
import DefaultPageTable from "components/default/page/DefaultPageTable";
import {mapActions, mapGetters} from "vuex";
import PagesClientPhysNew from "components/pages/clients/PagesClientPhysNew";
import DefaultPageAskDelete from "components/default/page/DefaultPageAskDelete";
export default {
  name: "phys",
  components: {DefaultPageAskDelete, PagesClientPhysNew, DefaultPageTable, DefaultPageToolbar},
  data: () => ({
    list: {data: []},
    id: 0,
    name: '',
    modalNewItem: false,
    modalAskDelete: false
  }),
  computed: {
    headers() {
      return [
        {
          value: ["first_name", "last_name"],
          text: this.$store.state.t[this.$route.params.language]['form']['first_name'] + ' ' + this.$store.state.t[this.$route.params.language]['form']['last_name']
        },
        {
          value: ["email"],
          text: this.$store.state.t[this.$route.params.language]['form']['email']
        },
        {
          value: ["phone"],
          text: this.$store.state.t[this.$route.params.language]['form']['phone']
        }
      ]
    },
  },
  watch: {
    '$route.query'() {
      if(this.$route.name === 'phys') {
        this.getList()
      }
    }
  },
  mounted() {
    if(this.$route.name === 'phys') {
      this.getList()
    }
  },
  methods: {
    ...mapActions("user", ["onUserList", "onUserDelete"]),
    ...mapActions("auth", ["onAuthRegisterUser"]),
    async getList(){
      this.list = {}
      await this.onUserList({type: 'natural', sort: 'desc', field: 'id'}).then(res => {
        this.list = res.data
      })
    },
    closeModals() {
      this.modalNewItem = false;
      this.modalAskDelete = false;
    },
    openNewItem(){
      this.modalNewItem = true;
    },
    async createItem(data) {
      data.data = JSON.stringify(data.data)
      console.log(data)
      await this.onAuthRegisterUser(data).then(() => {
        this.getList();
        this.closeModals()
      })
    },
    async deleteItem() {
      await this.onUserDelete(this.id).then(() => {
        this.getList();
        this.closeModals()
      })
    },
    askDeleteItem(id, name) {
      this.id = id;
      this.name = name;
      this.modalAskDelete = true;
    },
    editItem(id) {
      this.$router.push({name: 'phys_id', params: {id: id}})
    }
  }
}
</script>

<style scoped>

</style>
