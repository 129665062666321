<template>
  <DefaultPageMenu
      name="settings"
      :routes="Array('pages', 'order_status', 'control', 'interface', 'languages', 'roles', 'users')"
  />
</template>

<script>
import DefaultPageMenu from "@/components/default/page/DefaultPageMenu";
export default {
  name: "settings",
  components: {DefaultPageMenu}
}
</script>

<style scoped>

</style>
