<template>
  <DefaultPageModal
      :text="t['add_order']"
      :on-close="onClose"
      :key="key"
  >
    <v-form ref="form" lazy-validation>
      <DevInput
          select
          v-if="locationList.data"
          v-model="new_order.location_id"
          :items="locationList.data"
          :text="t['location']"
          :rules="[v => !!v || t['required_field']]"
      />
      <div class="grid_datetime-picker">
        <div>
          <v-input hide-details class="font12">
            {{ t['date_from'] }}
          </v-input>
          <v-datetime-picker
              @input="getDaysCount"
              v-model="new_order.start"
              :textFieldProps="{
                outlined: true,
                dense: true,
                rules: [v => !!v || t['required_field']],
                'validate-on-blur': true
              }"
              :datePickerProps="{
                'no-title': true,
                scrollable: true
              }"
              :timePickerProps="{
                'no-title': true,
                scrollable: true,
                'ampm-in-title': true
              }"
              :okText="t['save']"
              :clearText="t['clear']"
          >
            <template slot="dateIcon">
              <v-icon>mdi-calendar-outline</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>mdi-clock-outline</v-icon>
            </template>
          </v-datetime-picker>
        </div>
        <div>
          <v-input hide-details class="font12">
            {{ t['date_to'] }}
          </v-input>
          <v-datetime-picker
              @input="getDaysCount"
              v-model="new_order.end"
              :textFieldProps="{
                outlined: true,
                dense: true,
                rules: [v => !!v || t['required_field']],
                'validate-on-blur': true
              }"
              :datePickerProps="{
                'no-title': true,
                scrollable: true
              }"
              :timePickerProps="{
                'no-title': true,
                scrollable: true,
                'ampm-in-title': true
              }"
              :okText="t['save']"
              :clearText="t['clear']"
          >
            <template slot="dateIcon">
              <v-icon>mdi-calendar-outline</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>mdi-clock-outline</v-icon>
            </template>
          </v-datetime-picker>
        </div>
      </div>
      <DevInput
          select
          v-if="serviceList.data"
          v-model="new_order.service_id"
          :items="serviceList.data"
          :text="t['product']"
          :rules="[v => !!v || t['required_field']]"
          @change="getService"
      />
      <div class="grid_discount">
        <div>
          <v-input hide-details class="font12">
            {{ t['discount_code'] }}
          </v-input>
          <v-text-field
              @input="refreshDiscount"
              v-model="new_order.discount_code"
              outlined
              dense/>
        </div>
        <v-icon v-if="discount.error" class="mb-2 red--text">
          mdi-close-thick
        </v-icon>
        <v-icon v-else-if="discount.info.type" class="mb-2 primary--text">
          mdi-check-bold
        </v-icon>
        <v-icon v-else></v-icon>
        <v-btn @click="checkCode" class="second mb-2">
          {{ t['check_code'] }}
        </v-btn>
      </div>
      <DevInput
          select
          :text="t['client']"
          v-model="new_order.user_id"
          :items="userList.data"
      />
    </v-form>
    <div class="text-start mb-5">
      <v-btn @click="modalNewItem = true" class="second">
        <v-icon class="mr-2 ml-n2">mdi-plus</v-icon>
        {{ t['add_client'] }}
      </v-btn>
    </div>
    <div class="d-flex justify-start align-center font-weight-bold">
      {{ t['price'] }}: {{ price }}
    </div>
    <v-btn @click="setNewOrder" slot="btn" class="primary">
      {{ t['create'] }}
    </v-btn>
    <v-dialog v-model="modalNewItem" width="600">
      <PagesClientPhysNew v-if="modalNewItem" :on-save="createItem" :on-close="closeModals"/>
    </v-dialog>
  </DefaultPageModal>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import moment from 'moment';
import DefaultPageModal from "@/components/default/page/DefaultPageModal";
import DevInput from "@/components/dev/DevInput";
import PagesClientPhysNew from "components/pages/clients/PagesClientPhysNew";

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export default {
  name: "PagesOrdersNewOrder",
  components: {PagesClientPhysNew, DevInput, DefaultPageModal},
  props: {
    onClose: {},
    onSave: {}
  },
  data: () => ({
    new_order: {
      language_id: 1,
      service_id: 0,
      location_id: 0,
      start: null,
      end: null,
      discount_code: null
    },
    discount: {
      info: {},
      error: false
    },
    days: 0,
    price: 0,
    full_price: 0,
    users: {},
    modalNewItem: false,
    key: 0
  }),
  computed: {
    ...mapGetters("location", ["locationList"]),
    ...mapGetters("service", ["serviceList", "service"]),
    ...mapGetters("user", ["userList"]),
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['page_toolbar'],
          this.$store.state.t[this.$route.params.language]['fields'],
          this.$store.state.t[this.$route.params.language]['buttons'],
          this.$store.state.t[this.$route.params.language]['form']
      )
    },
  },
  watch: {
    modalNewItem() {
      this.modalNewItem === false ? this.getUsers() : ''
    }
  },
  mounted() {
    this.onLocationList('0');
    this.onServiceList({category: null, number: '0'});
    this.getUsers()
  },
  methods: {
    ...mapActions("location", ["onLocationList"]),
    ...mapActions("service", ["onServiceList", "onService"]),
    ...mapActions("user", ["onUserList"]),
    ...mapActions("order", ["onCheckCode"]),
    ...mapActions("auth", ["onAuthRegisterUser"]),

    async getUsers() {
      await this.onUserList({type: 'natural', number: '0', sort: 'desc', field: 'id'}).then(() => {
        for (let i in this.userList.data) {
          this.userList.data[i].name = this.userList.data[i].first_name + ' ' + this.userList.data[i].last_name
        }
        this.new_order.user_id = Number(this.userList.data[0].id)
        this.key++
      });
    },
    getDaysCount() {
      if (this.new_order.start && this.new_order.end) {
        this.days = Math.abs(Math.ceil((this.new_order.end - this.new_order.start) / _MS_PER_DAY));
        console.log('days: ')
        console.log(this.days)
        if (this.service.data) this.getPrice()
      }
    },
    getPrice() {
      console.log(this.t['is_no_price'])
      if (this.service.data.is_no_price) {
        this.full_price = this.t['is_no_price']
      } else {
        if (this.days < 3) this.full_price = this.days * this.service.data.price
        else if (this.days < 7) this.full_price = this.days * this.service.data.price2
        else this.full_price = this.days * this.service.data.price3
      }
      if (typeof this.full_price === 'number') {
        this.price = this.full_price + ' €'
      } else {
        this.price = this.full_price
      }

      console.log('price: ')
      console.log(this.price)
    },
    async getService(item) {
      console.log(item);
      await this.onService({lang: 1, id: item}).then(() => {
        console.log(this.service);
        this.getPrice()
      });
    },
    refreshDiscount() {
      this.discount = {
        info: {},
        error: false
      }
    },
    async checkCode() {
      await this.onCheckCode(this.new_order.discount_code)
          .then(res => {
            this.discount.info = res.data.data
            this.discount.error = false
            if (this.discount.info.type === 'value') {
              this.price = this.full_price - this.discount.info.value + ' €'
            } else {
              this.price = this.full_price - this.full_price * (this.discount.info.percent / 100) + ' €'
            }
          })
          .catch(() => {
            this.discount.error = true;
          })
    },
    setNewOrder() {
      if (this.$refs.form.validate()) {
        let data = this.new_order;
        let start = new Date(data.start);
        let end = new Date(data.end);
        start = moment(start).format('YYYY-MM-DD hh:mm:ss');
        end = moment(end).format('YYYY-MM-DD hh:mm:ss');
        delete data.start;
        delete data.end;
        data.start = start;
        data.end = end;
        if (!data.discount_code) delete data.discount_code;
        this.onSave(data)
      }
    },
    async createItem(data) {
      data.data = JSON.stringify(data.data)
      console.log(data)
      await this.onAuthRegisterUser(data).then(() => {
        this.getUsers()
        this.closeModals()
      })
    },
    closeModals() {
      this.modalNewItem = false;
      //this.key++
    }
  }
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-rows: auto;
  grid-gap: 12px;
  align-items: center;

  &_datetime-picker {
    @extend .grid;
    grid-template-columns: 1fr 1fr;
  }

  &_discount {
    @extend .grid;
    grid-template-columns: 1fr auto 1fr;
  }
}
</style>
