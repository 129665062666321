<template>
  <DefaultPageModal
      :on-close="onClose"
      :text="t['discount_code']"
  >
    <DevInput
        :text="t['discount_code']"
        v-model="data.code"
    />
    <div class="grid_datetime-picker">
      <div>
        <v-input hide-details class="font12">
          {{ t['date_from'] }}
        </v-input>
        <v-datetime-picker
            v-model="data.start"
            :textFieldProps="{
                outlined: true,
                dense: true,
                rules: [v => !!v || t['required_field']],
                'validate-on-blur': true
              }"
            :datePickerProps="{
                'no-title': true,
                scrollable: true
              }"
            :timePickerProps="{
                'no-title': true,
                scrollable: true,
                'ampm-in-title': true
              }"
            :okText="t['save']"
            :clearText="t['clear']"
        >
          <template slot="dateIcon">
            <v-icon>mdi-calendar-outline</v-icon>
          </template>
          <template slot="timeIcon">
            <v-icon>mdi-clock-outline</v-icon>
          </template>
        </v-datetime-picker>
      </div>
      <div>
        <v-input hide-details class="font12">
          {{ t['date_to'] }}
        </v-input>
        <v-datetime-picker
            v-model="data.end"
            :textFieldProps="{
                outlined: true,
                dense: true,
                rules: [v => !!v || t['required_field']],
                'validate-on-blur': true
              }"
            :datePickerProps="{
                'no-title': true,
                scrollable: true
              }"
            :timePickerProps="{
                'no-title': true,
                scrollable: true,
                'ampm-in-title': true
              }"
            :okText="t['save']"
            :clearText="t['clear']"
        >
          <template slot="dateIcon">
            <v-icon>mdi-calendar-outline</v-icon>
          </template>
          <template slot="timeIcon">
            <v-icon>mdi-clock-outline</v-icon>
          </template>
        </v-datetime-picker>
      </div>
    </div>
    <DevInput
        select
        :text="t['discount_type']"
        v-model="data.type"
        :items="type"
        item-text="name"
        item-value="type"
    />
    <v-input hide-details class="font12">
      {{t['discount_value']}}
    </v-input>
    <v-text-field-percent
        v-if="data.type === 'percent'"
        v-model="data.percent"
        v-bind:properties="{
        suffix: '%',
        readonly: false,
        disabled: false,
        outlined: false,
        outlined: true,
        placeholder: '',
        required: true,
        dense: true,
        'validate-on-blur': true,
        rules: [ v => !!v || t['required_field']]
      }"
        v-bind:options="{
        length: 2,
        precision: 0,
        empty: null,
      }"
    />
    <v-text-field
        v-else
        type="number"
        :rules="[v => !!v || t['required_field']]"
        v-model="data.value"
        outlined
        validate-on-blur
        dense/>
    <v-btn @click="saveItem" slot="btn" class="primary">
      {{ data.id ? t['save'] : t['create'] }}
    </v-btn>
  </DefaultPageModal>
</template>

<script>
import DefaultPageModal from "@/components/default/page/DefaultPageModal";
import DevInput from "@/components/dev/DevInput";

import moment from 'moment';

export default {
  name: "PagesOtherDiscountId",
  components: {DevInput, DefaultPageModal},
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    onClose: {},
    onSave: {},
    newItem: Boolean
  },
  data: () => ({
    item: []
  }),
  computed: {
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['menu'],
          this.$store.state.t[this.$route.params.language]['form'],
          this.$store.state.t[this.$route.params.language]['fields'],
          this.$store.state.t[this.$route.params.language]['buttons']
      )
    },
    type() {
      return [
        {
          name: this.$store.state.t[this.$route.params.language]['fields']['fixed_value'],
          type: "value"
        },
        {
          name: this.$store.state.t[this.$route.params.language]['fields']['percentage'],
          type: "percent"
        }
      ]
    },
  },
  created() {
    this.item = Object.assign(this.data)
  },
  methods: {
    saveItem() {
      this.onSave(this.item)
      this.onClose()
    }
  }
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-rows: auto;
  grid-gap: 12px;
  align-items: center;

  &_datetime-picker {
    @extend .grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
