<template>
  <div>
    <div class="d-flex justify-end align-center">
      <v-radio-group
          v-model="l"
          row
          hide-details
      >
        <v-radio
            v-for="lang in languages"
            :key="lang.id"
            :value="lang.id"
        >
          <template slot="label">
            <span class="text-uppercase">{{lang.name}}</span>
            <v-img :src="apiDomain + '/' + lang.flag" width="20" height="20" class="rounded-circle border ml-2"/>
          </template>
        </v-radio>
      </v-radio-group>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DefaultPageLanguage",
  data: () => ({
    l: 0
  }),
  computed: {
    ...mapGetters(["apiDomain", "language", "languages"]),
  },
  watch: {
    l() {
      console.log(this.l)
      this.$store.commit("SetLanguage", this.l)
    }
  },
  mounted() {
    this.l = this.language
  }
}
</script>

<style scoped lang="scss">
::v-deep .v-input--selection-controls {
  margin: 0 0 8px !important;
}
</style>
