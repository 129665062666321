<template>
  <v-card dark tile>
    <v-toolbar absolute width="100%">
      <v-spacer/>
      <v-btn @click="zoom = !zoom" fab text>
        <v-icon v-if="zoom">
          mdi-magnify-minus-outline
        </v-icon>
        <v-icon v-else>
          mdi-magnify-plus-outline
        </v-icon>
      </v-btn>
      <v-btn fab text @click="onClose">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-img
        :src="apiDomain + '/' + items[item]"
        :height="zoom ? '' : '100vh'"
        contain
    />
    <v-btn @click="item = item - 1" :disabled="item === 0" absolute fab text fixed style="top: 45%">
      <v-icon>
        mdi-chevron-left
      </v-icon>
    </v-btn>
    <v-btn @click="item = item + 1" :disabled="item === items.length - 1" absolute fixed fab text
           style="top: 45%; right: 0">
      <v-icon>
        mdi-chevron-right
      </v-icon>
    </v-btn>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DevImageView",
  computed: {
    ...mapGetters(["apiDomain"])
  },
  props: {
    onClose: {},
    items: {
      type: Array,
      default: () => {
        return []
      }
    },
    id: {
      type: [Number, String],
      default: -1
    }
  },
  data: () => ({
    zoom: false,
    item: -1,
  }),
  created() {
    this.item = this.id
  }
}
</script>

<style scoped lang="scss">
.v-toolbar {
  position: fixed;
  background-color: rgba(black, .2) !important;
}
</style>
