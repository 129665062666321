import axios from "axios";

const state = {
    userList: [],
    user: [],
};

const getters = {
    userList(state) {
        return state.userList;
    },
    user(state) {
        return state.user;
    },
};

const mutations = {
    setUserList: (state, item) => {
        state.userList = item;
    },
    setUser: (state, item) => {
        state.user = item;
    },
};

const actions = {
    // eslint-disable-next-line no-unused-vars
    async onUserList({ commit, dispatch }, { type, company, number, page, sort, field }) {
        console.log('number', number)
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/admin/common/user/grid?limit=" +
                (number ? number : this.state.number) +
                "&page=" +
                this.state.page
            if (type) {
                url = url + "&filter[]=type:" + type;
            }
            if (company) {
                url = url + "&filter[]=users:" + company;
            }
            if (sort && field) {
                url = url + '&order[]=' + field+ ':' + sort
            }
            console.log('url', url)
            axios
                .get(url)
                .then((response) => {
                    commit("setUserList", response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                    dispatch(
                        "notification/onNotification",
                        { IsError: true },
                        { root: true }
                    );
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    async onUserDelete({ commit }, id) {
        let url = this.state.apiDomain + "/api/admin/common/user/" + id;
        await axios
            .delete(url)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    },
    // eslint-disable-next-line no-unused-vars
    async onUser({ commit, dispatch }, id) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url = this.state.apiDomain + "/api/admin/common/user/" + id;
            axios
                .get(url)
                .then((response) => {
                    commit("setUser", response.data);
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                })
                .catch((error) => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    async onUserSave({ commit, dispatch }, data) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url = this.state.apiDomain + "/api/admin/common/user";
            axios
                .put(url, data)
                .then((response) => {
                    commit("SetLoadingAnimation", false, {root: true});
                    resolve(response);
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch((error) => {
                    commit("SetLoadingAnimation", false, {root: true});
                    reject(error);
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    async onUserBlock({ commit }, id) {
        return new Promise((resolve, reject) => {
            let url = this.state.apiDomain + "/api/admin/common/user/block/" + id;
            axios
                .put(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    async onUserUnblock({ commit }, id) {
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain + "/api/admin/common/user/unblock/" + id;
            axios
                .put(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
