import axios from "axios";

const state = {
    clientList: [],
    client: []
};

const getters = {
    clientList(state) {
        return state.clientList;
    }
};

const mutations = {
    setClientList: (state, item) => {
        state.clientList = item;
    }
};

const actions = {
    onClientList({ commit }) {
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/contact?number=" + this.state.number + "&page=" + this.state.page + "&method=asc&field=name";
            axios
                .get(url)
                .then(response => {
                    commit("setClientList", response.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    // eslint-disable-next-line no-unused-vars
    onClientSet({commit}, data) {
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/contact";
            axios
                .post(url, data)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
