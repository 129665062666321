<template>
  <DefaultPageMenu name="blog" :routes="Array('authors', 'categories', 'articles')"/>
</template>

<script>
import DefaultPageMenu from "@/components/default/page/DefaultPageMenu";

export default {
  name: "blog",
  components: {DefaultPageMenu}
}
</script>

<style scoped>

</style>
