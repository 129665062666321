<template>
  <div :key="key">
    <v-btn @click="showModalAdd('email')" class="primary my-5 ml-6">
      {{ $store.state.t[this.$route.params.language]['page_toolbar']['add_email'] }}
    </v-btn>
    <DefaultPageTable
      :data="user.data.contacts.emails"
      :headers="headers_email"
      :on-edit="showModalEmailEdit"
      :on-click="test"
      :on-delete="showModalDeleteEmail"
      :ask-delete-fields="['value']"
      edit-field="value"
    />
    <v-btn @click="showModalAdd('phone')" class="primary my-5 ml-6">
      {{ $store.state.t[this.$route.params.language]['page_toolbar']['add_phone'] }}
    </v-btn>
    <DefaultPageTable
      :data="user.data.contacts.phones"
      :headers="headers_phone"
      :on-edit="showModalPhoneEdit"
      :on-click="test"
      :on-delete="showModalDeletePhone"
      :ask-delete-fields="['value']"
      edit-field="value"
    />
    <v-dialog v-model="modal" width="600">
      <DefaultPageModal
        :text="modalType === 'edit' ? t[field] : $store.state.t[this.$route.params.language]['page_toolbar']['add_' + field]"
        :on-close="closeModal"
        @click:outside="closeModal"
        :key="'dialog_' + key"
      >
        <v-form lazy-validation ref="form">
          <DevInput :text="t[field]" v-model="value" :rules="[v => !!v || t['required_field']]" />
          <DevInput :text="t['description']" v-model="description" />
          <v-btn @click="saveContact" class="primary">
            {{ modalType === 'edit' ? t['save'] : t['add'] }}
          </v-btn>
        </v-form>
      </DefaultPageModal>
    </v-dialog>
    <v-dialog v-model="modalDelete" width="600">
      <DefaultPageAskDelete :text="field" :on-close="closeModalDelete" :on-delete="deleteContact" :name="name"/>
    </v-dialog>
  </div>
</template>

<script>
import DefaultPageTable from 'components/default/page/DefaultPageTable'
import { mapGetters, mapActions } from 'vuex'
import DefaultPageModal from 'components/default/page/DefaultPageModal'
import DefaultPageAskDelete from 'components/default/page/DefaultPageAskDelete'
import DevInput from 'components/dev/DevInput'

export default {
  name: 'jur_contacts',
  components: { DevInput, DefaultPageModal, DefaultPageTable, DefaultPageAskDelete },
  data: () => ({
    modal: false,
    modalType: null,
    modalDelete: false,
    value: '',
    description: '',
    field: '',
    key: 0,
    selectIndex: null,
    name: ''
  }),
  computed: {
    ...mapGetters('user', ['user']),
    headers_email() {
      return [
        {
          value: ['value'],
          text: this.$store.state.t[this.$route.params.language]['form']['email'],
        },
        {
          value: ['description'],
          text: this.$store.state.t[this.$route.params.language]['fields']['description'],
        },
      ]
    },
    headers_phone() {
      return [
        {
          value: ['value'],
          text: this.$store.state.t[this.$route.params.language]['form']['phone'],
        },
        {
          value: ['description'],
          text: this.$store.state.t[this.$route.params.language]['fields']['description'],
        },
      ]
    },
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['form'],
        this.$store.state.t[this.$route.params.language]['buttons'],
      )
    },
  },
  methods: {
    ...mapActions('user', ['onUser', 'onUserSave']),
    test(){

    },
    preparingDataForSending(data) {
      let saveData = {}
      saveData.id = data.id
      saveData.contacts = data.contacts
      saveData.first_name = data.first_name
      saveData.last_name = data.last_name
      saveData.role_id = data.role_id
      saveData.email = data.email
      if (typeof data.data !== 'string') {
        saveData.data = JSON.stringify(data.data)
      }
      return saveData
    },
    async onSaveChange() {
      await this.onUserSave(this.preparingDataForSending(this.user))
      // .then(() =>
      //   this.$parent.$parent.$parent.getItem(),
      // )
    },
    showModalAdd(field) {
      this.field = field
      this.modal = true
      this.modalType = 'add'
    },
    showModalEmailEdit(value, index) {
      this.field = 'email'
      this.selectIndex = index
      this.modal = true
      this.modalType = 'edit'
      this.value = this.user.data.contacts.emails[index].value
      this.description = this.user.data.contacts.emails[index].description
    },
    showModalPhoneEdit(value, index) {
      this.field = 'phone'
      this.selectIndex = index
      this.modal = true
      this.modalType = 'edit'
      this.value = this.user.data.contacts.phones[index].value
      this.description = this.user.data.contacts.phones[index].description
    },
    showModalDeletePhone(id, value, index) {
      this.field = 'phone'
      this.selectIndex = index
      this.modalDelete = true
    },
    showModalDeleteEmail(id, value, index) {
      this.name = value
      this.field = 'email'
      this.selectIndex = index
      this.modalDelete = true
    },
    async saveContact() {
      if (this.$refs.form.validate()) {
        let data = {
          value: this.value,
          description: this.description,
        }
        if (this.modalType === 'add') {
          if(this.user.data.contacts[this.field + 's'].length) {
            const maxId = await this.user.data.contacts[this.field + 's'].reduce((acc, curr) => acc.id > curr.id ? acc : curr).id
            data.id = maxId + 1
          } else {
            data.id = 1
          }
          this.user.data.contacts[this.field + 's'].push(data)
        } else {
          data.id = this.user.data.contacts[this.field + 's'][this.selectIndex].id
          this.user.data.contacts[this.field + 's'][this.selectIndex] = data
        }
        this.onSaveChange()
        this.key++
        this.closeModal()
      }
    },
    async deleteContact() {
      this.user.data.contacts[this.field + 's'].splice(this.selectIndex, 1)
      await this.onSaveChange()
      this.closeModalDelete()
    },
    closeModal() {
      this.modal = false
      this.value = ''
      this.description = ''
      this.key++
      this.modalType = null
    },
    closeModalDelete() {
      this.modalDelete = false
      this.field = null
      this.key++
    },
  },
}
</script>

<style scoped></style>
