<template>
  <div v-if="user.data" :key="key">
    <v-btn @click="showModalAdd" class="primary my-5 ml-6">
      {{ $store.state.t[this.$route.params.language]['page_toolbar']['add_client_in_company'] }}
    </v-btn>
    <DefaultPageTable
      :headers="headers"
      :data="user.data.persons"
      :on-edit="showModalEdit"
      :on-click="test"
      :on-delete="showModalDelete"
      :ask-delete-fields="['first_name', 'last_name']"
    />
    <!-- <v-dialog v-model="modal" width="600">
      <PagesClientPhysNew :on-save="createItem" :company="Number($route.params.id)" :on-close="closeModals"/>
    </v-dialog> -->
    <v-dialog v-model="modal" width="600">
      <DefaultPageModal
        :text="data.id ? t['contact_person'] : t['add_contact_person']"
        :on-close="closeModals"
        @click:outside="closeModals"
        :key="'dialog_' + key"
      >
        <v-form lazy-validation ref="form">
          <DevInput
            :text="t['first_name']"
            v-model="data.first_name"
            :rules="[v => !!v || t['required_field']]"
          />
          <DevInput
            :text="t['last_name']"
            v-model="data.last_name"
            :rules="[v => !!v || t['required_field']]"
          />
          <DevInput
            :text="t['position']"
            v-model="data.position"
            :rules="[v => !!v || t['required_field']]"
          />
          <DevInput
              :text="t['email']"
              v-model="data.email"
              :rules="[v => !!v || t['required_field']]"
          />
          <DevInput
              :text="t['phone']"
              v-model="data.phone"
              :rules="[v => !!v || t['required_field']]"
          />
          <v-btn @click="savePerson" class="primary">
            {{ data.id ? t['save'] : t['add'] }}
          </v-btn>
        </v-form>
      </DefaultPageModal>
    </v-dialog>
    <v-dialog v-model="modalDelete" width="600">
      <DefaultPageAskDelete
        text="contact"
        :name="name"
        :on-close="closeModals"
        :on-delete="deletePerson"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DefaultPageToolbar from 'components/default/page/DefaultPageToolbar'
import DefaultPageTable from 'components/default/page/DefaultPageTable'
import DefaultPageModal from 'components/default/page/DefaultPageModal'
import DefaultPageAskDelete from 'components/default/page/DefaultPageAskDelete'
import DevInput from 'components/dev/DevInput'
import PagesClientPhysNew from 'components/pages/clients/PagesClientPhysNew'

export default {
  name: 'jur_clients',
  components: {
    PagesClientPhysNew,
    DefaultPageAskDelete,
    DefaultPageTable,
    DefaultPageToolbar,
    DefaultPageModal,
    DevInput,
  },
  data: () => ({
    list: {},
    id: 0,
    name: '',
    modal: false,
    modalType: null,
    modalDelete: false,
    selectIndex: null,
    key: 0,
    data: {},
  }),
  computed: {
    ...mapGetters('user', ['user']),
    headers() {
      return [
        {
          value: ['first_name'],
          text: this.$store.state.t[this.$route.params.language]['form']['first_name'],
        },
        {
          value: ['last_name'],
          text: this.$store.state.t[this.$route.params.language]['form']['last_name'],
        },
        {
          value: ['email'],
          text: this.$store.state.t[this.$route.params.language]['form']['email'],
        },
        {
          value: ['phone'],
          text: this.$store.state.t[this.$route.params.language]['form']['phone'],
        },
        {
          value: ['position'],
          text: this.$store.state.t[this.$route.params.language]['fields']['position'],
        },
      ]
    },
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['form'],
        this.$store.state.t[this.$route.params.language]['buttons'],
      )
    },
  },
  methods: {
    ...mapActions('user', ['onUser', 'onUserSave']),
    test() {

    },
    preparingDataForSending(data) {
      let saveData = {}
      saveData.id = data.id
      saveData.contacts = data.contacts
      saveData.first_name = data.first_name
      saveData.last_name = data.last_name
      saveData.role_id = data.role_id
      saveData.email = data.email
      if (typeof data.data !== 'string') {
        saveData.data = JSON.stringify(data.data)
      }
      return saveData
    },
    async onSaveChange() {
      await this.onUserSave(this.preparingDataForSending(this.user))
      // .then(() =>
      //   this.$parent.$parent.$parent.getItem(),
      // )
    },
    showModalAdd() {
      this.modal = true
      this.modalType = 'add'
    },
    showModalEdit(value, index) {
      this.selectIndex = index
      this.modal = true
      this.modalType = 'edit'
      this.data = Object.assign({}, this.user.data.persons[this.selectIndex])
    },
    showModalDelete(id, value, index) {
      this.name = value
      this.selectIndex = index
      this.modalDelete = true
    },
    async savePerson() {
      if (this.$refs.form.validate()) {
        let data = Object.assign({}, this.data)
        if (this.modalType === 'add') {
          if(this.user.data.persons.length) {
            const maxId = await this.user.data.persons.reduce((acc, curr) => acc.id > curr.id ? acc : curr).id
            data.id = maxId + 1
          } else {
            data.id = 1
          }
          this.user.data.persons.push(data)
        } else {
          data.id = this.user.data.persons[this.selectIndex].id
          this.user.data.persons[this.selectIndex] = data
        }
        this.onSaveChange()
        this.key++
        this.closeModals()
      }
    },
    async deletePerson() {
      this.user.data.persons.splice(this.selectIndex, 1)
      await this.onSaveChange()
      this.closeModals()
    },
    closeModals() {
      this.modal = false
      this.modalDelete = false
      this.modalType = null
      this.data = {}
      this.key++
    },
  },
}
</script>

<style scoped></style>
