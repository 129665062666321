<template>
  <DefaultPageToolbar
      :create-text="$store.state.t[this.$route.params.language]['page_toolbar']['add_unit']"
      :on-create="openNewItem"
      :total="list.amount"
      :last_page="list.lastPage"
  >
    <DefaultPageTable
        :title="$store.state.t[this.$route.params.language]['menu']['unit']"
        :data="list.data"
        :headers="headers"
        :on-edit="editItem"
    />
  </DefaultPageToolbar>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DefaultPageToolbar from "@/components/default/page/DefaultPageToolbar";
import DefaultPageTable from "@/components/default/page/DefaultPageTable";

export default {
  name: "units",
  components: {DefaultPageTable, DefaultPageToolbar},
  data: () => ({
    list: {
      meta: {}
    },
    product: {},
    item: {},
    id: 0,
    name: '',
    modalAskDelete: false,
    modalNewItem: false,
    modalEdit: false
  }),
  computed: {
    headers() {
      return [
        {
          value: ["image", "name"],
          text: this.$store.state.t[this.$route.params.language]['fields']['unit_name'],
          text_position: "name"
        },
        {
          value: ["num"],
          text: this.$store.state.t[this.$route.params.language]['fields']['serial_number']
        },
        {
          value: ["service"],
          text: this.$store.state.t[this.$route.params.language]['fields']['product_name']
        }
      ]
    },
    ...mapGetters(["apiDomain", "language"]),
    ...mapGetters("service", ["service"])
  },
  watch: {
    '$route.query'() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },
  beforeDestroy() {
    this.$store.commit("SetBreadcrumbs", '')
  },
  methods: {
    ...mapActions("service", ["onServiceUnit", "onService"]),

    async getList() {
      this.list = {}
      await this.onServiceUnit({
        service: this.$route.query.product,
        lang: this.language
      }).then(res => {
        this.list = res.data;
        if (this.$route.query.product) {
          this.getService(this.$route.query.product)
        }
        else {
          this.setBreadcrumbs()
        }
        for (let i in this.list.data) {
          this.transformList(this.list.data[i]);
        }
      })
    },
    setBreadcrumbs() {
      let temp = [
        {
          "path": "home",
          "name": "VuokraHyva"
        },
        {
          "path": "services",
          "name": this.$store.state.t[this.$route.params.language]['menu']['services'],
        }
      ]
      if (this.$route.query.product) {
        temp.push(
            {
              "path": "products",
              "query": {"service": this.service.service_category.id},
              "name": this.service.service_category.title,
            }
        )
      }
      temp.push(
          {
            "path": "products",
            "name": this.$store.state.t[this.$route.params.language]['menu']['products'],
          }
      )
      if (this.$route.query.product) {
        temp.push(
            {
              "path": "units",
              "query": {"product": this.$route.query.product},
              "name": this.service.data.title,
            }
        )
        temp.push(
            {
              "path": "units",
              "name": this.$store.state.t[this.$route.params.language]['menu']['units'],
              "active": true
            }
        )
      } else {
        temp.push(
            {
              "path": "units",
              "name": this.$store.state.t[this.$route.params.language]['menu']['units'],
            }
        )
      }
      console.log(temp)
      this.$store.commit("SetBreadcrumbs", temp)
    },
    async getService(id) {
      await this.onService({id: id, lang: 1}).then(res => {
        this.product = res.data
        this.setBreadcrumbs()
      })
    },
    transformList(data) {
      data.image = [{
        src: this.apiDomain + '/' + data.image,
        type: 'image'
      }]
      data.num = String(data.id).padStart(4, '0')
      data.service = data.service.name
    },
    closeModals() {
      this.getList();
      this.modalAskDelete = false;
      this.modalNewItem = false;
      this.modalEdit = false
    },
    openNewItem() {
      this.$router.push({name: 'unit_id', params: {id: 0}})
    },
    async editItem(id) {
      this.$router.push({name: 'unit_id', params: {id: id}})
    }
  }
}
</script>

<style scoped>

</style>
