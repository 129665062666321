<template>
  <div>
    <v-row no-gutters justify="center" justify-md="start">
      <v-btn
          v-for="l in languages"
          :key="l.id"
          color="white"
          class="shadow"
          @click.stop="changeLanguage(l.name)"
      >
        <v-img
            :src="apiDomain + '/' + l.flag"
            max-height="30"
            max-width="30"
            width="30"
            height="30"
            class="rounded-circle mr-2"
        />
        <span class="text-uppercase">
          {{l.name}}
        </span>
        <v-spacer/>
        <v-radio-group v-model="lang">
          <v-radio :value="l.id" />
        </v-radio-group>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
  name: "languages",
  computed: {
    ...mapGetters(["languages", "apiDomain"]),
    lang() {
      return this.languages.filter(i => i.name === this.$route.params.language)[0].id
    }
  },
  methods: {
    ...mapActions(['onLanguage']),
    changeLanguage(name){
      this.onLanguage(name)
      this.$router.push({name: 'languages' ,params: { language:  name}}).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
.v-btn {
  min-width: 300px !important;
  height: 100px !important;
  margin: 12px !important;
  font-size: 18px !important;

  &:hover {
    background-color: $primary !important;
    color: white !important;

    * {
      color: white !important;
    }
  }
}
</style>
