import axios from "axios";

const state = {
  locationList: []
};

const getters = {
  locationList(state) {
    return state.locationList;
  }
};

const mutations = {
  setLocationList: (state, item) => {
    state.locationList = item;
  }
};

const actions = {
  onLocationList({ commit, dispatch }, number) {
      //commit("SetLoadingAnimation", true, {root: true});
    return new Promise((resolve, reject) => {
      let url =
        this.state.apiDomain +
        "/api/admin/location/grid?limit=" + (number ? number : this.state.number) + "&page=" + this.state.page + "&order[]=name:asc";

      axios
        .get(url)
        .then(response => {
          commit("setLocationList", response.data);
          resolve(response);
            commit("SetLoadingAnimation", false, {root: true});
        })
        .catch(error => {
          reject(error);
            commit("SetLoadingAnimation", false, {root: true});
            dispatch("notification/onNotification", {IsError: true}, {root: true});
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  onCreateLocation( {commit, dispatch}, data) {
      commit("SetLoadingAnimation", true, {root: true});
    return new Promise((resolve, reject) => {
      let url =
          this.state.apiDomain +
          "/api/admin/location";
      axios
          .post(url, data)
          .then(response => {
            resolve(response);
              commit("SetLoadingAnimation", false, {root: true});
              dispatch("notification/onNotification", {IsError: false}, {root: true});
          })
          .catch(error => {
            reject(error);
              commit("SetLoadingAnimation", false, {root: true});
              dispatch("notification/onNotification", {IsError: true}, {root: true});
          });
    });
  },
  // eslint-disable-next-line no-unused-vars
  onDeleteLocation({commit, dispatch}, id) {
      commit("SetLoadingAnimation", true, {root: true});
    return new Promise((resolve, reject) => {
      let url =
          this.state.apiDomain +
          "/api/admin/location/" + id;
      axios
          .delete(url)
          .then(response => {
            resolve(response);
              commit("SetLoadingAnimation", false, {root: true});
              dispatch("notification/onNotification", {IsError: false}, {root: true});
          })
          .catch(error => {
            reject(error);
              commit("SetLoadingAnimation", false, {root: true});
              dispatch("notification/onNotification", {IsError: true}, {root: true});
          });
    });
  },
  // eslint-disable-next-line no-unused-vars
  onLocation({commit, dispatch}, id) {
      commit("SetLoadingAnimation", true, {root: true});
    return new Promise((resolve, reject) => {
      let url =
          this.state.apiDomain +
          "/api/admin/location/grid?limit=1&page=1&filter[]=id:" + id;
      axios
          .get(url)
          .then(response => {
            resolve(response);
              commit("SetLoadingAnimation", false, {root: true});
          })
          .catch(error => {
            reject(error);
              commit("SetLoadingAnimation", false, {root: true});
              dispatch("notification/onNotification", {IsError: true}, {root: true});
          });
    });
  },
    // eslint-disable-next-line no-unused-vars
    onSaveLocation({commit, dispatch}, data) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/admin/location";
            axios
                .put(url, data)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    }
};
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
