<template>
  <DefaultPageToolbar
      :total="list.meta.total"
      :last_page="list.meta.last_page"
      :create-text="$store.state.t[this.$route.params.language]['page_toolbar']['add_category']"
      :on-create="openNewItem"
  >
    <DefaultPageTable
        :title="$store.state.t[this.$route.params.language]['menu']['categories']"
        :data="list.data"
        :headers="headers"
        :on-active="changeActive"
        :on-edit="editItem"
        :on-delete="askDeleteItem"
        :ask-delete-fields="['title']"
    />
    <v-dialog v-model="modalNewItem" width="600">
      <PagesBlogArticleCategory v-if="modalNewItem" :on-save="createItem" :on-close="closeModals"/>
    </v-dialog>
    <v-dialog v-model="modalEditItem" width="600">
      <PagesBlogArticleCategory v-if="modalEditItem" :on-item="getItem" :on-save="saveItem" :on-close="closeModals"/>
    </v-dialog>
    <v-dialog v-model="modalAskDelete" width="600">
      <DefaultPageAskDelete
          text="category"
          :name="name"
          :on-close="closeModals"
          :on-delete="deleteItem"
      />
    </v-dialog>
  </DefaultPageToolbar>
</template>

<script>
import DefaultPageToolbar from "components/default/page/DefaultPageToolbar";
import {mapActions} from "vuex";
import DefaultPageTable from "components/default/page/DefaultPageTable";
import PagesBlogArticleCategory from "components/pages/blog/PagesBlogArticleCategory";
import DefaultPageAskDelete from "components/default/page/DefaultPageAskDelete";

export default {
  name: "categories",
  components: {DefaultPageAskDelete, PagesBlogArticleCategory, DefaultPageTable, DefaultPageToolbar},
  data: () => ({
    list: {
      meta: {
        total: 0
      }
    },
    id: 0,
    name: '',
    modalNewItem: false,
    modalEditItem: false,
    modalAskDelete: false
  }),
  computed: {
    headers() {
      return [
        {
          value: ["title"],
          text: this.$store.state.t[this.$route.params.language]['fields']['title']
        },
        {
          value: ["articles_number"],
          text: this.$store.state.t[this.$route.params.language]['fields']['articles_count']
        }
      ]
    }
  },
  watch: {
    '$route.query'() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    ...mapActions("articleCategory", ["onArticleCategoryList", "onArticleCategoryCreate", "onArticleCategory", "onArticleCategorySave", "onArticleCategorySaveField", "onArticleCategoryDelete"]),
    async getList() {
      this.list = {meta: {}}
      await this.onArticleCategoryList().then(res => {
        this.list = res.data;
      })
    },
    closeModals() {
      this.modalNewItem = false;
      this.modalEditItem = false;
      this.modalAskDelete = false;
    },
    openNewItem() {
      this.modalNewItem = true;
    },
    askDeleteItem(id, name) {
      this.id = id;
      this.name = name;
      this.modalAskDelete = true;
    },
    changeActive(id, state) {
      this.onArticleCategorySaveField({
        id: id, data: {
          field: 'is_active',
          value: state
        }
      })
    },
    async deleteItem() {
      await this.onArticleCategoryDelete(this.id).then(() => {
        this.getList();
        this.closeModals()
      })
    },
    editItem(id) {
      this.id = id;
      this.modalEditItem = true
    },
    async saveItem(data) {
      await this.onArticleCategorySave({id: data.id, data: data}).then(() => {
        this.getList();
      })
    },
    async getItem() {
      console.log('id: ' + this.id)
      await this.onArticleCategory(this.id).then(res => {
        console.log(res.data)
      })
    },
    async createItem(data) {
      await this.onArticleCategoryCreate(data).then(() => {
        this.getList();
        this.closeModals()
      })
    }
  }
}
</script>

<style scoped>

</style>
