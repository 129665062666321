var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"login",attrs:{"width":"100vw","height":"100vh","color":"secondary"}},[_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"text":"","ripple":false,"plain":""},on:{"click":function($event){return _vm.refresh()}}},[_c('LogoXL')],1),(_vm.restore)?_c('div',{staticClass:"title white--text"},[_vm._v(" "+_vm._s(_vm.t.create_new_pass)+" ")]):_c('div',{staticClass:"title white--text"},[_vm._v(" "+_vm._s(_vm.t.create_pass)+" ")]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-card',{staticClass:"pa-4",attrs:{"width":"97vw","max-width":"432","light":""}},[_c('v-input',{staticClass:"inp",attrs:{"hide-details":""}},[_vm._v(" "+_vm._s(_vm.t.confirm_email)+" ")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","validate-on-blur":"","rules":[
                  function (v) { return !!v || _vm.t.required_field; },
                  function (v) { return /.+@.+\..+/.test(v) ||
                    _vm.t.bad_email; },
                  _vm.is_registered || _vm.t.email_not_exist,
                  !_vm.error_check || _vm.t.confirmation_error
                ]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.register()}},model:{value:(_vm.userInfo.email),callback:function ($$v) {_vm.$set(_vm.userInfo, "email", $$v)},expression:"userInfo.email"}}),_c('v-input',{staticClass:"inp",attrs:{"hide-details":""}},[_vm._v(" "+_vm._s(_vm.t.pick_pass)+" ")]),_c('v-text-field',{attrs:{"dense":"","validate-on-blur":"","outlined":"","type":"password","rules":[function (v) { return !!v || _vm.t.required_field; },
            _vm.userInfo.password.length > 5 || _vm.t.number_of_characters_must_be_at_least + ' 6']},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.register()}},model:{value:(_vm.userInfo.password),callback:function ($$v) {_vm.$set(_vm.userInfo, "password", $$v)},expression:"userInfo.password"}}),_c('v-input',{staticClass:"inp",attrs:{"hide-details":""}},[_vm._v(" "+_vm._s(_vm.t.repeat_pass)+" ")]),_c('v-text-field',{attrs:{"dense":"","validate-on-blur":"","outlined":"","type":"password","rules":[function (v) { return !!v || _vm.t.required_field; },
            _vm.userInfo.password === _vm.userInfo.password_confirmation || _vm.t.mismatch_pass]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.register()}},model:{value:(_vm.userInfo.password_confirmation),callback:function ($$v) {_vm.$set(_vm.userInfo, "password_confirmation", $$v)},expression:"userInfo.password_confirmation"}})],1),_c('v-btn',{staticClass:"font-weight-medium mt-4",attrs:{"color":"primary","rounded":"","depressed":""},on:{"click":function($event){return _vm.register()}}},[_vm._v(" "+_vm._s(_vm.t.register)+" ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }