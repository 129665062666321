<template>
  <DefaultPageToolbar
      :create-text="$store.state.t[this.$route.params.language]['page_toolbar']['add_product']"
      :on-create="openNewItem"
      :total="Number(list.meta.total)"
      :last_page="list.meta.last_page"
  >
    <DefaultPageTable
        :title="$store.state.t[this.$route.params.language]['menu']['product']"
        :data="list.data"
        :headers="headers"
        :on-edit="editItem"
        :on-delete="askDeleteItem"
        :on-active="activeItem"
        :on-click="clickItem"
        :ask-delete-fields="['name']"
    />
    <v-dialog v-model="modalAskDelete" width="600" style="z-index: 1000">
      <DefaultPageAskDelete
          text="product"
          :name="name"
          :on-close="closeModals"
          :on-delete="deleteItem"
      />
    </v-dialog>
    <v-dialog v-model="is_units" width="600">
      <DefaultPageModal
          :on-close="closeModals"
          :text="t['is_units']"
      >
        <div class="d-flex justify-space-around">
          <v-btn @click="clickItem(id)" class="primary">
            {{ t['show'] }}
          </v-btn>
          <v-btn @click="closeModals" class="second">
            {{ t['close'] }}
          </v-btn>
        </div>
      </DefaultPageModal>
    </v-dialog>
  </DefaultPageToolbar>
</template>

<script>
import DefaultPageToolbar from "@/components/default/page/DefaultPageToolbar";
import {mapActions, mapGetters} from "vuex";
import DefaultPageTable from "@/components/default/page/DefaultPageTable";
import DefaultPageAskDelete from "@/components/default/page/DefaultPageAskDelete";
import DefaultPageModal from "components/default/page/DefaultPageModal";

export default {
  name: "products",
  components: {DefaultPageModal, DefaultPageAskDelete, DefaultPageTable, DefaultPageToolbar},
  data: () => ({
    list: {
      meta: {}
    },
    item: {},
    id: 0,
    name: '',
    modalAskDelete: false,
    modalNewItem: false,
    modalEdit: false,
    units: null,
    is_units: false
  }),
  computed: {
    headers() {
      return [
        {
          value: ["image", "name"],
          text: this.$store.state.t[this.$route.params.language]['fields']['product_name'],
          text_position: "name"
        },
        {
          value: ["article_category"],
          text: this.$store.state.t[this.$route.params.language]['fields']['service_name'],
          text_position: "name"
        }
      ]
    },
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['fields'],
          this.$store.state.t[this.$route.params.language]['buttons'],
      )
    },
    ...mapGetters(["apiDomain"]),
  },
  watch: {
    '$route.query'() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },
  beforeDestroy() {
    this.$store.commit("SetBreadcrumbs", '')
  },
  methods: {
    ...mapActions("service", ["onServiceList", "onServiceDelete", "onServiceFieldPut", "onServiceUnit"]),

    async getList() {
      this.list = {meta: {}}
      await this.onServiceList({category: this.$route.query.service}).then(res => {
        this.list = res.data;
        this.setBreadcrumbs()
        for (let i in this.list.data) {
          this.transformList(this.list.data[i]);
        }
      })
    },
    setBreadcrumbs() {
      let temp = [
        {
          "path": "home",
          "name": "VuokraHyva"
        },
        {
          "path": "services",
          "name": this.$store.state.t[this.$route.params.language]['menu']['services'],
        }
      ]
      if (this.$route.query.service) {
        temp.push(
            {
              "path": "products",
              "query": {"service": this.$route.query.service},
              "name": this.list.data[0].article_category.title,
            }
        )
        temp.push(
            {
              "path": "products",
              "name": this.$store.state.t[this.$route.params.language]['menu']['products'],
              "active": true
            }
        )
      } else {
        temp.push(
            {
              "path": "products",
              "name": this.$store.state.t[this.$route.params.language]['menu']['products'],
            }
        )
      }
      this.$store.commit("SetBreadcrumbs", temp)
    },
    transformList(data) {
      data.image = [{
        src: this.apiDomain + '/' + JSON.parse(data.image)[0],
        type: 'image'
      }]
      data.article_category = data.article_category.title
    },
    closeModals() {
      this.modalAskDelete = false;
      this.modalNewItem = false;
      this.modalEdit = false
      this.is_units = false
    },
    openNewItem() {
      this.$router.push({name: 'product_id', params: {id: 0}})
    },
    askDeleteItem(id, name) {
      this.id = id;
      this.name = name;
      this.modalAskDelete = true;
    },
    async checkUnits() {
      await this.onServiceUnit({service: this.id}).then(res => {
        this.units = res.data.amount > 0;
      })
    },
    async deleteItem() {
      await this.checkUnits().then(() => {
        console.log(this.units)
        if (!this.units) {
          this.onDelete()
        } else {
          this.closeModals()
          this.is_units = true
        }
      })

    },
    async onDelete() {
      await this.onServiceDelete(this.id).then(() => {
        this.getList();
        this.closeModals()
      })
    },
    editItem(id) {
      this.$router.push({name: 'product_id', params: {id: id}})
    },
    saveItem(data) {
      console.log(data.id)
      // this.onServiceCategorySave({id: data.id, data: data})
      this.getList();
      this.closeModals()
    },
    createItem(data) {
      console.log(data)
      // this.onServiceCategoryCreate(data)
      this.getList();
      this.closeModals()
    },
    activeItem(id, state) {
      this.onServiceFieldPut({id: id, data: {
          field: "is_active",
          value: state
        }})
    },
    clickItem(id) {
      let query = Object.assign({}, this.$route.query);
      query['product'] = id;
      this.$router.push({name: 'units', query})
    }
  }
}
</script>
