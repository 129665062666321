var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{key:_vm.key,ref:"form",staticClass:"grid",attrs:{"lazy-validation":""}},[_c('v-card',{staticClass:"pa-4 border grid_inner"},[_c('div',{staticClass:"text-center"},[_c('DevImageBtn',{attrs:{"image":_vm.data.image,"width":"180","height":"180","circle":""}}),(_vm.edit)?_c('v-btn',{staticClass:"second mt-4",on:{"click":function($event){return _vm.loadImage('image')}}},[_vm._v(" "+_vm._s(_vm.data.image ? _vm.t['change'] : _vm.t['add'])+" ")]):_vm._e()],1),_c('div',{staticClass:"grid_inform"},[_vm._l((_vm.list),function(item,i){return _c('div',{key:i,staticClass:"inform"},[_c('div',{staticClass:"font12 grey--text text--darken-1"},[_vm._v(" "+_vm._s(_vm.t[item])+" ")]),(_vm.edit)?_c('v-text-field',{attrs:{"hide-details":"","dense":"","rules":[function (v) { return !!v || _vm.t['required_field']; }]},model:{value:(_vm.data[item]),callback:function ($$v) {_vm.$set(_vm.data, item, $$v)},expression:"data[item]"}}):_c('div',[_vm._v(" "+_vm._s(_vm.data[item] || '-')+" ")])],1)}),_c('div',{staticClass:"inform"},[_c('div',{staticClass:"font12 grey--text text--darken-1"},[_vm._v(" "+_vm._s(_vm.t.dob)+" ")]),(_vm.edit)?_c('v-menu',{ref:"menu_dob",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-details":"","dense":"","readonly":""},model:{value:(_vm.dobFormatted),callback:function ($$v) {_vm.dobFormatted=$$v},expression:"dobFormatted"}},'v-text-field',attrs,false),on))]}}],null,false,2511766648),model:{value:(_vm.menu_dob),callback:function ($$v) {_vm.menu_dob=$$v},expression:"menu_dob"}},[_c('v-date-picker',{attrs:{"no-title":"","active-picker":_vm.activePicker,"first-day-of-week":"1","max":new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substr(0, 10)},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event},"input":function($event){_vm.menu_dob = false}},model:{value:(_vm.data.dob),callback:function ($$v) {_vm.$set(_vm.data, "dob", $$v)},expression:"data.dob"}})],1):_c('div',[_vm._v(" "+_vm._s(_vm.dobFormatted || '-')+" ")])],1)],2)]),_c('v-card',{staticClass:"pa-4 border"},[_c('div',{staticClass:"font12 grey--text text--darken-1 mb-4"},[_vm._v(" "+_vm._s(_vm.t['files'])+" ")]),(_vm.edit)?_c('v-row',{attrs:{"no-gutters":""}},[_vm._l((_vm.data.files.length),function(file,f){return _c('div',{key:f,staticClass:"rel mr-2 mb-2"},[_c('div',{staticClass:"file"},[_c('iframe',{staticStyle:{"overflow":"hidden"},attrs:{"src":_vm.apiDomain + '/' + _vm.data.files[file - 1],"width":"100","height":"130","scrolling":"no"}})]),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [_c('v-btn',{attrs:{"text":"","width":"90","height":"120"},on:{"click":function($event){return _vm.delFile(file)}}},[(hover)?_c('v-icon',{staticClass:"red--text",attrs:{"size":"30"}},[_vm._v("mdi-close-circle-outline")]):_vm._e()],1)]}}],null,true)})],1)}),_c('v-btn',{staticClass:"second mt-8",on:{"click":function($event){return _vm.loadImage('doc')}}},[_c('v-icon',{staticClass:"ml-n2 mr-2"},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.t['add'])+" ")],1)],2):_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.data.files),function(file,f){return _c('div',{key:f,staticClass:"rel mr-2 mb-2"},[_c('div',{staticClass:"file"},[_c('iframe',{staticStyle:{"overflow":"hidden"},attrs:{"src":_vm.apiDomain + '/' + file,"width":"100","height":"130","scrolling":"no"}})]),_c('v-btn',{attrs:{"target":"_blank","href":_vm.apiDomain + '/' + file,"text":"","width":"90","height":"120"}})],1)}),0)],1)],1),(_vm.edit)?_c('v-btn',{staticClass:"primary my-5",on:{"click":_vm.saveItem}},[_vm._v(" "+_vm._s(_vm.t['save'])+" ")]):_c('div',{staticClass:"text-end my-5"},[_c('v-btn',{staticClass:"primary",attrs:{"fab":""},on:{"click":_vm.editItem}},[_c('v-icon',{staticClass:"min"},[_vm._v("mdi-pencil")])],1)],1),_c('input',{ref:"file",staticStyle:{"display":"none"},attrs:{"type":"file","id":"file","accept":".JPG, .PNG, .SVG, .GIF, .WebP"},on:{"change":function($event){return _vm.handleFileUpload()}}}),_c('input',{ref:"doc",staticStyle:{"display":"none"},attrs:{"type":"file","id":"doc","accept":".PDF"},on:{"change":function($event){return _vm.handleFileUpload()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }