<template>
  <DefaultPageMenu name="clients" :routes="Array('phys', 'jur')"/>
</template>

<script>
import DefaultPageMenu from "@/components/default/page/DefaultPageMenu";

export default {
  name: "clients",
  components: {DefaultPageMenu}
}
</script>

<style scoped>

</style>
