<template>
  <DefaultPageToolbar
      :total="Number(list.amount)"
      :last_page="list.lastPage"
      :create-text="$store.state.t[this.$route.params.language]['page_toolbar']['add_role']"
      :on-create="openNewItem"
  >
    <PagesSettingsRoleTable

    />
    {{list}}
  </DefaultPageToolbar>
</template>

<script>
import DefaultPageToolbar from "components/default/page/DefaultPageToolbar";
import {mapActions} from "vuex";
import PagesSettingsRoleTable from "components/pages/settings/PagesSettingsRoleTable";
export default {
  name: "roles",
  components: {PagesSettingsRoleTable, DefaultPageToolbar},
  data: () => ({
    list: {meta: {}},
    id: 0,
    name: '',
    modalNewItem: false,
    modalAskDelete: false
  }),
  computed: {
    headers() {
      return [
        {
          value: ["title"],
          text: this.$store.state.t[this.$route.params.language]['fields']['title']
        }
      ]
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    ...mapActions("userRole", ["onUserRoleAbilities", "onUserRoleList", "onUserRole", "onUserRoleSave",
      "onUserRoleDelete", "onUserRoleCreate"]),
    async getList() {
      this.list = { meta: {}}
      await this.onUserRoleList().then(res => {
        this.list = res.data
        console.log(res)
        // for (let i in this.list.data) {
        //   this.transformList(this.list.data[i]);
        // }
      })
    },
    transformList(data) {
      //
    },
    closeModals() {
      this.getList();
      this.modalNewItem = false;
      this.modalAskDelete = false;
    },
    openNewItem() {
      this.modalNewItem = true;
    },
    askDeleteItem(id, name) {
      this.id = id;
      this.name = name;
      this.modalAskDelete = true;
    },
    async deleteItem() {
      //
    },
    editItem(id) {
      //
    }
  }
}
</script>

<style scoped>

</style>
