<template>
  <svg width="213" height="100" viewBox="0 0 213 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M114.665 40.2234L114.742 40.3941L114.82 40.2234H114.665Z" fill="white"/>
    <path d="M165.476 0L164.674 1.69667L156.67 18.5702L152.529 27.3018L151.396 29.671L149.576 33.535L144.432 44.3823L134.97 64.3336H127.043L129.871 58.3539L130.559 56.8952L130.637 56.7038L131.03 55.902V55.8555L138.325 40.2234L141.448 33.535L142.358 31.6004L143.268 29.671L145.264 25.481L145.936 24.0533L146.345 23.174L149.297 16.9408L149.985 15.4769L152.074 11.1163L156.246 2.29153L157.332 0H165.476Z" fill="white"/>
    <path d="M112.054 40.2234L112.131 40.3941L112.209 40.2234H112.054ZM107.741 33.535V33.6126L107.772 33.535H107.741ZM141.826 0V0.315538L141.965 0H141.826Z" fill="white"/>
    <path d="M128.424 55.8555V55.902L128.03 56.7038L128.424 55.8555Z" fill="white"/>
    <path d="M101.417 0L100.114 2.80881L96.0501 11.5404L93.4648 17.1115L89.4937 25.6517L89.1162 26.469L85.4916 34.254L84.5505 36.2973L80.7966 44.3668L80.5639 44.853L58.9092 0H67.1926L80.5484 27.9277L81.4429 25.8897L85.3675 17.0029L89.1162 8.47815L91.7222 2.56052L92.8494 0H101.417Z" fill="#25C565"/>
    <path d="M107.741 33.5352H107.772L107.741 33.612V33.5352Z" fill="white"/>
    <path d="M112.214 40.2234L112.136 40.3941L112.054 40.2234H112.214Z" fill="white"/>
    <path d="M55.3105 0H47.5288L74.7414 56.3262L75.7134 54.226L76.277 53.0001L78.3349 48.6188L78.5521 48.1325L55.3105 0Z" fill="#25C565"/>
    <path d="M116.774 0V0.0931097L115.61 2.545L111.438 11.3646L109.364 15.7304L108.676 17.1891L105.3 24.3016L104.628 25.7293L98.6453 38.3353L96.546 42.7787L94.6277 46.8135L93.6556 48.8722V48.8878L92.1975 51.95L89.6535 57.3038L86.3288 64.3336H78.2936L78.8262 63.206L79.519 61.7421L81.5873 57.3814L81.8407 56.8642L81.9492 56.6107L81.9958 56.7038L85.6204 48.9343L85.8428 48.4636L89.5915 40.4096L90.5481 38.3508L92.1975 34.8385L94.5502 29.7796L96.546 25.5276L101.143 15.6838L104.628 8.20917L105.191 6.9884L107.244 2.60707L108.454 0H116.774Z" fill="white"/>
    <path d="M107.772 33.5352L107.741 33.612V33.5352H107.772Z" fill="white"/>
    <path d="M107.772 33.5352L107.741 33.612V33.5352H107.772Z" fill="white"/>
    <path d="M107.741 33.5352H107.772L107.741 33.612V33.5352Z" fill="white"/>
    <path d="M112.214 40.2234L112.136 40.3941L112.054 40.2234H112.214Z" fill="white"/>
    <path d="M151.47 0L150.854 1.31905L142.757 18.7306L140.704 23.174L137.674 29.671H110.544L112.41 25.7293L113.083 24.3016L113.274 23.893L113.62 23.174L115.342 19.5272L116.459 17.1891L117.147 15.7304L119.22 11.3646L123.393 2.545L124.556 0.0931097V0H132.167V0.0931097L131.004 2.545L126.831 11.3646L124.758 15.7304L124.07 17.1891L121.226 23.174H132.685L142.757 0.315538L142.902 0H151.47Z" fill="white"/>
    <path d="M94.1992 64.3336L94.8094 63.0145L102.912 45.6082L104.965 41.1648L107.995 34.6626H135.125L133.258 38.6042L132.586 40.0319L132.395 40.4406L132.049 41.1648L130.327 44.8064L129.21 47.1445L128.522 48.6032L126.449 52.969L122.276 61.7937L121.113 64.2404V64.3336H113.501V64.2404L114.665 61.7937L118.837 52.969L120.911 48.6032L121.599 47.1445L124.437 41.1648H112.984L102.912 64.0232L102.767 64.3336H94.1992Z" fill="white"/>
    <path d="M128.424 55.8555V55.902L128.031 56.7038L128.424 55.8555Z" fill="white"/>
    <path d="M0 75.0724H3.18505L13.6089 96.8704L23.7845 75.0724H27.0057L15.279 99.8034H11.944L0 75.0724ZM4.77758 75.0724H8.03502L13.5727 87.3111L18.9656 75.0724H22.2592L13.826 93.2133H13.4641L4.77758 75.0724Z" fill="white"/>
    <path d="M26.5456 92.0388V82.4847H28.7224V91.6871C28.7224 95.8667 30.8268 98.2254 35.3872 98.2254C40.1234 98.2254 42.0262 95.7684 42.0262 91.6871V82.4847H44.203V92.0388C44.203 97.0305 41.3644 99.9945 35.4131 99.9945C29.6117 99.9997 26.5456 97.2374 26.5456 92.0388ZM29.8392 91.506V82.4847H32.016V91.4078C32.016 94.2993 33.107 95.4373 35.4079 95.4373C37.7398 95.4373 38.7532 94.2735 38.7532 91.3819V82.4847H40.93V91.506C40.93 95.5097 39.079 97.2064 35.4079 97.2064C31.742 97.2116 29.8392 95.6649 29.8392 91.506Z" fill="#25C565"/>
    <path d="M46.324 91.1802C46.324 86.6696 49.6952 82.2572 55.6724 82.2572C61.1687 82.2572 65.0983 86.1833 65.0983 91.1026C65.0983 95.7167 61.5513 99.9998 55.5483 99.9998C50.0727 99.9998 46.324 95.9185 46.324 91.1802ZM62.9422 91.1543C62.9422 87.2489 59.9794 84.0315 55.6982 84.0315C51.0137 84.0315 48.5008 87.6576 48.5008 91.2268C48.5008 94.9253 51.3136 98.2203 55.5948 98.2203C60.2587 98.2255 62.9422 94.8011 62.9422 91.1543ZM49.6177 91.2268C49.6177 87.8076 51.8979 84.9419 55.7499 84.9419C59.7778 84.9419 61.8046 88.3869 61.8046 91.1285C61.8046 95.2098 58.9402 97.3151 55.6465 97.3151C52.4305 97.3099 49.6177 94.7546 49.6177 91.2268ZM59.6485 91.1285C59.6485 88.7697 58.2318 86.7161 55.6982 86.7161C53.2939 86.7161 51.7738 88.5421 51.7738 91.1285C51.7738 93.7407 53.3973 95.5408 55.6776 95.5408C58.0043 95.5357 59.6485 93.7873 59.6485 91.1285Z" fill="#25C565"/>
    <path d="M67.3273 82.4847H69.5041V99.7721H67.3273V82.4847ZM70.6209 82.4847H72.7977V90.1146L79.1058 82.4847H81.5101L74.6953 90.7974L81.7117 99.7669H79.1782L72.8029 91.5578V99.7669H70.6261V82.4847H70.6209ZM75.9672 90.6991L83.0354 82.4899H85.4655L78.4491 90.6784L85.7447 99.7773H83.2112L75.9672 90.6991Z" fill="#25C565"/>
    <path d="M87.074 82.4847H96.0191C100.223 82.4847 102.1 85.097 102.1 87.7299C102.1 89.8611 101.009 91.2008 99.4885 91.9871C100.274 92.3182 100.781 92.8716 101.665 94.47L104.628 99.7669H102.27L99.6591 95.0028C98.6974 93.3062 97.7357 92.3647 96.4431 92.0388V91.8629C98.5733 91.506 99.9384 90.1404 99.9384 88.0092C99.9384 85.8574 98.4182 84.259 95.5072 84.259H89.2508V99.7721H87.074V82.4847ZM90.3677 85.2729H95.2849C97.5651 85.2729 98.806 86.3902 98.806 88.1127C98.806 90.2439 97.3893 91.3043 94.5765 91.3043H90.3728V85.2729H90.3677ZM90.3677 92.3182H93.5114C96.3965 92.3182 97.5134 93.2027 98.8836 95.7891L100.988 99.7669H98.6044L97.0067 96.6736C95.9415 94.5424 95.409 94.0872 93.4338 94.0872H92.5238V99.7669H90.3677V92.3182ZM94.6747 89.53C95.6882 89.53 96.624 89.3024 96.624 88.2627C96.624 87.3989 96.0915 87.0471 95.078 87.0471H92.5186V89.53H94.6747Z" fill="#25C565"/>
    <path d="M112.969 82.4591H118.692L126.645 99.7672H124.24L117.224 84.2541H114.411L107.726 99.7672H105.321L112.969 82.4591ZM110.942 94.8272H120.921L123.051 99.7672H120.621L119.328 96.6014H112.617L111.298 99.7672H108.868L110.942 94.8272ZM114.918 85.2731H116.769L120.492 93.8392H111.371L114.918 85.2731ZM117.601 92.0649L115.854 87.9319L114.282 92.0649H117.601Z" fill="#25C565"/>
    <path d="M128.279 75.0724H131.392V99.7723H128.279V75.0724ZM132.948 75.0724H136.061V83.9799H146.05V75.0724H149.163V86.4783H132.948V75.0724ZM132.948 87.9629H149.163V99.7671H146.05V90.5338H136.061V99.7671H132.948V87.9629ZM150.761 75.0724H153.837V99.7723H150.761V75.0724Z" fill="white"/>
    <path d="M162.209 92.0388L155.523 82.4847H157.829L164.391 91.7854V99.7669H162.214V92.0388H162.209ZM159.194 82.4847H161.501L164.898 87.7299L168.393 82.4847H170.725L164.898 90.875L159.194 82.4847ZM165.477 91.7854L172.038 82.4847H174.344L167.659 92.0388V99.7669H165.482V91.7854H165.477Z" fill="#25C565"/>
    <path d="M175.027 82.4847H177.256L184.551 97.7444L191.671 82.4847H193.925L185.715 99.7928H183.383L175.027 82.4847ZM178.372 82.4847H180.653L184.531 91.0508L188.305 82.4847H190.611L184.706 95.1839H184.453L178.372 82.4847Z" fill="#25C565"/>
    <path d="M199.324 82.4591H205.048L213 99.7672H210.596L203.579 84.2541H200.767L194.081 99.7672H191.677L199.324 82.4591ZM197.297 94.8272H207.276L209.407 99.7672H206.976L205.684 96.6014H198.972L197.654 99.7672H195.224L197.297 94.8272ZM201.273 85.2731H203.124L206.847 93.8392H197.726L201.273 85.2731ZM203.962 92.0649L202.214 87.9319L200.642 92.0649H203.962Z" fill="#25C565"/>
  </svg>

</template>

<script>
export default {
name: "LogoXL"
}
</script>

<style scoped>

</style>
