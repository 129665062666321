<template>
  <DefaultPageModal
      :on-close="onClose"
      :text="t['message']"
  >
    <DevInput
        :text="t['first_name']"
        v-model="item.name"
        disabled
    />
    <DevInput
        :text="t['email']"
        v-model="item.email"
        disabled
    />
    <DevInput
        :text="t['phone']"
        v-model="item.phone"
        disabled
    />
    <DevInput
        :text="t['message']"
        v-model="item.message"
        text-area
        disabled
    />
    <v-btn @click="onClose" slot="btn" class="primary">
      {{ t['close'] }}
    </v-btn>
  </DefaultPageModal>
</template>

<script>
import DefaultPageModal from "@/components/default/page/DefaultPageModal";
import DevInput from "@/components/dev/DevInput";
export default {
  name: "PagesOtherMessagesId",
  components: {DevInput, DefaultPageModal},
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    onClose: {},
  },
  data: () => ({
    item: []
  }),
  computed: {
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['menu'],
          this.$store.state.t[this.$route.params.language]['form'],
          this.$store.state.t[this.$route.params.language]['fields'],
          this.$store.state.t[this.$route.params.language]['buttons']
      )
    },
  },
  created() {
    this.item = Object.assign(this.data)
  }
}
</script>
