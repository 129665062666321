<template>
  <v-card class="px-10 pt-12 pb-14 text-center">
    <v-btn @click="onClose" absolute top right icon>
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <span>
      {{t[text]}}
    </span>
    <span class="primary--text">{{name}}</span>
    <span>?</span>
    <div class="d-flex justify-space-around mt-5">
      <v-btn @click="onDelete" class="delete_button">
        {{t['delete']}}
      </v-btn>
      <v-btn @click="onClose" class="primary">
        {{t['cancel']}}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import DefaultPageModal from "@/components/default/page/DefaultPageModal";

export default {
  name: "DefaultPageAskDelete",
  components: {DefaultPageModal},
  props: {
    text: {
      style: String,
      default: ''
    },
    name: {
      style: String,
      default: ''
    },
    onClose: {},
    onDelete: {}
  },
  computed: {
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['ask_delete'],
          this.$store.state.t[this.$route.params.language]['buttons']
      )
    },
  }
}
</script>

<style scoped lang="scss">
span {
  font-size: 24px !important;
  font-weight: 500 !important;
}
</style>
