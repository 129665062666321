import axios from "axios";

const state = {
    serviceActionList: [],
    serviceAction: []
};

const getters = {
    serviceActionList(state) {
        return state.serviceActionList;
    },
    serviceAction(state) {
        return state.serviceAction;
    }
};

const mutations = {
    setServiceActionList: (state, item) => {
        state.serviceActionList = item;
    },
    setServiceAction: (state, item) => {
        state.serviceAction = item;
    }
};

const actions = {
    onServiceActionList({commit, dispatch}, {number}) {
        commit("setServiceActionList", []);
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/admin/service-action/grid?" +
                "limit=" +
                (number ? number : this.state.number) +
                "&page=" +
                this.state.page + '&order[]=id:desc';
            axios
                .get(url)
                .then(response => {
                    commit("setServiceActionList", response.data);
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    onServiceActionDelete({commit, dispatch}, id) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain + "/api/admin/service-action/" + id
            axios.delete(url)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    onServiceActionCreate({commit, dispatch}, data) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/admin/service-action";
            axios
                .post(url, data)
                .then(response => {
                    commit("SetLoadingAnimation", false, {root: true});
                    resolve(response);
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },

    onServiceAction({commit, dispatch}, id) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/admin/service-action/" + id;
            axios
                .get(url)
                .then(response => {
                    commit("setServiceAction", response.data);
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },

    onServiceActionSave({commit, dispatch}, {id, data}) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/admin/service-action/" + id;
            axios
                .put(url, data)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
