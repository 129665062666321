import axios from "axios";

const state = {
    orderList: [],
    order: [],
    available: false
};

const getters = {
    orderList(state) {
        return state.orderList;
    },
    order(state) {
        return state.order;
    },
    available(state) {
        return state.available;
    }
};

const mutations = {
    setOrderList: (state, item) => {
        state.orderList = item;
    },
    setOrder: (state, item) => {
        state.order = item;
    },
    setAvailability: (state, item) => {
        state.available = item;
    }
};

const actions = {
    onAvailability({commit, dispatch}, {start, end, id}){
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/admin/order/availability?start=" + start + "&end=" + end + "&service_id=" + id + "&location_id=1";
            axios
                .get(url)
                .then(response => {
                    commit("setAvailability", response.data);
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    onOrderList({commit, dispatch}, {type, user_id}) {
        //commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/admin/order/grid?limit=" + this.state.number + "&page=" + this.state.page + "&order[]=created_at:desc";
            if (type) {
                url = url + "&filter[]=type:" + type
            }
            if (user_id) {
                url = url + "&filter[]=user_id:" + user_id
            }
            axios
                .get(url)
                .then(response => {
                    commit("setOrderList", response.data);
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onCheckCode({commit}, value) {
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/online/order/check-discount?code=" + value;
            axios
                .get(url)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onNewOrder({commit, dispatch}, data) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain + "/api/admin/order";
            axios
                .post(url, data)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onDeleteOrder({commit, dispatch}, id) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain + "/api/admin/order/" + id;
            axios
                .delete(url)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onOrder({commit}, id) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain + "/api/admin/order/" + id;
            axios
                .get(url)
                .then(response => {
                    commit("setOrder", response.data);
                    commit("SetLoadingAnimation", false, {root: true});
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onOrderSave({commit, dispatch}, data) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain + "/api/admin/order";
            axios
                .put(url, data)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
