<template>
  <svg width="231" height="32" viewBox="0 0 231 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M66.4 6.4H68.8524L76.8784 23.1895L84.7133 6.4H87.1936L78.1604 25.4486H75.5965L66.4 6.4ZM70.0786 6.4H72.5867L76.8506 15.8267L81.0029 6.4H83.5389L77.0456 20.3727H76.7669L70.0786 6.4Z" fill="#2F313D"/>
    <path d="M86.8393 19.4683V12.1094H88.5153V19.1973C88.5153 22.4166 90.1357 24.2334 93.6471 24.2334C97.2938 24.2334 98.7589 22.3409 98.7589 19.1973V12.1094H100.435V19.4683C100.435 23.3131 98.2493 25.596 93.667 25.596C89.2001 25.6 86.8393 23.4724 86.8393 19.4683ZM89.3753 19.0579V12.1094H91.0513V18.9822C91.0513 21.2094 91.8914 22.0859 93.663 22.0859C95.4585 22.0859 96.2388 21.1895 96.2388 18.9623V12.1094H97.9149V19.0579C97.9149 22.1417 96.4896 23.4485 93.663 23.4485C90.8403 23.4525 89.3753 22.2612 89.3753 19.0579Z" fill="#25C565"/>
    <path d="M102.067 18.8069C102.067 15.3326 104.663 11.9341 109.265 11.9341C113.497 11.9341 116.523 14.9581 116.523 18.7471C116.523 22.3011 113.792 25.6 109.17 25.6C104.954 25.6 102.067 22.4564 102.067 18.8069ZM114.863 18.787C114.863 15.7789 112.581 13.3007 109.285 13.3007C105.678 13.3007 103.743 16.0936 103.743 18.8427C103.743 21.6915 105.909 24.2294 109.205 24.2294C112.796 24.2334 114.863 21.5958 114.863 18.787ZM104.603 18.8427C104.603 16.2092 106.359 14.0019 109.325 14.0019C112.426 14.0019 113.987 16.6554 113.987 18.767C113.987 21.9106 111.781 23.5322 109.245 23.5322C106.769 23.5282 104.603 21.556 104.603 18.8427ZM112.327 18.767C112.327 16.9502 111.236 15.3685 109.285 15.3685C107.434 15.3685 106.263 16.7749 106.263 18.767C106.263 20.7791 107.513 22.1656 109.269 22.1656C111.061 22.1616 112.327 20.8149 112.327 18.767Z" fill="#25C565"/>
    <path d="M118.239 12.1094H119.915V25.4247H118.239V12.1094ZM120.775 12.1094H122.451V17.9861L127.312 12.1094H129.163L123.916 18.5121L129.318 25.4207H127.368L122.455 19.0977V25.4207H120.779V12.1094H120.775ZM124.891 18.4324L130.334 12.1094H132.205L126.802 18.4164L132.42 25.4247H130.469L124.891 18.4324Z" fill="#25C565"/>
    <path d="M133.443 12.1094H140.33C143.567 12.1094 145.012 14.1214 145.012 16.1494C145.012 17.7909 144.172 18.8228 143.002 19.4284C143.607 19.6834 143.997 20.1097 144.678 21.3409L146.959 25.4207H145.143L143.133 21.7512C142.392 20.4444 141.652 19.7193 140.657 19.4683V19.3328C142.297 19.0579 143.348 18.0061 143.348 16.3646C143.348 14.7071 142.177 13.476 139.936 13.476H135.119V25.4247H133.443V12.1094ZM135.979 14.2569H139.765C141.521 14.2569 142.476 15.1175 142.476 16.4442C142.476 18.0857 141.385 18.9025 139.219 18.9025H135.983V14.2569H135.979ZM135.979 19.6834H138.399C140.621 19.6834 141.481 20.3647 142.536 22.3568L144.156 25.4207H142.321L141.091 23.0381C140.27 21.3966 139.86 21.046 138.34 21.046H137.639V25.4207H135.979V19.6834ZM139.295 17.5359C140.075 17.5359 140.796 17.3606 140.796 16.5598C140.796 15.8944 140.386 15.6235 139.606 15.6235H137.635V17.5359H139.295Z" fill="#25C565"/>
    <path d="M153.38 12.0895H157.788L163.911 25.4207H162.059L156.657 13.472H154.491L149.344 25.4207H147.492L153.38 12.0895ZM151.82 21.6158H159.503L161.144 25.4207H159.273L158.277 22.9824H153.11L152.095 25.4207H150.223L151.82 21.6158ZM154.881 14.2569H156.307L159.173 20.8548H152.15L154.881 14.2569ZM156.948 19.4882L155.602 16.3048L154.392 19.4882H156.948Z" fill="#25C565"/>
    <path d="M165.169 6.4H167.565V25.4247H165.169V6.4ZM168.764 6.4H171.16V13.2608H178.852V6.4H181.249V15.1852H168.764V6.4ZM168.764 16.3287H181.249V25.4207H178.852V18.3089H171.16V25.4207H168.764V16.3287ZM182.479 6.4H184.847V25.4247H182.479V6.4Z" fill="#2F313D"/>
    <path d="M191.293 19.4683L186.145 12.1094H187.921L192.973 19.273V25.4207H191.297V19.4683H191.293ZM188.972 12.1094H190.748L193.363 16.1494L196.055 12.1094H197.85L193.359 18.5718L188.972 12.1094ZM193.809 19.273L198.861 12.1094H200.637L195.485 19.4683V25.4207H193.809V19.273Z" fill="#25C565"/>
    <path d="M201.162 12.1094H202.878L208.496 23.8629L213.978 12.1094H215.714L209.391 25.4406H207.596L201.162 12.1094ZM203.738 12.1094H205.494L208.48 18.7073L211.386 12.1094H213.162L208.615 21.8907H208.42L203.738 12.1094Z" fill="#25C565"/>
    <path d="M219.87 12.0895H224.277L230.4 25.4207H228.549L223.146 13.472H220.981L215.833 25.4207H213.982L219.87 12.0895ZM218.309 21.6158H225.993L227.633 25.4207H225.762L224.767 22.9824H219.599L218.584 25.4207H216.713L218.309 21.6158ZM221.371 14.2569H222.796L225.662 20.8548H218.64L221.371 14.2569ZM223.441 19.4882L222.095 16.3048L220.885 19.4882H223.441Z" fill="#25C565"/>
    <path d="M33.2422 20.0054L33.2815 20.092L33.3209 20.0054H33.2422Z" fill="#2F313D"/>
    <path d="M58.4 0L58.0044 0.842416L54.0405 9.23902L51.9881 13.581L51.428 14.758L50.5271 16.6791L47.9812 22.076L43.2927 32H39.372L40.7703 29.0279L41.1111 28.2997L41.1502 28.2052L41.3461 27.8076V27.784L44.9574 20.0054L46.5046 16.6791L46.955 15.7185L47.4054 14.758L48.3925 12.6756L48.7254 11.9631L48.9291 11.5261L50.39 8.42416L50.7308 7.69984L51.7649 5.52688L53.829 1.14159L54.3656 0H58.4Z" fill="#2F313D"/>
    <path d="M31.9496 20.0054L31.9888 20.092L32.0279 20.0054H31.9496ZM29.811 16.6791V16.7184L29.8267 16.6791H29.811ZM46.6886 0V0.157461L46.7591 0H46.6886Z" fill="#2F313D"/>
    <path d="M40.0535 27.784V27.8076L39.8577 28.2052L40.0535 27.784Z" fill="#2F313D"/>
    <path d="M26.6815 0L26.0391 1.39747L24.0258 5.73945L22.745 8.51076L20.7788 12.7583L20.5908 13.1677L18.7969 17.0412L18.3308 18.0529L16.4742 22.0682L16.3567 22.3122L5.63239 0H9.73724L16.3489 13.892L16.7915 12.8764L18.7342 8.45565L20.5908 4.21602L21.8794 1.2715L22.4395 0H26.6815Z" fill="#25C565"/>
    <path d="M29.811 16.6791H29.8267L29.811 16.7184V16.6791Z" fill="#2F313D"/>
    <path d="M32.0284 20.0054L31.989 20.092L31.9496 20.0054H32.0284Z" fill="#2F313D"/>
    <path d="M3.85417 0H0L13.4739 28.0202L13.9557 26.9731L14.2338 26.3629L15.2522 24.186L15.3618 23.9419L3.85417 0Z" fill="#25C565"/>
    <path d="M34.284 0V0.0472383L33.7083 1.26362L31.6441 5.65285L30.6179 7.82581L30.2771 8.55013L28.6046 12.0891L28.2717 12.7976L25.3106 19.0685L24.2687 21.2808L23.3208 23.2885L22.8391 24.308V24.3159L22.1184 25.8393L20.8571 28.5044L19.2121 32H15.2326L15.495 31.441L15.8397 30.7128L16.8659 28.5437L16.9873 28.2839L17.0421 28.158L17.0656 28.2052L18.8596 24.3395L18.9692 24.1073L20.8258 20.0999L21.2997 19.0764L22.1184 17.3286L23.2817 14.8131L24.2687 12.6992L26.5444 7.80219L28.2717 4.08611L28.5498 3.47595L29.5682 1.29512L30.1675 0H34.284Z" fill="#2F313D"/>
    <path d="M29.8267 16.6791L29.811 16.7184V16.6791H29.8267Z" fill="#2F313D"/>
    <path d="M29.8267 16.6791L29.811 16.7184V16.6791H29.8267Z" fill="#2F313D"/>
    <path d="M29.811 16.6791H29.8267L29.811 16.7184V16.6791Z" fill="#2F313D"/>
    <path d="M32.0284 20.0054L31.989 20.092L31.9496 20.0054H32.0284Z" fill="#2F313D"/>
    <path d="M51.4633 0L51.1617 0.657399L47.1509 9.31775L46.1325 11.5261L44.6323 14.758H31.2015L32.1259 12.7976L32.4588 12.0891L32.5528 11.8844L32.7212 11.5261L33.579 9.71534L34.1274 8.55013L34.4721 7.82581L35.4983 5.65285L37.5625 1.26362L38.1382 0.0472383V0H41.9062V0.0472383L41.3304 1.26362L39.2663 5.65285L38.2401 7.82581L37.8993 8.55013L36.4932 11.5261H42.1647L47.1509 0.157461L47.2214 0H51.4633Z" fill="#2F313D"/>
    <path d="M23.1093 32L23.4109 31.3465L27.4218 22.6862L28.4401 20.4739L29.9364 17.242H43.3711L42.4467 19.2024L42.1138 19.9149L42.0198 20.1156L41.8514 20.4739L40.9936 22.2886L40.4413 23.4499L40.1006 24.1781L39.0743 26.3471L37.0102 30.7364L36.4344 31.9528V32H32.6664V31.9528L33.2422 30.7364L35.3063 26.3471L36.3326 24.1781L36.6733 23.4499L38.0795 20.4739H32.4079L27.4218 31.8465L27.3513 32H23.1093Z" fill="#2F313D"/>
    <path d="M40.0535 27.784V27.8076L39.8577 28.2052L40.0535 27.784Z" fill="#2F313D"/>
  </svg>

</template>

<script>
export default {
name: "LogoDark"
}
</script>

<style scoped>

</style>
