import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ru from "vuetify/lib/locale/ru";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: {
          base: "#25C565",
          darken1: "#13AC50"
        },
        secondary: {
          base: "#2f313d",
          lighten5: "#d5d5d8" // rgba(#2f313d, .2)
        },
        accent: "#207AF4",
        error: "#EC1B0E",
        grey: {
          base: "#D4D6E2",
          darken1: "#8C8E9A",
          lighten1: "#E7E9F8",
          lighten2: "#F3F4F4",
          lighten3: '#F7FAFD'
        }
      }
    }
  },
  lang: {
    locales: { ru },
    current: "ru"
  }
});
