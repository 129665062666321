<template>
  <div>
    <v-form ref="form" lazy-validation :key="key" :class="edit ? 'center_grid' : ''">
      <PagesOrdersProjectDocHeader>
        <div class="fix">
          <v-sheet class="border mx-auto brd" width="100%" max-width="1024">

            <v-sheet tile min-height="60" class="d-flex align-center my-padding">
            <span class="subtitle1 text-uppercase">
              {{ t['project_id'] }}
            </span>
              <span v-if="data.id" class="ml-3">
              {{ '#VH' + String(date.getFullYear()).slice(2, 4) + String(data.id).padStart(4, '0') }}
            </span>
            </v-sheet>
            <v-divider/>
            <div class="my-padding">
              <DevInput
                  v-if="edit && userList.data"
                  select
                  :text="t.client"
                  v-model="data.user_id"
                  item-text="company_name"
                  :items="userList.data"
                  @change="getUser"
                  class="max-width"
                  :rules="[v => !!v || t['required_field']]"
              />
              <div @click="openUser" class="title cursor">
                {{ usr.company_name }}
              </div>
              <div v-if="typeof usr.e_address === 'object'" class="my-5">
                {{
                  usr.e_address.street && usr.e_address.zip && usr.e_address.city ? (usr.e_address.street + ', ' + usr.e_address.zip + ', ' + usr.e_address.city) : '-'
                }}
              </div>
              <div class="grid">
                <div class="font-weight-bold">
                  {{ t.project_name }}
                </div>
                <DevInput
                    v-if="edit"
                    v-model="data.data.project_name"
                    :rules="[v => !!v || t['required_field']]"
                    class="max-width mb-n4"
                />
                <div v-else>
                  {{ data.data.project_name }}
                </div>
                <div class="font-weight-bold">
                  {{ t.address }}
                </div>
                <DevInput
                    v-if="edit && usr.data"
                    select
                    v-model="data.data.address"
                    :items="usr.data.addresses"
                    item-text="info"
                    item-value="id"
                    :rules="[v => !!v || t['required_field']]"
                    class="max-width mb-n4"
                />
                <div v-else>
                  {{
                    usr.id && data.data.address ? usr.data.addresses.filter(i => i.id === data.data.address)[0].info : '-'
                  }}
                </div>
                <div class="font-weight-bold">
                  {{ t.contact_person }}
                </div>
                <DevInput
                    v-if="edit && usr.data"
                    select
                    v-model="data.data.person"
                    :items="usr.data.persons"
                    :rules="[v => !!v || t['required_field']]"
                    class="max-width mb-n4"
                />
                <div v-else-if="usr.id && usr.data.persons && usr.data.persons[0]">
                  {{usr.data.persons.filter(i => i.id === data.data.person)[0] ? usr.data.persons.filter(i => i.id === data.data.person)[0].phone : '-'}}
                  <br/>
                  {{usr.data.persons.filter(i => i.id === data.data.person)[0] ? usr.data.persons.filter(i => i.id === data.data.person)[0].name : '-'}}
                </div>
                <div v-else>
                  -
                </div>
<!--                <div class="font-weight-bold">-->
<!--                  {{ t.project_number }}-->
<!--                </div>-->
<!--                <DevInput-->
<!--                    v-if="edit"-->
<!--                    v-model="data.data.number"-->
<!--                    :rules="[v => !!v || t['required_field']]"-->
<!--                    class="max-width mb-n4"-->
<!--                />-->
<!--                <div v-else>-->
<!--                  {{ data.data.number || '-' }}-->
<!--                </div>-->
              </div>
              <div class="title mt-15">
                VuokraHyva
              </div>
              <div class="grid my-5">
                <div class="font-weight-bold">
                  {{ t.address }}
                </div>
                <div>
                  Päivärinteenpolku 4 E 04600
                  <br/>
                  Mäntsälä
                </div>
                <div class="font-weight-bold">
                  {{ t.ytunnus }}
                </div>
                3088041-6
                <div class="font-weight-bold">
                  {{ t.contact_person }}
                </div>
                Aleks Salasin
                <div class="font-weight-bold">
                  {{ t.phone_number }}
                </div>
                +358 40 051 58 24
                <div class="font-weight-bold">
                  {{ t.email }}
                </div>
                toimisto@vuokrahyva.fi
                <div class="font-weight-bold">
                  {{ t.billing_address1 }}
                </div>
                <div>
                  Päivärinteenpolku 4 E 04600
                  <br/>
                  Mäntsälä
                </div>
                <div class="font-weight-bold">
                  {{ t.e_invoice }}
                </div>
                <div>
                  Verkkolaskutusosoite (OVT-tunnus): 003730880416
                  <br/>
                  Operaattori: Open Text Oy
                  <br/>
                  Operaattoritunnus: 003708599126
                </div>
              </div>
              <div class="grid">
                <div class="font-weight-bold">
                  {{ t.service }}
                </div>
                <div v-if="edit">
                  <DevInput
                      v-if="serviceCategoryList.data"
                      select
                      v-model="serviceCategory"
                      :text="t.service"
                      :items="serviceCategoryList.data"
                      class="max-width mb-n4"
                      @change="changeService"
                  />
                  <DevInput
                      v-if="serviceList.data"
                      select
                      v-model="data.service_id"
                      :text="t.product"
                      :items="serviceList.data"
                      class="max-width mb-n4"
                      @change="changeProduct"
                  />
                </div>
                <div v-else @click="openService" class="cursor">
                  {{ data.service ? data.service.title : '-' }}
                </div>
                <div class="font-weight-bold">
                  {{ t.product }}
                </div>
                <DevInput
                    v-if="edit && serviceUnit.data"
                    select
                    v-model="data.unit_id"
                    :text="t.unit"
                    :items="serviceUnit.data"
                    class="max-width mb-n4"
                    @change="changeUnit"
                />
                <div v-else @click="openUnit" class="cursor">
                  {{
                    data.service_unit ? (String(data.service_unit.id).padStart(4, '0') + ' ' + data.service_unit.name) : '-'
                  }}
                </div>
                <div class="font-weight-bold">
                  {{ t.week_price }}
                </div>
                <DevInput
                    v-if="edit"
                    v-model="data.data.month_price"
                    type="Number"
                    class="max-width mb-n4"
                >
                  <v-icon slot="append-outer" class="mt-1">mdi-currency-eur</v-icon>
                </DevInput>
                <div v-else>
                  {{ data.data.month_price + ' €' }}
                </div>
                <div class="font-weight-bold">
                  {{ t.product_installation_address }}
                </div>
                <div v-if="edit" class="max-width">
                  <DevInput
                      v-if="usr.data"
                      select
                      v-model="data.data.installation_addresses"
                      :items="usr.data.addresses"
                      item-text="info"
                      item-value="id"
                      :rules="[v => !!v || t['required_field']]"
                      return-object
                      class=" mb-n4"
                      style="max-width: 85vw"
                  />
                </div>
                <div v-else>
                  {{ data.data.installation_addresses ? data.data.installation_addresses.info : '' }}
                </div>
                <div class="font-weight-bold">
                  {{ t.doc_contract_term }}
                </div>
                <div v-if="edit">
                  <v-row style="max-width: 524px">
                    <v-col cols="12" sm="6">
                      <div class="font12">
                        {{ t['date_from'] }}
                      </div>
                      <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="true"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="data.start"
                              readonly
                              dense
                              outlined
                              v-bind="attrs"
                              v-on="on"
                              class="mb-n4"
                          >
                            <v-icon slot="prepend-inner" class="mt-1">mdi-calendar</v-icon>
                          </v-text-field>
                        </template>
                        <v-date-picker
                            :picker-date.sync="pickerDateStart"
                            v-model="data.start"
                            :active-picker.sync="activePicker"
                            :allowed-dates="isNew ? allowedDatesStart : null"
                            color="secondary"
                            ref="datePickerStart"
                            first-day-of-week="1"
                            no-title
                            elevation="1"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <div class="font12">
                        {{ t['date_to'] }}
                      </div>
                      <v-menu
                          ref="menu1"
                          v-model="menu1"
                          :close-on-content-click="true"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="data.end"
                              readonly
                              dense
                              outlined
                              v-bind="attrs"
                              v-on="on"
                              class="mb-n4"
                          >
                            <v-icon slot="prepend-inner" class="mt-1">mdi-calendar</v-icon>
                          </v-text-field>
                        </template>
                        <v-date-picker
                            :picker-date.sync="pickerDateEnd"
                            v-model="data.end"
                            :active-picker.sync="activePicker"
                            :allowed-dates="isNew ? allowedDatesEnd : null"
                            color="secondary"
                            ref="datePickerEnd"
                            first-day-of-week="1"
                            no-title
                            elevation="1"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                <div v-else-if="data.start_edit && data.end_edit">
                  {{
                    data.start_edit[2] + '.' + data.start_edit[1] + '.' + data.start_edit[0] + ' - ' + data.end_edit[2] + '.' + data.end_edit[1] + '.' + data.end_edit[0]
                  }}
                </div>
                <div class="font-weight-bold">
                  {{ t.agreement_date }}
                </div>
<!--                <DevInput-->
<!--                    v-if="edit"-->
<!--                    text-area-->
<!--                    v-model="data.data.offer_info"-->
<!--                    class="mb-n4"-->
<!--                />-->
                <div>
                  {{ data.created_at ?  data.created_at : '-' }}
                </div>
                <div class="font-weight-bold">
                  {{t.transportation_price}}
                </div>
                <DevInput
                    v-if="edit"
                    v-model="data.data.transportation_price"
                    type="Number"
                    class="max-width mb-n4"
                >
                  <v-icon slot="append-outer" class="mt-1">mdi-currency-eur</v-icon>
                </DevInput>
                <div v-else>
                  {{ data.data.transportation_price || '0.00' }} €
                </div>
              </div>
            </div>
          </v-sheet>
        </div>


        <div slot="doc" class="print fix">
          <div class="pb-2 px-10">
            <span class="subtitle1 text-uppercase">
              {{ t['project_id'] }}
            </span>
            <span v-if="data.id" class="pb-2 ml-3">
              {{ '#VH' + String(date.getFullYear()).slice(2, 4) + String(data.id).padStart(4, '0') }}
            </span>
          </div>
          <v-divider class="mb-5"/>
          <div class="title px-10">
            {{ usr.company_name }}
          </div>
          <div v-if="typeof usr.e_address === 'object'" class="my-5 px-10">
            {{
              usr.e_address.street && usr.e_address.zip && usr.e_address.city ? (usr.e_address.street + ', ' + usr.e_address.zip + ', ' + usr.e_address.city) : '-'
            }}
          </div>
          <div class="print_grid">
            <div class="font-weight-bold">
              {{ t.project_name }}
            </div>
            <div>
              {{ data.data.project_name }}
            </div>
          </div>
          <div class="print_grid">
            <div class="font-weight-bold">
              {{ t.project_address }}
            </div>
            <div>
              {{
                usr.id && data.data.address ? usr.data.addresses.filter(i => i.id === data.data.address)[0].info : '-'
              }}
            </div>
          </div>
          <div class="print_grid">
            <div class="font-weight-bold">
              {{ t.contact_person }}
            </div>
            <div v-if="usr.id && usr.data.persons && usr.data.persons[0]">
              {{usr.data.persons.filter(i => i.id === data.data.person)[0] ? usr.data.persons.filter(i => i.id === data.data.person)[0].phone : '-'}}
              <br/>
              {{usr.data.persons.filter(i => i.id === data.data.person)[0] ? usr.data.persons.filter(i => i.id === data.data.person)[0].name : '-'}}
            </div>
          </div>
<!--          <div class="print_grid">-->
<!--            <div class="font-weight-bold">-->
<!--              {{ t.project_number }}-->
<!--            </div>-->
<!--            <div>-->
<!--              {{ data.data.number || '-' }}-->
<!--            </div>-->
<!--          </div>-->
          <div class="title mt-8 mb-4 px-10">
            VuokraHyva
          </div>
          <div class="print_grid">
            <div class="font-weight-bold">
              {{ t.address }}
            </div>
            <div>
              Päivärinteenpolku 4 E 04600
              <br/>
              Mäntsälä
            </div>
          </div>
          <div class="print_grid">
            <div class="font-weight-bold">
              {{ t.ytunnus }}
            </div>
            3088041-6
          </div>
          <div class="print_grid">
            <div class="font-weight-bold">
              {{ t.contact_person }}
            </div>
            Aleks Salasin
          </div>
          <div class="print_grid">
            <div class="font-weight-bold">
              {{ t.phone_number }}
            </div>
            +358 40 051 58 24
          </div>
          <div class="print_grid">
            <div class="font-weight-bold">
              {{ t.email }}
            </div>
            toimisto@vuokrahyva.fi
          </div>
          <div class="print_grid">
            <div class="font-weight-bold">
              {{ t.billing_address1 }}
            </div>
            <div>
              Päivärinteenpolku 4 E 04600
              <br/>
              Mäntsälä
            </div>
          </div>
          <div class="print_grid">
            <div class="font-weight-bold">
              {{ t.e_invoice }}
            </div>
            <div>
              Verkkolaskutusosoite (OVT-tunnus): 003730880416
              <br/>
              Operaattori: Open Text Oy
              <br/>
              Operaattoritunnus: 003708599126
            </div>
          </div>
          <div class="print_grid">
            <div class="font-weight-bold">
              {{ t.service }}
            </div>
            {{ data.service ? data.service.title : '-' }}
          </div>
          <div class="print_grid">
            <div class="font-weight-bold">
              {{ t.product }}
            </div>
            {{
              data.service_unit ? (String(data.service_unit.id).padStart(4, '0') + ' ' + data.service_unit.name) : '-'
            }}
          </div>
          <div class="print_grid">
            <div class="font-weight-bold">
              {{ t.week_price }}
            </div>
            {{ data.data.month_price + ' €' }}
          </div>
          <div class="print_grid">
            <div class="font-weight-bold">
              {{ t.product_installation_address }}
            </div>
            {{ data.data.installation_addresses ? data.data.installation_addresses.info : '' }}
          </div>
          <div class="print_grid">
            <div class="font-weight-bold">
              {{ t.doc_contract_term }}
            </div>
            <div v-if="data.start_edit && data.end_edit">
              {{
                data.start_edit[2] + '.' + data.start_edit[1] + '.' + data.start_edit[0] + ' - ' + data.end_edit[2] + '.' + data.end_edit[1] + '.' + data.end_edit[0]
              }}
            </div>
          </div>
          <div class="print_grid">
            <div class="font-weight-bold">
              {{ t.agreement_date }}
            </div>
            {{ data.created_at ?  data.created_at: '-' }}
          </div>
          <div v-if="data.data.transportation_price" class="print_grid">
            <div class="font-weight-bold">
              {{ t.transportation_price }}
            </div>
            {{ data.data.transportation_price + ' €' }}
          </div>
        </div>


      </PagesOrdersProjectDocHeader>
      <v-card max-width="1024" class="border pa-4 my-10 mx-auto">
        <DevInput
            :text="t.note"
            v-model="data.data.billing"
            text-area
            :disabled="!edit"
            hide-details
        />
        <div v-if="edit" class="d-flex justify-end mt-5">
          <v-btn @click="saveItem(true)" class="primary">
            {{ data.id ? t['save'] : t['create'] }}
          </v-btn>
        </div>
      </v-card>
    </v-form>

  </div>
</template>

<script>
import PagesOrdersProjectDocHeader from 'components/pages/orders/PagesOrdersProjectDocHeader'
import DevImageBtn from 'components/dev/DevImageBtn'
import DevInput from "components/dev/DevInput";
import {mapActions, mapGetters} from "vuex";
import unit_id from "views/pages/services/unit_id";
import LogoDark from "components/svg/LogoDark";
import LogoDoc from "components/svg/LogoDoc";

export default {
  name: 'PagesOrdersProjectDoc',
  components: {LogoDoc, LogoDark, DevInput, DevImageBtn, PagesOrdersProjectDocHeader},
  refs: ["datePickerStart", "datePickerEnd"],
  props: {
    edit: Boolean,
    onSave: {}
  },
  data: () => ({
    data: {
      data: {},
      type: 'project',
      language_id: 1,
      location_id: 1
    },
    activePicker: null,
    menu: false,
    menu1: false,
    key: 0,
    image: null,
    usr: {
      data: {
        contacts: {}
      }
    },
    date: new Date(),

    availableStart: [],
    availableEnd: [],
    isNew: true,

    pickerDateStart: null,
    pickerDateEnd: null,

    serviceCategory: 0
  }),
  computed: {
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['fields'],
          this.$store.state.t[this.$route.params.language]['form'],
          this.$store.state.t[this.$route.params.language]['project_doc'],
          this.$store.state.t[this.$route.params.language]['menu'],
          this.$store.state.t[this.$route.params.language]['buttons'],
      )
    },
    ...mapGetters("user", ["userList", "user"]),
    ...mapGetters("service", ["serviceCategoryList", "serviceList", "serviceUnit"]),
    ...mapGetters("order", ["order", "available"]),
    ...mapGetters(["loadingAnimation"])
  },
  watch: {
    order() {
      this.isNew = false
      this.data = Object.assign(this.transformData(this.order))
      this.data.start_edit = this.data.start.split(' ')[0].split('-')
      this.data.end_edit = this.data.end.split(' ')[0].split('-')

      this.data.start = this.data.start.split(' ')[0]
      this.data.end = this.data.end.split(' ')[0]

      let created_at = this.data.created_at.split(' ')[0].split('.')
      this.data.created_at = created_at[0].padStart(2, '0') + '.' + created_at[1].padStart(2, '0') + '.'  + created_at[2]

      this.data.service_id = this.data.service.id
      this.data.user_id = this.data.user_data.user_id
      this.data.unit_id = this.data.service_unit.id
      this.data.service_unit_id = this.data.unit_id
      this.getUser(this.data.user_id)
      this.getService(this.data.service_id)

      this.key++
    },

    pickerDateStart(val) {
      this.getAllowedDates(val, true);
    },

    pickerDateEnd(val) {
      this.getAllowedDates(val, false);
    }
  },
  created() {
    this.openEdit()
  },
  methods: {
    ...mapActions("user", ["onUserList", "onUser"]),
    ...mapActions("service", ["onServiceCategoryList", "onServiceList", "onService", "onServiceUnit"]),
    ...mapActions("order", ["onOrderSave", "onNewOrder", "onAvailability"]),

    transformData(data) {
      while (typeof data.data === 'string') {
        data.data = JSON.parse(data.data)
      }
      data.created_at = data.created_at.split('/').join('.')
      return data
    },

    async getUser(id) {
      await this.onUser(id).then(res =>{
        this.usr = res.data
        this.usr.data = JSON.parse(res.data.data)
        for (let i in this.usr.data.persons) {
          this.usr.data.persons[i].name = this.usr.data.persons[i].first_name + ' ' + this.usr.data.persons[i].last_name
        }
        for (let i in this.usr.data.addresses) {
          this.usr.data.addresses[i].info = this.usr.data.addresses[i].street + ', ' + this.usr.data.addresses[i].zip + ', ' + this.usr.data.addresses[i].city
        }
        if (this.usr.e_address.length > 1) {
          this.usr.e_address = JSON.parse(this.usr.e_address)
        } else {
          this.usr.e_address = {}
        }
        if (this.usr.address.length > 1) {
          this.usr.address = JSON.parse(this.usr.address)
        } else {
          this.usr.e_address = {}
        }
        console.log(this.usr)
        this.key++
      })
    },

    async getService(id) {
      await this.onService({id: id}).then(res => {
        this.serviceCategory = res.data.service_category.id
        this.onServiceList({category: this.serviceCategory, number: '0'})
        this.onServiceUnit({service: id, number: '0'})
      })
    },

    openEdit() {
      this.onUserList({type: 'legal', number: '0'})
      this.onServiceCategoryList({number: '0'})
      this.onServiceList({number: '0'})
      this.onServiceUnit({number: '0'})
    },

    changeService(id) {
      this.onServiceList({category: id, number: '0'})
      this.onServiceUnit({number: 0})
    },

    changeProduct(id) {
      this.onServiceUnit({service: id, number: 0})
      this.getAllowedDates(null)
    },

    changeUnit(id){
      this.data.service_unit_id = this.data.unit_id
    },

    saveItem() {
      let temp = Object.assign(this.data)
      this.onSave(temp)
    },

    openUnit() {
      this.$router.push({name: 'unit_id', params: {id: this.data.unit_id}})
    },

    openService() {
      this.$router.push({name: 'product_id', params: {id: this.data.service_id}})
    },

    openUser() {
      this.$router.push({name: 'jur_address', params: {id: this.data.user_id}})
    },

    async getAllowedDates(val, start) {
      if (this.data.service_id) {
        let y;
        let m;

        if(val) {
          y = val.split('-')[0];
          m = Number(val.split('-')[1]) - 1;
        }
        else {
          if (start) {
            y = this.$refs.datePickerStart.tableYear;
            m = this.$refs.datePickerStart.tableMonth;
          } else {
            y = this.$refs.datePickerEnd.tableYear;
            m = this.$refs.datePickerEnd.tableMonth;
          }
        }

        let firstDay = new Date(y, m, 2).toISOString().slice(0, 10);
        let lastDay = new Date(y, m + 1, 1).toISOString().slice(0, 10);

        await this.onAvailability({start: firstDay, end: lastDay, id: this.data.service_id}).then(res => {

          if (val) {
            start ? this.availableStart = res.data : this.availableEnd = res.data
          } else {
            this.availableStart = res.data
            this.availableEnd = res.data
          }
        })
      }
    },

    allowedDatesStart(val) {
      for (let i = 0; i < this.availableStart.length; i++) {
        if (this.availableStart[i] == val) {
          return val;
        }
      }
    },

    allowedDatesEnd(val) {
      for (let i = 0; i < this.availableEnd.length; i++) {
        if (this.availableEnd[i] == val) {
          return val;
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .title {
  font-size: 30px !important;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    font-size: 24px !important;
  }
}

.max-width {
  //width: 100%;
  max-width: 500px !important;
}

::v-deep .subtitle1 {
  font-size: 24px !important;
  font-weight: 500 !important;
}

::v-deep .subtitle {
  font-size: 18px !important;
  font-weight: 700 !important;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    font-size: 16px !important;
  }
}

div:not(.title):not(.subtitle):not(.subtitle1):not(.doc) {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    font-size: 14px !important;
  }
}

.grid {
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;

  @media #{map-get($display-breakpoints, 'md-only')} {
    grid-template-columns: 200px 1fr;
  }

  @media #{map-get($display-breakpoints, 'sm-only')} {
    grid-template-columns: 100px 1fr;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    grid-template-columns: 1fr;
  }

  &_static {
    @extend .grid;
    grid-template-columns: 1fr 1fr;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      grid-template-columns: 1fr;
    }
  }
}

.center_grid {
  ::v-deep *:not(.v-input__control):not(.v-input) {
    align-items: center;
  }
}

.cursor {
  //text-decoration: underline;
  //text-decoration-color: #D4D6E2;
  cursor: pointer;

  &:hover {
    color: $primary !important;
    text-decoration-color: $primary;
  }
}

.brd {
  border-bottom: none !important;
  border-top: none !important;
}

.my-padding {
  padding: 16px 40px !important;
}

.my-padding1 {
  padding: 0 40px 16px !important;
}

.fix {
  font-family: 'Gotham Pro', sans-serif !important;
  line-height: 1.3 !important;
}


.mt-1 {
  margin-top: 4px !important;
}

.ml-1 {
  margin-left: 4px !important;
}

.ml-3 {
  margin-left: 12px !important;
}

.my-5 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mt-8 {
  margin-top: 32px !important;
}

.mb-4 {
  margin-bottom: 16px !important;
}

.mb-5 {
  margin-bottom: 20px !important;
}

.pb-2 {
  padding-bottom: 8px !important;
}

.pb-4 {
  padding-bottom: 16px !important;
}

.pb-6 {
  padding-bottom: 24px !important;
}

.px-10 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.py-4 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.mt-n2 {
  margin-top: -8px !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.print {
  ::v-deep div {
    font-size: 13px !important;
  }

  .title {
    font-size: 24px !important;
    font-weight: 500 !important;
  }

  .subtitle {
    font-size: 18px !important;
  }

  .subtitle1 {
    font-size: 20px !important;
  }

  &_grid {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;
    margin-bottom: 18px;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}


</style>
