<template>
  <div>
<!--    <DefaultPageLanguage/>-->
    <div class="grid">
      <v-sheet>
        <v-card class="border">
          <div class="d-flex align-center pa-4">
            <DevImageBtn :image="data.image" width="100" height="100" contain/>
            <v-input hide-details class="ml-4 font-weight-bold title">
              {{ data.company_name || '-'}}
            </v-input>
            <slot name="save_btn"/>
          </div>
          <v-divider/>
          <div class="pa-4 d-flex align-center justify-space-around">
            <div class="text-center">
              <div class="font12 grey--text text--darken-1">
                {{ t['vat'] }}
              </div>
              <div>
                {{ data.data.vat || '-' }}
              </div>
            </div>
            <div class="text-center">
              <div class="font12 grey--text text--darken-1">
                {{ t['ytunnus'] }}
              </div>
              <div>
                {{ data.ytunnus || '-' }}
              </div>
            </div>
          </div>
          <v-divider/>
          <div class="pa-4">
            <div class="grey--text text--darken-1 mb-3">
              {{ t['electronic_invoice'] }}
            </div>
            <v-divider/>
            <div v-for="(l, i) in list1" :key="i">
              <div class="pa-2">
                <div class="font12 grey--text text--darken-1">
                  {{ t[l] }}
                </div>
                <div>
                  {{ data.data[l] || '-' }}
                </div>
              </div>
              <v-divider/>
            </div>
          </div>
        </v-card>
        <v-card class="mt-4 border pa-4">
          <div class="grid_address">
            <div>
              <div class="grey--text text--darken-1 mb-3">
                {{ t['registration_address'] }}
              </div>
              <div>
                {{ data.e_address.street && data.e_address.zip && data.e_address.city ? ( data.e_address.street + ', ' + data.e_address.zip + ', ' + data.e_address.city ) : '-' }}
              </div>
            </div>
            <v-btn @click="editAddress('e_address')" icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </v-card>
        <v-card class="mt-4 border pa-4">
          <div class="grid_address">
            <div>
              <div class="grey--text text--darken-1 mb-3">
                {{ t['billing_address'] }}
              </div>
              <div>
                {{ data.address.street && data.address.zip && data.address.city ? ( data.address.street + ', ' + data.address.zip + ', ' + data.address.city ) : '-' }}
              </div>
            </div>
            <v-btn @click="editAddress('address')" icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </v-card>
        <v-card class="mt-4 border pa-4">
          <div class="grey--text text--darken-1 mb-3">
            {{ t['registration_data'] }} {{ t['contact'] }}
          </div>
          <v-divider/>
          <div v-for="(l, i) in list2" :key="i">
            <div class="pa-2">
              <div class="font12 grey--text text--darken-1">
                {{ t[l] }}
              </div>
              <div>
                {{ data[l] || '-' }}
              </div>
            </div>
            <v-divider/>
          </div>
        </v-card>
      </v-sheet>
      <v-sheet>
        <slot/>
      </v-sheet>
    </div>
    <v-dialog v-model="edit_address" width="600">
      <PagesClientJurAddressEdit
          v-if="edit_address"
          :item="data.address"
          :on-save="saveData"
          :on-close="closeModal"
          :text="field === 'address' ? t['billing_address'] : t['registration_address']"
          :repeat_text="field === 'address' ? t['use_for_reg_address'] : t['use_for_address']"
      />
    </v-dialog>
  </div>
</template>

<script>
import DefaultPageLanguage from "components/default/page/DefaultPageLanguage";
import DevImageBtn from "components/dev/DevImageBtn";
import Icon from "components/svg/Icon";
import PagesClientJurAddressEdit from "components/pages/clients/PagesClientJurAddressEdit";

export default {
  name: "PagesClientJurId",
  components: {PagesClientJurAddressEdit, Icon, DevImageBtn, DefaultPageLanguage},
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    onSave: Function,
    onItem: {}
  },
  data: () => ({
    list1: ['ovt', 'operator_id', 'operator'],
    list2: ['email', 'first_name', 'last_name', 'phone'],
    edit_address: false,
    field: ''
  }),
  computed: {
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['page_toolbar'],
          this.$store.state.t[this.$route.params.language]['fields'],
          this.$store.state.t[this.$route.params.language]['buttons'],
          this.$store.state.t[this.$route.params.language]['form']
      )
    }
  },
  methods: {
    editAddress(field) {
      this.edit_address = true
      this.field = field
    },

    closeModal() {
      this.edit_address = false
    },

    async saveData(data, repeat) {
      console.log(data)
      console.log(repeat)

      let saveData = {}
      saveData.id = this.data.id
      saveData.email = this.data.email
      saveData.first_name = this.data.first_name
      saveData.last_name = this.data.last_name
      saveData.role_id = this.data.role_id

      if (this.field === 'address') {
        saveData.address = JSON.stringify(data)
        if (repeat) {
          saveData.e_address = JSON.stringify(data)
        }
      }
      if (this.field === 'e_address') {
        saveData.e_address = JSON.stringify(data)
        if (repeat) {
          saveData.address = JSON.stringify(data)
        }
      }

      await this.onSave(saveData)
      this.onItem()
      this.closeModal()
    }

    // async editAddress() {
    //   if (this.edit_address) {
    //     let saveData = {}
    //     saveData.id = this.data.id
    //     saveData.address = this.data.address
    //     saveData.first_name = this.data.first_name
    //     saveData.last_name = this.data.last_name
    //     saveData.role_id = this.data.role_id
    //     await this.onSave(saveData)
    //     this.edit_address = !this.edit_address
    //   } else this.edit_address = !this.edit_address
    // },
    //
    // async editEAddress() {
    //   if (this.edit_e_address) {
    //     let saveData = {}
    //     saveData.id = this.data.id
    //     saveData.address = this.data.address
    //     saveData.first_name = this.data.first_name
    //     saveData.last_name = this.data.last_name
    //     saveData.role_id = this.data.role_id
    //     await this.onSave(saveData)
    //     this.edit_e_address = !this.edit_e_address
    //   } else this.edit_e_address = !this.edit_e_address
    // },

  }
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 2fr;
  grid-gap: 12px;

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    grid-template-columns: 1fr;
  }

  &_address {
    @extend .grid;
    grid-template-columns: 1fr auto;
    align-items: center;
  }
}
</style>
