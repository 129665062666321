<template>
  <svg width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.2305 8.56396L14.2473 8.60104L14.2642 8.56396H14.2305Z" fill="#2F313D"/>
    <path
        d="M25 0L24.8307 0.360623L23.1338 3.95506L22.2552 5.81379L22.0155 6.31765L21.6298 7.14001L20.5399 9.45036L18.5329 13.6986H16.8545L17.4531 12.4263L17.599 12.1146L17.6157 12.0741L17.6996 11.9039V11.8938L19.2455 8.56396L19.9078 7.14001L20.1006 6.72883L20.2935 6.31765L20.716 5.4262L20.8585 5.12119L20.9457 4.93414L21.5711 3.60623L21.717 3.29617L22.1597 2.36596L23.0433 0.488695L23.273 0H25Z"
        fill="#2F313D"/>
    <path
        d="M13.6772 8.56396L13.694 8.60104L13.7107 8.56396H13.6772ZM12.7617 7.14001V7.15686L12.7684 7.14001H12.7617ZM19.9867 0V0.0674062L20.0169 0H19.9867Z"
        fill="#2F313D"/>
    <path d="M17.1463 11.8938V11.9039L17.0625 12.0741L17.1463 11.8938Z" fill="#2F313D"/>
    <path
        d="M11.4219 0L11.1469 0.59823L10.285 2.45696L9.73675 3.64331L8.89504 5.46159L8.81455 5.63685L8.04661 7.29504L7.84708 7.72813L7.05231 9.44699L7.00201 9.55147L2.41113 0H4.16834L6.99866 5.94692L7.18813 5.51215L8.01979 3.61972L8.81455 1.8048L9.3662 0.544305L9.60597 0H11.4219Z"
        fill="#25C565"/>
    <path d="M12.7617 7.14001H12.7687L12.7617 7.15754V7.14001Z" fill="#2F313D"/>
    <path d="M13.7105 8.56396L13.6936 8.60104L13.6768 8.56396H13.7105Z" fill="#2F313D"/>
    <path d="M1.6499 0H0L5.76794 11.9949L5.97418 11.5467L6.09323 11.2855L6.52918 10.3536L6.57613 10.2491L1.6499 0Z"
          fill="#25C565"/>
    <path
        d="M14.6761 0V0.0202219L14.4296 0.540935L13.546 2.41988L13.1067 3.35009L12.9608 3.66016L12.2449 5.17511L12.1023 5.47844L10.8347 8.1629L10.3887 9.10995L9.98295 9.96938L9.77671 10.4058V10.4092L9.4682 11.0614L8.92829 12.2022L8.22406 13.6986H6.52051L6.63285 13.4593L6.7804 13.1476L7.2197 12.2191L7.27168 12.1078L7.29516 12.0539L7.30522 12.0741L8.07316 10.4193L8.12011 10.3199L8.91487 8.60441L9.11776 8.16627L9.4682 7.41806L9.96618 6.34124L10.3887 5.43631L11.3629 3.33998L12.1023 1.74919L12.2214 1.48799L12.6573 0.554416L12.9139 0H14.6761Z"
        fill="#2F313D"/>
    <path d="M12.7687 7.14001L12.7617 7.15754V7.14001H12.7687Z" fill="#2F313D"/>
    <path d="M12.7687 7.14001L12.7617 7.15754V7.14001H12.7687Z" fill="#2F313D"/>
    <path d="M12.7617 7.14001H12.7687L12.7617 7.15754V7.14001Z" fill="#2F313D"/>
    <path d="M13.7105 8.56396L13.6936 8.60104L13.6768 8.56396H13.7105Z" fill="#2F313D"/>
    <path
        d="M22.0302 0L21.901 0.281421L20.1841 3.98876L19.7481 4.93414L19.1059 6.31765H13.3564L13.7522 5.47844L13.8947 5.17511L13.9349 5.08749L14.007 4.93414L14.3742 4.15897L14.609 3.66016L14.7565 3.35009L15.1958 2.41988L16.0795 0.540935L16.3259 0.0202219V0H17.9389V0.0202219L17.6925 0.540935L16.8088 2.41988L16.3695 3.35009L16.2236 3.66016L15.6217 4.93414H18.0496L20.1841 0.0674063L20.2143 0H22.0302Z"
        fill="#2F313D"/>
    <path
        d="M9.89258 13.6986L10.0217 13.4189L11.7387 9.71155L12.1746 8.76449L12.8151 7.38098H18.5663L18.1706 8.22019L18.0281 8.5252L17.9878 8.61115L17.9157 8.76449L17.5485 9.54135L17.3121 10.0385L17.1662 10.3502L16.7269 11.2787L15.8433 13.1577L15.5968 13.6784V13.6986H13.9838V13.6784L14.2303 13.1577L15.1139 11.2787L15.5532 10.3502L15.6991 10.0385L16.301 8.76449H13.8731L11.7387 13.6329L11.7085 13.6986H9.89258Z"
        fill="#2F313D"/>
    <path d="M17.1463 11.8938V11.9039L17.0625 12.0741L17.1463 11.8938Z" fill="#2F313D"/>
  </svg>
</template>

<script>
export default {
  name: "LogoDoc"
}
</script>

<style scoped>

</style>
