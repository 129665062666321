<template>
  <div>
    <PagesClientJurId v-if="item.id" :data="item" :on-save="saveItem" :on-item="getItem">
      <v-btn @click="editItem" slot="save_btn" class="primary" fab>
        <v-icon class="min">mdi-pencil</v-icon>
      </v-btn>
      <div>
        <v-row no-gutters align="center" justify="center" justify-md="start" class="cont">
          <v-btn
              v-for="(item, i) in pages"
              :key="i"
              @click="goToPage(item)"
              :class="$route.name === item ? 'btn_active' : 'btn' "
              depressed
              tile
              width="230"
          >
            {{t[item]}}
          </v-btn>
        </v-row>
        <router-view/>
      </div>
    </PagesClientJurId>
    <v-dialog v-model="modalEditItem" :width="$vuetify.breakpoint.mdAndDown ? 600 : 1200">
      <PagesClientJurNew v-if="modalEditItem" :on-item="getItem" :on-save="saveItem" :on-close="closeModals"/>
    </v-dialog>
  </div>
</template>

<script>
import PagesClientJurId from "components/pages/clients/PagesClientJurId";
import {mapActions} from "vuex";
import PagesClientJurNew from "components/pages/clients/PagesClientJurNew";

export default {
  name: "jur_id",
  components: {PagesClientJurNew, PagesClientJurId},
  data: () => ({
    item: {},
    pages: ['jur_address', 'jur_contacts', 'jur_clients'],
    modalEditItem: false,
  }),
  computed: {
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['menu']
      )
    },
  },
  mounted() {
    this.getItem()
  },
  methods:{
    ...mapActions("user", ["onUser", "onUserSave"]),

    closeModals() {
      this.modalEditItem = false
    },

    editItem() {
      this.modalEditItem = true
    },

    async getItem() {
      await this.onUser(this.$route.params.id).then(res => {
        this.item = Object.assign(res.data)
        while (typeof this.item.data === 'string') {
          this.item.data = JSON.parse(this.item.data)
        }
        if (!this.item.data) {
          this.item.data = {}
        } else {
          if (!this.item.data.addresses) {
            this.item.data.addresses = []
          }
          if (!this.item.data.contacts) {
            this.item.data.contacts = {
              emails: [],
              phones: [],
            }
          }
          if (!this.item.data.persons) {
            this.item.data.persons = []
          }
        }
        if (this.item.address) {
          this.item.address = JSON.parse(this.item.address)
        } else {
          this.item.address = {}
        }
        if (this.item.e_address) {
          this.item.e_address = JSON.parse(this.item.e_address)
        } else {
          this.item.e_address = {}
        }
      })
    },
    saveItem(data) {
      if (typeof data.data === 'object') {
        data.data = JSON.stringify(data.data)
      }
      this.onUserSave(data).then(() => {
        this.getItem()
        this.closeModals()
      })
      console.log(data)
    },
    goToPage(path) {
      this.$router.push({name: path}).catch(() => {
      });
    }
  }
}
</script>


<style scoped lang="scss">
.cont {
  min-height: 45px;
}
.btn {
  margin: 1px !important;
  background-color: $primary !important;
  color: white!important;
  height: 30px !important;
  &_active {
    background-color: white !important;
    color: $primary !important;
    border: 1px solid $primary !important;
    height: 40px !important;
  }
}
</style>
