<template>
  <PagesAuthorizationConfirmEmail restore/>
</template>

<script>
import PagesAuthorizationConfirmEmail from "@/components/pages/authorization/PagesAuthorizationConfirmEmail";

export default {
  name: "confirm_restore",
  components: {PagesAuthorizationConfirmEmail}
}
</script>
