import axios from "axios";

const state = {
    feedbackList: []
};

const getters = {
    feedbackList(state) {
        return state.feedbackList;
    }
};

const mutations = {
    setFeedbackList: (state, item) => {
        state.feedbackList = item;
    }
};

const actions = {
    onFeedbackList({ commit, dispatch }) {
        //commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/feedback?number=" + this.state.number + "&page=" + this.state.page;
            axios
                .get(url)
                .then(response => {
                    commit("setFeedbackList", response.data);
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onFeedback ({commit, dispatch}, id) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/feedback/" + id + "/edit";
            axios
                .get(url)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onDeleteFeedback({commit, dispatch}, id) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/feedback/" + id;
            axios
                .delete(url)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
