<template>
  <div>
    <svg v-if="name === 'order_status'" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 45 45" fill="none">
      <path d="M34.7921 24.6601V0H23.8237V14.1335L17.3961 10.4225L10.9685 14.1335V0H0V34.7921H24.6601C25.2669 29.488 29.488 25.2669 34.7921 24.6601ZM4.00447 21.7676H8.88979V24.4043H4.00447V21.7676ZM13.7746 30.6236H4.00447V27.9869H13.7747V30.6236H13.7746Z" fill="currentColor"/>
      <path d="M21.1871 9.56654V0H13.6052V9.56654L17.3962 7.37789L21.1871 9.56654Z" fill="currentColor"/>
      <path d="M37.429 24.6605C38.9232 24.8314 40.3317 25.2884 41.5975 25.9764V6.80566H37.429V24.6605Z" fill="#5FE796"/>
      <path d="M36.1107 44.9998C41.0124 44.9998 45.0002 41.0119 45.0002 36.1102C45.0002 31.2086 41.0124 27.2207 36.1107 27.2207C31.2091 27.2207 27.2212 31.2086 27.2212 36.1102C27.2212 41.0119 31.2091 44.9998 36.1107 44.9998ZM32.3521 34.3569L34.6993 36.704L39.6665 31.7369L41.5309 33.6013L34.6993 40.433L30.4877 36.2213L32.3521 34.3569Z" fill="currentColor"/>
      <path d="M24.66 37.4287H6.80518V41.5973H25.976C25.2879 40.3315 24.8309 38.9229 24.66 37.4287Z" fill="#5FE796"/>
    </svg>
    <svg v-else-if="name === 'control'" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 40 45" fill="none">
      <g clip-path="url(#clip0)">
        <path d="M28.5355 4.59766V9.19501H10.3213V4.59766H4.62939V39.2311H34.2275V4.59766H28.5355ZM11.4597 35.0329L8.37773 31.922L9.98816 30.2961L11.4597 31.7826L15.208 27.9974L16.8185 29.6234L11.4597 35.0329ZM11.4597 28.1369L8.37773 25.026L9.98816 23.4001L11.4597 24.8866L15.208 21.1014L16.8185 22.7273L11.4597 28.1369ZM11.4597 21.2409L8.37773 18.13L9.98816 16.504L11.4597 17.9905L15.208 14.2054L16.8185 15.8313L11.4597 21.2409ZM29.6739 32.2584H18.29V29.9597H29.6739V32.2584ZM29.6739 25.3624H18.29V23.0637H29.6739V25.3624ZM29.6739 18.4663H18.29V16.1677H29.6739V18.4663Z" fill="currentColor"/>
        <path d="M21.7052 2.29868C21.7052 1.02912 20.686 0 19.4285 0C18.171 0 17.1517 1.02904 17.1517 2.29868C16.6008 2.29868 13.1527 2.29868 12.5981 2.29868V6.89603H26.2588C26.2588 6.33983 26.2588 2.85856 26.2588 2.29868C25.7079 2.29868 22.2598 2.29868 21.7052 2.29868Z" fill="currentColor"/>
      </g>
      <rect x="36.5715" y="13.8467" width="3.42856" height="27.6923" fill="#5FE796"/>
      <rect x="12.5715" y="45" width="3.46154" height="27.4284" transform="rotate(-90 12.5715 45)" fill="#5FE796"/>
      <defs>
        <clipPath id="clip0">
          <rect width="38.857" height="39.2308" fill="currentColor"/>
        </clipPath>
      </defs>
    </svg>
    <svg v-else-if="name === 'interface'" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 48 45" fill="none">
      <g clip-path="url(#clip0)">
        <path d="M40.8935 2.71484H34.7224V10.1201H42.1277V3.94905C42.1277 3.26744 41.5751 2.71484 40.8935 2.71484Z" fill="currentColor"/>
        <path d="M32.254 2.71484H1.23421C0.552596 2.71484 0 3.26744 0 3.94905V10.1201H32.254V2.71484Z" fill="currentColor"/>
        <path d="M9.05078 21.0635H16.456V28.4687H9.05078V21.0635Z" fill="currentColor"/>
        <path d="M0 38.1781C0 38.8597 0.552596 39.4123 1.23421 39.4123H40.8935C41.5751 39.4123 42.1277 38.8597 42.1277 38.1781V12.5889H0V38.1781ZM29.7033 18.5953H34.311C34.9926 18.5953 35.5452 19.1479 35.5452 19.8296C35.5452 20.5112 34.9926 21.0638 34.311 21.0638H29.7033C29.0217 21.0638 28.4691 20.5112 28.4691 19.8296C28.4691 19.1479 29.0217 18.5953 29.7033 18.5953ZM27.2349 23.5322H34.311C34.9926 23.5322 35.5452 24.0848 35.5452 24.7664C35.5452 25.448 34.9926 26.0006 34.311 26.0006H27.2349C26.5533 26.0006 26.0007 25.448 26.0007 24.7664C26.0007 24.0848 26.5533 23.5322 27.2349 23.5322ZM27.2349 28.469H34.311C34.9926 28.469 35.5452 29.0216 35.5452 29.7032C35.5452 30.3848 34.9926 30.9374 34.311 30.9374H27.2349C26.5533 30.9374 26.0007 30.3848 26.0007 29.7032C26.0007 29.0216 26.5533 28.469 27.2349 28.469ZM6.58245 19.8296C6.58245 19.1479 7.13504 18.5953 7.81666 18.5953H17.6903C18.3719 18.5953 18.9245 19.1479 18.9245 19.8296V29.7032C18.9245 30.3848 18.3719 30.9374 17.6903 30.9374H7.81666C7.13504 30.9374 6.58245 30.3848 6.58245 29.7032V19.8296Z" fill="currentColor"/>
      </g>
      <rect x="44.0427" y="15.3193" width="3.82979" height="25.8511" fill="#5FE796"/>
      <rect x="18.1917" y="45" width="3.82979" height="29.6809" transform="rotate(-90 18.1917 45)" fill="#5FE796"/>
      <defs>
        <clipPath id="clip0">
          <rect width="42.1277" height="42.1277" fill="currentColor"/>
        </clipPath>
      </defs>
    </svg>
    <svg v-else-if="name === 'languages'" xmlns="http://www.w3.org/2000/svg"
          :width="size" :height="size"
          viewBox="0 0 24 24">
      <path opacity=".3" d="M5.08 8h2.95c.32-1.25.78-2.45 1.38-3.56c-1.84.63-3.37 1.9-4.33 3.56zm2.42 4c0-.68.06-1.34.14-2H4.26c-.16.64-.26 1.31-.26 2s.1 1.36.26 2h3.38c-.08-.66-.14-1.32-.14-2zm-2.42 4a7.987 7.987 0 0 0 4.33 3.56A15.65 15.65 0 0 1 8.03 16H5.08zM12 4.04c-.83 1.2-1.48 2.53-1.91 3.96h3.82c-.43-1.43-1.08-2.76-1.91-3.96zM18.92 8a8.03 8.03 0 0 0-4.33-3.56c.6 1.11 1.06 2.31 1.38 3.56h2.95zM12 19.96c.83-1.2 1.48-2.53 1.91-3.96h-3.82c.43 1.43 1.08 2.76 1.91 3.96zm2.59-.4A8.03 8.03 0 0 0 18.92 16h-2.95a15.65 15.65 0 0 1-1.38 3.56zM19.74 10h-3.38c.08.66.14 1.32.14 2s-.06 1.34-.14 2h3.38c.16-.64.26-1.31.26-2s-.1-1.36-.26-2zM9.66 10c-.09.65-.16 1.32-.16 2s.07 1.34.16 2h4.68c.09-.66.16-1.32.16-2s-.07-1.35-.16-2H9.66z" fill="currentColor"/>
      <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95a15.65 15.65 0 0 0-1.38-3.56A8.03 8.03 0 0 1 18.92 8zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56A7.987 7.987 0 0 1 5.08 16zm2.95-8H5.08a7.987 7.987 0 0 1 4.33-3.56A15.65 15.65 0 0 0 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2s.07-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 0 1-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z" fill="currentColor"/>
    </svg>
    <svg v-else-if="name === 'roles'" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 45 45" fill="none">
      <path d="M44.9628 41.3501C44.9361 41.1677 44.8933 40.9961 44.8346 40.8298C44.4126 39.1993 43.4457 36.2278 43.0077 34.8976C42.9596 34.667 42.8848 34.4417 42.7886 34.2325C42.7886 34.2271 42.7886 34.2271 42.7886 34.2271C42.5215 33.6479 42.0835 33.1651 41.544 32.8433C41.5119 32.8218 41.4425 32.7842 41.3517 32.736C41.3142 32.7199 41.2769 32.6985 41.2394 32.6824C40.1765 32.1782 36.9125 30.8694 36.9125 30.8694C35.2779 30.1615 33.5899 29.5553 31.8645 28.9385C31.5547 30.2258 28.6327 38.7702 28.168 39.9555L27.7994 32.0172C27.9223 31.8295 28.0077 31.615 28.1039 31.4111L29.3218 28.9921C28.4618 29.7108 27.1745 30.1667 25.7374 30.1667C24.3325 30.1667 23.0719 29.7322 22.2118 29.0403L23.4084 31.4111C23.5046 31.6149 23.59 31.8295 23.7129 32.0172L23.3443 39.9554C22.8742 38.7701 17.9657 32.1566 17.6612 30.8694C15.9358 31.4862 16.229 30.156 14.5944 30.864C14.5944 30.864 11.3305 32.1781 10.2675 32.6769C10.2301 32.693 10.1927 32.7091 10.1553 32.7306C10.0645 32.7735 9.99505 32.811 9.963 32.8378C9.42348 33.1651 8.98545 33.6478 8.71836 34.227V34.2324C8.6222 34.4416 8.54742 34.6669 8.49934 34.8975C8.06131 36.2277 7.09444 39.1938 6.67243 40.8297C6.61367 40.996 6.57094 41.173 6.54423 41.35C6.51752 41.5055 6.50684 41.6718 6.50684 41.8327C6.50684 43.5813 7.91708 44.9973 9.65852 44.9973H12.666C16.9715 44.9973 21.277 45.0027 25.5825 44.9973C25.6413 44.9973 25.6947 44.9973 25.7535 44.9973C25.8122 44.9973 25.8657 44.9973 25.9244 44.9973C30.2299 44.9973 34.5354 44.9973 38.841 44.9973H41.8484C43.5899 44.9973 45.0001 43.5813 45.0001 41.8327C45.0001 41.6719 44.9841 41.5057 44.9628 41.3501Z" fill="#5FE796"/>
<!--      <path d="M43.1036 39.4835C43.0769 39.3011 43.0341 39.1295 42.9754 38.9632C42.5534 37.3327 41.5866 34.3611 41.1486 33.0309C41.1005 32.8003 41.0257 32.575 40.9295 32.3658C40.9295 32.3605 40.9295 32.3605 40.9295 32.3605C40.6624 31.7812 40.2244 31.2985 39.6849 30.9766C39.6528 30.9552 39.5834 30.9176 39.4926 30.8694C39.4551 30.8532 39.4178 30.8318 39.3803 30.8157C38.3174 30.3115 35.0534 29.0028 35.0534 29.0028C33.4188 28.2948 31.7308 27.6886 30.0054 27.0718C29.6956 28.3591 26.7735 36.9035 26.3089 38.0888L25.9403 30.1506C26.0632 29.9629 26.1486 29.7483 26.2448 29.5445L27.4627 27.1254C26.6027 27.8441 25.3153 28.3001 23.8783 28.3001C22.4734 28.3001 21.2128 27.8656 20.3527 27.1737L21.5493 29.5444C21.6454 29.7483 21.7309 29.9628 21.8538 30.1505L21.4852 38.0888C21.0151 36.9034 18.0878 28.3537 17.7833 27.0664C16.0579 27.6832 14.3698 28.2893 12.7352 28.9973C12.7352 28.9973 9.47138 30.3114 8.40836 30.8103C8.37097 30.8264 8.33357 30.8425 8.29618 30.8639C8.20537 30.9068 8.13592 30.9444 8.10387 30.9712C7.56435 31.2984 7.12632 31.7811 6.85923 32.3604V32.3657C6.76307 32.5749 6.68829 32.8002 6.64021 33.0308C6.20218 34.361 5.23531 37.3271 4.8133 38.9631C4.75454 39.1294 4.71181 39.3063 4.6851 39.4833C4.65839 39.6389 4.64771 39.8052 4.64771 39.9661C4.64771 41.7146 6.05795 43.1307 7.79939 43.1307H10.8068C15.1124 43.1307 19.4179 43.136 23.7234 43.1307C23.7822 43.1307 23.8356 43.1307 23.8943 43.1307C23.9531 43.1307 24.0065 43.1307 24.0653 43.1307C28.3708 43.1307 32.6763 43.1307 36.9819 43.1307H39.9893C41.7307 43.1307 43.141 41.7146 43.141 39.9661C43.141 39.8053 43.125 39.639 43.1036 39.4835Z" fill="white"/>-->
      <path d="M40.1237 38.3506C40.097 38.1683 40.0542 37.9966 39.9955 37.8304C39.5735 36.1999 38.6066 33.2283 38.1686 31.8981C38.1205 31.6675 38.0457 31.4422 37.9495 31.233C37.9495 31.2277 37.9495 31.2277 37.9495 31.2277C37.6824 30.6484 37.2444 30.1657 36.7049 29.8438C36.6728 29.8224 36.6034 29.7848 36.5126 29.7366C36.4751 29.7204 36.4378 29.699 36.4003 29.6829C35.3374 29.1787 32.0734 27.8699 32.0734 27.8699C30.4388 27.162 28.7508 26.5558 27.0254 25.939C26.7156 27.2263 23.7936 35.7707 23.3289 36.956L22.9603 29.0178C23.0832 28.83 23.1686 28.6155 23.2648 28.4117L24.4827 25.9926C23.6227 26.7113 22.3353 27.1672 20.8983 27.1672C19.4934 27.1672 18.2328 26.7328 17.3727 26.0409L18.5693 28.4116C18.6654 28.6154 18.7509 28.83 18.8738 29.0177L18.5052 36.956C18.0351 35.7706 15.1078 27.2209 14.8033 25.9336C13.0779 26.5504 11.3899 27.1565 9.75526 27.8645C9.75526 27.8645 6.4914 29.1786 5.42838 29.6774C5.39098 29.6935 5.35359 29.7097 5.3162 29.7311C5.22539 29.774 5.15594 29.8115 5.12389 29.8384C4.58437 30.1656 4.14634 30.6483 3.87925 31.2276V31.2329C3.78309 31.4421 3.70831 31.6674 3.66023 31.898C3.2222 33.2282 2.25533 36.1943 1.83332 37.8303C1.77456 37.9966 1.73183 38.1735 1.70512 38.3505C1.67841 38.5061 1.66772 38.6724 1.66772 38.8333C1.66772 40.5818 3.07797 41.9978 4.81941 41.9978H7.82686C12.1324 41.9978 16.4379 42.0032 20.7434 41.9978C20.8022 41.9978 20.8556 41.9978 20.9144 41.9978C20.9731 41.9978 21.0265 41.9978 21.0853 41.9978C25.3908 41.9978 29.6963 41.9978 34.0019 41.9978H37.0093C38.7508 41.9978 40.161 40.5818 40.161 38.8333C40.161 38.6725 40.145 38.5062 40.1237 38.3506Z" fill="currentColor"/>
      <path d="M29.1409 11.2369C29.1409 10.6791 29.2478 8.65163 29.2478 8.18499C29.2531 3.6634 25.5992 0 21.096 0C21.0907 0 21.0854 0 21.08 0C21.0747 0 21.0693 0 21.064 0C21.0105 0 20.9625 0 20.909 0C20.8556 0 20.8076 0 20.7542 0C20.7488 0 20.7435 0 20.7381 0C20.7328 0 20.7275 0 20.7221 0C16.2189 0 12.5651 3.66876 12.5651 8.19035C12.5651 8.65699 12.6719 10.6791 12.6719 11.2423C12.4743 11.253 10.6474 10.8132 10.845 13.0069C11.2617 17.6626 13.0298 16.7615 13.0832 16.7615C13.954 19.5667 15.3054 21.3582 16.6302 22.5007C18.6975 24.2814 20.7007 24.4745 20.738 24.4745C20.7969 24.4745 20.8503 24.4799 20.909 24.4799C20.9624 24.4799 21.0212 24.4799 21.08 24.4745C21.112 24.4745 23.1045 24.2814 25.1611 22.5222C26.4912 21.3851 27.8533 19.5882 28.7294 16.7616C28.7829 16.7616 30.551 17.6627 30.9677 13.007C31.1654 10.8078 29.3385 11.2477 29.1409 11.2369Z" fill="currentColor"/>
    </svg>
    <svg v-else-if="name === 'users'" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 41 45" fill="none">
      <path d="M40.9972 40.279C40.9309 38.3322 40.7042 36.3767 40.3665 34.4602C39.9574 32.1387 39.4367 28.7352 37.4221 27.2223C36.2569 26.3475 34.7482 26.0605 33.4144 25.533C32.7652 25.2764 32.1836 25.0215 31.6378 24.7314C29.7956 26.7639 27.3935 27.8271 24.5961 27.8272C21.7995 27.8272 19.3977 26.764 17.5557 24.7314C17.0099 25.0216 16.4283 25.2765 15.7791 25.533C14.4453 26.0605 12.9366 26.3474 11.7716 27.2223C9.75678 28.7352 9.23615 32.1387 8.82711 34.4602C8.48937 36.3767 8.26256 38.3322 8.19635 40.279C8.14507 41.7868 8.88508 41.9981 10.1389 42.4534C11.709 43.023 13.3299 43.4459 14.9617 43.7926C18.1133 44.4621 21.3619 44.9768 24.5966 44.9998C27.8313 44.9767 31.0799 44.4621 34.2314 43.7926C35.8632 43.4461 37.4843 43.0232 39.0543 42.4534C40.3086 41.9983 41.0485 41.7869 40.9972 40.279Z" fill="currentColor"/>
      <path d="M39.1767 38.4469C39.1104 36.5002 38.8836 34.5446 38.546 32.6282C38.1369 30.3066 37.6162 26.9032 35.6015 25.3902C34.4364 24.5155 32.9276 24.2285 31.5938 23.701C30.9446 23.4444 30.3631 23.1895 29.8173 22.8994C27.9751 24.9318 25.5729 25.995 22.7756 25.9952C19.979 25.9952 17.5771 24.932 15.7351 22.8994C15.1893 23.1896 14.6077 23.4445 13.9586 23.701C12.6248 24.2285 11.116 24.5154 9.95102 25.3902C7.93622 26.9032 7.41559 30.3066 7.00655 32.6282C6.66881 34.5446 6.442 36.5002 6.37579 38.4469C6.32451 39.9548 7.06453 40.1661 8.31835 40.6213C9.88842 41.191 11.5094 41.6139 13.1412 41.9606C16.2928 42.6301 19.5414 43.1447 22.776 43.1678C26.0107 43.1447 29.2593 42.6301 32.4109 41.9606C34.0427 41.614 35.6638 41.1911 37.2337 40.6213C38.488 40.1662 39.2279 39.9549 39.1767 38.4469Z" fill="#96f2bb"/>
      <g clip-path="url(#clip0)">
        <path d="M20.4856 21.9559C26.5484 21.9556 28.7297 15.8483 29.3009 10.8316C30.0044 4.65133 27.0997 0 20.4856 0C13.8726 0 10.9663 4.65099 11.6703 10.8315C12.242 15.8482 14.4227 21.9564 20.4856 21.9559Z" fill="currentColor"/>
        <path d="M36.8859 36.4987C36.8196 34.552 36.5929 32.5964 36.2552 30.68C35.8461 28.3584 35.3254 24.9549 33.3107 23.442C32.1456 22.5672 30.6369 22.2802 29.303 21.7527C28.6539 21.4961 28.0723 21.2413 27.5265 20.9512C25.6843 22.9836 23.2822 24.0468 20.4848 24.0469C17.6882 24.0469 15.2863 22.9837 13.4443 20.9512C12.8985 21.2413 12.317 21.4963 11.6678 21.7527C10.334 22.2802 8.82523 22.5671 7.66024 23.442C5.64545 24.9549 5.12482 28.3584 4.71578 30.68C4.37804 32.5964 4.15123 34.552 4.08502 36.4987C4.03374 38.0065 4.77376 38.2179 6.02758 38.6731C7.59765 39.2428 9.2186 39.6657 10.8504 40.0123C14.002 40.6819 17.2506 41.1965 20.4853 41.2195C23.7199 41.1964 26.9685 40.6819 30.1201 40.0123C31.7519 39.6658 33.373 39.2429 34.9429 38.6731C36.1972 38.218 36.9372 38.0066 36.8859 36.4987Z" fill="currentColor"/>
      </g>
<!--      <defs>-->
<!--        <clipPath id="clip0">-->
<!--          <rect width="40.9706" height="41.2194" fill="white"/>-->
<!--        </clipPath>-->
<!--      </defs>-->
    </svg>
    <svg v-else-if="name === 'orders'" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 1200 1200">
      <path d="M1199.398 403.537l-50.323 288.696c-6.206 31.891-31.615 51.282-60.917 51.646H354.5l-14.566 82.106h699.226c36.818 2.798 61.793 28.88 62.242 62.24c-2.678 36.743-28.758 61.786-62.242 62.242H265.773c-41.827-3.762-66.768-37.103-62.242-74.16l33.107-180.104l-50.323-505.88L43.292 145.3C8.341 131.423-5.924 99.805 2.239 67.167c13.573-34.015 46.096-49.556 78.133-41.053l182.752 58.269c24.62 9.229 38.783 29.382 42.377 52.972l10.594 100.646l829.006 92.7c38.09 8.251 58.769 38.422 54.297 72.836zm-744.126 677.619c0 52.476-42.54 95.017-95.018 95.017c-52.477 0-95.017-42.541-95.017-95.017c0-52.478 42.541-95.019 95.017-95.019c52.477.001 95.018 42.543 95.018 95.019zm567.252 0c0 52.476-42.541 95.017-95.019 95.017c-52.477 0-95.017-42.541-95.017-95.017c0-52.478 42.54-95.019 95.017-95.019c52.478.001 95.019 42.543 95.019 95.019z" fill="currentColor"/>
    </svg>
    <svg v-else-if="name === 'projects'" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 24 24"><path d="M19 4h-4.18a2.988 2.988 0 0 0-5.64 0H5a2.006 2.006 0 0 0-2 2v14a2.006 2.006 0 0 0 2 2h14a2.006 2.006 0 0 0 2-2V6a2.006 2.006 0 0 0-2-2zm-7 0a1 1 0 1 1-1 1a1.003 1.003 0 0 1 1-1zm-2 5l2.79 2.794l2.52-2.52L14 8h4v4l-1.276-1.311l-3.932 3.935L10 11.83l-2.586 2.584L6 13zm9 10H5v-2h14z" fill="currentColor"/></svg>




    <svg v-else xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 16 16" fill="none">
      <path d="M10.75 4.8H4.25V3.2H10.75V4.8ZM10.75 6.4H4.25V8H10.75V6.4ZM14 1.6V11.2L9.125 16H2.625C2.19402 16 1.7807 15.8314 1.47595 15.5314C1.1712 15.2313 1 14.8243 1 14.4V1.6C1 1.17565 1.1712 0.768687 1.47595 0.468629C1.7807 0.168571 2.19402 0 2.625 0H12.375C12.806 0 13.2193 0.168571 13.524 0.468629C13.8288 0.768687 14 1.17565 14 1.6ZM12.375 9.6V1.6H2.625V14.4H7.5V11.2C7.5 10.7757 7.67121 10.3687 7.97595 10.0686C8.2807 9.76857 8.69402 9.6 9.125 9.6H12.375Z" fill="currentColor"/>
    </svg>

  </div>
</template>

<script>
export default {
  name: "Icon",
  props: {
    size: {
      type: [Number, String],
      default: 20
    },
    name: {
      type: String,
      default: ''
    }
  }
}
</script>
