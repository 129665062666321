<template>
  <DefaultPageToolbar
      :total="Number(list.amount)"
      :last_page="list.lastPage"
      :create-text="$store.state.t[this.$route.params.language]['page_toolbar']['add_user']"
      :on-create="openNewItem"
  >
    <DefaultPageTable
        :title="$store.state.t[this.$route.params.language]['menu']['users']"
        :data="list.data"
        :headers="headers"
        :on-delete="askDeleteItem"
        :ask-delete-fields="['title']"
        :on-edit="editItem"
    />
    <v-dialog v-model="modalEditItem" width="900">
      <PagesSettingsUser
          v-if="modalEditItem"
          :on-item="getItem"
          :on-save="saveItem"
          :on-close="closeModals"
          :block="userBlock"
          :unblock="userUnblock"
      />
    </v-dialog>
    <v-dialog v-model="modalNewItem" width="600">
      <PagesSettingsUser v-if="modalNewItem" :on-save="createItem" :on-close="closeModals"/>
    </v-dialog>
    <v-dialog v-model="modalAskDelete" width="600">
      <DefaultPageAskDelete
          text="user"
          :name="name"
          :on-close="closeModals"
          :on-delete="deleteItem"
      />
    </v-dialog>
  </DefaultPageToolbar>
</template>

<script>
import DefaultPageToolbar from "components/default/page/DefaultPageToolbar";
import {mapActions} from "vuex";
import DefaultPageTable from "components/default/page/DefaultPageTable";
import PagesSettingsUser from "components/pages/settings/PagesSettingsUser";
import DefaultPageAskDelete from "components/default/page/DefaultPageAskDelete";
export default {
  name: "users",
  components: {DefaultPageAskDelete, PagesSettingsUser, DefaultPageTable, DefaultPageToolbar},
  data: () => ({
    list: {},
    id: 0,
    name: '',
    modalNewItem: false,
    modalEditItem: false,
    modalAskDelete: false
  }),
  computed: {
    headers() {
      return [
        {
          value: ["first_name", "last_name"],
          text: this.$store.state.t[this.$route.params.language]['form']['first_name'] + ' ' + this.$store.state.t[this.$route.params.language]['form']['last_name']
        },
        {
          value: ["email"],
          text: this.$store.state.t[this.$route.params.language]['form']['email']
        },
        {
          value: ["phone"],
          text: this.$store.state.t[this.$route.params.language]['form']['phone']
        },
        {
          value: ["status"],
          text: this.$store.state.t[this.$route.params.language]['fields']['status']
        },
        {
          value: ["role"],
          text: this.$store.state.t[this.$route.params.language]['fields']['role']
        }
      ]
    },
    user_status() {
      return Object.assign(this.$store.state.t[this.$route.params.language]['user_status'])
    },
    t() {
      return Object.assign(this.$store.state.t[this.$route.params.language]['fields'])
    }
  },
  watch: {
    '$route.query'() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    ...mapActions("user", ["onUserList", "onUser", "onUserDelete", "onUserSave", "onUserBlock", "onUserUnblock"]),
    ...mapActions("userRole", ["onAssignRolesToUser", "onRemoveRolesFromUser"]),
    ...mapActions("auth", ["onAuthRegisterUser"]),
    async getList() {
      this.list = {}
      await this.onUserList({}).then(res => {
        this.list = res.data
        console.log(res)
        for (let i in this.list.data) {
          this.transformList(this.list.data[i]);
        }
      })
    },
    transformList(data) {
      data.status = [{
        value: this.user_status[data.status].title,
        color: this.user_status[data.status].color,
        type: 'btn'
      }]
      if (data.roles[0]) {
        data.role = []
        for (let i in data.roles) {
          data.role.push({
            value: data.roles[i].role_name,
            color: data.roles[i].back_color,
            text: data.roles[i].text_color,
            type: 'btn'
          })
        }
      } else {
        data.role = [{
          value: this.t['no_role'],
          color: '#2C2C2C',
          type: 'btn'
        }]
      }
    },
    closeModals() {
      this.modalNewItem = false;
      this.modalEditItem = false;
      this.modalAskDelete = false;
    },
    openNewItem() {
      this.modalNewItem = true;
    },
    askDeleteItem(id, name) {
      this.id = id;
      this.name = name;
      this.modalAskDelete = true;
    },
    async deleteItem() {
      await this.onUserDelete(this.id).then(() => {
        this.getList()
        this.closeModals()
      })
    },
    editItem(id) {
      this.id = id
      this.modalEditItem = true
    },
    async getItem() {
      console.log('12345678')
      await this.onUser(this.id).then(res => {
        console.log(res)
      })
    },

    async saveItem(data, new_roles, old_roles) {
      //data.data = JSON.stringify({ "vat": "FI24442479", "ovt": "003724442479", "operator": "Open Text Oy", "operator_id": "003708599126", "addresses": [ { "street": "Sörnäisten rantatie 7", "zip": "00530", "city": "Helsinki", "description": "", "id": 2 }, { "street": "Meilahden Liikuntapuisto, Zaidankatu 9", "zip": "00250", "city": "Helsinki", "description": "", "id": 4 }, { "street": "Valimotie 10", "zip": "00380", "city": "Helsinki", "id": 5 } ], "contacts": { "emails": [ { "value": "info@sbrakennus.fi", "description": "MUURAUS", "id": 4 }, { "value": "teras@sbrakennus.fi", "description": "TERÄSRAKENTEET", "id": 5 } ], "phones": [ { "value": "+358 40 051 58 24", "description": "Aleks Salasin", "id": 3 }, { "value": "+358 40 352 33 21", "description": "Ilja Salasin", "id": 4 } ] }, "persons": [ { "email": "aleks.salasin@sbrakennus.fi", "first_name": "Aleks", "last_name": "Salasin", "position": "Työpäällikkö", "id": 1 }, { "email": "ilja.salasin@sbrakennus.fi", "first_name": "Ilja", "last_name": "Salasin", "position": "Työnjohtaja", "id": 2 } ] })
      await this.onUserSave(data).then(() => {
        for (let i in new_roles) {
          if(old_roles.indexOf(new_roles[i]) === -1) {
            this.onAssignRolesToUser({id: data.id, roles: [new_roles[i]]})
          }
        }
        for (let i in old_roles) {
          if(new_roles.indexOf(old_roles[i]) === -1) {
            this.onRemoveRolesFromUser({id: data.id, roles: [old_roles[i]]})
          }
        }
        this.getList()
      })
    },

    async createItem(data) {
      data.send_email = true
      await this.onAuthRegisterUser(data).then(() => {
        this.getList()
        this.closeModals()
      })
    },

    userBlock() {
      this.onUserBlock(this.id)
    },
    userUnblock() {
      this.onUserUnblock(this.id)
    },
  }
}
</script>

<style scoped>

</style>
