<template>
  <DefaultPageModal
    :text="edit ? t['address'] : $store.state.t[this.$route.params.language]['page_toolbar']['add_address']"
    :on-close="onClose"
  >
    <v-form ref="form1" lazy-validation>
      <DevInput
        :text="t['street']"
        v-model="temp.street"
        :rules="[v => !!v || t['required_field']]"
      />
      <DevInput :text="t['zip']" v-model="temp.zip" :rules="[v => !!v || t['required_field']]" />
      <DevInput :text="t['city']" v-model="temp.city" :rules="[v => !!v || t['required_field']]" />
      <DevInput :text="t['description']" v-model="temp.description" />
    </v-form>
    <v-btn slot="btn" @click="saveChange" class="primary">
      {{ edit ? t['save'] : t['add'] }}
    </v-btn>
  </DefaultPageModal>
</template>

<script>
import DefaultPageModal from 'components/default/page/DefaultPageModal'
import DevInput from 'components/dev/DevInput'

export default {
  name: 'PagesClientJurAddressModal',
  components: { DevInput, DefaultPageModal },
  props: {
    item: Object,
    addNew: Function,
    onClose: Function,
    onEdit: Function,
    edit: Boolean,
  },
  data: () => ({
    temp: { street: '' },
  }),
  created() {
    if (this.item) {
      this.temp = Object.assign({}, this.item)
    }
  },
  computed: {
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['form'],
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['buttons'],
      )
    },
  },
  methods: {
    saveChange() {
      if (this.$refs.form1.validate()) {
        if (!this.edit) {
          this.addNew(this.temp)
        } else {
          this.onEdit(this.temp)
        }
      }
    },
  },
}
</script>

<style scoped></style>
