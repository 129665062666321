<template>
  <div>
    <v-dialog v-model="modalNewClient" :width="$vuetify.breakpoint.mdAndDown ? 600 : 1200">
      <PagesClientJurNew
        v-if="modalNewClient"
        :on-save="createClient"
        :on-close="closeClientModal"
      />
    </v-dialog>
    <v-form lazy-validation ref="form" class="grid">
      <v-sheet>
        <v-card class="pa-4 border">
          <DevInput
            v-model="data.data.project_name"
            :text="t['project_name']"
            :rules="[v => !!v || t['required_field']]"
          />
          <DevInput
            v-model="data.user_id"
            :text="t['client']"
            autocomplete
            :items="allUserList.filter(i => i.role_id === 1)"
            :itemText="i => i.last_name + ' ' + i.first_name + ' - ' + i.email"
            :rules="[v => !!v || t['required_field']]"
          />
          <v-btn class="second" @click="modalNewClient = true">
            <v-icon class="ml-n2 mr-2">mdi-plus</v-icon>
            {{ t['add_client'] }}
          </v-btn>
        </v-card>
        <v-card class="pa-4 border mt-5">
          <DevInput
            v-model="data.data.responsible_person"
            :text="t['responsible_person']"
            select
            :items="allUserList.filter(i => i.role_id === 2)"
            :itemText="i => i.last_name + ' ' + i.first_name + ' - ' + i.email"
            :rules="[v => !!v || t['required_field']]"
            class="mb-n4"
          />
        </v-card>
        <v-card class="pa-4 border mt-5">
          <DevInput
            v-model="data.price"
            :text="t['month_price']"
            type="Number"
            :rules="[v => !!v || t['required_field']]"
            class="mb-n4"
          >
            <template slot="append-outer">
              <v-icon class="mt-1">mdi-currency-eur</v-icon>
            </template>
          </DevInput>
        </v-card>
        <v-card class="pa-4 border mt-5">
          <div v-for="(address, key) in data.data.installation_addresses" :key="key">
            <hr class="mb-5" v-if="key !== 0" />
            <DevInput
              v-model="address.value"
              :text="t['product_installation_address']"
              :rules="[v => !!v || t['required_field']]"
            />

            <v-input hide-details>
              {{ t['agreement_date'] }}
            </v-input>
            <div class="grid_date-picker mt-3">
              <DevInput
                v-model="address.start"
                :text="t['date_from']"
                dateTime
                :rules="[v => !!v || t['required_field']]"
                class="mb-4"
              />
              <DevInput
                v-model="address.end"
                :text="t['date_from']"
                dateTime
                :rules="[v => !!v || t['required_field']]"
                class="mb-4"
              />
            </div>
          </div>
          <v-btn class="second" @click="onAddInstallationAddress">
            <v-icon class="ml-n2 mr-2">mdi-plus</v-icon>
            {{ t['add'] }}
          </v-btn>
        </v-card>
      </v-sheet>
      <v-sheet>
        <v-card class="pa-4 border">
          <DevInput
            v-model="data.data.category"
            :text="t['service']"
            select
            :items="serviceCategoryList.data"
            :rules="[v => !!v || t['required_field']]"
          />
          <DevInput
            v-model="data.service_id"
            :text="t['product']"
            autocomplete
            :items="serviceList.data"
            :rules="[v => !!v || t['required_field']]"
            class="mb-n4"
            :disabled="!data.data.category"
          />
        </v-card>
        <v-card class="pa-4 mt-4 border">
          <DevInput
            v-model="data.data.client_contact_person"
            :items="
              data.user_id && allUserList.find(i => i.id === data.user_id).type === 'legal'
                ? allUserList.find(i => i.id === data.user_id).data.persons
                : allUserList
            "
            :itemText="
              i =>
                (i.position ? i.position + ' ' : '') +
                i.last_name +
                ' ' +
                i.first_name +
                ' - ' +
                i.email
            "
            :itemValue="i => i.id"
            :text="t['customer_contact_person']"
            autocomplete
            :rules="[v => !!v || t['required_field']]"
            class="mb-n4"
            :disabled="
              !data.user_id ||
                (data.user_id && allUserList.find(i => i.id === data.user_id).type !== 'legal')
            "
          />
        </v-card>
        <v-card class="pa-4 mt-4 border">
          <DevInput
            v-model="data.data.balance"
            :text="t['balance']"
            :rules="[v => !!v || t['required_field']]"
            type="Number"
            class="mb-n4"
          >
            <template slot="append-outer">
              <v-icon class="mt-1">mdi-currency-eur</v-icon>
            </template>
          </DevInput>
        </v-card>
        <v-card class="pa-4 mt-4 border">
          <DevInput v-model="data.data.note" :text="t['note']" text-area hide-details />
        </v-card>
      </v-sheet>
      <v-sheet>
        <v-card class="pa-4 border">
          <div class="grid_date-picker">
            <DevInput
              v-model="data.data.agreement_date"
              :text="t['agreement_date']"
              dateTime
              :rules="[v => !!v || t['required_field']]"
              class="mb-n4"
            />
            <DevInput
              v-model="data.data.agreement_number"
              :text="t['agreement_number']"
              class="mb-n4"
              :rules="[v => !!v || t['required_field']]"
            />
          </div>
        </v-card>
        <v-card class="pa-4 mt-4 border">
           <DevInput
          select
          v-model="data.location_id"
          :items="locationList.data"
          :text="t['location']"
          :rules="[v => !!v || t['required_field']]"
      />
          <v-input hide-details class="font12">
            {{ t['validity'] }}
          </v-input>
          <div class="grid_date-picker mt-3">
            <DevInput
              v-model="data.start"
              :text="t['date_from']"
              dateTime
              :rules="[v => !!v || t['required_field']]"
            />
            <DevInput
              v-model="data.end"
              :text="t['date_to']"
              dateTime
              :rules="[v => !!v || t['required_field']]"
            />
          </div>
        </v-card>
        <v-card class="pa-4 mt-4 border">
          <DevInput
            v-model="data.data.transportation"
            :text="t['transportation']"
            :items="transportationList"
            :itemText="i => i.text"
            :itemValue="i => i.value"
            select
            :rules="[v => !!v || t['required_field']]"
            class="mb-n4"
          />
        </v-card>
      </v-sheet>
    </v-form>
    <v-btn @click="createClient" class="primary mt-5 mb-10">
      {{ t['confirm'] }}
      <v-icon class="pl-5" style="margin: 0 !important">mdi-arrow-top-right mdi-rotate-45</v-icon>
    </v-btn>
  </div>
</template>

<script>
import PagesClientJurNew from 'components/pages/clients/PagesClientJurNew'
import DevInput from 'components/dev/DevInput'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
export default {
  name: 'PagesOrdersNewProject',
  components: { DevInput, PagesClientJurNew },
  data: () => ({
    data: {
      type: 'project',
      data: {
        installation_addresses: [
          {
            value: null,
            start: null,
            end: null,
          },
        ],
      },
    },
    modalNewClient: false,
    key: 0,
  }),
  created() {
    this.onServiceCategoryList({number: '0'})
    this.onUserList({ number: 0, page: 1 })
    this.onLocationList('0')
  },
  watch: {
    data: {
      deep: true,
      handler() {
        ++this.key
      },
    },
    'data.data.category'() {
      this.data.service_id = null
      this.onServiceList({ category: this.data.data.category, number: 0, page: 1 })
    },
    'data.user_id'() {
      if (this.data.user_id) {
        if (this.allUserList.find(i => i.id === this.data.user_id).type !== 'legal') {
          this.data.data.client_contact_person = this.allUserList.find(
            i => i.id === this.data.user_id,
          ).id
        } else this.data.data.client_contact_person = null
      }
    },
  },
  computed: {
    ...mapGetters(['language']),
    ...mapGetters('service', ['serviceList', 'serviceCategoryList']),
    ...mapGetters('user', ['userList']),
    ...mapGetters("location", ["locationList"]),
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['form'],
        this.$store.state.t[this.$route.params.language]['buttons'],
        this.$store.state.t[this.$route.params.language]['page_toolbar'],
      )
    },
    allUserList() {
      let list = this.userList.data || []
      if (this.userList.data) {
        for (let user of this.userList.data) {
          while (typeof user.data === 'string') {
            user.data = JSON.parse(user.data)
          }
        }
      }
      return list
    },
    transportationList() {
      return [
        { text: 'Клиент', value: 'client' },
        { text: 'Компания', value: 'company' },
      ]
    },
  },
  methods: {
    ...mapActions('order', ['onCheckCode', 'onNewOrder']),
    ...mapActions('service', ['onServiceCategoryList', 'onServiceList']),
    ...mapActions('user', ['onUserList']),
    ...mapActions("location", ["onLocationList"]),
    onAddInstallationAddress() {
      this.data.data.installation_addresses.push({
        value: null,
        start: null,
        end: null,
      })
    },
    createClient() {
      // Сказать Василию что бы убрал location_id из списка обязательных полей
      let dataSend = Object.assign({},this.data)
      const dateFields = ['start', 'end']
      dataSend.language_id = this.language
      for (let dateField of dateFields) {
        if (dataSend[dateField]) {
          dataSend[dateField] = moment(dataSend[dateField]).format('YYYY-MM-DD HH:mm:ss')
        }
      }
      dataSend.data =  JSON.stringify(dataSend.data)
      this.onNewOrder(dataSend)
        .then(result => {this.$router.push({name: 'project_id', params: {id: result.data.id}})})
        .catch(err => {})
    },
    closeClientModal() {
      this.modalNewClient = false
    },
  },
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    grid-template-columns: 1fr;
  }
  @media #{map-get($display-breakpoints, 'xl-only')} {
    grid-template-columns: 1fr 1fr 1.5fr;
  }

  &_date-picker {
    @extend .grid;
    grid-template-columns: 1fr 1fr !important;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      grid-template-columns: 1fr !important;
    }
  }
}
</style>
