<template>
  <div v-else class="d-flex justify-center align-center text-center">
    {{t.welcome}}
  </div>
</template>

<script>
export default {
  name: "welcome",
  computed: {
    t() {
      return Object.assign(this.$store.state.t[this.$route.params.language]['home'])
    }
  }
}
</script>

<style scoped lang="scss">
div {
  height: calc(100vh - 152px);
  font-size: 24px;
  font-weight: 400;
}
</style>
