<template>
  <DefaultPageToolbar
      :create-text="$store.state.t[this.$route.params.language]['page_toolbar']['add_location']"
      :on-create="openNewItem"
      :total="list.amount"
      :last_page="list.lastPage"
  >
    <DefaultPageTable
        :title="$store.state.t[this.$route.params.language]['menu']['locations']"
        :data="list.data"
        :headers="headers"
        :on-delete="askDeleteItem"
        :ask-delete-fields="['name']"
        :on-edit="editItem"
    />
    <v-dialog v-model="modalEdit" width="600">
      <PagesOtherLocationsId
          v-if="modalEdit"
          :data="item"
          :on-close="closeModals"
          :on-save="saveItem"
      />
    </v-dialog>
    <v-dialog v-model="modalNewItem" width="600">
      <PagesOtherLocationsId
          v-if="modalNewItem"
          :data="item"
          :on-close="closeModals"
          :on-save="createItem"
      />
    </v-dialog>
    <v-dialog v-model="modalAskDelete" width="600">
      <DefaultPageAskDelete
          text="location"
          :name="name"
          :on-close="closeModals"
          :on-delete="deleteItem"
      />
    </v-dialog>
  </DefaultPageToolbar>
</template>

<script>
import DefaultPageToolbar from "@/components/default/page/DefaultPageToolbar";
import {mapActions} from "vuex";
import DefaultPageTable from "@/components/default/page/DefaultPageTable";
import PagesOtherLocationsId from "@/components/pages/other/PagesOtherLocationsId";
import DefaultPageAskDelete from "@/components/default/page/DefaultPageAskDelete";

export default {
  name: "locations",
  components: {DefaultPageAskDelete, PagesOtherLocationsId, DefaultPageTable, DefaultPageToolbar},
  data: () => ({
    list: {
      meta: {}
    },
    item: {},
    id: 0,
    name: '',
    modalAskDelete: false,
    modalNewItem: false,
    modalEdit: false
  }),
  computed: {
    headers() {
      return [
        {
          value: ["name"],
          text: this.$store.state.t[this.$route.params.language]['fields']['title']
        },
        {
          value: ["street", "zip", "city"],
          text: this.$store.state.t[this.$route.params.language]['fields']['address']
        }
      ]
    }
  },
  watch: {
    '$route.query'() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    ...mapActions("location", ["onLocationList", "onDeleteLocation", "onLocation", "onSaveLocation", "onCreateLocation"]),
    async getList() {
      this.list = {meta: {}}
      await this.onLocationList().then(res => {
        this.list = res.data;
      })
    },
    closeModals() {
      this.modalAskDelete = false;
      this.modalNewItem = false;
      this.modalEdit = false
    },
    openNewItem() {
      this.item = {}
      this.modalNewItem = true;
    },
    askDeleteItem(id, name) {
      this.id = id;
      this.name = name;
      this.modalAskDelete = true;
    },
    async deleteItem() {
      await this.onDeleteLocation(this.id).then(() => {
        this.getList();
        this.closeModals()
      })
    },
    async editItem(id) {
      this.item = {}
      await this.onLocation(id).then(res => {
        this.item = res.data.data[0]
        this.modalEdit = true
      })
    },
    async saveItem(data) {
      if(data.user) {
        data.user_id = data.user
      }
      await this.onSaveLocation(data).then(() => {
        this.getList();
      })
    },
    createItem(data) {
      this.onCreateLocation(data)
      this.getList();
      this.closeModals()
    }
  }
}
</script>
