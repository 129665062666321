<template>
  <v-sheet width="100vw" height="100vh" color="secondary" class="login">
    <div class="text-center">
      <v-btn text @click="refresh()" :ripple="false" plain>
        <LogoXL/>
      </v-btn>
      <div v-if="!forgot" class="title white--text">
        {{ t.enter }}
      </div>
      <div v-else-if="is_registered !== true" class="title white--text">
        {{ t.recovery_pass }}
      </div>
      <v-form ref="form" lazy-validation>
        <v-card
            v-if="is_registered !== true"
            width="97vw"
            max-width="432"
            light
            class="pa-4"
        >
          <v-input hide-details class="inp">
            {{ t.email }}
          </v-input>
          <v-text-field
              v-model="userInfo.username"
              outlined
              dense
              v-on:keyup.enter="login()"
              validate-on-blur
              :rules="[
              v => !!v || t.required_field,
              v =>
                /.+@.+\..+/.test(v) ||
                t.bad_email,
              is_registered === null || t.email_not_exist
            ]"
          />
          <div v-if="!forgot">
            <v-input hide-details class="inp">
              {{ t.password }}
            </v-input>
            <v-text-field
                v-model="userInfo.password"
                dense
                v-on:keyup.enter="login()"
                outlined
                type="password"
                :rules="[v => !!v || t.required_field, !error_login || t.bad_password]"
            />
            <div class="d-flex justify-space-between align-center">
              <v-checkbox
                  v-model="remember"
                  :label="t.remember_me"
                  hide-details
              />
              <v-btn
                  @click="forgot = true"
                  text
                  class="text-none font-weight-medium"
              >
                {{ t.forgot_password }}
              </v-btn>
            </div>
          </div>
        </v-card>
        <v-btn
            v-if="!forgot"
            @click="login()"
            color="primary"
            class="font-weight-medium mt-4"
            rounded depressed
        >
          {{ t.sign_in }}
        </v-btn>
        <v-btn
            v-else-if="is_registered !== true"
            @click="recovery_pass()"
            color="primary"
            class="font-weight-medium mt-4"
            rounded depressed
        >
          {{ t.send }}
        </v-btn>
      </v-form>
      <div v-if="is_registered === true" class="title">
        <v-icon size="56" class="primary--text"
        >mdi-check-circle-outline
        </v-icon
        >
        <div class="subtitle white--text">
          {{ t.message }}
        </div>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import LogoXL from "components/svg/LogoXL";

export default {
  name: "PagesAuthorizationLogin",
  components: {LogoXL},
  data: () => ({
    is_registered: null,
    remember: false,
    forgot: false,
    error_login: false,
    userInfo: {
      username: "",
      password: "",
      client_id: "1",
      client_secret: "kAUsFYysaEEi82JrFgzgYcU8SJzx8ubRkf7NJj8l",
      grant_type: "password"
    }
  }),
  computed: {
    t() {
      return Object.assign(this.$store.state.t[this.$route.params.language]['login'], this.$store.state.t[this.$route.params.language]['form'])
    },
    ...mapGetters("auth", ["authLoggedIn"]),
    ...mapGetters("auth", ["authCheckEmail"])
  },
  methods: {
    ...mapActions("auth", ["onAuthLogin", "onAuthIsLoggedIn", "onCheckEmail", "onAuthRestorePassword"]),
    refresh() {
      this.is_registered = null;
      this.forgot = false;
      this.remember = false;
      this.userInfo.password = "";
      this.userInfo.username = "";
      this.$refs.form.resetValidation();
    },
    login() {
      if (this.$refs.form.validate()) {
        this.submit(this.userInfo, this.remember);
      }
    },
    async submit(item, remember) {
      await this.onAuthLogin({
        data: item,
        remember: remember
      })
      await this.onAuthIsLoggedIn().then(() => {
        if (this.authLoggedIn) {
          this.$router.push({name: 'home'});
          //document.location.reload(true);
        } else this.error_login = true;
      });
      if (this.error_login === false) {
        document.location.reload(true);
      }
    },
    recovery_pass() {
      if (this.$refs.form.validate()) {
        //this.CheckEmail();
        this.onAuthRestorePassword(this.userInfo.username).then(res => {
          if (res.data.status) {
            this.is_registered = true
            setTimeout(() => {
              this.refresh()
            }, 3000)
          } else {
            this.is_registered = false
          }
        })
      }
    },
    async CheckEmail(email, language_id) {
      await this.onCheckEmail({email, language_id}).then(() => {
        this.is_registered = !!this.authCheckEmail.data;
        setTimeout(() => {
          this.refresh()
        }, 5000);
      })
    }
  }
};
</script>
