<template>
  <DefaultPageModal :on-close="onClose" :text="text">
    <v-form ref="form" lazy-validation>
      <DevInput
          :text="t['street']"
          v-model="street"
          :rules="[v => !!v || t['required_field']]"
      />
      <DevInput
          :text="t['zip']"
          v-model="zip"
          :rules="[v => !!v || t['required_field']]"
      />
      <DevInput
          :text="t['city']"
          v-model="city"
          :rules="[v => !!v || t['required_field']]"
      />
    </v-form>
    <v-checkbox v-model="repeat" :label="repeat_text" hide-details dense/>
    <v-btn @click="saveItem" slot="btn" class="primary">
      {{t['save']}}
    </v-btn>
  </DefaultPageModal>
</template>

<script>
import DefaultPageModal from "components/default/page/DefaultPageModal";
import DevInput from "components/dev/DevInput";

export default {
  name: "PagesClientJurAddressEdit",
  components: {DevInput, DefaultPageModal},
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    text: {
      type: String,
      default: ''
    },
    repeat_text: {
      type: String,
      default: ''
    },
    onSave: {},
    onClose: {}
  },
  computed: {
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['fields'],
          this.$store.state.t[this.$route.params.language]['buttons'],
          this.$store.state.t[this.$route.params.language]['form']
      )
    }
  },
  data: () => ({
    street: '',
    zip: '',
    city: '',
    repeat: false
  }),
  created() {
    this.editItem()
  },
  methods: {
    editItem(){
      if (this.item) {
        this.street = this.item.street
        this.zip = this.item.zip
        this.city = this.item.city
      }
    },
    saveItem() {
      if (this.$refs.form.validate()) {
        this.onSave({
          street: this.street,
          zip: this.zip,
          city: this.city
        }, this.repeat)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.v-input--checkbox {
  ::v-deep label {
    @extend .font12;
    color: black !important;
  }
}
</style>
