import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Axios from "axios"
import "./plugins/vuetify-mask.js"
import "./plugins/vuetify-datetime-picker"

Vue.config.productionTip = true

Vue.prototype.axios = Axios;
const localStorageToken = localStorage.getItem("token");
const sessionStorageToken = sessionStorage.getItem("token");
if (localStorageToken) {
    Vue.prototype.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorageToken;
} else if (sessionStorageToken) {
    Vue.prototype.axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorageToken;
}

new Vue({
    router,
    store,
    vuetify,
    render: function (h) {
        return h(App)
    }
}).$mount('#app')
