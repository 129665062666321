import axios from "axios";

const state = {
    articleCategoryList: [],
    articleCategory: []
};

const getters = {
    articleCategoryList(state) {
        return state.articleCategoryList;
    },
    articleCategory(state) {
        return state.articleCategory;
    }
};

const mutations = {
    setArticleCategoryList: (state, item) => {
        state.articleCategoryList = item;
    },
    setArticleCategory: (state, item) => {
        state.articleCategory = item;
    }
};

const actions = {
    onArticleCategoryList({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/articleCategory?number=" + this.state.number + "&page=" + this.state.page;
            axios
                .get(url)
                .then(response => {
                    commit("setArticleCategoryList", response.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onArticleCategoryCreate({commit, dispatch}, data) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/articleCategory";
            axios
                .post(url, data)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onArticleCategory({commit, dispatch}, id) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/articleCategory/" + id + "/edit?language_id=" + this.state.language;
            axios
                .get(url)
                .then(response => {
                    commit("setArticleCategory", response.data)
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onArticleCategorySave({commit, dispatch}, {id, data}) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/articleCategory/" + id;
            axios
                .put(url, data)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onArticleCategoryDelete({commit, dispatch}, id) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/articleCategory/" + id;
            axios
                .delete(url)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onArticleCategorySaveField({commit}, {id, data}) {
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/articleCategory/" + id + "/field";
            axios
                .put(url, data)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
