<template>
  <DefaultPageMenu name="ord" :routes="Array('orders', 'projects')"/>
</template>

<script>
import DefaultPageMenu from "@/components/default/page/DefaultPageMenu";

export default {
  name: "ord",
  components: {DefaultPageMenu}
}
</script>
