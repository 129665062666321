<template>
  <DefaultPageModal
      :text="t['user']"
      :on-close="onClose"
  >
    <DefaultPageLanguage/>
    <v-form ref="form" lazy-validation :key="key" :class="data.id ? 'grid_user' : ''">
      <div>
        <DevInput
            :text="t['email']"
            v-model="data.email"
            :rules="[v => !!v || t['required_field']]"
        />
        <DevInput
            :text="t['first_name']"
            v-model="data.first_name"
            :rules="[v => !!v || t['required_field']]"
        />
        <DevInput
            :text="t['last_name']"
            v-model="data.last_name"
            :rules="[v => !!v || t['required_field']]"
        />
        <DevInput
            :text="t['phone']"
            v-model="data.phone"
            :rules="[v => !!v || t['required_field']]"
        />
      </div>
      <div v-if="data.id">
        {{t['roles']}}
        <div v-for="(role, r) in roles"
             :key="r">
          <v-checkbox
              v-model="temp"
              :value="role"
              :label="role"
              hide-details
          />
        </div>
      </div>
    </v-form>
    <div v-if="data.id" class="d-flex flex-column align-center justify-center">
      <v-btn v-if="data.status === 'blocked'" @click="unblockItem" text color="#26BC2C" class=" btn_block text-none">
        <LockOpen class="mr-3"/>
        {{t['unblock']}}
      </v-btn>
      <v-btn v-else @click="blockItem" text color="red" class="btn_block text-none">
        <Lock class="mr-3"/>
        {{t['block']}}
      </v-btn>
    </div>
    <v-btn slot="btn" @click="saveItem" class="primary mt-4">
      {{data.id ? t['save'] : t['create']}}
    </v-btn>
  </DefaultPageModal>
</template>

<script>
import DefaultPageModal from "components/default/page/DefaultPageModal";
import {mapActions, mapGetters} from "vuex";
import DevInput from "components/dev/DevInput";
import LockOpen from "components/svg/LockOpen";
import Lock from "components/svg/Lock";
import DefaultPageLanguage from "components/default/page/DefaultPageLanguage";

export default {
  name: "PagesSettingsUser",
  components: {DefaultPageLanguage, Lock, LockOpen, DevInput, DefaultPageModal},
  props: {
    onClose: {},
    onSave: {},
    onItem: {},
    block: {},
    unblock: {},
  },
  data: () => ({
    data: {},
    key: 0,
    image: null,
    deleted_images: [],
    new_images: [],
    load_file: '',
    saved: false,
    temp: [],
    roles: [],
    old_temp: []
  }),
  computed: {
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['page_toolbar'],
          this.$store.state.t[this.$route.params.language]['fields'],
          this.$store.state.t[this.$route.params.language]['buttons'],
          this.$store.state.t[this.$route.params.language]['form'],
      )
    },
    ...mapGetters(["language", "apiDomain", "file"]),
    ...mapGetters("user", ["user"]),
    ...mapGetters("userRole", ["userRoleList"])
  },
  watch: {
    user() {
      this.data = Object.assign(this.user)
      this.data.language_id = this.language
      if (!this.image) {
        this.image = this.data.image
      }
      this.key++
    },
    userRoleList() {
      this.roles = this.userRoleList.data
      for (let i in this.roles) {
        this.roles[i] = this.roles[i].role_name
      }
      for (let i in this.data.roles) {
        this.temp.push(this.data.roles[i].role_name) //в этой переменной меняем роли
        this.old_temp = this.temp //в этой переменной храним изначальные роли для дальнейшего сравнения
      }
      this.key++
    }
  },
  created() {
    this.getItem()
    this.onUserRoleList()
  },
  methods: {
    ...mapActions("userRole", ["onAssignRolesToUser", "onRemoveRolesFromUser", "onUserRoleList"]),

    getItem() {
      if (this.onItem) {
        this.onItem()
      }
    },

    saveItem() {
      if (this.$refs.form.validate()) {
        this.saved = true
        this.data.language_id = this.language
        this.onSave(this.data, this.temp, this.old_temp)
        this.closeModal()
      }
    },

    closeModal() {
      this.saved = false
    },

    unblockItem() {
      this.unblock(this.data.id)
      this.data.status = 'unblocked'
      this.key++
    },

    blockItem() {
      this.block(this.data.id)
      this.data.status = 'blocked'
      this.key++
    }
  }
}
</script>

<style scoped lang="scss">
.grid_user {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 1.5fr;
  grid-gap: 30px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    grid-template-columns: 1fr;
    grid-gap: 0;
    padding-bottom: 30px;
  }
}
</style>
