import axios from "axios";

const state = {
    userRoleAbilities: [],
    userRoleList: []
};

const getters = {
    userRoleAbilities(state) {
        return state.userRoleAbilities;
    },
    userRoleList(state) {
        return state.userRoleList;
    }
};

const mutations = {
    setUserRoleAbilities: (state, item) => {
        state.userRoleAbilities = item;
    },
    setUserRoleList: (state, item) => {
        state.userRoleList = item;
    }
};

const actions = {
    // eslint-disable-next-line no-unused-vars
    async onUserRoleAbilities({commit}){
        return new Promise((resolve, reject) => {
            let url = this.state.apiDomain + "/api/admin/common/ability/list";
            axios.get(url)
                .then(response => {
                    commit("setUserRoleAbilities", response.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    async onUserRoleList({commit}){
        return new Promise((resolve, reject) => {
            let url = this.state.apiDomain + "/api/admin/common/role/list";
            axios.get(url)
                .then(response => {
                    commit("setUserRoleList", response.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    // eslint-disable-next-line no-unused-vars
    async onUserRole({commit}, id){
        return new Promise((resolve, reject) => {
            let url = this.state.apiDomain + "/api/admin/common/role/" + id;
            axios.get(url)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    async onUserRoleSave({commit}, data){
        return new Promise((resolve, reject) => {
            let url = this.state.apiDomain + "/api/admin/common/role?id=" +
                data.id + '&role_name=' + data.role_name + '&back_color=' + data.back_color +
                '&text_color=' + data.text_color
                 ;
            for(let i in data.abilities){
                url = url + '&abilities[]=' + data.abilities[i].ability_name
            }
            axios.put(url)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    async onUserRoleCreate({commit}, data){
        return new Promise((resolve, reject) => {
            let url = this.state.apiDomain + "/api/admin/common/role?role_name=" + data.role_name + '&back_color=' + data.back_color +
                '&text_color=' + data.text_color
            ;
            for(let i in data.abilities){
                url = url + '&abilities[]=' + data.abilities[i].ability_name
            }
            axios.post(url)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    async onUserRoleDelete({commit}, id){
        return new Promise((resolve, reject) => {
            let url = this.state.apiDomain + "/api/admin/common/role/"+ id + "?with_relations=0";
            axios.delete(url)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    async onAssignRolesToUser({commit}, {id, roles}){
        return new Promise((resolve, reject) => {
            let url = this.state.apiDomain + '/api/admin/common/role/user-role?user_id=' + id
            for (let i in roles) {
                url = url + '&roles[]=' + roles[i]
            }
            axios.put(url)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    // eslint-disable-next-line no-unused-vars
    async onRemoveRolesFromUser({commit}, {id, roles}){
        return new Promise((resolve, reject) => {
            let url = this.state.apiDomain + '/api/admin/common/role/user-role?user_id=' + id
            for (let i in roles) {
                url = url + '&roles[]=' + roles[i]
            }
            axios.delete(url)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
